@media only screen and (max-width: 767px) {
  .hamburger-lines {
    left: 5px;
  }
}
.css-h4y409-MuiList-root{
  padding-top: 0px !important;
}
.MuiBox-root.css-k008qs {
  display: block !important;
  margin-right: -18rem !important;
}
.hamburger-lines {
  display: block;
  height: 24px;
  width: 28px;
  position: absolute;
  /* top: 17px; */
  /* left: 20px; */
  z-index: 2852;
  display: flex;
  flex-direction: column;
  gap:8px;
}

.hamburger-lines .line {
  display: block;
  height: 2.5px;
  width: 100%;
  border-radius: 10px;
  background: black;
}
.hamburger-lines .line2 {
    display: block;
    height: 1.5px;
    width: 100%;
    border-radius: 10px;
    background: black;
  }
