/* Vavess Component CSS */
.main-area-wrapper {
    height: 100vh;
    padding: 0;
    background-size: cover;
  }

  .logo img{
    width:100%;
  }
  
  .main-area {
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 0;
    color: #000;
  }
  
  .headers {
    width: 40%;
    text-align: center;
    top: 10%;
    left: 8%;
  }
  
  .headers h1 {
    font-size: 3em;
    line-height: 1.2;
    font-weight: bold;
  }
  
  .headers .logo {
    width: 50%;
    max-width: 300px; /* Adjust the max-width as needed */
    margin: 0 auto;
    margin-bottom: 15px;
  }
  
  .headers h2 {
    font-size: 2em;
    line-height: 1.2;
    font-weight: 500;
    width: 70%;
    margin: 0 auto;
  }
  
  .headers a {
    display: inline-block;
    background: #00a1fe;
    color: #fff;
    padding: 15px 30px;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: bold;
    margin-top: 30px;
  }
  
  .headers a:hover {
    background-color: #000;
  }
  
  .footer {
    width: 40%;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    left: 8%;
    justify-content: space-around;
    text-align: center;
    align-items: center;
  }
  
  .footer .logo {
    width: 200px;
  }
  
  .footer .copy {
    margin-top: 10px;
  }
  
  @media only screen and (max-width: 1440px) {
    .header h1 {
      font-size: 2em;
    }
    .header .logo {
      width: 75%;
    }
    .header h2 {
      font-size: 1.5em;
      width: 100%;
    }
  }
  
  @media only screen and (min-width: 961px) and (max-width: 1200px) {
    .main-area-wrapper {
      background-position: 65%;
    }
    .header,
    .footer {
      left: 3%;
    }
    .header h2 {
      font-size: 1.5em;
    }
  }
  
  @media only screen and (max-width: 960px) {
    .header {
      width: 60%;
      text-align: center;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .footer {
      width: 90%;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      box-sizing: border-box;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .header {
      width: 75%;
    }
    .header .logo {
      width: 100%;
    }
    .main-area-wrapper {
      background-position: 90%;
    }
  }



  