@import url("fontawesome/css/fontawesome-all.min.css");

/* @import url('normalize.css');
@import url('slider.css');
@import url('main.css');
@import url('desktop.css');
@import url('tablet.css');
@import url('mobile.css');
@import url('animate.css'); */

@import url("style-main.min.css");

@media (max-width: 767px) {
  .home-slider1-container .slide1 #home-video {
    width: 100%;
    transform: scale(1.5) translateY(-60px);
  }
  h2 span {
    line-height: 1;
  }
}


.photo-grid-news {
  display: grid;
  gap: 10px;
}

.photo-item-news img {
  width: 100%;
  height: auto;
  display: block;
}

@media (min-width: 768px) {
  /* Desktop view: 2x2 grid */
  .photo-grid-news {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  /* Mobile view: 1x4 grid */
  .photo-grid-news {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
}
