@media (max-width: 767px) {
  

  #seis #h22 {
    font-size: 1.7rem !important;
    width: 20rem !important;
    margin-top: 0px !important;
    filter: none !important;
    /* justify-content: center !important; */
  }
  #seis #its {
    display: flex !important;
    width: auto !important;
    /* width: 100% !important; */
    filter: none !important;
  }
  #seis #its span,
  #seis #its a {
    font-weight: 500;
    filter: none !important;
  }
  #seis #seis-logo img {
    width: 18rem !important;
    position: absolute;
    top: -120px;
    right: -195px;
    filter: none !important;
  }
}
