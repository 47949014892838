.main-area-wrapper{  height: 100vh; padding: 0; background-size: cover; }
.main-area{ position: relative; z-index: 1; height: 100%; padding: 0; color: #fff; }
.viraataheader { width: 25%; text-align: right; position: absolute; top: 100px; right: 200px; }
.viraataheader h2 { color: #000; font-weight: bold; font-size: 42px; text-align: center; margin-top: 15px; }
.viraatafooter { position: absolute; bottom: 30px; width: 95%; left: 0; right: 0; margin: 0 auto; font-size: 20px; color: #333;  } 
.viraatafooter .social { margin-top: 15px; font-size: 22px; }
.viraatafooter .social a { color: #000; }
.ptag { line-height: 1.6; font-size: 16px; font-weight: 400; color: #000; }
.navlink { line-height: 1.6; font-size: 16px; font-weight: 400; color: #000; }
.center-text{ text-align: center; } 
.viraatafooter .socialdiv { margin-top: 15px; font-size: 22px; }
.social-icon { margin: 1px; color: #000; }
@media only screen and (max-width:1920px) { 
    .viraataheader {  width: 30%; right: 100px;  }
    .viraataheader h2 {  font-size: 32px; }
}
@media only screen and (max-width:1200px) { }
@media only screen and (min-width:961px) and (max-width:1200px) { 
    .viraataheader {  width: 40%; right: 100px;  }
    .viraataheader h2 {  font-size: 30px; }
}
@media only screen and (max-width:960px) {
    .viraataheader { width: 50%; margin: 0 auto; position: relative; right: auto; }
    .viraataheader h2 {  font-size: 28px; }
    
}
@media only screen and (max-width:767px) { 
    .viraataheader { width: 75%;  }
    .viraataheader h2 {  font-size: 20px; }

}