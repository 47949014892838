.chatbotContainer {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;
}
#chatbot p {
  opacity: 1 !important;
}
.chatToggleButton {
  background-color: #00a1fe;
  color: white;
  padding: 1rem;
  border-radius: 9999px;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.chatWindow {
  position: absolute;
  bottom: 5rem;
  right: 0;
  width: 24rem;
  height: 28rem;
  background-color: #f3f4f6;
  border-radius: 1rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.chatHeader {
  background-color: #00a1fe;
  color: white;
  padding: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.botAvatar {
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  background-color: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.botInfo {
  margin-left: 0.75rem;
}

.botTitle {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: normal;
}

.botStatus {
  font-size: 0.75rem;
  color: #fff;
  opacity: 1 !important;
  margin-top: 0px !important;
}

.closeButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 9999px;
}

.closeButton:hover {
  background-color: #00a1fe;
}

.messagesContainer {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.messageBox {
  margin-bottom: 1rem;
  max-width: 80%;
}

.botMessage {
  display: flex;
  align-items: flex-start;
}

.botMessageContent {
  background-color: white;
  color: #1f2937;
  padding: 0.75rem;
  border-radius: 1rem;
  border-bottom-left-radius: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 0.5rem;
}

.userMessage {
  background-color: #00a1fe;
  color: white;
  padding: 0.75rem;
  border-radius: 1rem;
  border-bottom-right-radius: 0;
  margin-left: auto;
}

.inputContainer {
  padding: 1rem;
  background-color: white;
  /* border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem; */
  display: flex;
  gap: 0.5rem;
}

.messageInput {
  flex-grow: 1;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 9999px;
  outline: none;
}

.messageInput:focus {
  border-color: #00a1fe;
}

.sendButton {
  background-color: #00a1fe;
  color: white;
  padding: 1rem;
  border-radius: 9999px;
  border: none;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  cursor: pointer;
}

.sendButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.contactFallback {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contactLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00a1fe;
  text-decoration: none;
}

.contactLink:hover {
  text-decoration: underline;
}

.contactButton {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #00a1fe;
  color: white;
  border-radius: 9999px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.contactButton:hover {
  background-color: #0081cb;
}

.loadingContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.licensed img {
  width: 42px;
  height: 20px;
  /* margin-top: 1rem; */
}
.licensed{
  padding-block: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.licensed p{
  transform: none;
  margin-top: 0px;
}