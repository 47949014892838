.slides {
  overflow: hidden;
  margin: -1px;
}
#zidd {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}

#virtuosoship{
    margin-bottom: -1px !important;
}

#traditional{
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}

#experience-jobs{
    margin-top: -1px !important;
    margin-bottom: 0px !important;
}

#it{
    margin-bottom: -1px !important;
}

#rightapps {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#virtuos-exp-design {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#vivacis {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#virtuos-cloud {
    margin-bottom: -2px !important;
    margin-top: -1px !important;
}
#o.digital {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

#vuca {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#business40 {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#traditional {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#all-agile {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#virtuosoship {
    margin-bottom: -2px !important;
    margin-top: -1px !important;
}
#business {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
#digital-transformation {
    margin-bottom: -1px !important;
    margin-top: 0px !important;
}
#robo-code {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#cloud-vlocis {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#digital-dexterity {
    margin-bottom: 0px !important;
    margin-top: -1px !important;
}
#last-slide {
    margin-bottom: 0px !important;
    margin-top: 7px !important;
}
#cx-digital {
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#cx-strategy,#cxnow ,#rainbowcx,#cxdesk,#indicial{
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
#w.digital,#josh ,#okrx,#talentare,#crayonz,#culturo{
    margin-bottom: -1px !important;
    margin-top: -1px !important;
}
