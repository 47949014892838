@media only screen and (min-width: 768px) and (max-width: 960px) {
  .top-right-logo {
    top: 20px;

    height: 40px;
  }
  .mob-skillnow-logo {
    height: 2rem !important;
  }
  .mob-slider-logo-new {
    height: 2rem !important;
  }
  .home-slider1-container .slide1 .left-side-text {
    width: 80% !important;
  }
  .home-slider1-container .slide1 .left-side-text h1 {
    font-size: 2em !important;
  }
  .home-slider1-container .slide1 .left-side-text {
    left: 50% !important;
  }
}

@media only screen and (max-width: 960px) {
  .top-right-logo {
    top: 37px !important;

    height: 45px !important;
  }
  .top-header,
  body:after {
    display: none;
  }
  .capabilities-section .page-wrap,
  .who-we-are .page-wrap {
    margin: 0;
  }
  .community-login-section .item a,
  .contact-form-wrapper .btn,
  .cxnow-plans .plans-wrapper a.btn.btn-secondary,
  .job-application-wrapper .btn,
  .strategy-page .milestones .item .btn {
    min-width: auto;
  }
  html {
    overflow-x: hidden;
  }
  .btn .hidden-cta {
    width: 100%;
    opacity: 1;
    position: relative;
  }
  .btn-primary:hover {
    background-color: initial;
    color: #00a1fe;
  }
  .btn-primary:active,
  .btn-secondary:hover {
    background-color: #00a1fe;
  }
  .btn-primary:active {
    color: #fff;
  }
  .btn-secondary:active {
    background-color: #0091e4;
  }
  .btn-tertiary:hover {
    color: #f01c86;
    background-color: rgba(0, 0, 0, 0.02);
  }
  .btn-tertiary:active {
    color: #333;
    background-color: rgba(0, 0, 0, 0.04);
  }
  .bx-wrapper .bx-controls-direction a {
    color: rgba(255, 255, 255, 0.5);
  }
  .hero-line {
    font-size: 12px;
  }
  .main-wrapper {
    padding-top: 48px;
  }
  .header-wrapper {
    top: 0;
    position: fixed;
    background-color: #fff;
    -webkit-transition: top 0.3s ease-in-out;
    transition: top 0.3s ease-in-out;
    width: 100%;
    z-index: 999;
    border-bottom: 1px solid #ddd;
  }
  .blogs-section .article-wrapper,
  .cx-digital-wrapper,
  .home-page .customer-success-section .clients-wrapper,
  .product-section .right-content,
  .services .service-wrapper {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .hero-content h2 {
    font-size: 26px;
  }
  .hero-content p {
    font-size: 16px;
  }
  .service-wrapper .item.pink {
    background-color: #f01c86;
  }
  .service-wrapper .item.orange {
    background-color: #f37a0f;
  }
  .service-wrapper .item.blue {
    background-color: #00a1fe;
  }
  .service-wrapper .item.yellow {
    background-color: #d5b417;
  }
  .service-wrapper .item.green {
    background-color: #3d9d1a;
  }
  .service-wrapper .item.red {
    background-color: #ed6b6b;
  }
  .service-wrapper .item .item-wrapper {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .service-wrapper .item figure {
    opacity: 0.2;
  }
  .service-wrapper .item:after {
    opacity: 0.4;
  }
  .service-wrapper .item {
    border: 0;
  }
  .section-main-heading {
    display: block;
    padding: 5px 10px 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .section-heading {
    font-size: 20px;
    display: inline-block;
  }
  .main-footer .main-nav .row,
  .news-section .social-feeds {
    display: none;
  }
  .section-desc {
    font-size: 16px;
    max-width: 700px;
  }
  .main-header li {
    padding: 0;
  }
  .service-wrapper [class*="col-"] {
    padding-right: 0;
  }
  .service-wrapper {
    padding-bottom: 0;
  }
  .cx-digital-wrapper {
    padding: 60px 0;
    min-height: initial;
  }
  .customer-success-section .item .item-wrapper {
    min-height: 200px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .customer-success-section .clients-wrapper .item img {
    height: auto;
    width: 100%;
  }
  .customer-success-section .clients-wrapper [class*="col-"] {
    padding: 15px;
  }
  .customer-success-section .clients-wrapper .item {
    padding: 0;
  }
  .news-section .web-feeds .col-8,
  .news-section .web-feeds .row:last-child [class*="col-"] {
    border-right: 1px solid #ddd;
  }
  .news-section .web-feeds .row:last-child [class*="col-"]:last-child {
    border: 1px solid #ddd;
  }
  .blogs-section,
  .customer-success-section {
    padding: 40px 0;
  }
  .customer-success-section .clients-wrapper {
    padding-top: 0;
  }
  .main-footer .virtuos-brand {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .main-footer .footer-bottom .social-network .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .cx-digital-wrapper .building-block-inner,
  .cx-digital-wrapper .building-block-outer {
    display: none;
  }
  .main-footer .social-network li {
    padding: 0;
  }
  .main-footer .quick-links {
    padding: 50px 0;
  }
  .main-home-parallax-slider .item {
    height: 50vh;
  }
  .home-page .main-wrapper {
    padding-top: 48px;
  }
  .main-home-parallax-slider .item .center-wrapper {
    height: auto;
    /* padding-top: 60px; */
    justify-content: center;
    background: none;
    width: 80%;
    min-width: 80%;
  }
  .main-home-parallax-slider .center-wrapper h2 {
    font-size: 36px;
    line-height: 46px;
  }
  .main-home-parallax-slider {
    height: auto;
  }
  .who-we-are {
    background: #fff;
    padding: 20px 15px;
  }
  .cx-digital-page .main-header .nav-menu-icon i,
  .cx-digital-page.fixednav .main-header .nav-menu-icon i,
  .xonomy-page .main-header .nav-menu-icon i,
  .xonomy-page.fixednav .main-header .nav-menu-icon i,
  .home-page .header-wrapper.nav-down .main-header .nav-menu-icon i,
  .home-page .main-header .nav-menu-icon i {
    background-color: #333;
  }
  .highlight {
    padding: 0;
  }
  .color-highlight {
    line-height: 1.6;
    font-size: 46px;
  }
  .capabilities-section .page-wrap > .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-wrap: wrap;
  }
  .capabilities-section {
    padding: 30px 0;
  }
  .capabilities-section .tabs {
    padding-top: 0;
    width: 100%;
  }
  .capabilities-section .tabs ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
  }
  .capabilities-section .tabs .item {
    text-align: center;
    font-size: 14px;
    padding: 10px 20px;
    white-space: nowrap;
  }
  .full-height-video {
    height: 400px;
  }
  .capabilities-section .content-wrapper {
    width: 100%;
    margin-top: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
  }
  .capabilities-section .content-wrapper .info-wrapper {
    color: #333;
  }
  .capabilities-section .content-wrapper .info-wrapper:after,
  .capabilities-section .content-wrapper:before,
  .capabilities-section .tabs .item.active:after {
    display: none;
  }
  .showcase-news-cards-wrapper .item-wrapper {
    padding: 10px;
  }
  .capabilities-section .tabs .item.active {
    color: #fff;
    background-color: #00a0fe;
  }
  .cx-extra-features .item {
    padding: 20px 50px;
  }
  .cx-extra-features.offices-region .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .application-page .product-hero-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
  }
  .application-page .product-hero-wrapper .col-10 {
    width: 100%;
  }
  .hoverable-tabs {
    overflow: scroll;
  }
  .clients-list,
  .how-we-work {
    padding: 30px 0;
  }
  .showcase-news-cards-wrapper .row {
    padding-top: 10px;
  }
  .blogs-section .article-wrapper .blog-info {
    font-size: 12px;
  }
  .customer-success-section .clients-wrapper .row {
    padding: 0;
  }
  .product-section .product-banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .customer-success-section .clients-wrapper [class*="col-"] {
    width: 50%;
  }
  .news-section .web-feeds,
  .social-network {
    width: 100%;
  }
  .main-footer .virtuos-brand span {
    width: 70px;
  }
  .consultare-services {
    padding: 40px 0;
  }
  .about-culture .culture-collage {
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .about-culture .culture-collage .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .about-culture .culture-collage figure {
    width: 100%;
    height: auto;
  }
  .about-culture .culture-collage img {
    height: 100%;
  }
  .about-culture .culture-collage .bottom-layer {
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: initial;
  }
  .about-culture .culture-content {
    position: static;
  }
  .about-culture .culture-content-wrap {
    width: 100%;
    font-size: 16px;
  }
  .company-mv-wrapper blockquote {
    margin-right: 0;
  }
  blockquote {
    font-size: 20px;
  }
  .team-page .about-team-wrap ul li {
    width: 130px;
  }
  .team-banner figure img {
    transform: skew(0deg, 5deg) scale(2.5);
  }
  .team-page .about-team-wrap .feature-section a .txt {
    font-size: 16px;
  }
  .team-page .about-team-wrap .feature-section a i {
    font-size: 22px;
  }
  .team-page .about-team-wrap .feature-section a:hover i {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .about-team-wrap .item {
    display: block;
  }
  .hero-banner .hero-illustration {
    padding: 0 20px;
  }
  .featured-hero-article {
    bottom: -300px;
  }
  .abcde-nav .pattern {
    height: 100%;
  }
  .abcde-nav .pattern img {
    width: auto;
    height: 100%;
  }
  .main-header .header-nav-wrapper {
    display: none;
  }
  .consultare-services .item:hover .item-wrapper {
    background-color: initial;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .consultare-services .item .invisible-wrapper {
    position: static;
    opacity: 1;
    padding: 0;
    color: #00a1fe;
    background-color: transparent;
  }
  .why-choose-us-section .heading {
    padding: 80px 0;
  }
  .why-choose-us-section .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: -60px;
  }
  .why-choose-us-section .item {
    width: 50%;
    background-color: #fff;
    padding: 15px;
  }
  .why-choose-us-section .item .icon {
    margin-top: 0;
  }
  .customer-success-section .clients-wrapper .col-12 {
    width: 100%;
  }
  .customer-success-section .row .quote figure {
    width: 170px;
  }
  .clients-page .customer-success-section .clients-wrapper .item img {
    width: auto;
    height: 100%;
  }
  .clients-page .customer-success-section .item .item-wrapper {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }
  .clients-page .top-hero-product-banner,
  .clients-page .product-hero-wrapper {
    height: 40vh;
  }
  .clients-page .product-hero-wrapper .row {
    flex-direction: column;
  }
  .clients-page .product-hero-wrapper .col-5 figure {
    display: none;
  }
  .clients-page .product-hero-wrapper .item-wrapper {
    width: 100%;
    text-align: center;
  }
  .clients-page .product-hero-wrapper .item-wrapper span {
    font-size: 1.2em;
  }
  .clients-page .customer-success-section .item.featured .item-wrapper {
    width: 100%;
  }
  .clients-page .customer-success-section .clients-wrapper .item {
    min-height: auto;
  }

  .solutions-page .customer-success-section .clients-wrapper .item img {
    width: auto;
    height: 100%;
  }
  .solutions-page .customer-success-section .item .item-wrapper {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }
  .solutions-page .top-hero-product-banner,
  .solutions-page .product-hero-wrapper {
    height: 40vh;
  }
  .solutions-page .product-hero-wrapper .row {
    flex-direction: column;
  }
  .solutions-page .product-hero-wrapper .col-5 figure {
    height: 100%;
    padding: 0;
    margin-top: 20px;
  }
  .solutions-page .product-hero-wrapper .item-wrapper {
    width: 100%;
    text-align: center;
  }
  .solutions-page .product-hero-wrapper .item-wrapper span {
    font-size: 1.2em;
  }
  .solutions-page .customer-success-section .item.featured .item-wrapper {
    width: 100%;
  }
  .solutions-page .customer-success-section .clients-wrapper .item {
    min-height: auto;
  }

  .team-page .team-banner .hiring {
    width: 100px;
  }
  .abcde-nav .heading,
  .abcde-nav .row {
    display: -webkit-box;
    display: -ms-flexbox;
  }
  .abcde-nav .row,
  .our-products .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .customer-success-section .clients-wrapper .item:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 0.8;
  }
  .blogs-page .main-blogs {
    padding: 60px 15px;
  }
  .main-blogs .article-container .item {
    padding-right: 15px;
  }
  .main-blogs .article-container {
    padding-top: 15px;
  }
  .abcde-nav {
    padding: 50px 0;
  }
  .abcde-nav .row {
    display: flex;
    flex-direction: column;
  }
  .abcde-nav .section-main-heading {
    text-align: center;
  }
  .abcde-nav .heading {
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 20px;
    border: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-item-align: center;
    align-self: center;
    display: flex;
    width: auto;
  }
  .abcde-nav .navgation-wrapper {
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .abcde-nav .a,
  .abcde-nav .b,
  .abcde-nav .c,
  .abcde-nav .d,
  .abcde-nav .e {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 20px;
    width: 48%;
  }
  .abcde-nav .item .alphabet-embelem {
    margin-bottom: 0;
  }
  .our-products .row .col-3 {
    width: auto;
  }
  .our-products .row .col-3:first-child {
    width: calc(8.33333% * 3);
  }
  .our-products .row {
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .partner-wrapper,
  .partner-wrapper:nth-child(even),
  .related-offerings .item {
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
  .our-products .info {
    width: 100%;
    padding: 20px;
  }
  .main-blogs .article-wrapper .article-info {
    padding: 20px;
  }
  .disqus-wrapper {
    padding: 50px;
  }
  .cx-offerings {
    padding: 30px 15px;
  }
  .advantages-of-aury figure {
    padding: 20px;
  }
  .ecosystem-section img {
    height: auto;
  }
  .related-offerings .row {
    padding-top: 0;
  }
  .related-offerings .item {
    flex-direction: column;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    justify-content: center;
  }
  .related-offerings .item .content-wrap {
    padding: 0 10px;
    text-align: center;
    line-height: 1.5em;
  }
  .related-offerings .item .content-wrap .section-heading {
    text-align: center;
    line-height: 1.5em;
    padding-top: 20px;
    display: block;
    font-weight: 500;
  }
  .news-events-wrapper .news-articles-wrapper,
  .product-section .product-banner img {
    width: 100%;
  }
  .news-articles-wrapper {
    padding-right: 0;
  }
  .product-section {
    height: 400px;
  }
  .product-section .right-content.page-wrap {
    top: auto;
    max-width: 600px;
  }
  .news-events-wrapper .sticky-content {
    display: none;
  }
  .strategy-career-section {
    margin-top: 50px;
  }
  .content-wrap .section-illus a {
    opacity: 1;
  }
  .about-content-wrapper .main-about .feature-section a {
    padding: 10px 20px;
  }
  .about-content-wrapper .main-about .feature-section li.col-2-half {
    margin-bottom: 20px;
  }
  .blue-ocean-wrapper .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .blue-ocean-wrapper .item-wrapper {
    width: 33.33%;
  }
  #business-tech .col-4,
  #c-suite .col-4,
  #industry-focus .col-4 {
    width: calc(8.33333% * 5);
  }
  .about-content-wrapper .main-about .feature-section {
    padding-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .about-content-wrapper .main-about .feature-section li {
    width: 25%;
    padding: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .strategy-page .about-content-wrapper .main-about .feature-section a {
    border-right: 1px solid #ddd;
  }
  .partner-wrapper {
    padding: 20px 0;
  }
  .partner-wrapper,
  .partner-wrapper:nth-child(even) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
  }
  .partner-wrapper .partner-desc {
    width: 100%;
    padding-right: 0;
  }
  .partner-wrapper .partner-desc .content-wrapper p {
    font-size: 16px;
  }
  .partner-wrapper .partner-desc .content-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-bottom: 130px;
  }
  .partner-wrapper .partner-whats-new {
    width: 100%;
    padding: 20px 0 40px;
    border-bottom: 1px solid #ddd;
  }
  .partner-wrapper .partner-desc .partner-headquarter img {
    width: 100%;
  }
  .small-articles-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }
  .opportunities .additional-resources,
  .opportunities .single-section {
    padding: 30px 0;
  }
  .additional-resources .row {
    padding-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .additional-resources .section-heading,
  .careers-main-wrapper .two-list-col .page-wrap .what-is {
    padding: 0 15px;
  }
  .additional-resources .row .col-3 {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .job-openings-section .item .job-info .career-tags,
  .job-openings-section .item .job-info .static-text,
  .offices-wrapper .address-wrapper .address:before,
  .offices-wrapper .map-wrapper .g-map {
    display: none;
  }
  .tab-content .tab-brief {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .careers-main-wrapper .two-list-col .page-wrap .list-wrap {
    width: calc(8.33333% * 5);
  }
  .block-over-image-section .content-wrapper {
    width: 50%;
    margin-left: -20px;
  }
  .block-over-image-section
    .block-over-image-wrapper:nth-child(even)
    .content-wrapper {
    margin-right: -20px;
  }
  .job-openings-section .item .job-info {
    bottom: 0;
    height: auto;
  }
  .full-text-slider-wrapper .item {
    padding: 20px 40px;
  }
  .two-col-section .item {
    padding: 0 10px;
  }
  .two-col-section .item figure {
    height: 170px;
  }
  .contact-form-wrapper {
    padding: 40px 40px 40px 0;
  }
  .contact-page-wrapper .row .col-4 {
    width: calc(8.33333% * 5);
  }
  .contact-page-wrapper .contact-band a {
    margin-bottom: 10px;
  }
  .offices-wrapper .address-wrapper {
    padding: 50px 0;
    border-bottom: 1px solid #ddd;
  }
  .offices-wrapper .map-wrapper {
    padding: 0;
    margin-top: 30px;
  }
  .offices-wrapper .map-wrapper .office-image {
    position: relative;
  }
  .address-wrapper .section-heading .highlight {
    padding: 0 5px;
  }
  .contact-career-section {
    padding: 50px 0;
  }
  .offices-wrapper .address-wrapper .address {
    padding-left: 0;
    padding-bottom: 0;
  }
  .consultare-services .item-wrapper {
    padding: 10px 10px 0;
  }
  .two-col-section {
    padding: 30px 0;
  }
  .cxa-member-wrapper {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .cxa-member-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cxa-member-wrapper .item-wrapper {
    margin-bottom: 30px;
    width: 50%;
  }
  .cxa-member-wrapper .button-wrapper {
    padding-top: 20px;
  }
  .cx-extra-features .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cx-extra-features .row .col-4 {
    width: 100%;
  }
  .business3-parts .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .business3-parts .row a.item-wrapper {
    width: 50%;
  }
  .business3-page .exp-business .item p {
    font-size: 22px;
  }
  .cxnow-plans .plans-wrapper {
    padding-top: 20px;
    background-color: transparent;
  }
  .clients-list ul,
  .exp-business,
  .exp-economy-section {
    padding: 30px 0 0;
  }
  .exp-economy-section .right-section {
    padding: 10px;
  }
  .business3-page .exp-business .row {
    padding: 0;
  }
  .exp-economy-section .col-6 {
    width: calc(8.33333% * 8);
  }
  .business3-page .exp-business .grey-bg:after {
    left: 44%;
  }
  .consultare-services .item {
    height: 100%;
  }
  .business3-page .exp-business .grey-bg {
    margin-top: 40px;
  }
  .advantages-of-aury {
    padding: 30px 15px;
  }
  .advantages-of-aury .page-wrap {
    padding: 0;
  }
  .platform-ecosystem-page .advantages-of-aury .page-wrap,
  .technology-services-page .ecosystem-section .row {
    padding-top: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .platform-ecosystem-page .advantages-of-aury [class*="col-"],
  .technology-services-page .ecosystem-section [class*="col-"] {
    width: 100%;
  }
  .platform-ecosystem-page .advantages-of-aury .points,
  .technology-services-page .ecosystem-section .points {
    padding: 0 40px;
  }
  .full-width-image-breaker .aikidox-wrapper {
    margin-left: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .full-width-image-breaker.aikidox-container .content-wrapper {
    background: #f9f9f9;
    margin-top: 30px;
    position: static;
  }
  .advantages-of-aury .page-wrap .row .col-5 {
    width: calc(8.33333% * 4);
  }
  .advantages-of-aury .page-wrap .row .col-6 {
    width: calc(8.33333% * 8);
  }
  .business3-tab-section .advantages-of-aury .points .section-main-heading {
    margin-bottom: 20px;
    padding: 0;
  }
  .ecosystem-section .points li a {
    padding: 0;
  }
  .ecosystem-section .icon {
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: initial;
    padding-top: 10px;
  }
  .related-offerings {
    padding: 15px;
  }
  .related-offerings .section-main-heading {
    padding: 20px 10px;
  }
  .engg-service-wrapper {
    padding: 30px 0;
  }
  .engg-service-wrapper .item {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #eee;
    background-color: transparent;
  }
  .engg-service-wrapper .item:last-child {
    border-bottom: none;
  }
  .engg-service-wrapper .illus {
    display: none;
  }
  .engg-service-wrapper .content-wrapper {
    width: 100%;
  }
  .block-over-image-section .illus figure {
    height: 350px;
  }
  .two-col-section .item h3 {
    font-size: 24px;
  }
  .core-values-wrapper .the-values .item .icon {
    width: 70px;
    height: 70px;
    font-size: 24px;
  }
  .core-values-wrapper .the-values .item .icon i {
    width: 70px;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 24px;
    border-radius: 50%;
    background-color: #fff;
  }
  .core-values-wrapper .the-values .item {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .careers-job-page .job-openings-section,
  .job-form-wrapper {
    padding-top: 30px;
  }
  .careers-job-page .job-openings-section .row {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .careers-job-page .job-openings-section .row:last-child {
    border: 0;
  }
  .job-openings-section {
    padding-bottom: 30px;
  }
  .job-detail-wrapper .sticky-content {
    top: 100px;
  }
  .job-detail-wrapper .col-8 {
    width: calc(8.33333% * 9);
  }
  .job-details .list li {
    font-size: 16px;
  }
  .factors-wrapper .factors-content {
    padding: 0 15px;
  }
  .factors-wrapper .hero-factors .row {
    padding: 30px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
  }
  .factors-wrapper .hero-factors .row:last-child {
    border: none;
  }
  .factors-wrapper .hero-factors .row .col-7 {
    width: calc(8.33333% * 8);
  }
  .factors-wrapper .hero-factors .row .col-4 {
    width: calc(8.33333% * 3);
  }
  .factors-wrapper .hero-factors .icon {
    text-align: inherit;
  }
  .factors-wrapper .hero-factors .icon img {
    height: auto;
  }
  .exp-job-features {
    padding: 40px 0 0;
  }
  .degree360-section {
    padding: 30px 0 0;
  }
  .our-consultare-services {
    padding: 30px 0;
  }
  #talentare-section .our-consultare-services .col-4 {
    width: calc(8.33333% * 5);
  }
  #talentare-section .our-consultare-services .col-8,
  .vertical-tab-content .scrollable-vertical-section {
    width: calc(8.33333% * 7);
  }
  #talentare-section .our-consultare-services .right-content .icon {
    font-size: 30px;
    padding-right: 20px;
  }
  .talentare-business .tabs .item h3 {
    font-size: 16px;
    font-weight: 500;
  }
  .talentare-business .tabs:before {
    height: 80px;
  }
  .vertical-tab-content .talentare-form {
    width: calc(8.33333% * 5);
  }

  .skills-network .title-wrap {
    height: 100%;
    padding: 10px;
    text-align: center;
  }
  .talentare-project .project-list {
    padding-top: 10px;
  }
  .talentare-project .item {
    width: 25%;
  }
  .cx-flow-section .other-features .item .icon i {
    width: 50px;
    height: 50px;
    font-size: 20px;
    margin-top: 5px;
  }
  .consultare-services .item-wrapper .icon {
    width: 50%;
  }
  .cx-offerings.consultare-services .item-wrapper .icon img {
    height: auto;
    width: auto;
  }
  .cxnow-plans .plans-wrapper .item-wrapper {
    padding: 0 10px;
  }
  .testimonial-wrapper {
    padding: 30px 0;
  }
  .subscribe-section {
    padding: 50px 0;
  }
  .cx-flow-section,
  .cxnow-plans {
    padding: 30px 0;
  }
  .digital-services-section {
    padding: 30px 0 150px;
  }
  .digital-program-wrapper {
    padding-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .digital-process-tabs-wrapper {
    width: 100%;
    padding: 0;
  }
  .dragon-page .audi-container .row {
    flex-direction: column-reverse;
    align-items: center;
  }
  .dragon-page .audi-container .row .col-8-half {
    width: 100%;
    margin-top: 30px;
  }
  .dragon-page .audi-container .row .col-3 {
    width: 35%;
  }
  .digital-process-tabs-wrapper ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;
  }
  .digital-process-tabs-wrapper ul li {
    height: 100%;
  }
  .digital-process-content-wrapper {
    width: 100%;
    padding: 15px 0;
  }
  .digital-process-content-wrapper .vertical-tab-content:before {
    height: 10px;
    width: 100%;
    left: 0;
  }
  .digital-process-tabs-wrapper .item {
    margin-bottom: 0;
    border: none;
  }
  .digital-process-content-wrapper .steps-wrapper {
    padding-top: 20px;
  }
  .digital-process-content-wrapper .steps-wrapper .item-wrap {
    padding: 20px;
  }
  .digital-process-content-wrapper .digital-steps-wrapper .content-wrap {
    padding: 50px;
    min-height: 250px;
  }
  .digital-process-tabs-wrapper .item.active h3 {
    color: #fff;
  }
  .digital-services-section .item-wrapper {
    padding: 10px;
  }
  .digital-page .digital-services-section figure {
    height: 150px;
  }
  .digital-page .digital-services-section img {
    width: auto;
    height: 100%;
  }
  .digital-page .indicial .row .col-2:last-child {
    width: auto;
  }
  .digital-page .digital-program-wrapper .digital-process-tabs-wrapper .item {
    width: 33%;
    box-sizing: border-box;
  }
  .digital-services-section .content-wrapper h3 {
    font-size: 22px;
    line-height: 1.2;
  }
  .dragon-page .recommendation .row .left-block {
    width: 65%;
    padding: 30px;
  }
  .dragon-page .recommendation .row .right-block {
    width: 35%;
  }
  .accordian-section .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .exp-cloud-services .row,
  .other-small-blocks .row {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
  }
  .two-col-full-section.accordian-section .content-wrapper {
    padding: 30px;
  }

  .accordian-section .row .col-6,
  .accordian-wrapper .accordian-content ul li {
    width: 100%;
  }
  .accordian-wrapper .item.active .accordian-content {
    height: 100%;
  }
  .cxa-top-section .item .content-wrapper {
    font-size: 18px;
    line-height: 1.5;
  }
  .cxa-top-section .item-wrapper {
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .cxa-top-section,
  .cxa-top-section .page-wrap,
  .other-small-blocks {
    padding: 30px 0;
  }
  .awards-section .item figure {
    width: 100%;
    height: 100%;
  }
  .cxa-member-wrapper .item h3 {
    font-size: 20px;
    padding-top: 10px;
  }
  .other-small-blocks .row {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .other-small-blocks .row .col-2 {
    margin-bottom: 10px;
  }
  .other-small-blocks .item {
    font-size: 18px;
  }
  .cxa-top-section {
    margin: 0 15px;
  }
  .cxa-member-wrapper {
    padding: 15px;
  }
  .other-small-blocks .row .col-2 {
    width: 100%;
    margin-right: 10px;
  }
  .other-small-blocks .item-wrapper {
    padding: 0;
  }
  .consultare-services .section-desc {
    padding-bottom: 0;
  }
  .ai-page .exp-job-features .row {
    padding: 30px 0;
  }
  .exp-cloud-services .row .item-wrapper,
  .industrie .item-wrapper {
    padding: 0;
  }
  .section-breaker .section-logo {
    width: 150px;
  }
  .industrie {
    margin-bottom: 0;
  }
  .exp-cloud-services .row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .exp-cloud-services .row .item-wrapper .item {
    padding: 30px 15px;
  }
  .exp-cloud-page .exp-business .item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .exp-cloud-page .exp-business .item .icon {
    padding-right: 0;
  }
  .exp-cloud-page .image-title-layout .product-hero-wrapper h3,
  .exp-cloud-page .product-hero-wrapper .item-wrapper span {
    font-size: 22px;
  }
  .exp-cloud-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1.6);
  }
  .exp-cloud-page .product-hero-wrapper item-wrapper figure {
    width: 150px;
  }
  .exp-business .row {
    padding: 0;
  }
  .cf-form-wrapper {
    padding: 40px 0;
  }
  .cf-form-wrapper .popup-form-trigger {
    margin-top: 15px;
  }
  .exp-cloud-page .accordian-wrapper .item.active .accordian-content {
    height: 100px;
  }
  .thinking-new .row {
    padding-top: 0;
  }
  .economy-products a.col-6 {
    width: calc(8.33333% * 6);
  }
  .exp-economy-section .right-section img {
    height: 550px;
  }
  .innovation-landscape .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .innovation-landscape .row .col-3 {
    width: 50%;
  }
  .our-ideas-section {
    padding: 30px 0;
  }
  .job-openings-section .item {
    padding: 0 5px;
    height: 100%;
  }
  .job-openings-section {
    padding: 30px 0;
  }
  .about-team-wrap {
    padding: 30px 15px;
  }
  .about-team-wrap .page-wrap {
    padding-top: 30px;
  }
  .team-member-wrap .content .member-pic {
    width: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .team-member-wrap .content .member-pic figure img {
    width: 100%;
  }
  .team-member-wrap .content .member-info {
    padding: 0 0 0 30px;
    text-align: left;
    width: 50%;
  }
  .team-member-wrap .content .member-header {
    padding-top: 50px;
  }
  .team-member-wrap .content .about-member {
    padding-top: 50px;
    margin-top: 0;
  }
  .team-member-wrap .content .member-info .section-main-heading {
    font-size: 42px;
    text-align: left;
    padding: 0;
    line-height: 1.2;
  }
  .section-main-heading {
    font-size: 28px;
  }
  .professional-services-page .two-col-full-section figure {
    width: 100%;
    height: 500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
  }
  .professional-services-page .application-services ul li span {
    width: 150px;
  }
  .experience-design-page .about-team-wrap .item figure {
    height: auto;
  }
  .right-apps-page .products-info .item figure {
    height: 150px;
  }
  .talentare-page .product-section .content figure {
    width: 50%;
  }
  .talentare-page .product-section .content p {
    font-size: 24px;
  }
  .talentare-page .product-section .product-banner img {
    transform: scale(1.5);
  }
  .professional-services-page .two-col-full-section .content-wrapper {
    width: 100%;
  }
  .engineering-service-page .engg-service-tab-wrapper .stack .item-wrap {
    text-align: left;
    margin-right: 0;
    padding: 10px;
  }
  .experience-design-page .engg-service-wrapper .experience-design .item {
    padding: 0 15px;
    border-bottom: none;
  }
  .experience-design-page .xd-process .process-items {
    margin-top: -115px;
  }
  .experience-design-page .xd-process .process-number .item {
    font-size: 100px;
  }
  .experience-design-page .xd-process .process-items .item h3 {
    font-size: 20px;
    margin-bottom: 50px;
    padding-bottom: 0;
  }
  .experience-design-page .section-breaker figure img {
    width: 100%;
    height: auto;
  }
  .experience-design-page .xd-process {
    padding-bottom: 0;
  }
  .experience-design-page .why-us-section {
    padding: 30px 0;
  }
  .org-page .countdown-wrapper .item {
    padding: 0;
    margin: 0;
  }
  .events .event-slider .item figure img {
    height: 100%;
    width: auto;
  }
  .experience-design-page .why-us-section .page-wrap {
    padding: 30px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .experience-design-page .why-us-section .page-wrap .col-6 {
    width: 100%;
    padding: 5px;
  }
  .experience-design-page .why-us-section .benefits .row .col-6 {
    width: 25%;
  }
  .experience-design-page .why-us-section .benefits .item p {
    font-size: 24px;
  }
  .experience-design-page .about-team-wrap {
    width: 95%;
    margin-top: -60px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .experience-design-page .section-breaker .content-wrapper h2 {
    font-size: 34px;
  }
  .experience-design-page .why-us-section .section-main-heading {
    padding: 0 15px;
  }
  .cx-digital-inner-page .cx-doc-section {
    padding: 30px 15px;
  }
  .cx-digital-inner-page .cx-doc-section .row {
    padding: 15px 0;
  }
  .cx-digital-inner-page .cx-extra-features .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cx-digital-inner-page .cx-extra-features .item-wrapper {
    padding: 30px 0;
  }
  .cx-digital-inner-page .cx-extra-features .item {
    padding-top: 0;
    text-align: center;
  }
  .cx-extra-features .item .icon {
    margin: 15px auto;
    position: static;
  }
  .cx-digital-inner-page .cx-extra-features .item .section-heading {
    display: block;
    color: #fff;
  }
  .cx-digital-inner-page .cx-doc-section .section-main-heading {
    font-size: 24px;
  }
  .community-login-section .section-main-heading,
  .consultare-page .service-wrapper .item .section-heading {
    font-size: 28px;
  }
  .cxa-page .cxa-top-section .item figure {
    height: 200px;
  }
  .consultare-page .abcde-nav {
    min-height: 500px;
    padding: 30px 0;
  }
  .community-login-section .item a {
    width: 50%;
  }
  .comminity-edge .item-wrapper {
    padding: 0 10px;
  }
  .comminity-edge .item {
    padding: 15px;
  }
  .comminity-edge,
  .comminity-edge .page-wrap,
  .community-merchandise .heading {
    padding: 30px 0;
  }
  .comminity-edge .item .title-wrapper h3 {
    font-size: 20px;
  }
  .comminity-edge .item .title-wrapper h3 span {
    font-size: 28px;
  }
  .section-breaker .content-wrapper h2 {
    margin-bottom: 0;
  }
  .comminity-edge .item .title-wrapper .image {
    width: 150px;
    height: 150px;
  }
  .comminity-edge .item .title-wrapper h3 .image img {
    width: 75%;
  }
  .merchandise-products-wrapper .item {
    font-size: 24px;
    min-height: 200px;
  }
  .merchandise-products-wrapper .item h3 i {
    font-size: 34px;
  }
  .merchandise-products-wrapper .item.title {
    font-size: 30px;
  }
  .merchandise-products-wrapper .item figure img {
    height: 100%;
  }
  .merchandise-products-wrapper .item.title span {
    padding: 10px 20px;
    border-radius: 20px;
  }
  .merchandise-products-wrapper .item.title span img {
    width: 30px;
    height: 30px;
  }
  .org-page .exp-economy-section .right-section figure {
    text-align: center;
  }
  .org-page .cx-extra-features .item {
    padding-top: 0;
  }
  .org-page .cx-extra-features .section-heading {
    font-size: 28px;
  }
  .org-page .mission {
    padding: 30px 15px;
  }
  .org-page .mission .mission-wrapper .col-2-half {
    width: 24%;
  }
  .org-page .mission-wrapper .item .icon {
    font-size: 40px;
    padding: 0;
    text-align: center;
  }
  .org-page .mission-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .org-page .two-col-full-section figure {
    width: 100%;
    height: 100%;
  }
  .org-page .two-col-full-section figure img {
    width: auto;
    height: 100%;
  }
  .org-page .two-col-full-section .content-wrapper {
    padding: 40px;
  }
  .org-page .two-col-full-section .content-wrapper .section-heading {
    font-size: 26px;
  }
  .org-page .two-col-full-section .content-wrapper ul {
    padding: 20px 0;
  }
  .org-page .two-col-full-section .content-wrapper ul li,
  .org-page .upcoming-event .content-wrapper .event-detail-wrapper {
    padding: 0;
  }
  .org-page .two-col-full-section .content-wrapper p {
    margin: 0;
  }
  .org-page .two-col-full-section .content-wrapper a {
    margin-top: 20px;
  }
  .org-page .upcoming-event figure img {
    width: 100%;
    height: 100%;
  }
  .org-page .events .event-slider .item .content-wrap {
    height: auto;
    bottom: 40px;
    width: 100%;
    top: auto;
  }
  .org-page .bx-wrapper .section-heading {
    font-size: 22px;
    line-height: 1.5;
  }
  .org-page .events .event-slider .item .content-wrap p {
    font-size: 16px;
    line-height: 1.5;
  }
  .org-page .upcoming-event .content-wrapper {
    min-height: 400px;
    padding: 50px 20px 300px;
  }
  .org-page .upcoming-event .content-wrapper .section-desc {
    color: #fff;
  }
  .org-page .ar-wrapper .row.page-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 0;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .about-consultare-page .exp-edge-section,
  .about-page .blogs-section .article-wrapper,
  .cx-continuum-page .cx-desk-section .content,
  .cx-header-img .row,
  .product-page .header-article-wrapper .row:first-child,
  .right-apps .two-col-section .row {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }
  .org-page .ar-wrapper figure img {
    height: auto;
    width: 100%;
    transform: scale(1.8) translateX(50px);
  }
  .org-page .ar-wrapper .row .col-6 {
    width: 50%;
    padding: 15px;
  }
  .org-page .ar-wrapper figure {
    width: 100%;
    left: 0;
    padding-top: 0;
  }
  .org-page .aury-metrices .count-band {
    padding-top: 20px;
  }
  .org-page .aury-metrices .count-band .row {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .org-page .donation-section {
    padding: 30px 0;
  }
  .org-page .donation-section .page-wrap {
    padding: 30px 15px;
  }
  .org-page .donation-section .item-wrapper {
    padding: 0 5px;
    margin: 0;
  }
  .org-page .latest-org-blogs {
    padding: 30px 0;
  }
  .org-page .timeline-wrapper {
    margin: 50px auto;
  }
  .org-page .timeline-blogs-wrapper .item-wrapper {
    padding-bottom: 30px;
  }
  .team-page .blog-social-share {
    padding: 0;
  }
  .insights-page .customer-success-section .clients-wrapper [class*="col-"],
  .insights-page .smartest-thinking-section .item-wrapper {
    padding: 5px;
  }
  .insights-page .insights-featured-customers .row {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .insights-page .insights-featured-customers .row .col-3 {
    width: calc(8.33333% * 4);
  }
  .insights-page .smartest-thinking-section {
    margin: 0 auto;
  }
  .insights-page .smartest-thinking-section .row {
    padding: 10px;
    width: 90%;
  }
  .product-page .header-article-wrapper .row:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .product-page .header-article-wrapper .row .col-6 {
    width: 100%;
    margin-bottom: 10px;
  }
  .product-page .header-article-wrapper .item {
    height: 350px;
  }
  .product-page .header-article-wrapper .item img {
    width: auto;
  }
  .product-page .header-article-wrapper .section-heading {
    font-size: 18px;
  }
  .product-page .product-services-wrapper {
    padding: 30px 0;
  }
  .product-page .product-services-wrapper .page-wrap {
    padding-top: 20px;
  }
  .product-page .product-services-wrapper .item-wrapper {
    padding: 0 15px;
    margin-bottom: 15px;
  }
  .product-page .header-article-wrapper .row .col-6:first-child {
    margin-right: 10px;
  }
  .professional-services-page .two-col-full-section {
    padding: 30px 0;
  }
  .professional-services-page .two-col-full-section .content-wrapper {
    padding: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .professional-services-page
    .two-col-full-section
    .content-wrapper
    .section-heading {
    font-size: 26px;
    color: #fff;
  }
  .professional-services-page .two-col-full-section .content-wrapper ul {
    padding: 20px 0;
  }

  .professional-services-page .standard-page {
    padding: 30px 0;
  }
  .professional-services-page .standard-page .section-desc {
    padding-bottom: 30px;
  }
  .professional-services-page .standard-page .row {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 0;
  }
  .professional-services-page
    .aury-metrices.product-section
    .right-content.page-wrap {
    top: auto;
  }
  .professional-services-page .aury-metrices .count-band .row {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .professional-services-page
    .customer-success-section
    .clients-wrapper
    [class*="col-"]:first-child {
    padding-left: 15px;
  }
  .engineering-service-page .engg-service-tab-wrapper .tab-content-wrapper,
  .engineering-service-page .engg-service-tab-wrapper .tabs li.active {
    background-color: #fff;
  }
  .engineering-service-page .industry-product-wrapper .tabs {
    background-color: rgba(230, 230, 230, 0.2);
  }
  .engineering-service-page .soft-engg .section-main-heading {
    font-size: 28px;
  }
  .engineering-service-page .soft-engg .section-desc {
    margin-bottom: 15px;
  }
  .engineering-service-page
    .engg-service-tab-wrapper
    .stack
    .item-wrap:last-child {
    border-bottom: none;
  }
  .engineering-service-page .engg-service-tab-wrapper .stack .icon {
    margin: 0 auto;
  }
  .iov-page .aury-section .banner,
  .right-apps-page .right-apps-products .item .content-wrap h3 {
    margin-bottom: 0;
  }
  .engineering-service-page .industry-product-wrapper {
    padding-top: 20px;
  }
  .right-apps-page .two-col-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 30px;
  }
  .right-apps-page .two-col-section .item-wrap {
    width: 100%;
    padding: 0;
  }
  .right-apps-page .two-col-section .item-wrap .icon i {
    width: 50px;
    height: 50px;
    font-size: 40px;
    padding-top: 5px;
    margin-right: 0;
  }
  .right-apps-page .two-col-section .item-wrap .content-wrap {
    padding: 0 15px;
  }
  .right-apps-page .right-apps-products .row {
    padding-top: 0;
  }
  .right-apps-page .right-apps-products .item .product-logo {
    padding: 0 0 15px;
  }
  .right-apps .two-col-section .row {
    -ms-flex-direction: column;
    flex-direction: column;
    border-right: 1px solid #ddd;
  }
  .right-apps .two-col-section .row:last-child {
    border-right: 0;
  }
  .right-apps-page .right-apps .two-col-section .illus {
    display: block;
    width: 100%;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .right-apps-page .ar-wrapper figure {
    margin-top: 0;
    -ms-flex-item-align: auto;
    align-self: auto;
    padding-top: 0;
  }
  .right-apps-page .ar-wrapper .left-content h3 {
    font-size: 24px;
  }
  .right-apps-page .product-info-wrapper {
    padding: 30px 15px;
  }
  .right-apps-page .product-info-wrapper .section-heading {
    font-size: 32px;
  }
  .right-apps-page .product-info-wrapper .col-8 p {
    margin-top: 0;
  }
  .right-apps-page .ar-wrapper img {
    width: 100%;
    height: auto;
  }
  .ar-wrapper .left-content {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  .right-apps-page .ar-wrapper .left-content h3,
  .right-apps-page .ar-wrapper .left-content p {
    padding-left: 0;
  }
  .right-apps-page .ar-wrapper .left-content p img {
    height: 30px;
  }
  .right-apps-page .products-info {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #ddd;
  }
  .right-apps-page figure.right-apps-logo {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 200px;
    left: auto;
    bottom: auto;
  }

  .product-showcase {
    padding: 60px 20px;
  }
  .sticky-tracker {
    display: none;
  }
  .seven-c-wrapper {
    font-size: 24px;
  }
  .cx-digital-page .main-wrapper,
  .xonomy-page .main-wrapper {
    padding-top: 48px;
  }
  .slides {
    height: 100%;
  }
  .main-wrapper.slides {
    padding-top: 20px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }
  .cx-digital-page .header-wrapper,
  .cx-digital-page.fixednav .header-wrapper,
  .xonomy-page .header-wrapper,
  .xonomy-page.fixednav .header-wrapper,
  .home-page .header-wrapper {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  .iov-page .ar-wrapper .row.page-wrap {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .io-i3-section .banner img {
    width: auto;
    height: 100%;
    opacity: 0.5;
  }
  .io-i3-section .content {
    font-size: 42px;
    padding: 80px 0;
  }
  .cx-process-wrapper .group {
    opacity: 1;
  }
  .cx-process-wrapper .group.down,
  .cx-process-wrapper .group.left,
  .cx-process-wrapper .group.right {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .industry-main-wrapper .industry-product-banner {
    height: 100%;
  }
  .industry-main-wrapper .industry-product-banner img {
    height: 100%;
    width: auto;
  }
  .industry-main-wrapper .content-wrapper {
    padding-top: 20px;
  }
  .industry-main-wrapper .industry-form-wrapper {
    padding: 40px;
    min-height: 700px;
  }
  .about-story-page .vistuos-story-wrapper .header,
  .exp-edge-page .cxa-membership.section-breaker .content-wrapper {
    min-height: 200px;
  }
  .industry-main-wrapper .industry-form-wrapper > .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .industry-main-wrapper .industry-form-wrapper .row .left-side-wrapper,
  .industry-main-wrapper .industry-form-wrapper .row .right-side-wrapper {
    width: 100%;
  }
  .industry-main-wrapper .industry-form-wrapper form {
    max-width: initial;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cx-process-wrapper .group.down:after,
  .cx-process-wrapper .group.left:after,
  .cx-process-wrapper .group.right:after,
  .industry-main-wrapper
    .industry-form-wrapper
    .input-wrapper
    input[type="radio"]:checked
    ~ label:after {
    display: none;
  }
  .industry-main-wrapper .industry-form-wrapper form .section-heading {
    width: 100%;
    padding-bottom: 20px;
  }
  .industry-main-wrapper .industry-form-wrapper .input-wrapper label {
    font-size: 16px;
    border: 1px solid #ddd;
  }
  .industry-two-cols .item h3 {
    font-size: 26px;
  }
  .industry-two-cols .item p {
    font-size: 16px;
  }
  .industrie {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .bx-wrapper .bx-controls-direction a.bx-next:after,
  .bx-wrapper .bx-controls-direction a.bx-prev:after {
    opacity: 1;
  }
  .iov-page .aury-section .banner img {
    width: auto;
    height: auto;
    margin-bottom: 0;
  }

  .about-consultare-page .our-consultare-services .right-content .icon {
    font-size: 24px;
    width: 140px;
    padding-right: 20px;
  }
  .about-consultare-page .about-content-wrapper .main-about {
    padding-bottom: 30px;
  }
  .about-consultare-page .exp-edge-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .about-consultare-page .exp-edge-section .col-6 {
    width: 100%;
  }
  .exp-edge-page .virtuoso-features {
    padding-bottom: 40px;
  }
  .exp-edge-page .ai-tech figure {
    height: 300px;
    width: 100%;
  }
  .exp-edge-page .ai-tech figure img {
    width: auto;
    height: auto;
  }
  .exp-edge-page .section-breaker .content-wrapper {
    padding: 30px;
    font-size: 24px;
  }
  .exp-edge-page .ai-tech .content-wrapper h1.highlight {
    margin-bottom: 0;
    font-size: 24px;
  }
  .exp-edge-page .two-col-section .page-wrap {
    padding-top: 30px;
  }
  .exp-edge-page .digital-drawinist .item {
    border-right: none;
  }
  .strategy-page .exp-economy-section {
    padding: 50px 0;
  }
  .strategy-page .strategy-tabs-section .content-wrapper {
    padding: 40px;
  }
  .strategy-page .ar-wrapper {
    margin: 40px 0;
  }
  .strategy-page .ar-wrapper .row.page-wrap {
    min-height: auto;
    position: relative;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .strategy-page .ar-wrapper .left-content {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  .strategy-page .ar-wrapper figure {
    left: auto;
  }
  .strategy-page .ar-wrapper img {
    width: 90%;
    height: auto;
  }
  .strategy-page .ar-wrapper .left-content h3 {
    font-size: 24px;
  }
  .strategy-page .about-content-wrapper {
    padding: 40px 15px;
  }
  .strategy-page .about-content-wrapper .main-about {
    padding: 0;
  }
  .strategy-page .blue-ocean-strategy {
    padding-bottom: 40px;
  }
  .strategy-page .blue-ocean-strategy .heading {
    padding: 30px 15px;
  }
  .strategy-page .blue-ocean-strategy .blue-ocean-wrapper {
    margin-top: 0;
  }
  .strategy-page .milestones .item-wrapper {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .strategy-page .milestones .item {
    padding: 30px 15px;
    height: auto;
  }
  .strategy-page .milestones .item span.icon {
    display: none;
  }
  .strategy-page .milestones .item-wrapper figure {
    width: 100%;
    height: 300px;
  }
  .strategy-page .milestones .item-wrapper figure img {
    width: auto;
    height: 100%;
  }
  .strategy-page .milestones .item h4 {
    font-size: 22px;
    line-height: 1.5;
  }
  .strategy-page .section-breaker .content-wrapper {
    padding: 50px;
    font-size: 24px;
  }
  .about-partner-page .partner-wrapper {
    padding: 20px 0 0;
  }
  .about-partner-page .partner-wrapper .partner-whats-new {
    padding-bottom: 0;
  }
  .about-partner-page .partner-wrapper .partner-whats-new .row {
    padding-bottom: 20px;
  }
  .about-partner-page .partner-wrapper:last-child .partner-whats-new {
    border-bottom: none;
    padding-bottom: 0;
  }
  .about-partner-page .cloudle-section .content {
    font-size: 2em;
    padding: 60px 30px;
  }
  .about-partner-page .cloudle-section .icon {
    width: 200px;
    height: auto;
  }
  .news-page .news-events-wrapper {
    padding: 20px 0 60px;
  }
  .blogs-page .main-blogs .article-wrapper .article-heading,
  .news-detail-page .main-blogs .article-content .title,
  .news-detail-page .main-blogs .article-wrapper .article-heading {
    font-size: 24px;
  }
  .careers-page .tab-content .tab-brief {
    padding: 0;
  }
  .careers-page .job-openings-section figure {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 200px;
  }
  .careers-page .careers-main-wrapper .view-all-btn {
    margin-top: 30px;
  }
  .careers-page .alternate-content-section figure {
    height: 200px;
  }
  .careers-page .alternate-content-section {
    padding: 0 15px;
  }
  .careers-page .experience-job .two-list-col .row,
  .degree360-page .factors-wrapper .hero-factors .section-desc {
    padding: 20px 0;
  }
  .careers-page .alternate-content-section .section-wrapper {
    border-bottom: 1px solid #ddd;
  }
  .careers-page .alternate-content-section .section-wrapper:last-child {
    border: none;
  }
  .careers-page
    .alternate-content-section
    .section-wrapper
    .content-wrapper
    a.btn-primary {
    margin-bottom: 10px;
  }
  .careers-page .experience-job .col-3-half figure {
    width: 200px;
    height: 200px;
  }
  .careers-page .section-breaker {
    min-height: 170px;
  }
  .careers-page .ar-wrapper .row.page-wrap {
    min-height: auto;
  }
  .careers-page .ar-wrapper .row.page-wrap .left-content {
    opacity: 1;
  }
  .careers-page .ar-wrapper .section-heading {
    font-size: 28px;
  }
  .business3-page .banner-wrapper .business3-parts {
    display: none;
  }
  .business3-page .section-breaker .content-wrapper {
    padding: 70px 0;
  }
  .business3-page .exp-business .value-wrapper {
    padding: 50px 0;
  }
  .business3-page .product-section {
    height: 300px;
  }
  .business3-page .section-breaker figure img {
    width: 100%;
    height: auto;
  }
  .cx-continuum-page .consultare-services .item-wrapper .icon img {
    width: 100%;
  }
  .cx-continuum-page .cx-desk-section .banner img {
    width: auto;
    height: 100%;
  }
  .cx-continuum-page .cx-desk-section .content {
    padding: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 28px;
  }
  .cx-continuum-page .cx-desk-section .icon {
    width: 200px;
    height: auto;
    margin: 0;
  }
  .talentare-page .core-values-wrapper .tabs .item {
    width: auto;
  }
  .talentare-page .product-section {
    height: 250px;
  }

  .talentare-page .product-section .content {
    padding: 40px 0;
    font-size: 32px;
  }
  .talentare-page .product-section .content .icon {
    width: 300px;
  }
  .cx-transformation-page .cx-flow-section .illus {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .platform-ecosystem-page .campusyes .content-wrapper .col-6,
  .platform-ecosystem-page .campusyes figure {
    display: none;
  }
  .application-page .consultare-services .item-wrapper .icon img {
    width: 100%;
    height: 120px;
  }
  .application-page .why-choose-us-section .item .icon {
    width: 100px;
    height: 100px;
    background-color: #00a1fe;
    color: #fff;
    border-color: #fff;
  }
  .crm-application-page .related-offerings .item img {
    width: 120px;
  }
  .crm-application-page .consultare-detail-container .detail-wrapper {
    padding-left: 15px;
  }
  .crm-application-page
    .consultare-detail-container
    .product-wrapper
    .item
    .content-wrap {
    width: auto;
  }
  .crm-application-page
    .consultare-detail-container
    .product-wrapper
    .item
    .content-wrap
    figure {
    right: 50px;
  }
  .platform-ecosystem-page
    .cx-offerings.consultare-services
    .item-wrapper
    .icon
    img {
    width: 100%;
    height: 100px;
  }
  .platform-ecosystem-page .campusyes {
    height: auto;
    padding: 40px;
  }
  .platform-ecosystem-page .campusyes .section-heading {
    font-size: 32px;
  }
  .platform-ecosystem-page .campusyes .content-wrapper {
    position: inherit;
    padding: 0;
  }
  .platform-ecosystem-page .campusyes .content-wrapper .col-5 {
    width: 100%;
  }
  .platform-ecosystem-page .ecosystem-section .icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 0;
  }
  .platform-ecosystem-page .ecosystem-section figure,
  .technology-services-page .ecosystem-section figure {
    margin-top: 0;
    height: 300px;
  }
  .platform-ecosystem-page .ecosystem-section figure img,
  .technology-services-page .ecosystem-section figure img {
    height: 100%;
  }

  .community-login-section .item-wrapper figure img {
    width: auto;
    height: 100%;
  }
  .full-width-image-breaker .aikidox-wrapper {
    background-color: transparent;
    width: 100%;
    padding: 40px 20px;
  }
  .engineering-service-page .aikidox-container .col-6 {
    display: none;
  }
  .engineering-service-page .engg-service-tab-wrapper .stack .item-wrap {
    border-bottom: none;
  }
  .engineering-service-page .full-width-image-breaker .section-heading {
    font-size: 32px;
  }
  .cx-header-img {
    height: auto;
  }
  .cx-header-img .row {
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .cx-header-img .content-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 0;
  }
  .cx-header-img .image-wrapper figure {
    padding: 20px 20px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 350px;
  }
  .cx-header-img .image-wrapper,
  .cx-transformation-page .cx-header-img .col-6 img {
    width: 100%;
    height: auto;
  }
  .cx-header-img .section-desc {
    text-align: center;
  }
  .cx-transformation-page .cx-header-img .image-wrapper figure {
    padding: 20px 20px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .bg-image-wrapper {
    height: 100%;
  }
  .bg-image-wrapper img {
    width: auto;
    height: 100%;
  }
  .product-hero-wrapper {
    height: 300px;
  }
  .product-hero-wrapper .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .product-hero-wrapper .item-wrapper {
    padding-right: 0;
    padding-top: 20px;
  }

  .two-col-section .page-wrap {
    padding-top: 30px;
  }
  .ux-processes-wrapper .section-desc {
    font-size: 18px;
  }
  .crm-cx-page .abc-wrapper .content-wrap,
  .crm-cx-page .happy-customers-wrapper .content-wrap {
    position: static;
    padding: 0;
  }
  .crm-cx-page .abc-wrapper .content-wrap > [class*="col-"],
  .crm-cx-page .happy-customers-wrapper .items-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
  .crm-cx-page .happy-customers-wrapper .blank-div {
    display: none;
  }
  .crm-cx-page .abc-wrapper h2,
  .crm-cx-page .happy-customers-wrapper .items-block h2 {
    position: absolute;
    top: 10%;
    padding-left: 20px;
  }
  .crm-cx-page .happy-customers-wrapper .items-block .col-12 {
    top: -270px;
  }
  .crm-cx-page .happy-customers-wrapper .items-block h2 {
    right: 20px;
    top: -70%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .crm-cx-page .abc-wrapper .abc-block {
    width: 33.33%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 10px;
    text-align: center;
    border: 0;
  }
  .crm-cx-page .abc-wrapper .abc-block:last-child {
    border: 0;
  }
  .crm-cx-page .abc-wrapper .abc-block .icon figure {
    margin: 0;
  }
  .crm-cx-page .happy-customers-wrapper .items-block .item {
    padding: 15px;
  }
  .crm-cx-page .services-wrapper .page-wrap {
    padding: 0;
  }
  .crm-cx-page .services-wrapper .col-3.item {
    padding: 0 15px;
  }
  .crm-cx-page .services-wrapper .col-3.item .icon {
    padding: 15px;
  }
  .crm-cx-page .crm-portfolio-block .item .item-block:hover .content-wrap {
    opacity: 1;
  }
  .crm-cx-page .vertical-image-hoverable-wrapper .page-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .crm-cx-page .vertical-image-hoverable-wrapper .hoverable-tabs {
    width: 100%;
  }
  .crm-cx-page .vertical-image-hoverable-wrapper .hoverable-tabs ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .crm-cx-page .vertical-image-hoverable-wrapper .content-image-wrapper {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .crm-cx-page .services-wrapper {
    background-color: #ceedff;
  }
  .crm-cx-page .services-wrapper,
  .crm-cx-page .crm-wrapper {
    padding: 30px 0;
  }
  .crm-cx-page .vteam-wrapper {
    padding: 30px 0;
    margin: 0;
  }
  .crm-portfolio-wrapper {
    padding: 30px 0 0;
  }
  .crm-cx-page .vteam-wrapper ul li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .crm-cx-page .vteam-wrapper .content-wrapper {
    width: 100%;
    text-align: center;
  }
  .crm-cx-page .vteam-wrapper .img-wrapper {
    width: 50%;
    margin: 0 auto;
    padding: 30px;
  }
  .crm-portfolio-block .item {
    padding: 10px;
    width: 50%;
  }
  .business3-page .plans-table .page-wrap figure {
    padding: 0 15px;
  }
  .crm-portfolio-block .item .item-block .invisible-wrapper {
    position: static;
    height: auto;
    padding: 20px 10px 10px;
    background-color: transparent;
    opacity: 1;
  }
  .crm-portfolio-block .item .clients-logo-wrap figure {
    width: 30%;
    padding: 5px;
    border: 0;
  }
  .crm-portfolio-block .item .clients-logo-wrap {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .enablement .row,
  .professional-services-page .standard-page .row {
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
  .experience-design-page .engg-service-wrapper .experience-design .item {
    margin-bottom: 10px;
  }
  .experience-design-page .experience-design .two-col-section .item figure {
    height: 70px;
  }
  .professional-services-page .two-col-full-section .illus {
    display: none;
  }
  .professional-services-page .standard-page .row {
    flex-direction: column;
  }
  .professional-services-page .standard-page .row [class*="col-"] {
    width: 100%;
  }
  .core-values-wrapper .tabs .item {
    width: auto;
  }
  .org-page .mission-wrapper .item-wrapper {
    width: 50%;
    margin-bottom: 10px;
  }
  .plus-page .hoverable-content-wrapper .hoverable-tab-content p {
    min-height: 120px;
  }
  .system-of-engagement .tabs a {
    padding: 10px;
    font-size: 14px;
  }
  .enablement .row {
    flex-direction: column;
  }
  .system-of-engagement .content-wrapper {
    padding: 15px;
  }
  .plus-page .enablement .illus,
  .plus-page .two-col-full-section .content-wrapper {
    width: 100%;
  }
  .plus-page .enablement .illus ul li .item {
    padding: 5px;
    margin: 5px;
  }
  .customer-voice-wrapper {
    padding-top: 20px;
  }
  .customer-voice-wrapper .item,
  .virtuoso-features {
    margin-bottom: 0;
  }
  .milestones .item-wrapper figure img,
  .section-breaker figure img {
    height: 100%;
    width: auto;
  }
  .careers-page .section-breaker figure img {
    width: 100%;
    height: auto;
  }
  .stats-wrapper-layout .item-wrapper {
    width: auto;
  }
  .stats-wrapper-layout .digit {
    margin: 0;
  }
  .stats-wrapper-layout .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .content-table-wrapper .table-wrap:after,
  .digital-page .accordian-section .illus,
  .insights-article-tabs {
    display: none;
  }
  .cx-desk-page .virtuoso-features .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .icon-text-layout.two-col-section .page-wrap {
    padding: 20px 0 0;
  }
  .icon-text-layout.two-col-section .item {
    padding: 20px;
  }
  .icon-text-layout.two-col-section .item figure {
    height: 60px;
  }
  .half-egde-icon-wrapper.related-offerings .item .icon {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .business3-page .consultare-services .services-block .icon img {
    width: 70%;
  }
  .plans-table .item,
  .plans-table .item-wrapper {
    padding: 20px 10px;
  }
  .talentare-project .item .icon {
    width: 80px;
    height: 80px;
  }
  .talentare-project .item .icon figure {
    padding: 10px;
  }
  .talentare-project .project-list .row {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .insights-article-section {
    padding-top: 0;
  }
  .insights-article-section .blogs-section .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .insights-article-section .blogs-section {
    border-bottom: 1px solid #ddd;
  }
  .insights-article-section .blogs-section .article-wrapper {
    width: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .cx-strategy-page .bg-image-wrapper img,
  .industry-insights .item .content-wrapper {
    width: 100%;
  }
  .cx-strategy-page .product-hero-wrapper figure {
    height: 100%;
  }
  .cx-strategy-page .product-hero-wrapper figure img {
    height: 100%;
    width: 100%;
  }
  .cx-strategy-page .product-hero-wrapper .col-7 {
    width: 100%;
  }
  .cx-strategy-page .product-hero-wrapper .item-wrapper {
    width: 100%;
    text-align: center;
  }
  .cx-strategy-page .cx-view-container {
    padding: 30px 0 0;
  }
  .vertical-section.last-slide .content-wrapper {
    padding-bottom: 20px;
  }
  .cx-strategy-page .vertical-tab-content h3 {
    font-size: 12px;
    text-align: center;
  }
  .cx-strategy-page .section-breaker figure img {
    opacity: 0.5;
    -webkit-transform: none;
    transform: none;
  }
  .cx-strategy-page .virtuoso-features .row {
    padding-top: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .cx-strategy-page .virtuoso-features .item {
    min-height: 180px;
  }
  .cx-strategy-page .product-hero-wrapper h3 {
    font-size: 36px;
  }
  .cx-strategy-page .virtuoso-features .item-wrapper:last-child .item,
  .cx-strategy-page .virtuoso-features .item-wrapper:nth-child(even) .item {
    border: 0;
  }
  .cjourney-page .next-steps-wrapper .item:last-child,
  .cx-strategy-page .next-steps-wrapper .row .item:last-child {
    border: 1px solid #ddd;
  }
  .cx-strategy-page .next-steps-wrapper {
    padding: 40px 15px;
  }
  .cx-strategy-page .next-steps-wrapper .row .item {
    padding: 20px;
    width: 49%;
  }
  .next-steps-wrapper .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 20px;
  }
  .digital-human-wrapper .col-8,
  .digital-human-wrapper .title {
    font-size: 28px;
  }
  .customer-voice-wrapper .item-container {
    padding-top: 0;
    margin-top: 0;
  }

  .cx-now-page .cx-now-section .banner {
    height: 100%;
  }
  .cx-now-page .cx-now-section .banner img {
    width: auto;
    height: 100%;
  }
  .logos-steps-wrapper .row {
    width: 90%;
  }
  .process-steps-wrapper .item-wrapper {
    padding: 0 10px;
  }
  .process-steps-wrapper .item {
    padding: 0;
  }
  .digital-human-wrapper .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cxprimer-page .bg-image-wrapper img,
  .rigour-page .bg-image-wrapper img {
    margin-top: -40px;
  }
  .cxprimer-page .process-steps-section {
    padding-top: 10px;
  }
  .process-steps-wrapper {
    padding-top: 30px;
  }
  .cxaudit-page .gen .illus {
    padding: 10px;
  }
  .cxaudit-page .gen .section-desc {
    padding: 0 0 10px;
  }
  .cxaudit-page .gen .points li {
    font-size: 12px;
  }
  .cxaudit-page .next-steps-wrapper .section-heading {
    width: 100%;
  }
  .next-steps-wrapper .item {
    padding: 15px 10px;
    border: 1px solid #ddd;
    width: 32%;
    margin-bottom: 15px;
  }

  .content-table-wrapper .item,
  .faq-section .qa-item {
    width: 100%;
  }
  .cvoice-page .digital-process-section .content-block .item {
    font-size: 18px;
  }
  .two-col-section .illus {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .other-partners-wrapper .item figure img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  .digital-header-img .section-heading {
    font-size: 32px;
    display: contents;
  }
  .digital-page .digital-header-img {
    height: 400px;
  }
  .digital-page .digital-process-section {
    padding: 60px 0 0;
  }
  .digital-page .digital-header-img .col-6 img {
    opacity: 0.5;
  }
  .digital-page .digital-header-img .content-wrapper {
    margin-left: 50px;
  }
  .digital-page .accordian-content .content-block {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .c-digital-page .section-breaker figure img,
  .digital-page .section-breaker figure img {
    width: 100%;
    height: auto;
  }
  .content-table-wrapper .table-wrapper .content-wrap .section-heading {
    font-size: 20px;
  }
  .content-table-wrapper .table-wrap {
    width: 56%;
  }
  .content-table-wrapper .content-wrap {
    width: 40%;
  }
  .journey-path-wrapper .journey-path-wrap .item {
    width: 100px;
    height: 100px;
    padding: 10px;
  }
  .journey-path-wrapper .journey-path-wrap .item.big {
    width: 200px;
    height: 200px;
    padding: 30px;
  }
  .journey-path-wrapper {
    background-position: center -10px;
  }
  .indicial-page .vertical-image-hoverable-wrapper .page-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .indicial-page .hoverable-tabs {
    width: 100%;
  }
  .indicial-page .hoverable-tabs ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .indicial-page .hoverable-content-wrapper {
    width: 100%;
    padding-top: 20px;
  }
  .vertical-image-hoverable-wrapper .item {
    width: 30%;
  }
  .indicial-page .vertical-image-hoverable-wrapper .item.active {
    background-color: rgba(0, 161, 254, 0.5);
    color: #fff;
  }
  .indicial-page .advantages-of-aury .page-wrap {
    padding-top: 0;
  }
  .ai-page .ecosystem-section .page-wrap.row figure {
    display: none;
  }
  .ai-page .ecosystem-section .page-wrap.row .points {
    width: 100%;
  }
  .ai-page .ecosystem-section .page-wrap.row.gen .col-6:last-child {
    display: none;
  }

  .ai-wrapper .ai-container .item-block .item {
    padding: 15px;
  }
  .ai-wrapper .ai-container .item-block .item .col-9 {
    width: 100%;
  }
  .ai-wrapper .item-block .item figure {
    position: static;
  }
  .ai-page .ecosystem-section .page-wrap.row.gen figure {
    position: relative;
    width: 100%;
    height: auto;
  }
  .ai-page .ecosystem-section .page-wrap.row.gen figure img {
    width: 100%;
    height: auto;
  }
  .ai-page .skills-container {
    padding: 30px 0;
  }
  .connect-wrapper h2 {
    font-size: 30px;
  }
  .connect-container .item .btn {
    padding: 5px 20px;
  }
  .connect-container {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .industrie .item figure {
    height: 120px;
  }
  .iov-page .exp-economy-section .right-section figure img,
  .iov-page .two-image-wrapper {
    height: auto;
  }
  .industrie .item h3 {
    font-size: 20px;
  }
  .social-breaker .social-breaker-wrapper {
    padding: 40px 0;
  }
  .social-breaker .social-breaker-wrapper .item {
    text-align: center;
  }
  .social-breaker .social-breaker-wrapper .item .title {
    font-size: 16px;
  }

  .rigour-page .advantages-of-aury .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rigour-page .advantages-of-aury .row [class*="col-"] {
    width: 100%;
  }
  .rigour-page .image-title-layout .bg-image-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .iov-page .blogs-section .article-wrapper {
    padding: 0 10px;
  }
  .iov-page .blogs-section .article-wrapper p {
    font-size: 14px;
  }
  .job-openings-section .job-item-wrapper {
    padding-bottom: 90px;
    height: 250px;
  }
  .iov-page .product-hero-wrapper {
    padding: 20px 0;
    height: 400px;
  }
  .iov-page .product-hero-wrapper [class*="col-"] {
    width: 100%;
  }
  .iov-page .product-hero-wrapper.two-image-wrapper .image-wrapper figure {
    height: 250px;
  }
  .iov-page .product-hero-wrapper .item-wrapper figure,
  .iov-page .product-hero-wrapper .item-wrapper figure img {
    width: 100%;
  }

  .story-wrapper.exp-business .item .content-wrapper .section-heading {
    font-size: 22px;
  }
  .company-mv-wrapper {
    padding: 30px 0;
  }

  .about-story-page .history,
  .experience-design-page .section-breaker .content-wrapper {
    padding: 30px 0;
  }
  .community-login-section .item,
  .happiests-culture-page .ar-wrapper .row.page-wrap {
    min-height: 250px;
  }
  .about-story-page .history .feature-section {
    padding-top: 20px;
  }
  .about-story-page .vistuos-story-wrapper .header .content-wrapper {
    padding: 40px 0;
  }
  .about-story-page .about-team-wrap .col-9 {
    flex-wrap: nowrap;
  }
  .about-story-page .about-team-wrap .col-9 .row {
    flex-direction: column;
  }
  .about-story-page .about-team-wrap .col-9 .col-4 {
    width: 100%;
  }
  .about-story-page
    .about-team-wrap
    .col-9
    .row:first-child
    .col-4:nth-child(even) {
    flex-direction: row-reverse;
  }
  .about-story-page
    .about-team-wrap
    .col-9
    .row:last-child
    .col-4:nth-child(odd) {
    flex-direction: row-reverse;
  }

  .team-page .cheart {
    padding: 0 15px;
  }
  .team-page .cheart .info .text-content {
    margin-right: 0;
  }
  .team-page .domain-expertise {
    padding: 30px 0;
  }
  .team-page .domain-expertise .item {
    padding: 0 10px;
  }
  .team-page .domain-expertise .item-wrapper {
    padding: 0;
  }
  .team-page .domain-expertise .item figure {
    height: auto;
  }
  .team-page .section-breaker figure img {
    opacity: 0.5;
    width: 100%;
    height: auto;
  }
  .team-page .domain-expertise .page-wrap {
    padding-top: 0;
  }
  .team-page .etiquette,
  .team-page .xd-process {
    padding: 30px 0;
  }
  .team-page .section-breaker {
    margin-bottom: 30px;
  }
  .team-page .section-breaker .content-wrapper {
    padding: 60px 0;
  }
  .cxa-member-wrapper .row {
    margin-top: 20px;
  }
  .exp-edge-page .exp-advantages .item {
    padding: 10px;
  }
  .exp-edge-page .digital-drawinist .item {
    padding: 20px;
    border-right: 1px solid #ddd;
  }
  .careers-header-img {
    height: 40vh;
  }
  .image-hoverable-wrapper {
    padding: 30px 0;
  }
  .careers-page .abcde-block ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .careers-page .abcde-wrapper .abcde-block .item {
    width: 30%;
  }
  .image-hoverable-wrapper .row {
    padding-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .image-hoverable-wrapper .hoverable-content-wrapper .hoverable-tab-content,
  .image-hoverable-wrapper .hoverable-tabs,
  .image-hoverable-wrapper .image-wrappers {
    width: 100%;
    overflow: hidden;
  }
  .image-hoverable-wrapper
    .hoverable-content-wrapper
    .hoverable-tab-content
    img {
    width: 50%;
  }
  .careers-page .section-breaker .content-wrapper {
    padding: 40px 0;
  }
  .careers-page .section-breaker.ordinary figure img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .vertical-image-hoverable-wrapper .content-image-wrapper .item-wrapper {
    padding: 5px;
  }
  .vertical-image-hoverable-wrapper
    .content-image-wrapper
    .item-wrapper
    figure {
    height: 150px;
  }
  .block-over-image-section .block-over-image-wrapper {
    padding: 20px 0;
  }
  .culture-page .two-col-wrapper {
    height: 250px;
  }
  .culture-page .two-col-wrapper .section-heading {
    font-size: 28px;
  }
  .core-values-section .heading {
    padding: 50px 0 150px;
  }
  .community-page .section-breaker .content-wrapper {
    padding: 60px 0;
  }
  .community-page .section-breaker img {
    width: 100%;
  }
  .comminity-edge.shared-value .item figure {
    max-height: 300px;
  }
  .customer-success-section {
    padding-top: 40px;
  }
  .main-blogs .article-wrapper .article-image img {
    width: auto;
  }
  .main-blogs .article-content {
    padding: 40px 0;
    width: 100%;
  }
  .main-blogs .highlights-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .main-blogs .highlights-wrapper .item {
    width: 100%;
    margin-bottom: 5px;
  }
  .main-blogs .article-content .highlights-wrapper .header {
    font-size: 24px;
  }
  .main-blogs .article-content .items-container {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .main-blogs .article-content .blog-content-image {
    width: auto;
  }
  .contactpage .contact-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 20px 15px;
  }
  .contactpage .contact-container .offices-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .contactpage .contact-container .address-wrapper {
    width: 42%;
    padding: 20px;
  }
  .contactpage .contact-container .col-8,
  .org-page .skill .content-wrapper {
    width: 100%;
  }
  .contactpage .contact-container .address-wrapper:last-child {
    width: 100%;
    padding: 20px;
  }
  .contactpage .contact-band {
    padding: 10px 15px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ddd;
  }
  .contactpage .contact-band a {
    margin-bottom: 0;
  }
  .contactpage .virtual-office-section {
    height: 600px;
  }
  .contactpage .contact-form-wrapper {
    padding: 20px;
  }
  .org-page blockquote {
    padding: 0 0 0 30px;
    margin: 30px;
  }
  .org-page blockquote:before {
    left: -30px;
  }
  .org-page .cx-extra-features .item-wrapper {
    padding: 30px 0;
  }
  .org-page .cx-extra-features .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .org-page .cx-extra-features .item {
    padding: 0 15px;
  }
  .org-page .cx-extra-features .item h3.section-heading {
    font-size: 36px;
  }
  .org-page .cx-extra-features .item h3.section-heading span {
    font-size: 24px;
    padding: 0 10px;
  }
  .org-page .exp-economy-section .section-main-heading {
    font-size: 24px;
  }
  .org-page .mission-wrapper .item {
    text-align: center;
    padding: 30px;
  }
  .org-page .mission-wrapper {
    padding: 30px 0 0;
  }
  .org-page .skill .illus {
    display: none;
  }
  .org-page .aury-metrices .count-band .count-wrapper {
    width: 70px;
    height: 70px;
    font-size: 24px;
  }
  .careers-page .additional-resources {
    padding: 30px 0;
  }
  .careers-page .photo-slider-wrapper {
    padding-bottom: 20px;
  }
  .exp-job-page .experience-job .col-3-half figure {
    width: 200px;
    height: 200px;
  }
  .exp-job-page .exp1 {
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .exp-job-page .exp1 .what-is {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .exp-job-page .exp1 .content-wrap {
    width: 50%;
    margin-right: 20px;
  }
  .exp-job-page .exp1 .content-wrap:last-child {
    margin-right: 0;
  }
  .exp-job-page .two-list-col .exp1 .list-wrap {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
  }
  .exp-job-page .section-breaker figure {
    padding: 30px 15px;
  }
  .exp-job-page .experience-job .col-4 .label img {
    height: 20px;
  }
  .full-text-slider-wrapper .item blockquote {
    font-size: 32px;
  }
  .happiests-culture-page .factors-wrapper .hero-factors .row {
    padding: 20px 0;
  }
  .happiests-culture-page .factors-wrapper .hero-factors {
    padding: 60px 15px 0;
  }
  .happiests-culture-page .ar-wrapper .left-content h3 {
    font-size: 24px;
  }
  .happiests-culture-page .factors-wrapper .hero-factors .section-desc {
    padding: 20px 0;
  }
  .careers-job-page .job-openings-section,
  .employee-benefits-page .factors-wrapper .two-col-section {
    padding: 15px;
  }
  .employee-benefits-page .factors-wrapper .two-col-section .item figure {
    margin: 0;
  }
  .employee-benefits-page .factors-wrapper .two-col-section .item h3 {
    margin-bottom: 10px;
  }
  .careers-job-page .job-openings-section .item {
    padding-bottom: 20px;
  }
  .opportunities-page .alternate-content-section {
    padding: 30px 15px;
  }
  .opportunities-page .alternate-content-section figure {
    height: 200px;
  }
  .alumni-page .factors-wrapper .hero-factors {
    padding: 15px;
  }
  .alumni-page .factors-wrapper .hero-factors .section-desc {
    padding: 0 0 30px;
  }
  .additional-resources {
    padding: 60px 0 0;
    border-top: 1px solid #ddd;
  }
  .alumni-page .two-col-section .item figure {
    margin: 0;
  }
  .alumni-page .two-col-section .item h3 {
    margin-bottom: 10px;
  }
  .solutions-wrapper .solutions-container .item {
    padding: 10px;
    width: 33.33%;
  }
  .solutions-wrapper .solutions-container .feature-block {
    margin-bottom: 50px;
  }
  .solutions-wrapper .solutions-container .feature-block .icon {
    width: 50px;
    height: 50px;
  }
  .solutions-wrapper .solutions-container .row.col-12 .feature-block .txt {
    font-size: 20px;
  }
  .solution-page .cx-services-wrapper .services-container .item {
    width: 50%;
  }
  .talk-video-breaker .content-block {
    padding: 60px 0;
  }
  .experts-wrapper .experts-container .item {
    width: 50%;
    padding: 30px 15px;
  }
  .timeline-blogs-wrapper .left-wrapper .item .timeline-pointer {
    right: -77px;
  }
  .timeline-blogs-wrapper .right-wrapper .item .timeline-pointer {
    left: -74px;
  }
  .org-page .ar-wrapper .row.page-wrap {
    min-height: 300px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .org-page .ar-wrapper .row .col-6 {
    margin-right: 50px;
  }
  .org-page .ar-wrapper .section-heading {
    font-size: 28px;
  }
  .exp-cloud-page .product-hero-wrapper .page-wrap,
  .platform-ecosystem-page .product-hero-wrapper .page-wrap {
    max-width: 98%;
  }

  .right-apps-page .bg-image-wrapper {
    position: absolute;
  }
  .engineering-service-page .bg-image-wrapper img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .engineering-service-page .product-hero-wrapper .left-img figure {
    height: auto;
    padding: 0;
  }
  .engineering-service-page .product-hero-wrapper .row.page-wrap {
    align-items: flex-start;
  }
  .experience-design-page .product-hero-wrapper .row {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .about-partner-page .product-hero-wrapper .row,
  .ai-page .product-hero-wrapper .row,
  .application-page .product-hero-wrapper .row,
  .c-digital-page .top-hero-product-banner .page-wrap,
  .cx-marries-digital-page .product-hero-wrapper .row,
  .exp-cloud-page .product-hero-wrapper .page-wrap,
  .exp-edge-page .product-hero-wrapper .page-wrap,
  .insights-page .product-hero-wrapper .page-wrap,
  .o-digital-page .product-hero-wrapper .page-wrap,
  .org-page .product-hero-wrapper .page-wrap,
  .professional-services-page .product-hero-wrapper .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
  }
  .experience-design-page .product-hero-wrapper .row [class*="col-"] {
    width: 70%;
  }
  .experience-design-page
    .product-hero-wrapper
    .row
    [class*="col-"]:first-child {
    display: none;
  }
  .experience-design-page .bg-image-wrapper img {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0.4;
  }
  .exp-edge-page .image-title-layout .product-hero-wrapper p {
    font-size: 22px;
  }

  .cx-continuum-page .product-hero-wrapper {
    height: auto;
  }
  .cx-continuum-page .product-hero-wrapper figure img {
    width: 100%;
  }
  .cx-continuum-page .product-hero-wrapper .item-wrapper figure {
    width: auto;
  }
  .cx-continuum-page .product-hero-wrapper .page-wrap {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0;
  }
  .cx-continuum-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 22px;
  }

  .ai-page .product-hero-wrapper .row,
  .exp-cloud-page .product-hero-wrapper .page-wrap {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .exp-cloud-page .product-hero-wrapper .item-wrapper {
    width: 60%;
  }
  .cvoice-page .bg-image-wrapper img,
  .cxprimer-page .bg-image-wrapper img,
  .rigour-page .image-title-layout .bg-image-wrapper img {
    width: 100%;
    height: auto;
  }
  .cxprimer-page .product-hero-wrapper {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    text-align: center;
  }
  .right-apps-page .product-hero-wrapper figure img {
    width: 200px;
  }
  .cxprimer-page .image-title-layout .product-hero-wrapper h3 {
    width: 100%;
  }

  .cxprimer-page .product-hero-wrapper .cx-primer-block {
    padding: 50px 20px 20px 20px;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block ul.links li {
    font-size: 0.8em;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block .title {
    font-size: 1.5rem;
  }
  .cxprimer-page .product-hero-wrapper {
    height: 350px;
    text-align: left;
    align-items: center;
  }
  .cx-strategy-page .product-hero-wrapper .image-wrapper {
    height: 300px;
  }
  .cxaudit-page .top-hero-product-banner .page-wrap .col-12 {
    width: 70%;
  }
  .cxaudit-page .next-steps-wrapper .item {
    padding: 15px 10px;
  }
  .cxaudit-page .xd-process {
    padding: 0;
  }

  .c-digital-page .top-hero-product-banner .page-wrap {
    max-width: 90%;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .c-digital-page .section-breaker .content-wrapper {
    padding: 60px 0;
  }
  .app-group-circle-links {
    width: 250px;
    height: 250px;
  }
  .app-group-circle-links a {
    font-size: 13px;
    font-weight: 300;
  }
  .application-page .product-hero-wrapper .row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .application-page .application-container .vertical-clickable-tabs {
    padding-right: 20px;
    min-height: 400px;
    width: 400px;
  }
  .application-page .application-container .vertical-clickable-tabs li {
    font-size: 1rem;
    padding: 12px;
  }
  .application-page .application-container .content-block {
    width: 400px;
  }
  .application-page .application-container .img-block {
    width: 100%;
    right: 0;
    top: 220px;
    z-index: 10;
    bottom: 0;
  }
  .application-page .application-container .content-block a.more {
    right: 20px;
    bottom: -20px;
    padding: 5px 20px;
    z-index: 11;
    background-color: #fff;
  }
  .application-page .application-container .content-block a.more:hover {
    right: 20px;
  }
  .application-page .success-mantra-section .item-container {
    padding: 5px;
  }
  .platform-ecosystem-page .product-hero-wrapper .page-wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .platform-ecosystem-page
    .full-width-image-breaker.aikidox-container
    .content-wrapper {
    background-color: transparent;
  }
  .full-height-video .item span {
    font-size: 60px;
  }
  .bpm-page .bg-image-wrapper img {
    width: 100%;
    height: auto;
  }
  .bpm-page .block-4 .page-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .bpm-page .block-4 .item-wrapper {
    padding: 10px;
    width: 50%;
  }
  .bpm-page .cx-now-section .banner img {
    width: auto;
    height: 100%;
  }
  .bpm-page .block-3 .item figure {
    height: 150px;
  }
  .bpm-page .exp-cloud-services .row .item-wrapper {
    width: 100%;
  }
  .bpm-page .exp-cloud-services .row .item-wrapper .item {
    padding: 60px 15px;
    width: 100%;
  }
  .bpm-page .points-section .banner img {
    width: auto;
    height: 100%;
  }
  .bpm-page .cx-extra-features .item-wrapper figure img {
    width: 100%;
    height: auto;
    -webkit-transform: none;
    transform: none;
  }
  .exp-edge-page .product-hero-wrapper {
    height: 400px;
  }
  .exp-edge-page .product-hero-wrapper .page-wrap {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .exp-edge-page .product-hero-wrapper [class*="col-"] {
    width: 100%;
  }
  .exp-edge-page .product-hero-wrapper figure {
    height: auto;
  }
  .exp-edge-page .product-hero-wrapper img {
    width: 100%;
    height: auto;
  }
  .exp-edge-page .product-hero-wrapper .item-wrapper {
    position: absolute;
    z-index: 99;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
  }
  .exp-edge-page .product-hero-wrapper .item-wrapper h3 {
    font-size: 32px;
    margin-bottom: 0;
  }
  .insights-page .product-hero-wrapper .section-heading,
  .org-page .product-hero-wrapper span {
    font-size: 26px;
  }
  .org-page .product-hero-wrapper .page-wrap {
    -ms-flex-direction: row;
    flex-direction: row;
    max-width: 90%;
  }
  .comminity-page .section-breaker figure img {
    width: 100%;
  }
  .insights-page .product-hero-wrapper .page-wrap {
    max-width: 90%;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .insights-page .product-hero-wrapper .item-wrapper {
    text-align: center;
  }
  #social-share-fixed {
    top: 20%;
  }
  .main-blogs .article-wrapper .article-image {
    height: auto;
  }

  .news-detail-page .main-blogs .article-content {
    width: 70%;
  }
  .job-openings-section .item figure {
    height: 250px;
  }
  .careers-job-page .job-form-wrapper {
    padding: 40px 15px;
  }

  .solution-page .about-wrapper .laptop-block {
    height: 55%;
    left: 10px;
  }
  .solution-page .about-wrapper h1 {
    font-size: 30px;
    line-height: normal;
  }
  .listing-page .main-header .logo-wrapper .partnership-icon {
    width: 40px;
    height: 40px;
  }
  .careers-page .vertical-image-hoverable-wrapper .item {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .legal-section {
    padding: 40px 20px;
  }
  .cx-marries-digital-page .product-hero-wrapper .row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cx-marries-digital-page .top-hero-product-banner h2 {
    font-size: 26px;
  }
  .cx-desk-page .product-hero-wrapper {
    height: 300px;
    padding: 20px 0;
  }
  .cx-desk-page .top-hero-product-banner .right-fixed-logo {
    height: 30px;
  }
  .cx-desk-page .cx-view-container {
    padding: 30px 0 0;
  }
  .employee-beneits-page .product-hero-wrapper .item-wrapper,
  .training-page .product-hero-wrapper .item-wrapper {
    padding-bottom: 20px;
  }
  .privacy-policy-page .product-hero-wrapper .row,
  .safe-harbour-page .product-hero-wrapper .row,
  .term-condition-page .product-hero-wrapper .row {
    flex-direction: row;
  }
  .exp-job-page .top-hero-product-banner .page-wrap,
  .opportunities-page .top-hero-product-banner .page-wrap {
    max-width: 90%;
    flex-direction: row;
  }
  .privacy-policy-page .product-hero-wrapper .section-heading,
  .safe-harbour-page .product-hero-wrapper .section-heading,
  .term-condition-page .product-hero-wrapper .section-heading {
    font-size: 40px;
  }
  .section-desc,
  .section-heading,
  .section-main-heading,
  [class*="offport-"],
  p {
    opacity: 1;
    transform: translate(0);
  }
  .alumni-page .main-wrapper {
    padding-top: 20px;
  }
  .top-hero-product-banner .right-fixed-logo {
    width: 150px;
  }
  .opportunities-page .product-hero-wrapper figure {
    height: 100%;
    padding-top: 50px;
  }
  .opportunities-page .product-hero-wrapper figure img {
    width: 100%;
    height: auto;
  }
  .product-hero-wrapper .item-wrapper span {
    font-size: 22px;
  }
  .image-title-layout .product-hero-wrapper h3 {
    font-size: 26px;
  }
  .culture-page .top-hero-product-banner .page-wrap,
  .life-at-virtuos-page .top-hero-product-banner .page-wrap {
    max-width: 90%;
    flex-direction: row;
  }
  .exp-job-page .product-hero-wrapper figure {
    height: 100%;
  }
  .exp-job-page .product-hero-wrapper figure img {
    width: 100%;
    height: auto;
  }

  .happiests-culture-page .top-hero-product-banner .page-wrap,
  .training-page .top-hero-product-banner .page-wrap {
    max-width: 90%;
    flex-direction: row;
  }

  .happiests-culture-page .product-hero-wrapper figure {
    height: 100%;
  }
  .happiests-culture-page .product-hero-wrapper figure img {
    width: 100%;
    height: auto;
    padding: 0;
  }
  .alumni-page .top-hero-product-banner .page-wrap,
  .employee-beneits-page .top-hero-product-banner .page-wrap {
    flex-direction: row;
    max-width: 90%;
  }
  .training-page .product-hero-wrapper figure {
    height: 100%;
  }
  .training-page .product-hero-wrapper figure img {
    width: 100%;
    height: auto;
  }
  .training-page .product-hero-wrapper .item-wrapper figure {
    width: 100px;
  }
  .employee-beneits-page .product-hero-wrapper figure {
    height: 100%;
  }
  .employee-beneits-page .product-hero-wrapper figure img {
    width: 100%;
    height: auto;
  }
  .alumni-page .product-hero-wrapper figure {
    height: 100%;
  }
  .alumni-page .product-hero-wrapper figure img {
    width: 100%;
    height: auto;
  }
  .degree360-page .top-hero-product-banner .page-wrap {
    max-width: 90%;
    flex-direction: row;
  }
  .degree360-page .product-hero-wrapper figure {
    height: 300px;
  }

  .business3-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 36px;
  }
  .business3-page .image-title-layout .bg-image-wrapper:after {
    background: rgba(255, 255, 255, 0);
  }
  .business3-page .product-hero-wrapper .col-6 {
    width: 100%;
  }
  .business3-page .product-hero-wrapper .col-4 {
    width: 70%;
    margin: 0 auto;
  }
  .home-page .about-virtuos {
    padding: 75px 0;
  }
  .home-page .services-container .item-wrapper {
    margin-right: 10px;
  }
  .capabilities-section .row.block1 .item-wrapper {
    width: 50%;
    margin-right: 0;
  }
  .capabilities-section .row.block2 .item-wrapper:first-child {
    width: 100%;
    margin-right: 0;
    padding: 30px;
  }
  .capabilities-section .row.block2 {
    margin-top: 50px;
  }
  .capabilities-section .row.block2 .item-wrapper {
    width: 100%;
    margin-right: 0;
  }
  .four-blocks-container .row {
    display: flex;
    flex-wrap: wrap;
  }
  .four-blocks-container .content-block {
    width: 50%;
    padding: 20px;
  }
  .filter-container #portfoliolist_s .portfolio,
  .four-blocks-container .img-block {
    width: 50%;
  }
  .four-blocks-container .img-block figure {
    display: flex;
    justify-content: center;
  }
  .four-blocks-container .img-block figure img {
    width: auto;
  }
  .filter-container {
    padding: 50px 0;
  }
  .filter-container #filters {
    margin-bottom: 50px;
  }
  .filter-container #filters li span {
    font-size: 1.2rem;
  }
  .showcase-news-cards-wrapper {
    padding: 40px 0 80px;
  }
  .showcase-news-cards-wrapper .item-wrapper {
    margin-right: 0;
  }
  .showcase-news-cards-wrapper .item figure {
    height: 175px;
  }
  .showcase-news-cards-wrapper .item .content-wrapper {
    min-height: auto;
  }
  .twitter-feeds-wrapper {
    margin-top: 0;
  }
  .home-page .cx-extra-features .item-wrapper {
    padding: 40px;
  }
  .home-page .cx-extra-features .item {
    padding: 0;
  }
  .home-page .full-height-video {
    height: 450px;
  }
  .home-page .full-height-video .item span {
    font-size: 42px;
  }
  .home-page .interest-container {
    padding: 75px 0;
  }
  .home-page .interest-container .left-img {
    position: absolute;
    top: 0;
    left: -152px;
  }
  .home-page .interest-container .right-img {
    position: absolute;
    bottom: 0;
    right: -300px;
    top: auto;
  }

  .home-page .interest-container .label {
    display: block;
  }
  .home-page .interest-container .custom-select {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .capabilities-section .row.block1 {
    margin-top: -50px;
  }
  .four-blocks-container .row .col-3:nth-child(3) {
    order: 4;
  }
  .four-blocks-container .row .col-3:nth-child(4) {
    order: 3;
  }
  .filter-container .portfolio .label {
    bottom: 0;
  }
  .filter-container .portfolio img {
    top: -30px;
  }

  .cx-now-page .cx-header-img .content-wrap figure {
    margin: 0 auto;
  }
  .careers-job-page .product-hero-wrapper {
    height: 400px;
  }

  .download-brochure .brochure-container .content {
    font-size: 1em;
  }

  .cx-header-img .section-heading {
    width: auto;
    font-size: 2em;
  }
  .cx-header-img .image-wrapper {
    display: none;
  }
  .cx-header-img .content-wrapper {
    border-right: 0;
    margin-right: 0;
  }
  .cx-header-img .links-wrapper {
    margin-right: 0;
  }
  .cx-header-img .links-wrapper ul {
    width: 45%;
  }

  .crm-cx-page .abc-wrapper .content-wrap {
    max-width: 100%;
  }
  .crm-cx-page .abc-wrapper h2 {
    top: 25%;
    width: 50%;
    font-size: 1.5em;
  }
  .crm-cx-page .abc-wrapper .abc-block {
    width: 25%;
  }
  .cx-unified-page .product-hero-wrapper .page-wrap {
    flex-direction: row;
  }
  .cx-unified-page .product-hero-wrapper .item-wrapper {
    padding-top: 0;
  }
  .cx-unified-page .image-title-layout .product-hero-wrapper figure.logo {
    width: 150px;
    margin-bottom: 10px;
  }
  .cx-unified-page .image-title-layout .product-hero-wrapper .title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .cx-unified-page .product-hero-wrapper .item-wrapper span {
    font-size: 16px;
  }
  .crm-cx-page .happy-customers-wrapper .content-wrap {
    max-width: 100%;
    margin-bottom: 30px;
    border: none;
  }

  .cxprimer-page .top-hero-product-banner .page-wrap {
    max-width: 90%;
    width: auto;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block .cx-now-logo {
    width: 100px;
  }

  /* O-digital page css STARTS here */
  .o-digital-page .product-hero-wrapper {
    height: 400px;
  }
  .o-digital-page .product-hero-wrapper figure.o-icon {
    height: 140px;
  }
  .o-digital-page .top-hero-product-banner .page-wrap {
    max-width: 90%;
    width: auto;
    height: 350px;
  }
  .o-digital-page .header-block .title {
    font-size: 1.5rem;
  }
  .o-digital-page .header-block ul.links li {
    font-size: 0.8em;
  }
  .o-digital-page .header-block .top-logo {
    font-size: 16px;
    width: 35%;
  }
  .o-digital-page .header-block .left-logo figure {
    width: 100%;
  }
  .o-digital-page .business3-page .bg-image-wrapper img {
    width: 100%;
  }
  .o-digital-page .header-block {
    padding: 50px 20px 20px 20px;
  }
  .o-digital-page .related-offerings .item {
    justify-content: flex-start;
  }
  .o-digital-page .cx-header-img .row {
    flex-direction: row;
  }
  .o-digital-page .cx-bg .content-wrapper figure {
    width: 100px;
  }
  .o-digital-page img {
    height: auto;
  }
  .o-digital-page .cx-header-img .section-desc {
    margin-bottom: 0;
  }
  .o-digital-page .consultare-services .harmony .illus img {
    width: 100%;
  }
  .o-digital-page .two-col-section .item-wrap .content-wrap {
    padding: 0;
  }
  .o-digital-page .product-hero-wrapper .page-wrap {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .o-digital-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 30px;
    width: 100%;
  }
  .o-digital-page .product-hero-wrapper figure {
    height: 280px;
  }
  .o-digital-page .image-title-layout .product-hero-wrapper span {
    font-size: 22px;
  }
  .o-digital-page .product-section p {
    font-size: 24px;
    margin: 0;
    line-height: normal;
  }
  .o-digital-page .plans-table .item-wrapper {
    width: 95%;
  }
  .o-digital-page .plans-table .item-wrapper .item {
    padding: 20px;
  }
  .o-digital-page .plans-table .item h3 {
    font-size: 24px;
    text-align: center;
    line-height: normal;
  }
  .o-digital-page .product-section .section-heading {
    font-size: 36px;
  }
  .o-digital-page .product-section p {
    font-size: 20px;
  }
  .o-digital-page .product-section {
    height: 200px;
  }
  /* O-digital page css ENDS here */

  /* cx-now-page css STARTS here */
  .cx-now-page .cx-header-img .image-wrapper figure {
    justify-content: center;
  }
  .cx-now-page .cx-header-img .image-wrapper figure img {
    height: 100%;
    width: auto;
  }
  .cx-now-page .cx-header-img .row {
    padding-bottom: 0;
  }
  .cx-now-page.home-page .main-wrapper {
    padding-top: 48px;
  }
  .cx-now-page.home-page .about-virtuos {
    padding: 30px 0;
  }
  .cx-now-page.home-page .services-container {
    padding: 30px 0 0;
  }
  .cx-now-page .cx-view-container {
    padding: 30px 0 0;
  }
  /* cx-now-page css ENDS here */

  /* w-digital page css STARTS here */
  .w-digital-page .vertical-section.last-slide .content-wrapper {
    padding: 60px 0;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block {
    top: 12%;
    left: 20%;
    right: 20%;
    width: 150px;
    margin: 0 auto;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block p {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block.josh {
    width: 250px;
    top: 12%;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block.okrx {
    width: 250px;
    top: 12%;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block.expjob {
    width: 175px;
    top: 8%;
    right: 0;
    left: 0;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block.culturo {
    width: 300px;
    top: 20%;
  }
  /* w-digital page css ENDS here */

  /* professional services page css STARTS here*/
  .professional-services-page .product-hero-wrapper {
    padding: 0;
    height: 400px;
  }
  .professional-services-page
    .standard-page
    .accordian-wrapper
    .item.active
    .accordian-content {
    height: auto;
  }
  .professional-services-page .standard-page .content-wrapper {
    padding-right: 0;
  }
  /* professional services page css ENDS here*/

  /* iov-page css STARTS here */
  .iov-page .exp-economy-section .page-wrap.row {
    display: flex;
    flex-direction: column;
  }
  .iov-page .exp-economy-section .left-section {
    width: 100%;
  }
  .iov-page .exp-economy-section .right-section {
    padding: 0;
    width: 100%;
  }
  .iov-page .ar-wrapper .left-content h3 {
    font-size: 24px;
  }
  .ar-wrapper .left-content .btn:hover {
    background-color: #fff;
    color: #000;
  }
  /* iov-page css ENDS here */

  /* about-partner-page css STARTS here */
  .about-partner-page .product-hero-wrapper .row {
    flex-direction: row;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
  }
  .about-partner-page .bg-image-wrapper img {
    width: 100%;
    height: auto;
  }
  .about-partner-page .product-hero-wrapper blockquote {
    margin: 0;
    top: -150px;
  }
  .about-partner-page .image-title-layout .product-hero-wrapper h3 {
    padding-right: 15px;
  }
  /* about-partner-page css ENDS here */

  /* Specialised partner page css STARTS here */
  .specialised-page .other-partners-wrapper {
    padding: 0 30px;
  }
  .specialised-page .main-header-img .content-wrap h1 {
    font-size: 36px;
  }
  /* Specialised partner page css ENDS here */

  /* About page css STARTS here */
  .about-page .about-page-wrapper {
    margin-top: 50px;
  }
  .about-page .product-hero-wrapper {
    height: 400px;
  }
  .about-page .product-hero-wrapper .page-wrap h3 {
    font-size: 32px;
    display: block;
  }
  .about-page .bg-image-wrapper img {
    width: 100%;
    height: auto;
  }
  .about-page .core-values-band .col-3 {
    width: 30%;
  }
  .about-page .core-values-band a {
    padding: 10px 20px;
  }
  .about-page .story-wrapper.exp-business .item figure {
    height: auto;
    order: 1;
  }
  .about-page .story-wrapper.exp-business .btn {
    min-width: auto;
    padding: 5px 25px;
  }
  .about-page .story-wrapper.exp-business .item .content-wrapper {
    min-height: 350px;
  }
  .about-page .company-mv-wrapper .mission figure img {
    margin-bottom: 20px;
  }
  .about-page .about-team-wrap .item-wrap {
    padding: 0;
  }
  .about-page .donation-section {
    padding: 40px 0;
  }
  .about-page .donation-section .page-wrap {
    padding: 40px 0 0;
  }
  .about-page .donation-section .item-wrapper {
    padding: 0 5px;
  }
  .about-page .experience-design-page {
    margin-bottom: 50px;
  }
  .about-page .about-testimonials figure img {
    width: auto;
    height: 100%;
  }
  .about-page .about-testimonials.full-text-slider-wrapper .item p {
    font-size: 16px;
    line-height: 1.8;
  }
  .about-page .blogs-section .article-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-right: 1px solid #ddd;
    margin-top: 20px;
    padding: 0 10px;
  }
  .about-page .blogs-section .article-wrapper .section-heading,
  .about-page .story-wrapper .section-heading {
    font-size: 22px;
  }
  .about-page .blogs-section .article-wrapper:last-child {
    border-right: none;
  }
  .about-page .blogs-section .article-wrapper figure {
    border: 0;
  }
  .about-page .section-breaker figure img {
    width: 100%;
    height: auto;
  }
  .about-page .about-team-wrap figure {
    width: 150px;
    margin: 0 auto;
  }
  .about-page .about-team-wrap .col-9 {
    width: 100%;
  }
  .about-page .about-team-wrap .item-wrap.col-3 {
    width: calc(8.33333% * 4);
  }
  .about-page .company-mv-wrapper .row.page-wrap {
    flex-direction: column;
  }
  .about-page .company-mv-wrapper .col-6 {
    width: 100%;
    padding: 10px;
    border: 0;
  }
  .about-page .about-team-wrap {
    border: 1px solid #eee;
  }
  .about-page .about-team-wrap .btn-wrap {
    margin-top: 30px;
  }
  .about-page .about-virtuos {
    padding: 15px 0;
  }
  .about-page .about-virtuos blockquote {
    padding: 0;
  }
  .about-page .why-virtuos {
    padding: 15px;
  }
  .about-page .why-virtuos .page-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .about-page .why-virtuos .left-section {
    padding-right: 0;
    width: 100%;
  }
  .about-page .why-virtuos .left-section .section-heading {
    font-size: 28px;
  }
  .about-page .why-virtuos .right-section {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
  }
  /* About page css ENDS here */

  /* about story page css STARTS here*/
  .about-story-page .about-content-wrapper {
    padding: 30px 0;
  }
  .about-story-page .about-content-wrapper .main-about {
    max-width: 100%;
    padding: 0;
  }
  .about-story-page .about-content-wrapper .main-about .feature-section {
    padding-top: 0;
  }
  .about-story-page .about-content-wrapper .content-wrap {
    padding: 30px 0;
    flex-direction: column;
  }
  .about-story-page .about-content-wrapper .content-wrap .text-content {
    padding: 0;
    width: 100%;
    order: 2;
  }
  .about-story-page .vistuos-story-wrapper {
    padding: 0;
  }
  .about-story-page .about-content-wrapper .section-illus {
    padding: 0;
  }
  .about-story-page
    .vistuos-story-wrappe.exp-cloud-page
    .two-col-full-section.accordian-section
    .content-wrapperr {
    padding-bottom: 50px;
  }
  .about-story-page .vistuos-story-wrapper .item figure {
    height: 200px;
  }
  .about-story-page .vistuos-story-wrapper .item figure img {
    width: 100%;
    height: auto;
  }
  .about-story-page .vistuos-story-wrapper .item-wrapper {
    padding: 0 5px;
  }
  .about-story-page .about-culture h2 .icon {
    width: auto;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .about-story-page .vistuos-story-wrapper .item h3 {
    font-size: 24px;
  }
  .about-story-page .vistuos-story-wrapper .item .btn {
    margin: 10px 0;
  }
  /* about story page css STARTS here*/

  /* professional services page css STARTS here */
  .professional-services-page .bg-image-wrapper img {
    -webkit-transform: scale(1.7) translateX(-100px);
    transform: scale(1.7) translateX(-100px);
  }
  .professional-services-page .product-hero-wrapper .item-wrapper {
    width: 100%;
    text-align: left;
  }
  .professional-services-page .product-hero-wrapper .row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .professional-services-page .product-hero-wrapper span img {
    width: 70px;
    height: 20px;
  }
  /* professional services page css ENDS here */

  /* budh page css STARTS here */
  .budh-page .product-hero-wrapper {
    height: 300px;
  }
  .budh-page .product-hero-wrapper .row.page-wrap {
    flex-direction: row;
  }
  .budh-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 24px;
  }
  .budh-page .product-hero-wrapper .col-4-half figure {
    height: auto;
    padding: 0;
  }
  .budh-page .product-hero-wrapper .item-wrapper figure {
    width: 60%;
    height: 50px;
    margin-bottom: 10px;
  }
  .budh-page .two-col-section .item {
    padding: 0 20px;
  }
  .budh-page .two-col-section .item h3 {
    margin-bottom: 20px;
  }
  /* budh page css ENDS here */

  /* ai-page css STARTS here */
  .ai-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 24px;
    display: block;
  }
  .ai-page .product-hero-wrapper span {
    font-size: 20px;
  }
  .ai-page .product-hero-wrapper {
    height: 400px;
  }
  .ai-page .skills-container figure {
    right: -100px;
  }
  .ai-page .advantages-of-aury .points li {
    padding: 20px;
    box-sizing: border-box;
  }
  .ai-page .ecosystem-section .page-wrap.row {
    padding: 0;
  }
  /* ai-page css ENDS here */

  /* experience cloud page css STARTS here */
  .exp-cloud-page .exp-business .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .exp-cloud-page .exp-business .item-wrapper,
  .exp-cloud-services .row .item-wrapper {
    width: 50%;
  }
  .exp-cloud-page .two-col-full-section.accordian-section .content-wrapper {
    padding: 40px 0;
  }
  .exp-cloud-page .accordian-wrapper .item.active .accordian-content {
    height: 120px;
  }
  .exp-cloud-page .accordian-wrapper .item.active .accordian-content p {
    padding: 0 20px;
  }
  /* experience cloud page css ENDS here */

  /* talentare page css STARTS here */
  .talentare-page .product-hero-wrapper {
    height: 300px;
  }
  .talentare-page .talentare-job-openings .item {
    border: 1px solid #ddd;
    padding: 15px;
  }
  .talentare-page .business3-tab-section {
    padding-bottom: 0;
  }
  .talentare-page .vertical-tab-content .sticky-content {
    margin-bottom: 30px;
  }
  .talentare-page .block-over-image-section .illus figure img {
    transform: scale(1.5);
  }
  .talentare-page .block-over-image-section .content-wrapper {
    margin-left: 0;
  }
  .talentare-page .block-over-image-wrapper.ej .content-wrapper:hover {
    margin-left: 0;
  }
  /* talentare page css ENDS here */

  /* experience design page css STARTS here */
  .experience-design-page .section-breaker {
    padding-bottom: 60px;
  }
  .experience-design-page .about-team-wrap {
    border: 1px solid #eee;
    margin-bottom: 0;
  }
  /* experience design page css ENDS here */

  /* technology services page css STARTS here */
  .technology-services-page .product-hero-wrapper {
    height: 300px;
  }
  .technology-services-page .two-col-full-section.cloudle {
    height: auto;
  }
  .technology-services-page .two-col-full-section.cloudle .illus figure {
    padding: 10px 0;
  }
  .technology-services-page
    .two-col-full-section.cloudle
    .content-wrapper
    .section-heading {
    font-size: 24px;
  }
  .technology-services-page .cloudle-wrapper .col-7.content-wrapper {
    width: 100%;
    padding: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .technology-services-page .cloudle-wrapper .col-7.content-wrapper h3 {
    font-size: 36px;
  }
  .technology-services-page
    .cloudle-wrapper
    .col-7.content-wrapper
    .inline-logo-wrapper {
    width: 250px;
  }
  .technology-services-page .product-hero-wrapper .page-wrap {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .technology-services-page .product-hero-wrapper .page-wrap [class*="col-"] {
    width: 90%;
  }
  .technology-services-page .product-hero-wrapper .page-wrap .col-5 {
    width: 60%;
  }
  /* technology services page css ENDS here */

  /* customer voice page css STARTS here */
  .cvoice-page .product-hero-wrapper {
    height: 300px;
  }
  .cvoice-page .image-title-layout .product-hero-wrapper h3 {
    width: 100%;
    font-size: 24px;
  }
  .cvoice-page .product-hero-wrapper figure {
    width: 80%;
    margin: 0;
  }
  .cvoice-page .product-hero-wrapper .page-wrap {
    flex-direction: row;
  }
  .cvoice-page .product-hero-wrapper a {
    font-size: 20px;
  }
  .cvoice-page .next-steps-wrapper {
    padding: 0;
  }
  .cvoice-page .data .illus img {
    width: 100%;
  }
  .cvoice-page .next-steps-wrapper .item {
    width: 100%;
    padding: 10px;
  }
  .cvoice-page .develop .item-wrap {
    padding: 20px;
  }
  .cvoice-page .rich-vein .illus figure {
    width: 70%;
  }
  .cvoice-page .two-col-fig-content-wrapper .points {
    padding: 0 15px;
  }
  .cvoice-page .two-col-fig-content-wrapper figure {
    overflow: hidden;
  }
  .cvoice-page .advantages-of-aury .points p {
    margin: 0;
  }
  .cvoice-page .two-col-fig-content-wrapper figure img {
    width: auto;
    height: 100%;
  }
  /* customer voice page css ENDS here */

  /* cjourney page css STARTS here */
  .cjourney-page .product-hero-wrapper {
    height: 400px;
    padding: 30px 0;
  }
  .cjourney-page .product-hero-wrapper figure {
    height: auto;
    padding: 20px;
    width: 80%;
    margin: 0 auto;
  }
  .cjourney-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .cjourney-page .product-hero-wrapper .item-wrapper figure {
    width: 200px;
    padding: 0;
    margin: 0 0 15px 0;
  }
  .cjourney-page .product-hero-wrapper .consumer-cycle {
    width: 80%;
  }
  .cjourney-page .image-title-layout .product-hero-wrapper h3 {
    padding-left: 0;
    width: 100%;
    font-size: 22px;
  }
  .cjourney-page .customer-voice-wrapper .item-container {
    margin-top: 0;
  }
  .cjourney-page .next-steps-wrapper .item {
    margin-right: 0;
  }
  .cjourney-page .next-steps-wrapper .row {
    margin-top: 0;
  }
  .cjourney-page .next-steps-wrapper {
    padding: 40px 15px;
  }
  .cjourney-page .advantages-of-aury .how figure img {
    width: 70%;
  }
  .cjourney-page .left-border {
    padding: 0 15px;
  }
  /* cjourney page css ENDS here */

  .cxaudit-page .top-hero-product-banner {
    height: 300px;
  }

  /* culture page css STARTS here */
  .culture-page .product-hero-wrapper figure {
    height: 100%;
    right: -200px;
  }
  .culture-page .product-hero-wrapper figure img {
    width: auto;
    height: 100%;
  }
  .culture-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .culture-page .vertical-clickable-tabs ul li {
    width: 33.3%;
    margin-right: 10px;
  }
  .culture-page .vertical-clickable-tabs ul li .item {
    width: 100%;
  }
  /* culture page css ENDS here */

  /* life at virtuos page css STARTS here */
  .life-at-virtuos-page .video-wrapper .page-wrap {
    flex-direction: column-reverse;
  }
  .life-at-virtuos-page .video-wrapper .page-wrap .col-4,
  .life-at-virtuos-page .video-wrapper .page-wrap .col-7 {
    width: 100%;
    text-align: center;
  }
  .life-at-virtuos-page .video-wrapper .page-wrap .col-4 {
    margin-top: 20px;
  }
  .life-at-virtuos-page .product-hero-wrapper figure {
    height: 100%;
  }
  .life-at-virtuos-page .product-hero-wrapper figure img {
    width: 100%;
    height: auto;
    padding: 0;
  }
  /* life at virtuos page css ENDS here */

  /* experience job page css STARTS here */
  .exp-job-page .top-hero-product-banner .item-wrapper .section-heading,
  .exp-job-page .top-hero-product-banner .item-wrapper span {
    font-size: 24px;
  }
  .exp-job-page .full-text-slider-wrapper {
    padding: 0;
  }
  /* experience job page css ENDS here */

  .opportunities-page .job-openings-section .job-item-wrapper {
    height: 250px;
  }
  .opportunities-page .alternate-content-section .btn-primary {
    margin-bottom: 10px;
  }

  /* training and develpment page css STARTS here */
  .training-page .top-hero-product-banner .page-wrap figure img {
    padding: 0;
  }
  /* training and develpment page css ENDS here */

  /* alumni page css STARTS here */
  .alumni-page .top-hero-product-banner .page-wrap figure img {
    padding: 0;
  }
  /* alumni page css ENDS here */

  /* 360 degree page css STARTS here */
  .degree360-page .product-hero-wrapper .col-4,
  .degree360-page .product-hero-wrapper .col-7 {
    width: 50%;
  }
  .degree360-page .image-title-layout .product-hero-wrapper .logos {
    width: 100%;
    display: flex;
  }
  .degree360-page .image-title-layout .product-hero-wrapper .logos img {
    width: 50px;
  }
  /* 360 degree page css ENDS here */

  /* experience cloud page css STARTS here */
  .exp-cloud-page .product-hero-wrapper figure {
    padding: 0;
    height: 100%;
  }
  .exp-cloud-page .product-hero-wrapper {
    height: 400px;
  }
  /* experience cloud page css ENDS here */

  /* c-digital page css STARTS here */
  .cdigital-page .header-container figure {
    height: 300px;
  }
  .cdigital-page .header-container figure img {
    width: 100%;
    transform: scale(1.2);
  }
  .cdigital-page .header-container .header-block .title {
    font-size: 2em;
  }
  .cdigital-page .crm-cx-container,
  .cdigital-page .spotlight-container,
  .cdigital-page .numbers-container {
    padding: 60px 0;
  }
  .cdigital-page .crm-cx-container .title-content {
    margin-bottom: 60px;
  }
  .cdigital-page .crm-cx-container .page-wrap:last-child {
    flex-direction: column;
  }
  .cdigital-page .crm-cx-container .items {
    width: 100%;
    margin-bottom: 30px;
  }
  .cdigital-page .spotlight-container .page-wrap {
    flex-wrap: wrap;
  }
  .cdigital-page .spotlight-container .content-block {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .cdigital-page .spotlight-container .hightlight-block {
    width: 70%;
  }
  .cdigital-page .xyz-video-container {
    padding: 60px 0 250px 0;
  }
  .cdigital-page .xyz-video-container .content-block {
    font-size: 2em;
    line-height: normal;
  }
  .cdigital-page .xyz-video-container .content-block .icon {
    width: 42px;
  }
  .cdigital-page .crm-container .item {
    margin-right: 10px;
    padding: 30px 20px 20px 20px;
  }
  .cdigital-page .filter-container #filters li span {
    font-size: 1rem;
    padding: 0;
  }
  .cdigital-page .flip-container .page-wrap {
    flex-wrap: wrap;
  }
  .cdigital-page .flip-container .item {
    margin-right: 0;
    width: 50%;
    margin-bottom: 30px;
  }
  .cdigital-page .flip-container .front {
    padding: 10px;
  }
  .cdigital-page .company-hightlight {
    margin: 30px 0;
  }
  .cdigital-page .company-hightlight .row {
    flex-direction: column;
  }
  .cdigital-page .company-hightlight .img-container {
    width: 100%;
    height: 400px;
  }
  .cdigital-page .company-hightlight .content-container {
    padding: 40px;
    width: 100%;
  }
  .cdigital-page .company-hightlight .content-container .title {
    font-size: 2em;
  }
  .cdigital-page .industry-container .row:last-child {
    flex-wrap: wrap;
  }
  .cdigital-page .industry-container .row .item {
    margin-right: 0;
    text-align: center;
    width: 48%;
  }
  .cdigital-page .industry-container .front .overlay {
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .cdigital-page .industry-container .front .overlay .content {
    padding: 20px;
  }
  .cdigital-page .industry-container .front .overlay .content a {
    color: #fff;
  }
  .cdigital-page .industry-container .front .overlay .content p {
    line-height: normal;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #ff0000;
  }
  .cdigital-page .industry-container .front:hover .overlay .content {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    color: #fff;
  }
  .cdigital-page .customer-success {
    padding: 0;
    margin: 60px 0;
  }
  .cdigital-page .customer-success .row {
    display: flex;
    flex-direction: column;
  }
  .cdigital-page .customer-success .img-container {
    height: 400px;
    width: 100%;
  }
  .cdigital-page .customer-success .content-container {
    width: 100%;
    padding: 30px;
  }
  .cdigital-page .customer-success .content-container .title {
    font-size: 2em;
  }
  .cdigital-page .customer-success .content-container ul.points {
    margin-bottom: 0;
  }
  .cdigital-page .cx-portflio-container .row {
    flex-direction: column;
  }
  .cdigital-page .cx-portflio-container .row .col-5 {
    width: 100%;
  }
  .cdigital-page .overview-container {
    padding: 60px 0;
  }
  .cdigital-page .overview-container .row {
    flex-direction: column;
  }
  .cdigital-page .overview-container .content-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .cdigital-page .overview-container .row .col-5 {
    width: 100%;
    background-color: #fff;
    z-index: 11;
    padding: 30px;
    box-sizing: border-box;
  }
  .cdigital-page .clients-container {
    margin: 60px 0;
  }
  .cdigital-page .testi-container {
    padding: 60px 0;
  }
  .cdigital-page .testi-container .row:last-child {
    flex-wrap: wrap;
  }
  .cdigital-page .testi-container .testi-wrapper {
    padding: 40px;
    margin-right: 0;
    width: 100%;
    border-bottom: 1px solid #333;
    box-sizing: border-box;
  }
  .cdigital-page .testi-container .testi-wrapper:last-child {
    border: none;
  }
  .cdigital-page .tabs-container {
    padding: 0;
  }
  .cdigital-page .tabs-container .tabcontent {
    display: flex;
    flex-direction: column;
  }
  .cdigital-page .tabs-container .tabcontent .box1 {
    width: 100%;
    height: 400px;
  }
  .cdigital-page .tabs-container .tabcontent .box2,
  .cdigital-page .tabs-container .tabcontent .box3 {
    width: 100%;
    height: auto;
    padding: 40px;
  }
  .cdigital-page .dthink-container {
    padding: 60px 0;
  }
  .cdigital-page .dthink-container .row {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }
  .cdigital-page .dthink-container .col-3 {
    margin-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  .cdigital-page .dthink-container .col-2 {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    border: 0;
    margin-right: 0;
  }
  .cdigital-page .blog-container {
    margin: 30px 0;
  }
  .cdigital-page .dthink-container .col-2 .icon {
    margin-bottom: 0;
    margin-right: 20px;
  }
  .cdigital-page .blog-container .blog {
    padding: 20px;
  }
  /* c-digital page css ENDS here */

  /* Qualtrics page css STARTS here */
  .qualtrics-page .header-container figure {
    height: 300px;
  }
  .qualtrics-page .header-container figure img {
    width: 100%;
    transform: scale(1.2);
  }
  .qualtrics-page .header-container .header-block .title {
    font-size: 2em;
  }
  .qualtrics-page .qxm-practice,
  .qualtrics-page .cxnow-qualtrics,
  .qualtrics-page .numbers-container {
    padding: 30px 0;
  }
  .qualtrics-page .qxm-practice .title-content {
    margin-bottom: 60px;
  }
  .qualtrics-page .qxm-practice .page-wrap:last-child {
    flex-direction: column;
  }
  .qualtrics-page .qxm-practice .items {
    width: 100%;
    margin-bottom: 30px;
  }
  .qualtrics-page .cxnow-qualtrics .page-wrap {
    flex-wrap: wrap;
  }
  .qualtrics-page .cxnow-qualtrics .content-block {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .qualtrics-page .cxnow-qualtrics .hightlight-block {
    width: 70%;
  }
  .qualtrics-page .qxm-video {
    padding: 60px 0 250px 0;
  }
  .qualtrics-page .qxm-video .content-block {
    font-size: 2em;
    line-height: normal;
  }

  .qualtrics-page .crm-container .item {
    margin-right: 10px;
    padding: 30px 20px 20px 20px;
  }
  .qualtrics-page .filter-container #filters li span {
    font-size: 1rem;
    padding: 0;
  }
  .qualtrics-page .flip-container .page-wrap {
    flex-wrap: wrap;
  }
  .qualtrics-page .flip-container .item {
    margin-right: 0;
    width: 50%;
    margin-bottom: 30px;
  }
  .qualtrics-page .flip-container .front {
    padding: 10px;
  }

  .qualtrics-page .qxm-benefit {
    margin: 30px 0;
  }
  .qualtrics-page .qxm-benefit .row {
    flex-direction: column;
  }
  .qualtrics-page .qxm-benefit .img-container {
    width: 100%;
    height: 400px;
  }
  .qualtrics-page .qxm-benefit .content-container {
    padding: 40px;
    width: 100%;
  }
  .qualtrics-page .qxm-benefit .content-container .title {
    font-size: 2em;
  }

  .qualtrics-page .team-container .row:last-child {
    flex-wrap: wrap;
  }
  .qualtrics-page .team-container .row .item {
    margin-right: 0;
    text-align: center;
    width: 48%;
  }
  .qualtrics-page .team-container .front .overlay {
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .qualtrics-page .team-container .front .overlay .content {
    padding: 20px;
  }
  .qualtrics-page .team-container .front .overlay .content a {
    color: #fff;
    margin: 0 10px;
  }
  .qualtrics-page .team-container .front .overlay .content p {
    line-height: normal;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #ff0000;
  }
  .qualtrics-page .team-container .front:hover .overlay .content {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    color: #fff;
  }
  .qualtrics-page .customer-success {
    padding: 0;
    margin: 60px 0;
  }
  .qualtrics-page .customer-success .row {
    display: flex;
    flex-direction: column;
  }
  .qualtrics-page .customer-success .img-container {
    height: 400px;
    width: 100%;
  }
  .qualtrics-page .customer-success .content-container {
    width: 100%;
    padding: 30px;
  }
  .qualtrics-page .customer-success .content-container .title {
    font-size: 2em;
  }
  .qualtrics-page .customer-success .content-container ul.points {
    margin-bottom: 0;
  }
  .qualtrics-page .cx-portflio-container .row {
    flex-direction: column;
  }
  .qualtrics-page .cx-portflio-container .row .col-5 {
    width: 100%;
  }
  .qualtrics-page .overview-container {
    padding: 60px 0;
  }
  .qualtrics-page .overview-container .row {
    flex-direction: column;
  }
  .qualtrics-page .overview-container .content-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .qualtrics-page .overview-container .row .col-5 {
    width: 100%;
    background-color: #fff;
    z-index: 11;
    padding: 30px;
    box-sizing: border-box;
  }
  .qualtrics-page .clients-container {
    margin: 60px 0;
  }
  .qualtrics-page .testi-container {
    padding: 60px 0;
  }
  .qualtrics-page .testi-container .row:last-child {
    flex-wrap: wrap;
  }
  .qualtrics-page .testi-container .testi-wrapper {
    padding: 40px;
    margin-right: 0;
    width: 100%;
    border-bottom: 1px solid #333;
    box-sizing: border-box;
  }
  .qualtrics-page .testi-container .testi-wrapper:last-child {
    border: none;
  }
  .qualtrics-page .tabs-container {
    padding: 0;
  }
  .qualtrics-page .tabs-container .tabcontent {
    display: flex;
    flex-direction: column;
  }
  .qualtrics-page .tabs-container .tabcontent .box1 {
    width: 100%;
    height: 400px;
  }
  .qualtrics-page .tabs-container .tabcontent .box2,
  .qualtrics-page .tabs-container .tabcontent .box3 {
    width: 100%;
    height: auto;
    padding: 40px;
  }
  .qualtrics-page .dthink-container {
    padding: 60px 0;
  }
  .qualtrics-page .dthink-container .row {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }
  .qualtrics-page .dthink-container .col-3 {
    margin-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  .qualtrics-page .dthink-container .col-2 {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    border: 0;
    margin-right: 0;
  }
  .qualtrics-page .leadership {
    margin: 0;
    padding: 0;
  }
  .qualtrics-page .dthink-container .col-2 .icon {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .qualtrics-page .team-container .front .overlay {
    align-items: flex-end;
    background: rgba(0, 0, 0, 0);
  }
  .qualtrics-page .team-container .front .overlay .content {
    background: #000;
  }
  .qualtrics-partner .row {
    flex-direction: column;
  }
  .qualtrics-page .qualtrics-partner .col-6 {
    width: 100%;
    box-sizing: border-box;
  }
  .qualtrics-page .qualtrics-partner {
    padding: 0;
    margin: 0;
  }
  .qualtrics-page .qualtrics-partner .content-container .title {
    font-size: 32px;
  }
  .qualtrics-page .leadership .header h2 {
    font-size: 24px;
  }

  /* Qualtrics page css ENDS here */

  /* home page css STARTS here */
  .main-home-parallax-slider .item .home-bg-slider-wrapper img {
    height: 100%;
    transform: scale(2);
  }
  .main-home-parallax-slider .item:nth-child(2) .center-wrapper,
  .main-home-parallax-slider .item:nth-child(4) .center-wrapper {
    background: none;
    justify-content: center;
  }
  .main-home-parallax-slider .item .center-wrapper .btn-secondary {
    min-width: 150px;
  }
  .main-home-parallax-slider .item .center-wrapper .category {
    top: 0;
  }
  .main-home-parallax-slider .item .center-wrapper .btn-secondary {
    bottom: 0;
  }
  .main-home-parallax-slider .center-wrapper h2 {
    margin: 0 0 20px 0;
  }
  .four-blocks-container .row {
    flex-direction: column;
  }
  .home-page .four-blocks-container .img-block {
    width: 100%;
    order: 1;
  }
  .home-page .four-blocks-container .content-block {
    width: 100%;
    order: 2;
    padding: 40px;
  }
  .four-blocks-container .row .col-3:nth-child(3) {
    order: 3;
  }
  .four-blocks-container .row .col-3:nth-child(4) {
    order: 4;
  }
  .home-page .dt-container {
    padding: 60px 0;
  }
  .home-page .dt-container .row {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  .home-page .dt-container .item {
    width: 100%;
    box-sizing: border-box;
  }
  .home-page .filter-container {
    padding: 0 0 60px 0;
  }
  /* home page css ENDS here */

  /* cx-strategy page css STARTS here */
  .product-hero-wrapper .row {
    flex-direction: row;
  }
  .cx-strategy-page .product-hero-wrapper {
    height: 375px;
  }
  .cx-strategy-page .product-hero-wrapper figure {
    height: auto;
    padding: 0;
    width: 100%;
    margin-left: 20px;
  }
  .cx-strategy-page .product-hero-wrapper .item-wrapper {
    text-align: left;
  }
  .cx-strategy-page .gen .btn-wrapper {
    text-align: left;
    margin-left: 50px;
  }
  .cx-strategy-page .gen .col-6:first-child {
    width: 40%;
  }
  .cx-strategy-page .gen .col-6:last-child {
    width: 60%;
  }
  .cx-strategy-page .gen .btn-wrapper {
    text-align: left;
    margin-left: 50px;
  }
  .cx-strategy-page .ecosystem-section .gen figure {
    display: flex;
    align-items: flex-end;
  }
  .cx-strategy-page .ecosystem-section .gen figure img {
    height: auto;
    width: 100%;
  }
  /* cx-strategy page css ENDS here */

  /* cx-cntinuum-page css STARTS here */
  .cx-continuum-page .product-hero-wrapper .page-wrap {
    height: 300px;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cx-continuum-page .product-hero-wrapper .col-6 figure {
    display: none;
  }
  .cx-continuum-page .header-block {
    padding: 50px 20px 20px 20px;
  }
  .cx-continuum-page .header-block .top-logo {
    font-size: 1em;
  }
  .cx-continuum-page .header-block .title {
    font-size: 1.5rem;
  }
  .cx-continuum-page .header-block ul.links li {
    font-size: 0.8em;
  }
  .cx-continuum-page .header-block .left-logo figure img {
    width: 100%;
  }

  /* cx-cntinuum-page css ENDS here */

  /* coonsultare-page css STARTS here */
  .consultare-page .header-slider-container ul li {
    display: table-cell;
  }
  .consultare-page .header-slider-container ul li div a figure {
    width: 150px;
  }
  .consultare-page .header-slider-container ul li,
  .consultare-page .header-slider-container ul li:hover,
  .consultare-page .header-slider-container ul:hover li,
  .consultare-page .header-slider-container ul:hover li:hover {
    display: table-cell;
  }
  .consultare-page .industry-container {
    padding: 60px 0;
  }
  .consultare-page .meaning-container {
    padding: 60px 0 150px;
  }
  .consultare-page .meaning-container .rgt-bg {
    right: 100px;
  }
  .consultare-page .meaning-container .pot-bg {
    right: 30px;
  }
  .consultare-page .meaning-container .row {
    display: flex;
    flex-wrap: wrap;
  }
  .consultare-page .meaning-container .col-3 {
    width: 50%;
    margin: 0 0 20px 0;
  }
  .consultare-page .meaning-container .col-6 {
    margin-left: 0;
    width: 100%;
    padding: 30px;
  }
  .consultare-page .highlights-container {
    padding: 60px 0 20px;
  }
  .consultare-page .highlights-container .row {
    display: flex;
    flex-wrap: wrap;
  }
  .consultare-page .highlights-container .item {
    width: 40%;
    margin-bottom: 40px;
  }
  .consultare-page .services-container .item .content-container {
    width: 75%;
  }
  .consultare-page .industry-container .row {
    display: flex;
    flex-wrap: wrap;
  }
  .consultare-page .industry-container .col-3 {
    margin: 0 0 40px 0;
    width: 100%;
  }
  .consultare-page .industry-container .col-2 {
    margin: 0;
    padding: 0;
  }
  .consultare-page .numbers-container .item {
    justify-content: flex-start;
    padding: 0 20px;
  }
  .consultare-page .numbers-container .item .title {
    font-size: 2em;
  }
  .consultare-page .flip-container {
    width: 100%;
    padding: 30px 0 60px 0;
  }
  .consultare-page .flip-container .iceberg-lft-bg {
    width: 110px;
    position: absolute;
    bottom: 0;
    left: 16.5%;
  }
  .consultare-page .flip-container .iceberg-rgt-bg {
    width: 90px;
    position: absolute;
    bottom: 0;
    left: 54.9%;
  }
  .consultare-page .flip-container .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .consultare-page .flip-container .item {
    width: 100%;
    margin: 10px;
  }
  .consultare-page .flip-container .front {
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .consultare-page .flip-container .back {
    box-sizing: border-box;
  }
  .consultare-page .full-height-video .item {
    z-index: 10;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    padding-top: 50px;
  }
  .consultare-page .full-height-video figure img {
    width: 100%;
    transform: scale(2);
  }
  .consultare-page .capabilities-section .row.block1 {
    margin: 0 0 30px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .consultare-page .full-height-video p {
    width: 90%;
  }
  .consultare-page .full-height-video .item span {
    font-size: 36px;
  }
  .consultare-page .capabilities-section .row.block1 .item-wrapper {
    width: 100%;
  }
  .consultare-page .applications-container .page-wrap {
    flex-direction: column;
  }
  .consultare-page .applications-container .page-wrap .item-wrapper {
    width: 100%;
  }
  /* coonsultare-page css ENDS here */

  /* bpm-page css STARTS here */
  .bpm-page .product-hero-wrapper {
    height: 400px;
  }
  .bpm-page .bg-image-wrapper img {
    transform: scale(1.5);
  }
  .bpm-page .product-hero-wrapper figure {
    padding: 0;
  }
  .bpm-page .aury-metrices .count-band .count-wrapper.animate {
    font-size: 36px;
  }
  /* bpm-page css ENDS here */

  /* xonomy page css STARTS here */
  .xonomy-page .slider-container .slider-wrapper figure img {
    transform: scale(3);
  }
  .xonomy-page .slider-container .slider-wrapper .title {
    font-size: 3em;
  }
  .xonomy-page .about-container {
    padding: 100px 0;
    background: none;
  }
  .xonomy-page .io-container {
    padding: 60px 0 0;
  }
  .xonomy-page .io-container .subtitle {
    margin-bottom: 50px;
  }
  .xonomy-page .io-container .io-portfolio-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
  .xonomy-page .io-container .io-portfolio-container .item {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .xonomy-page .blog-container {
    padding: 0;
  }
  .xonomy-page .clients-container figure {
    padding: 10px;
  }
  .xonomy-page .form-container {
    padding-bottom: 100px;
  }
  .xonomy-page .blog-container .row:last-child {
    display: flex;
    flex-wrap: wrap;
  }
  .xonomy-page .blog-container .blog-wrapper {
    width: 100%;
    margin: 0 0 20px 0;
  }
  /* xonomy page css ENDS here */

  /* new-page css STARTS here */
  .rigour-page .top-hero-product-banner .right-fixed-logo {
    width: 100px;
  }
  .rigour-page .main-header .logo-wrapper .page-title.alliance-logo img {
    width: 70px;
  }
  .rigour-page .product-hero-wrapper .page-wrap,
  .rigour-page .product-hero-wrapper,
  .rigour-page .product-hero-wrapper figure {
    height: 350px;
  }
  .rigour-page .product-hero-wrapper .title {
    font-size: 2.5em;
  }
  .rigour-page .block2-container {
    padding: 60px 0;
  }
  .rigour-page .block2-container .col-4:nth-child(1),
  .rigour-page .block2-container .col-4:nth-child(2) {
    width: 50%;
  }
  .rigour-page .block2-container .col-4:nth-child(3) {
    width: 100%;
    padding-top: 20px;
  }
  .rigour-page .block2-container figure img {
    padding-right: 0;
  }
  .rigour-page .block2-container .page-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .rigour-page .block3-container {
    padding: 30px 0;
  }
  .rigour-page .block3-container .row {
    flex-direction: column;
  }
  .rigour-page .block3-container .row .col-5 {
    width: 100%;
  }
  .rigour-page .portfolio-container .page-wrap {
    max-width: 100%;
  }
  .rigour-page .portfolio-container .item {
    padding: 2px;
  }
  .rigour-page .portfolio-container .page-wrap .col-3 {
    width: 100%;
    display: flex;
  }
  .rigour-page .portfolio-container .page-wrap .col-6 {
    width: 100%;
  }
  .rigour-page .portfolio-container .item .overlay {
    margin: 0;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
  .rigour-page .people-container,
  .rigour-page .blog-container {
    padding: 60px 0;
  }
  .rigour-page .block3-container .progress-bar-container {
    margin: 30px 0;
  }
  .rigour-page .block2-container .title {
    font-size: 2.5em;
    width: 70%;
  }
  .rigour-page .product-hero-wrapper .content {
    font-size: 14px;
  }
  .rigour-page .product-hero-wrapper .title {
    font-size: 2em;
    margin-bottom: 20px;
  }
  .rigour-page .product-hero-wrapper .page-wrap {
    height: 450px;
  }
  .rigour-page .slider1,
  .rigour-page .slider2,
  .rigour-page .slider3 {
    background-position: 35% 0;
  }
  .rigour-page .product-hero-wrapper {
    height: 450px;
  }
  /* new-page css ENDS here */

  /* cx-story page css STARTS here */
  .cx-story-page .connect-wrapper .page-wrap {
    padding: 50px 15px;
  }
  .cx-story-page .two-col-fig-content-wrapper .points {
    width: 100%;
    padding: 0;
  }
  .cx-story-page .two-col-fig-content-wrapper .page-wrap.row {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }
  .cx-story-page .two-col-fig-content-wrapper .logo figure {
    margin-bottom: 20px;
  }
  .cx-story-page .two-col-fig-content-wrapper .page-wrap.row.indiafirst {
    flex-direction: column-reverse;
  }
  .cx-story-page .two-col-fig-content-wrapper .page-wrap.row.barc {
    flex-direction: column-reverse;
  }
  .cx-story-page .image-title-layout .product-hero-wrapper figure.logo {
    width: 150px;
    height: 50px;
    margin-bottom: 5px;
  }
  .cx-story-page .image-title-layout .product-hero-wrapper .title {
    font-size: 18px;
    margin-bottom: 0;
  }
  .cx-story-page .two-col-fig-content-wrapper .page-wrap.row.team-pics {
    flex-direction: row;
  }
  .cx-story-page .two-col-fig-content-wrapper .page-wrap.row.team-pics img {
    height: auto;
  }
  /* cx-story page css STARTS here */

  .aury-page.rigour-page .product-hero-wrapper {
    height: 300px;
  }
  .aury-page.rigour-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1.2);
  }
  .aury-page.rigour-page .product-hero-wrapper figure {
    width: 200px;
    height: 50px;
  }

  .aury-page.self-service-page .product-hero-wrapper figure {
    height: auto;
    padding: 0;
  }
  /* new home page changes css STARTS here */
  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  .home-page .about-virtuos {
    padding: 40px 0 150px 0;
  }
  .home-page .about-virtuos .page-wrap {
    padding-top: 0;
  }
  .about-virtuos .row.block2 {
    flex-direction: column;
  }
  .about-virtuos .row.block2 .item-wrapper:first-child {
    padding-left: 0;
    width: 100%;
    margin: 0;
  }
  .about-virtuos .row.block2 .left-content {
    text-align: center;
    font-size: 2rem;
  }
  .about-virtuos .row.block2 .highlight {
    font-size: 2rem;
  }
  .about-virtuos .row.block2 .col-8.item-wrapper {
    width: 100%;
    background-size: contain;
  }
  .home-page .services-container {
    padding: 40px 0 0;
  }
  .home-page .about-virtuos .lft-bg {
    width: 75%;
    left: 0;
    bottom: -70px;
    right: 0;
    margin: 0 auto;
  }
  .home-page .dt-container .lft-bg,
  .filter-container .rgt-bg {
    display: none;
  }
  .showcase-news-cards-wrapper {
    padding: 40px 0;
  }
  .clients-list h2 {
    margin-bottom: 0;
  }
  .clients-list li {
    height: 50px;
    background: none;
  }
  .clients-list {
    padding: 30px 0;
  }
  .twittie {
    padding: 60px 0 150px 0;
  }
  .twitter-feeds-wrapper {
    background-color: #fff;
    width: 95%;
    height: 250px;
    border-radius: 10px;
  }
  .twitter-feeds-wrapper .tweet {
    width: 80%;
    top: 120px;
  }
  .twitter-feeds-wrapper .btn-twitter {
    right: 0;
  }
  .twitter-feeds-wrapper .section-header h3 {
    top: 20px;
  }
  .twitter-feeds-wrapper .twitter-man {
    height: 300px;
    width: 100px;
    top: 100px;
    right: 20px;
  }
  .twitter-feeds-wrapper .twitter-man img {
    width: 100%;
  }
  .main-footer {
    padding: 40px 20px 200px;
  }
  .main-footer .quick-links {
    padding: 0;
  }
  .main-footer .footer-bottom {
    flex-direction: column;
  }
  .main-footer .lft-bg {
    left: -100px;
  }
  .main-footer .rgt-bg {
    right: -150px;
  }
  .footer-bottom .left-content {
    text-align: center;
  }
  .home-slider1-container {
    height: 60vh;
  }
  .home-slider1-container .slide1 {
    height: 55vh;
  }
  .home-slider1-container .slide1 .content {
    width: 80%;
  }
  .home-slider1-container .slide1 .content h1 {
    font-size: 2em;
  }

  #home-slide1 svg #header-img {
    transform: translateY(0);
  }

  .o-digital-page.agnitor-page .product-hero-wrapper {
    padding: 0;
    height: 400px;
  }
  .o-digital-page.agnitor-page .bg-image-wrapper img {
    -webkit-transform: scale(1.7);
    transform: scale(1.7);
  }
  .o-digital-page.agnitor-page .product-hero-wrapper .page-wrap {
    width: 100%;
  }
  .o-digital-page.agnitor-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 26px;
  }

  .cxpyramid-page .cxpyramid-container,
  .cxpyramid-page .cx-different-container,
  .cxpyramid-page .cx-balance-container,
  .cxpyramid-page .pyramid-container {
    padding: 30px 0;
  }
  .cxpyramid-page .cx-different-container .highlight,
  .cxpyramid-page .cx-different-container .highlighter {
    font-size: 22px;
  }
  .cxpyramid-page .cx-different-container .col-5 figure {
    width: 250px;
  }
  .cxpyramid-page .cx-different-container .page-wrap:first-child {
    margin-bottom: 60px;
  }

  .cxpyramid-page .vertical-image-hoverable-wrapper .hoverable-tabs {
    width: 40%;
  }
  .cxpyramid-page .hoverable-content-wrapper .hoverable-tab-content .row {
    display: flex;
    flex-direction: column;
  }
  .cxpyramid-page
    .hoverable-content-wrapper
    .hoverable-tab-content
    .row
    .item-wrapper {
    width: 100%;
  }
  .cxpyramid-page .hoverable-content-wrapper .hoverable-tab-content p {
    padding: 15px;
    min-height: 100%;
    margin-top: 0;
  }
  .cxpyramid-page .vertical-image-hoverable-wrapper .item {
    width: 100%;
    box-sizing: border-box;
  }

  .rainbowcx-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1.6) translateX(-120px);
  }
  .rainbowcx-page .two-col-full-section.cloudle .bg-banner img {
    transform: scale(2.5);
  }
  .rainbowcx-page .cx-stack-container {
    padding: 30px 0 0;
  }
  .rainbowcx-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 32px;
  }
  .rainbowcx-page .exp-business {
    padding: 30px 0 30px;
  }
  .rainbowcx-page .channels .channel-img figure {
    width: 50%;
    margin: 0 15px;
  }
  .rainbowcx-page .channels .multi-img figure {
    width: 100%;
  }
  .rainbowcx-page .stack-container .multi-icons .item-wrapper {
    width: 50%;
  }
  .rainbowcx-page .cx-ex-container {
    padding: 30px 0 0;
  }
  .rainbowcx-page .digital-process-section {
    padding-bottom: 0;
    margin: 15px;
  }
  .rainbowcx-page .cx-stack-container .items-container {
    padding: 0;
    margin: 15px;
    box-sizing: border-box;
    width: 96%;
  }
  .rainbowcx-page .cx-stack-container .items-container .item {
    width: 100%;
    margin-bottom: 15px;
  }
  .rainbowcx-page .cx-stack-container .items-container .item .col-5 {
    width: 20%;
  }
  .rainbowcx-page .cx-stack-container .items-container .item .col-7 {
    width: 80%;
  }

  .cxunity-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1.7) translateX(-100px);
  }
  .cxunity-page .cxunity-container,
  .cxunity-page .rcit-container {
    padding: 30px 0;
  }
  .cxunity-page .cxunity-container blockquote {
    width: 90%;
  }
  .cxunity-page .rcit-container .item {
    padding: 0;
  }
  .cxunity-page .video-wrapper p {
    font-size: 1.2em;
  }

  .virtuosoship-page .image-title-layout .product-hero-wrapper {
    height: 300px;
  }
  .virtuosoship-page .virtuoso-container,
  .virtuosoship-page .being-container,
  .virtuosoship-page .framework-container,
  .virtuosoship-page .heuristic-container,
  .virtuosoship-page .core-container,
  .virtuosoship-page .blocks-container,
  .virtuosoship-page .mantra-container,
  .virtuosoship-page .productivity-container {
    padding: 30px 0;
  }
  .virtuosoship-page .framework-container .skills-container .item,
  .virtuosoship-page .heuristic-container .skills-container .item {
    padding: 15px;
  }
  .virtuosoship-page .framework-container .items-container .item ul li {
    width: 100%;
  }
  .virtuosoship-page .breaker-container .col-7 {
    padding: 20px;
  }
  .virtuosoship-page .breaker-container p {
    font-size: 1.2em;
  }
  .virtuosoship-page .heuristic-container .items-container,
  .virtuosoship-page .innovative-container .mission-wrapper,
  .virtuosoship-page .productivity-container .items-block {
    flex-wrap: wrap;
  }
  .virtuosoship-page .heuristic-container .item,
  .virtuosoship-page .productivity-container .items-block .item {
    width: 48%;
  }
  .virtuosoship-page .innovative-container,
  .virtuosoship-page .section-breaker figure {
    padding: 30px 0;
  }
  .virtuosoship-page .innovative-container .mission-wrapper .item-wrapper {
    width: 50%;
    margin-bottom: 10px;
  }
  .virtuosoship-page .digital-process-tabs-wrapper .item {
    width: 28%;
  }
  .virtuosoship-page
    .vertical-tab-content-wrapper
    .vertical-tab-content:before {
    display: none;
  }
  .vivacis-page .automation-container .item figure {
    display: flex;
    align-items: center;
  }
  .vivacis-page .request-call .row {
    display: flex;
    flex-direction: column;
  }
  .vivacis-page .request-call .row .left-block,
  .vivacis-page .request-call .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }

  .agnitor-page .breaker-container .left-block {
    padding: 40px;
    width: 75%;
    margin: 0 auto;
  }
  .agnitor-page .breaker-container p {
    font-size: 1.5em;
  }
  .agnitor-page .challenge-container {
    padding: 0 0 30px 0;
  }
  .agnitor-page .new-model-container,
  .agnitor-page .why-container,
  .agnitor-page .four-blocks-container {
    padding: 30px 0;
  }
  .agnitor-page .four-blocks-container .items-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .agnitor-page .four-blocks-container .items-container .item {
    padding: 0;
    width: 48%;
    margin-bottom: 15px;
  }
  .agnitor-page .new-model-container .row:nth-child(n + 2) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .agnitor-page .new-model-container .item {
    padding: 20px;
    margin: 0 0 15px 0;
    width: 48%;
  }
  .agnitor-page .consultare-services .col-6 {
    width: 100%;
    margin: 30px 0;
  }
  .agnitor-page .consultare-services .col-6 figure {
    padding: 0;
  }
  .agnitor-page .challenge-container .item {
    padding: 30px;
    border: 1px solid #eee;
  }
  .agnitor-page .challenge-container .item:last-child {
    border: 1px solid #eee;
  }
  .agnitor-page .breaker-container .bg-banner img {
    display: none;
  }

  .cxopia-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1.7);
  }
  .cxopia-page .cxopia-container,
  .cxopia-page .brand-first-container,
  .cxopia-page .cxmetrics-container,
  .cxopia-page .optimize-container {
    padding: 30px 0;
  }
  .cxopia-page .customer-voice-wrapper .item {
    width: 100%;
  }
  .cxopia-page .advantages-of-aury figure {
    padding: 0;
  }
  .cxopia-page .cxmetrics-container .diagram,
  .cxopia-page .optimize-container figure {
    width: 90%;
  }
  .cxopia-page .eight-block-container,
  .cxopia-page .technology-stack-container {
    padding: 30px 0;
  }
  .cxopia-page .eight-block-container .items-container {
    display: flex;
    flex-direction: column;
  }
  .cxopia-page
    .eight-block-container
    .digital-process-content-wrapper
    .steps-wrapper
    .item {
    min-width: 150px;
  }
  .cxopia-page .technology-stack-container figure {
    width: 90%;
    box-shadow: 0 0 15px #ccc;
  }

  .cxdatum-page .product-hero-wrapper .item-wrapper figure {
    margin: 0;
    width: 200px;
    padding: 0;
  }
  .cxdatum-page .image-title-layout .product-hero-wrapper h3 {
    width: 50%;
  }
  .cxdatum-page .cxdatum-container,
  .cxdatum-page .cxdata-container,
  .cxdatum-page .genesis-container,
  .cxdatum-page .dexp-container,
  .cxdatum-page .da-container {
    padding: 30px 0;
  }
  .cxdatum-page .breaker-container .bg-banner img {
    transform: scale(1.8);
  }
  .cxdatum-page .dexp-container .row:last-child {
    flex-wrap: wrap;
  }
  .cxdatum-page .dexp-container .item {
    margin: 0 0 30px 0;
    width: 48%;
  }

  .aury-page.rigour-page .product-hero-wrapper figure {
    width: 100%;
    height: 100%;
  }
  .aury-page.rigour-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1.4);
  }
  .aury-page.rigour-page .product-hero-wrapper .item-wrapper .top .heading {
    font-size: 36px;
  }
  .aury-page.rigour-page .ai-wrapper {
    padding: 30px 0 0;
  }
  .aury-page .reach-container {
    padding: 15px 0;
  }
  .aury-page .reach-container .item {
    padding: 0 15px;
  }
  .aury-page .purpose-container .choice-container {
    padding: 30px 0 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .aury-page .purpose-container .choice-container .items-container {
    margin: 0 15px 15px 0;
    width: 45%;
  }
  .aury-page .purpose-container .choice-container .items figure {
    width: 35%;
  }
  .aury-page .channel-container {
    padding: 0 0 30px 0;
  }
  .aury-page .poll-container,
  .aury-page .ai-robot-container {
    padding: 30px 0;
  }
  .aury-page .breaker-container .row {
    flex-wrap: wrap;
  }
  .aury-page .breaker-container .content-block {
    width: 40%;
  }
  .aury-page .breaker-container .img-block {
    width: 60%;
  }
  .aury-page .poll-container .poll-block .col-4 {
    width: 70%;
  }
  .aury-page .poll-container figure {
    width: 90%;
  }
  .aury-page .poll-container .poll-block .col-4 label {
    padding: 10px;
  }

  .crm-digital-page ul.crm {
    display: none;
  }
  .crm-digital-page .product-hero-wrapper {
    height: 300px;
  }
  .crm-digital-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 26px;
  }
  .crm-digital-page .bg-image-wrapper img {
    transform: scale(1.3) translateX(-50px);
  }
  .crm-digital-page .crm-digital-container,
  .crm-digital-page .industry-container,
  .crm-digital-page .skills-cloud-container,
  .crm-digital-page .advantage-container,
  .crm-digital-page .circle-container-vir,
  .crm-digital-page .clients-container,
  .crm-digital-page .blogs-section,
  .crm-digital-page .cust-centric-breaker {
    padding: 30px 0;
  }
  .crm-digital-page h2,
  .crm-digital-page .crm-digital-container .items-container h3 {
    font-size: 28px;
  }
  .crm-digital-page .crm-digital-container .items-container {
    padding: 30px 15px;
    flex-wrap: wrap;
  }
  .crm-digital-page .crm-digital-container .items-container figure {
    width: 50% !important;
  }
  .crm-digital-page .crm-digital-container .items-container .img-block {
    width: 100%;
  }
  .crm-digital-page .crm-digital-container .items-container .content-block {
    width: 100%;
    padding: 30px;
  }
  .crm-digital-page .crm-digital-container .items-container.pink-bg,
  .crm-digital-page .crm-digital-container .items-container.orange-bg {
    flex-direction: column-reverse;
  }
  .crm-digital-page .breaker-container .row {
    flex-wrap: wrap;
  }
  .crm-digital-page .breaker-container .content-block,
  .crm-digital-page .breaker-container .img-block {
    width: 50%;
  }
  .crm-digital-page .industry-container .items-container .item {
    width: 45%;
  }
  .crm-digital-page .skills-cloud-container h2 {
    font-size: 5em;
  }
  .crm-digital-page .advantage-container .items-container {
    padding: 0;
  }
  .crm-digital-page .cust-centric-breaker .items-container .left,
  .crm-digital-page .cust-centric-breaker .items-container .mid,
  .crm-digital-page .cust-centric-breaker .items-container .right {
    width: 100%;
    padding: 30px;
  }
  .crm-digital-page .cust-centric-breaker .items-container {
    flex-wrap: wrap;
    text-align: center;
  }
  .crm-digital-page .speed-container {
    padding: 30px 0 0 0;
  }
  .crm-digital-page .circle-container-vir .number {
    width: 50px;
    height: 50px;
    font-size: 28px;
  }
  .crm-digital-page .speed-container .items-container {
    flex-wrap: wrap;
  }
  .crm-digital-page .speed-container .items-container .item-block {
    width: 50%;
    order: 2;
  }
  .crm-digital-page .speed-container .items-container .mid-block {
    width: 100%;
    order: 1;
    margin-bottom: 30px;
  }
  .crm-digital-page .speed-container .items-container .mid-block figure {
    width: 25%;
    margin: 0 auto;
  }
  .crm-digital-page .speed-container .mid-block h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .w-digital-page .image-title-layout .product-hero-wrapper {
    height: 300px;
  }
  .w-digital-page .bg-image-wrapper img {
    transform: scale(1.2);
  }
  .w-digital-page .lifecycle-container,
  .w-digital-page .story-container,
  .w-digital-page .metrics-container,
  .w-digital-page .pillars-container {
    padding: 30px 0;
  }
  .w-digital-page .metrics-container .items-container .item-rgt li:before {
    width: 120px;
    left: -120px;
  }
  .w-digital-page .it-hr-container {
    height: 600px;
    padding: 30px 0 0 0;
    align-items: flex-start;
  }
  .w-digital-page .it-hr-container figure {
    width: 600px;
  }
  .w-digital-page .it-hr-container .content {
    font-size: 42px;
    width: 100%;
    text-align: center;
  }
  .w-digital-page .how-breaker .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .w-digital-page .how-breaker .col-5,
  .w-digital-page .how-breaker .col-7 {
    width: 100%;
  }
  .w-digital-page .why-breaker .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .w-digital-page .why-breaker .row .col-4 {
    width: 50%;
    margin: 0 auto;
  }
  .w-digital-page .why-breaker .row .col-7 {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
  }
  .w-digital-page .it-hr-container .striker {
    top: 150px;
  }
  .w-digital-page .how-breaker .content {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }
  .w-digital-page .how-breaker .btn-block {
    justify-content: center;
  }
  .w-digital-page .pdf-download,
  .w-digital-page .action-plan {
    padding: 30px 0 0 0;
  }
  .w-digital-page .pdf-download .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .w-digital-page .pdf-download .row .col-8 {
    margin-bottom: 20px;
  }
  .w-digital-page .pdf-download .row .col-2 {
    width: 25%;
  }

  .cx-page .product-hero-wrapper {
    height: 300px;
  }
  .cx-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 26px;
  }
  .cx-page .cx-improve-container,
  .cx-page .cx-leader-container,
  .cx-page .cx-head-container,
  .cx-page .cx-maturity-container,
  .cx-page .pressure-container,
  .cx-page .cx-roadmap-container,
  .cx-page .realtime-capability-container,
  .cx-page .realtime-cx-container,
  .cx-page .breaker-container,
  .cx-page .quote-container,
  .cx-page .centricity-container,
  .cx-page .questions-container,
  .cx-page .cx-roadmap-container {
    padding: 30px 0;
  }
  .cx-page .cx-improve-container figure {
    width: 100%;
  }
  .cx-page .breaker-container .col-1 {
    display: none;
  }
  .cx-page .breaker-container .col-3 {
    width: auto;
  }
  .cx-page .breaker-container .item {
    width: 225px;
    height: 225px;
  }
  .cx-page .breaker-container .bg img {
    transform: scale(2.6);
  }
  .cx-page .cx-head-container .items-container .item {
    width: 90%;
    margin: 0 auto;
  }
  .cx-page .realtime-capability-container .row .item {
    padding: 20px;
  }
  .cx-page .realtime-cx-container .diagram {
    padding-top: 200px;
  }
  .cx-page .realtime-cx-container .diagram .left-note {
    width: 250px;
  }
  .cx-page .realtime-cx-container .diagram .right-note {
    width: 350px;
  }
  .cx-page .future-tech-container,
  .cx-page .quote-container {
    padding: 0;
  }
  .cx-page .centricity-container .diagram figure {
    width: 100%;
  }
  .cx-page .questions-container .items-container .item {
    width: 47%;
  }
  .cx-page .breaker-container .item {
    font-size: 16px;
  }
  .cx-roadmap-container .row {
    overflow: auto;
  }
  .cx-page .cx-perception-container .item {
    padding: 15px;
    margin: 5px;
  }

  .webinar-page .webinars-wrapper .filters label {
    padding: 0;
  }
  .webinar-page .webinars-wrapper ul li {
    width: 50%;
    padding: 15px;
  }
  .webinar-page .webinars-wrapper .webinar-detail {
    width: 50%;
  }
  .webinar-page .webinars-wrapper .col-4 {
    width: 45%;
  }
  .webinar-page .webinars-wrapper .webinar-detail .title {
    font-size: 24px;
  }

  .customer360-page .retailer-challenge,
  .customer360-page .airline-challenge,
  .customer360-page .university-challenge,
  .customer360-page .key-issues,
  .customer360-page .engagement-hub {
    padding: 30px 0;
  }
  .customer360-page .retailer-challenge .items-container .left-content2 {
    left: -240px;
  }
  .customer360-page .retailer-challenge .items-container .right-content1 {
    right: -260px;
  }
  .customer360-page .retailer-challenge .items-container .right-content2 {
    right: -250px;
  }
  .customer360-page .retailer-challenge .items-container .note {
    margin-top: 100px;
    width: 100%;
  }
  .customer360-page .airline-challenge .items-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .customer360-page .airline-challenge .items-container .item {
    width: 45%;
  }
  .customer360-page .breaker-quote .col-8 {
    width: 85%;
    padding: 60px 0;
  }
  .customer360-page .key-issues a {
    width: 150px;
    height: 150px;
    font-size: 14px;
  }
  .customer360-page .engagement-hub .items-container {
    display: flex;
    flex-wrap: wrap;
  }
  .customer360-page .engagement-hub .items-container .item {
    width: 100%;
  }
  .customer360-page .col-7 {
    padding: 60px;
  }
  .customer360-page .breaker-container p {
    font-size: 1.4em;
  }

  .ci-page .image-title-layout .product-hero-wrapper {
    height: 100%;
  }
  .ci-page .image-title-layout .product-hero-wrapper p {
    font-size: 22px;
  }
  .ci-page .ci-mainstream .items-container .item {
    flex-direction: column;
  }
  .ci-page .key-issues,
  .ci-page .cust-moment,
  .ci-page .customer-moment,
  .ci-page .imp-cust-data,
  .ci-page .ci-exp,
  .ci-page .edge-app,
  .ci-page .ci-mainstream,
  .ci-page .ci-analytics,
  .ci-page .increase-capability,
  .ci-page .ci-cust-engage-hub,
  .ci-page .realtime-ceh {
    padding: 30px 0;
  }
  .ci-page .key-issues a {
    width: 150px;
    height: 150px;
    font-size: 14px;
  }
  .ci-page .virgin-train .items-container {
    padding: 30px 15px;
    flex-direction: column;
  }
  .ci-page .virgin-train .items-container .col-4 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ci-page .virgin-train .solution {
    margin-bottom: 0;
    width: 45%;
  }
  .ci-page .virgin-train .result {
    width: 50%;
  }
  .ci-page .virgin-train .result .items-container {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  .ci-page .virgin-train .result .items-container .item {
    width: 50%;
    margin: 0;
  }
  .ci-page .virgin-train h3 {
    text-align: center;
  }
  .ci-page .virgin-train .col-7 {
    width: 100%;
    margin-top: 30px;
  }
  .ci-page .virgin-train .bg-banner img {
    transform: scale(4);
  }
  .ci-page .virgin-train .col-7 .note1,
  .ci-page .virgin-train .col-7 .note2,
  .ci-page .virgin-train .col-7 .note3 {
    left: 0;
  }
  .ci-page .virgin-train .col-7 .note4,
  .ci-page .virgin-train .col-7 .note5,
  .ci-page .virgin-train .col-7 .note6 {
    right: 0;
  }
  .ci-page .virgin-train .col-7 .note5 {
    top: 160px;
  }
  .ci-page .virgin-train .col-7 .note6 {
    top: 320px;
  }
  .ci-page .virgin-train .col-7 .note1::after {
    content: "";
    width: 70px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 40px;
    right: -65px;
  }
  .ci-page .virgin-train .col-7 .note2::after {
    content: "";
    width: 90px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 10px;
    right: -65px;
  }
  .ci-page .virgin-train .col-7 .note3::after {
    content: "";
    width: 90px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 10px;
    right: -65px;
  }
  .ci-page .virgin-train .col-7 .note4::after {
    content: "";
    width: 90px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 35px;
    left: -85px;
  }
  .ci-page .virgin-train .col-7 .note5::after {
    content: "";
    width: 90px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 35px;
    left: -85px;
  }
  .ci-page .virgin-train .col-7 .note6::after {
    content: "";
    width: 90px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    top: 10px;
    left: -85px;
  }
  .ci-page .imp-cust-data .items-container .item {
    margin: 30px 0 0 0;
  }
  .ci-page .customer-moment li {
    font-size: 16px;
  }
  .ci-page .customer-moment .animated-arrows {
    font-size: 32px;
  }
  .ci-page .edge-app .item {
    margin: 10px 5px 0 5px;
  }
  .ci-page .ci-analytics .items-container,
  .ci-page .increase-capability .diagram {
    padding-top: 30px;
  }
  .ci-page .increase-capability figure {
    width: 80%;
  }
  .ci-page .increase-capability .note-left {
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .ci-page .increase-capability .note-left::before {
    content: "Situational Awareness";
    position: absolute;
    top: -25px;
    font-weight: 500;
  }
  .ci-page .increase-capability .note-right {
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    display: block;
    margin-bottom: 20px;
  }
  .ci-page .increase-capability .note-right::before {
    content: "Real-Time Orchestration";
    position: absolute;
    top: -25px;
    font-weight: 500;
  }
  .ci-page .increase-capability .note-bottom {
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    display: block;
    margin-top: 20px;
    margin-left: 0;
  }
  .ci-page .increase-capability .note-bottom::before {
    content: "Continuous Intelligence";
    position: absolute;
    top: -25px;
    font-weight: 500;
  }
  .ci-page .increase-capability li {
    position: relative;
  }
  .ci-page .ci-cust-engage-hub figure {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
  }
  .ci-page .breaker-quote .col-8 {
    width: 85%;
    padding: 30px 0;
  }
  .ci-page .realtime-ceh figure {
    width: 100%;
    margin: 0 auto;
    padding-top: 30px;
  }

  .ceh-page .image-title-layout .product-hero-wrapper {
    height: 300px;
  }
  .ceh-page .key-issues,
  .ceh-page .start,
  .ceh-page .lead-hub,
  .ceh-page .business-ceh,
  .ceh-page .stock,
  .ceh-page .technology,
  .ceh-page .link-context,
  .ceh-page .focus-container,
  .ceh-page .develop-roadmap,
  .ceh-page .enable-technology {
    padding: 30px 0;
  }
  .ceh-page .key-issues a {
    width: 150px;
    height: 150px;
    font-size: 14px;
  }
  .ceh-page .start .items-container .item figure {
    width: 50%;
  }
  .ceh-page .business-ceh .row {
    padding: 0 15px 20px 15px;
  }
  .ceh-page .technology .row .col-12 {
    overflow: auto;
  }
  .ceh-page .link-context figure {
    width: 100%;
  }
  .ceh-page .focus-container .row:last-child .col-12 {
    overflow: auto;
  }
  .ceh-page .develop-roadmap .item figure {
    width: 100px;
    height: 100px;
    padding: 20px;
  }
  .ceh-page .develop-roadmap .item .animated-arrows {
    font-size: 32px;
  }

  .business-page .image-title-layout .product-hero-wrapper {
    height: 300px;
  }
  .business-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 26px;
  }
  .business-page .business-container {
    padding: 30px 0;
  }
  .business-page .video-wrapper .page-wrap {
    flex-direction: column-reverse;
  }
  .business-page .video-wrapper .page-wrap .col-6 {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  .business-page .video-wrapper .page-wrap .col-6 figure {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .business-page .video-wrapper .page-wrap .col-5 {
    width: 100%;
    text-align: center;
  }
  .business-page .video-wrapper .page-wrap .col-5 iframe {
    width: 100%;
    height: 400px;
  }
  .business-page .breaker-container .col-7 {
    padding: 30px;
  }
  .business-page .breaker-container .bg-banner img {
    transform: scale(1.5);
  }
  .business-page .breaker-container {
    margin: 30px 0;
  }
  .business-page .business-units {
    padding: 30px 0;
  }
  .business-page .business .row .center {
    display: none;
  }
  .business-page .business .row .center ul {
    flex-direction: column;
  }
  .business-page .business .row .arrow-container h2 {
    font-size: 26px;
  }

  .sales-page .stack-model .digital-process-tabs-wrapper li.arrow {
    display: none;
  }
  .sales-page .sales-pipeline,
  .sales-page .stack-model,
  .sales-page .succeedant-container,
  .sales-page .sales-mgmt-container,
  .sales-page .dati-container,
  .sales-page .cpq-fulfillment-container,
  .sales-page .virtuos-advantage {
    padding: 30px 0;
  }
  .sales-page .cpq-container figure,
  .sales-page .cpq-fulfillment-container .row figure,
  .sales-page .sales-mgmt-container .row figure,
  .sales-page .crm-xyz-container .row figure {
    width: 100%;
  }
  .sales-page .sales-training .row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  .sales-page .sales-training figure {
    width: 85%;
    margin-bottom: 30px;
  }
  .sales-page .sales-training .left-note {
    width: 40%;
    position: relative;
    top: auto;
    left: auto;
  }
  .sales-page .sales-training .right-note {
    position: relative;
    top: auto;
    right: auto;
    width: 40%;
  }

  .service-page .partner-container,
  .service-page .broad-spectrum,
  .service-page .service-maturity,
  .service-page .crm-cx-framework,
  .service-page .four-pillars,
  .service-page .cs-covid {
    padding: 30px 0;
  }
  .service-page .partner-container .item {
    padding: 0 20px;
  }
  .service-page .support-breaker .row .left-content {
    font-size: 26px;
  }
  .service-page .four-pillars .row figure,
  .service-page .cs-covid .row figure {
    width: 100%;
  }

  .marketing-page .tech-imp,
  .marketing-page .healthy-marketing,
  .marketing-page .core-compo,
  .marketing-page .update-strategy,
  .marketing-page .strategy-framework,
  .marketing-page .abm-container,
  .marketing-page .expertise-category,
  .marketing-page .recommendation,
  .marketing-page .relationware,
  .marketing-page .marketing-leaders {
    padding: 30px 0;
  }
  .marketing-page .tech-imp figure {
    width: 50%;
  }
  .marketing-page .strategy-breaker .row .left-content h2 {
    font-size: 26px;
  }
  .marketing-page .core-compo .top-block ul {
    flex-wrap: wrap;
  }
  .marketing-page .core-compo .top-block ul li {
    width: 25%;
    margin-bottom: 20px;
  }
  .marketing-page .core-compo .bottom-block ul li {
    width: 35%;
  }
  .marketing-page .update-strategy .left-block {
    width: 40%;
    font-size: 42px;
    padding-right: 30px;
  }
  .marketing-page .update-strategy .right-block {
    padding-left: 30px;
    width: 60%;
  }
  .marketing-page .relationware ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .marketing-page .relationware ul li {
    margin: 0 5px;
  }
  .marketing-page .relationware .row .col-12:last-child ul li:nth-child(1),
  .marketing-page .relationware .row .col-12:last-child ul li:nth-child(2),
  .marketing-page .relationware .row .col-12:last-child ul li:nth-child(3),
  .marketing-page .relationware .row .col-12:last-child ul li:nth-child(4) {
    padding: 15px;
    margin: 0 5px;
  }
  .marketing-page .marketing-leaders figure,
  .marketing-page .abm-container figure {
    width: 100%;
  }
  .marketing-page .strategy-framework .row .col-4 {
    margin: 0 10px;
    width: 50%;
  }
  .marketing-page .abm-container .row {
    justify-content: space-around;
  }
  .marketing-page .abm-container .row .left-block {
    width: 40%;
  }
  .marketing-page .abm-container .row .right-block {
    width: 50%;
  }
  .marketing-page .expertise-category {
    height: 800px;
  }
  .marketing-page .expertise-category .tl-note,
  .marketing-page .expertise-category .bl-note {
    left: 30px;
  }
  .marketing-page .expertise-category .tr-note,
  .marketing-page .expertise-category .br-note {
    right: -30px;
  }
  .marketing-page .recommendation .row .left-block {
    width: 60%;
  }
  .marketing-page .recommendation .row .right-block {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .commerce-page .future-commerce,
  .commerce-page .c-digital,
  .commerce-page .magento,
  .commerce-page .dati-container,
  .commerce-page .trusted-partner {
    padding: 30px 0;
  }
  .commerce-page .future-commerce .items-container {
    margin-top: 30px;
  }
  .commerce-page .digital-business figure,
  .commerce-page .dati-container .bottom-block figure {
    width: 100%;
  }
  .commerce-page .digital-business .left-block,
  .commerce-page .digital-business .right-block {
    padding: 0 30px;
  }
  .commerce-page .magento .row,
  .commerce-page .magento p {
    margin-bottom: 30px;
  }
  .commerce-page .breaker-container .content-block {
    padding: 30px;
  }
  .commerce-page .breaker-container .content-block h3 {
    font-size: 26px;
  }
  .commerce-page .trusted-partner .row .left-block {
    width: 60%;
  }
  .commerce-page .trusted-partner .row .right-block {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .it-page .top-hero-product-banner,
  .it-page .top-hero-product-banner .bg-image-wrapper {
    height: 300px;
  }
  .it-page .product-hero-wrapper .page-wrap {
    position: static;
    max-width: 95%;
  }
  .it-page .product-hero-wrapper .item-wrapper {
    padding-right: 0;
    padding-top: 20px;
    width: 100%;
  }
  .it-page .product-hero-wrapper ul {
    box-shadow: 0 0 10px #ccc;
  }
  .it-page .product-hero-wrapper ul li a {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .it-page .product-hero-wrapper figure {
    margin: 0 auto;
    margin-right: auto;
  }
  .it-page .enterprise {
    padding: 60px 0 30px 0;
  }
  .it-page .enterprise ul li {
    width: 45%;
  }
  .it-page .technology .row ul,
  .it-page .technology .row .btn {
    margin-top: 30px;
  }
  .it-page .technology .row ul li {
    width: 100%;
    margin-bottom: 10px;
  }
  .it-page .technology .row ul li .left figure {
    height: auto;
  }
  .it-page .technology .row ul li:nth-child(3) {
    margin: 30px 0;
  }
  .it-page .technology,
  .it-page .vivacis-container,
  .it-page .fabindia-container,
  .it-page .digital-engineering,
  .it-page .cloud-container,
  .it-page .rightapps-container,
  .it-page .mastermind {
    padding: 30px 0;
  }
  .it-page .fabindia-container .bg-banner img {
    transform: scale(2.2);
  }
  .it-page .fabindia-container .row {
    align-items: center;
    flex-direction: column;
  }
  .it-page .fabindia-container .left-block {
    padding: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .it-page .fabindia-container .left-block figure {
    width: 200px;
    margin: 0 auto;
  }
  .it-page .fabindia-container .right-block {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .it-page .fabindia-container .right-block ul {
    display: flex;
    margin: 0 auto;
  }
  .it-page .vivacis-container .diagram {
    width: 100%;
    margin: 30px auto;
  }
  .it-page .vivacis-container ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .it-page .vivacis-container ul li {
    width: 22%;
    display: flex;
    align-items: center;
    margin: 10px;
  }
  .it-page .digital-engineering .row.points {
    padding-top: 30px;
  }
  .it-page .digital-engineering .platform {
    padding: 30px 0;
    align-items: center;
    flex-direction: column;
  }
  .it-page .digital-engineering .platform .col-5 {
    width: 85%;
  }
  .it-page .digital-engineering .platform .col-6 {
    width: 85%;
    margin-top: 30px;
  }
  .it-page .cloud-container .row {
    flex-direction: column;
  }
  .it-page .cloud-container .left,
  .it-page .cloud-container .right {
    width: 100%;
    margin-bottom: 30px;
  }
  .it-page .rightapps-container .row {
    flex-direction: column-reverse;
  }
  .it-page .rightapps-container .left,
  .it-page .rightapps-container .right {
    width: 100%;
    margin-bottom: 30px;
  }
  .it-page .breaker-container .content-block {
    padding: 30px;
    width: 50%;
  }
  .it-page .breaker-container .bg-banner img {
    transform: scale(1.5);
  }

  .ex-page .top-hero-product-banner {
    height: 300px;
  }
  .ex-page .employee-exp,
  .ex-page .worthy,
  .ex-page .investing-container,
  .ex-page .voice-emp,
  .ex-page .ex-practice,
  .ex-page .mastermind {
    padding: 30px 0;
  }
  .ex-page .employee-exp .right-container {
    padding: 30px;
  }
  .ex-page .employee-exp .right-container .left-block,
  .ex-page .employee-exp .right-block .right-block {
    width: 100%;
  }
  .ex-page .employee-exp .right-container .left-block {
    margin-bottom: 20px;
  }
  .ex-page .employee-exp .row.gartner {
    padding-top: 30px;
  }
  .ex-page .breaker-container .row {
    align-items: center;
    padding: 30px;
  }
  .ex-page .breaker-container .left-block {
    padding: 30px;
  }
  .ex-page .breaker-container .left-block p {
    font-size: 26px;
  }
  .ex-page .breaker-container .bg-banner img {
    transform: scale(2.2);
  }
  .ex-page .mastermind .row ul {
    width: 100%;
  }

  .vivacis-page .product-hero-wrapper .item-wrapper {
    padding-right: 0;
    width: 80%;
  }
  .vivacis-page .vivacis-container,
  .vivacis-page .agiloft-container,
  .vivacis-page .clm-breaker,
  .vivacis-page .creatio-container,
  .vivacis-page .netsuite-container,
  .vivacis-page .automation-container,
  .vivacis-page .mastermind,
  .vivacis-page .request-call {
    padding: 30px 0;
  }
  .vivacis-page .clm-breaker .mid-block ul li {
    font-size: 16px;
  }
  .vivacis-page .clm-breaker .right-block ul li {
    margin-bottom: 30px;
  }
  .vivacis-page .clm-breaker .left-block h2 {
    font-size: 22px;
    line-height: normal;
  }
  .vivacis-page .vivacis-container h2 {
    padding: 0 0 5px 0;
  }
  .vivacis-page .vivacis-container .row {
    flex-direction: column;
  }
  .vivacis-page .vivacis-container .row .left-block {
    width: 100%;
    margin-bottom: 30px;
  }
  .vivacis-page .vivacis-container .right-block {
    width: 100%;
  }
  .vivacis-page .v-tabs-container .v-tabs-wrapper {
    width: 35%;
  }
  .vivacis-page .v-tabs-container .vertical-tab-content-wrapper {
    width: 65%;
  }
  .vivacis-page
    .v-tabs-container
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .item {
    width: 100%;
  }
  .vivacis-page .clm-projects-container .item {
    padding-bottom: 70px;
  }
  .vivacis-page .h-tabs-container .vertical-tab-content-wrapper .content-block {
    padding: 30px;
  }
  .vivacis-page .netsuite-container .row.erp .item {
    margin: 0 5px;
  }
  .vivacis-page .netsuite-container .row.erp .item .content-block {
    padding: 10px;
    font-size: 16px;
  }
  .vivacis-page .netsuite-container .row.industry {
    flex-wrap: wrap;
  }
  .vivacis-page .netsuite-container .row.industry .item {
    width: 45%;
    margin-bottom: 30px;
  }
  .vivacis-page .automation-container .row .content {
    width: 100%;
    box-sizing: border-box;
  }
  .vivacis-page .automation-container .row .item {
    width: 50%;
    padding: 0 20px;
  }
  .vivacis-page .mastermind .item {
    margin-bottom: 30px;
    width: 50%;
  }
  .vivacis-page .mastermind .success {
    width: 100%;
    margin-bottom: 30px;
  }
  .vivacis-page .mastermind .people {
    width: 100%;
  }
  .vivacis-page .automation-container .row .item {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
  }
  .vivacis-page .mastermind .item {
    margin-bottom: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }
  .vivacis-page .mastermind .success {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 30px 0;
  }
  .vivacis-page .mastermind .people {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    background: none;
  }
  .vivacis-page .mastermind .people .content-block {
    width: 100%;
  }
  .vivacis-page .mastermind .people .hidden-block ul {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .vivacis-page .mastermind .people .hidden-block ul li {
    width: 100%;
    border-bottom: 1px solid #fff;
    border-right: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .vivacis-page .mastermind .people .hidden-block ul li:last-child {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .vivacis-page .mastermind .people .hidden-block ul li a.link {
    margin-top: 0;
  }
  .vivacis-page .mastermind .people .hidden-block ul li .name {
    line-height: normal;
  }

  .cwide-page .product-hero-wrapper {
    height: 300px;
  }
  .cwide-page .faces-container {
    padding: 30px 0;
  }
  .cwide-page .faces-container .img-block {
    background-color: #f6f6f6;
  }
  .cwide-page .faces-container .img-block .showcase {
    background: transparent;
  }
  .cwide-page .faces-container .content-block ul {
    flex-direction: column;
  }
  .cwide-page .faces-container .content-block ul li {
    width: 100%;
  }

  .creatio-page .product-hero-wrapper figure {
    width: 150px;
  }
  .creatio-page .top-hero-product-banner figure.bg-image-wrapper {
    background-color: #8dc9ff;
  }
  .creatio-page .top-hero-product-banner figure.bg-image-wrapper img {
    display: none;
  }
  .creatio-page .product-hero-wrapper .item-wrapper {
    padding-right: 0;
    width: 100%;
    text-align: center;
  }
  .creatio-page .product-hero-wrapper h3 {
    font-size: 32px;
  }
  .creatio-page .top-hero-product-banner .right-fixed-logo {
    width: 100%;
    left: 0;
    right: 0;
  }
  .creatio-page .product-hero-wrapper figure {
    width: 200px;
    margin: 0 auto;
    top: 50px;
    right: 0;
    position: relative;
  }
  /* .creatio-page .slide3 .product-hero-wrapper .right-fixed-logo, .creatio-page .slide3 .product-hero-wrapper .right-fixed-logo figure { margin: 0 auto; } */

  .creatio-page .edge-container,
  .creatio-page .why-creatio,
  .creatio-page .creatio-lead,
  .creatio-page .advantage,
  .creatio-page .trust-indicator,
  .creatio-page .additional-container {
    padding: 30px 0;
  }
  .creatio-page .edge-container .logo {
    width: 200px;
  }
  .creatio-page .edge-container .row h3,
  .creatio-page .pdf-download .title {
    font-size: 26px;
  }
  .creatio-page .why-creatio .row .item {
    width: 30%;
  }
  .creatio-page .creatio-container {
    padding: 30px 0 0;
  }
  .creatio-page .advantage .row .left-block {
    width: 65%;
  }
  .creatio-page .advantage .row .right-block {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .creatio-page .advantage .row .left-block li {
    width: 100%;
  }
  .creatio-page .creatio-lead ul {
    justify-content: center;
    margin: 30px 0;
    flex-wrap: wrap;
  }
  .creatio-page .creatio-lead ul li {
    width: 120px;
    margin: 0 20px 30px 20px;
  }
  .creatio-page
    .h-tabs-container
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .item {
    width: 100%;
  }
  .creatio-page .testimonials figure img {
    width: 100%;
    transform: scale(2);
  }
  .creatio-page .additional-container .item {
    padding: 20px;
    border: 1px solid #eee;
    margin: 0 5px;
  }

  .xlv-page .mastermind,
  .xlv-page .cev-container,
  .xlv-page .cust-container,
  .xlv-page .financial-measurement,
  .xlv-page .hub-cev,
  .xlv-page .breaker-container,
  .xlv-page .board-actions {
    padding: 30px 0;
  }
  .xlv-page .mastermind .right-block {
    width: 40%;
    padding: 30px;
    margin-top: 0;
  }
  .xlv-page .cev-container .value {
    padding: 30px;
  }
  .xlv-page .cev-container .quote {
    margin-top: 30px;
    padding: 30px;
  }
  .xlv-page .cust-container .item {
    padding: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  .xlv-page .financial-measurement figure {
    width: 100%;
  }
  .xlv-page .hub-cev .item {
    margin: 0 5px;
  }
  .xlv-page .board-actions .row .left-block {
    width: 65%;
    padding: 30px;
  }
  .xlv-page .board-actions .row .right-block {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .xlv-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 32px;
  }

  .exp-crowd-page .product-hero-wrapper figure {
    height: auto;
    padding: 30px;
  }
  .exp-crowd-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1.2);
  }
  .exp-crowd-page .product-hero-wrapper .col-5 figure {
    width: 150px;
  }
  .exp-crowd-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 26px;
  }
  .exp-crowd-page .exp-business,
  .exp-crowd-page .cloud-crowd,
  .exp-crowd-page .real-crowd,
  .exp-crowd-page .community-crowd,
  .exp-crowd-page .opencoder-container,
  .exp-crowd-page .customernow-container,
  .exp-crowd-page .dati-container,
  .exp-crowd-page .democrate-container {
    padding: 30px 0;
  }
  .exp-crowd-page .exp-business .row {
    margin-top: 30px;
    align-items: center;
    padding: 0 15px;
  }
  .exp-crowd-page .real-crowd figure {
    width: 100%;
  }
  .exp-crowd-page .breaker-container .row {
    flex-wrap: wrap;
  }
  .exp-crowd-page .breaker-container .row .col-4 {
    width: 100%;
  }
  .exp-crowd-page .community-crowd .table {
    margin: 30px auto;
  }
  .exp-crowd-page .opencoder-container .row {
    flex-direction: column;
  }
  .exp-crowd-page .opencoder-container .row .col-4 {
    width: 75%;
  }
  .exp-crowd-page .opencoder-container .row .items-container {
    width: 100%;
    margin-top: 30px;
  }
  .exp-crowd-page .customernow-container .item {
    padding: 15px;
  }
  .exp-crowd-page .dati-container .row {
    margin: 30px auto;
  }
  .exp-crowd-page .democrate-container .row .left-block {
    width: 65%;
    padding: 30px;
  }
  .exp-crowd-page .democrate-container .row .right-block {
    width: 35%;
  }

  .oracle-page .canvas {
    overflow-x: unset;
  }
  .oracle-page .header-container .row .col-5 {
    width: 50%;
  }
  .oracle-page .header-container .header h1,
  .oracle-page .duo-container .row h3 {
    font-size: 26px;
  }
  .oracle-page .duo-container,
  .oracle-page .vedam-oracle,
  .oracle-page .oda-container,
  .oracle-page .chatbot-container,
  .oracle-page .offers-container,
  .oracle-page .testi-container {
    padding: 30px 0;
  }
  .oracle-page .vedam-oracle .row.services .item {
    margin: 0 5px;
  }
  .oracle-page .vedam-oracle .row.services .item .content-block {
    padding: 20px;
    font-size: 16px;
  }
  .oracle-page .vedam-oracle .row.oracle-products .item,
  .oracle-page .chatbot-container .items-container .item {
    width: 50%;
  }
  .oracle-page .oda-container .items-container .item {
    width: 100%;
    box-sizing: border-box;
  }
  .oracle-page .chatbot-container .items-container .item:nth-child(2),
  .oracle-page .chatbot-container .items-container .item:nth-child(4) {
    border-right: none;
  }
  .oracle-page .chatbot-container .items-container .item:nth-child(3) {
    border-right: 1px solid #eee;
  }
  .oracle-page .chatbot-container .items-container .item:nth-child(4) {
    border-bottom: 1px solid #eee;
  }
  .oracle-page .stories-container {
    padding: 30px 0;
  }
  .oracle-page .customers .left-block {
    width: 30%;
    font-size: 16px;
    padding: 10px;
  }
  .oracle-page .customers .right-block {
    width: 70%;
    padding: 10px;
  }
  .oracle-page .customers .right-block ul li {
    width: 50px;
    margin: 0 10px;
  }
  .oracle-page .offers-container .row .col-4-half {
    display: none;
  }
  .oracle-page .offers-container .row .items-container {
    width: 100%;
  }
  .oracle-page .testi-container .row {
    flex-wrap: wrap;
  }
  .oracle-page .testi-container .header {
    margin-bottom: 30px;
  }
  .oracle-page .testi-container .testi-wrapper {
    width: 100%;
    padding: 30px;
    margin: 0;
    border-bottom: 1px solid #ddd;
  }
  .oracle-page .industry-container {
    padding: 30px 0;
    margin: 0 0 60px 0;
  }
  .oracle-page .tabs-container .tab {
    margin-bottom: 30px;
  }
  .oracle-page .tabs-container .tabcontent .box1 {
    display: none;
  }
  .oracle-page .tabs-container .tabcontent .box2,
  .oracle-page .tabs-container .tabcontent .box3 {
    width: 45%;
    margin-right: 30px;
  }

  .microsoft-page .header-container h1 {
    font-size: 26px;
  }
  .microsoft-page .consulting,
  .microsoft-page .dynamics365 {
    padding: 30px 15px;
  }
  .microsoft-page .consulting .row,
  .microsoft-page .dynamics365 .row,
  .microsoft-page .next-step-container {
    padding: 30px 0;
  }
  .microsoft-page .cloudle-powerapps .row {
    margin-top: -60px;
    display: flex;
    flex-direction: column;
  }
  .microsoft-page .cloudle-powerapps .left-block {
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .microsoft-page .cloudle-powerapps .right-logo {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 1;
    left: 0;
  }
  .microsoft-page .cloudle-powerapps figure {
    height: 200px;
    background: rgba(0, 0, 0, 1);
  }
  .microsoft-page .cloudle-powerapps figure img {
    opacity: 0.3;
  }
  .microsoft-page .cloudle-powerapps .right-block {
    width: 100%;
  }
  .microsoft-page .cloudle-powerapps .right-block ul {
    padding-top: 30px;
  }
  .microsoft-page .cloudle-powerapps .right-logo ul li {
    color: #fff;
  }
  .microsoft-page .cloudle-powerapps .right-block ul li figure {
    background: none;
  }
  .microsoft-page .cloudle-powerapps .right-block ul li img {
    transform: scale(1);
    opacity: 1;
  }
  .microsoft-page .tabs-container {
    padding: 30px 0;
  }
  .microsoft-page .tabs-container .tabcontent .box1 {
    display: none;
  }
  .microsoft-page .tabs-container .tabcontent .box2,
  .microsoft-page .tabs-container .tabcontent .box3 {
    width: 45%;
  }

  .agiloft-page .header-container,
  .agiloft-page .header-container figure {
    height: 300px;
  }
  .agiloft-page .header-container .header-block .title,
  .agiloft-page .clm-process .row h2 {
    font-size: 26px;
  }
  .agiloft-page .header-container .right-fixed-logo figure {
    width: 100px;
    top: 30px;
    right: 15px;
  }
  .agiloft-page .vivacis-agiloft,
  .agiloft-page .top-rated,
  .agiloft-page .clm-implement,
  .agiloft-page .buy-clm {
    padding: 30px 0;
  }
  .agiloft-page .vivacis-agiloft .item1 .content .title,
  .agiloft-page .vivacis-agiloft .item2 .content .title {
    font-size: 16px;
  }
  .agiloft-page .vivacis-agiloft .item1 .content,
  .agiloft-page .vivacis-agiloft .item2 .content {
    padding: 15px;
  }
  .agiloft-page .vivacis-agiloft .item3 figure,
  .agiloft-page .vivacis-agiloft .item2 figure,
  .agiloft-page .vivacis-agiloft .item1 figure {
    height: 225px;
  }
  .agiloft-page .top-rated h2 {
    font-size: 22px;
  }
  .agiloft-page .stories-container .item figure {
    height: auto;
  }
  .agiloft-page .stories-container .highlights .item {
    width: 100%;
  }
  .agiloft-page .clm-implement .row {
    justify-content: space-around;
  }
  .agiloft-page .buy-clm .left-block .top p {
    font-size: 16px;
  }

  .cloud-page .header-container .left-cloud,
  .cloud-page .header-container .right-cloud {
    width: 40%;
  }
  .cloud-page .cloud-exp {
    padding: 0;
  }
  .cloud-page .why-cloud,
  .cloud-page .opportunities,
  .cloud-page .public-cloud,
  .cloud-page .cloud-strategy,
  .cloud-page .cloud-agility,
  .cloud-page .power-cloud {
    padding: 30px 0;
  }
  .cloud-page .why-cloud .row {
    margin-top: 30px;
  }
  .cloud-page .opportunities,
  .cloud-page .opportunities .row:last-child {
    margin-bottom: 0;
    position: relative;
  }
  .cloud-page .public-cloud .row h3 {
    font-size: 16px;
    line-height: normal;
  }
  .cloud-page .public-cloud .row .col-5 {
    width: 45%;
  }
  .cloud-page .public-cloud .row .col-5 figure img {
    width: 99%;
  }
  .cloud-page .opportunities .row:last-child {
    display: flex;
    flex-wrap: wrap;
  }
  .cloud-page .opportunities .item {
    width: 48%;
  }
  .cloud-page .cloud-container {
    padding: 30px 0;
  }
  .cloud-page .cloud-container .row {
    display: flex;
    flex-direction: column;
  }
  .cloud-page .cloud-container .left {
    width: 100%;
    margin-bottom: 30px;
  }
  .cloud-page .cloud-container .right {
    width: 100%;
  }
  .cloud-page .cloud-strategy figure {
    width: 100%;
    margin: 15px auto;
  }
  .cloud-page .cloud-agility .item {
    padding: 0 10px;
  }
  .cloud-page .power-cloud .row .left-block {
    width: 65%;
    flex-direction: column-reverse;
  }
  .cloud-page .power-cloud .row .right-block {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .cloud-page .power-cloud .row .left-block figure {
    width: 75%;
    margin: 0 auto;
  }
  .cloud-page .power-cloud .row .left-block .txt-block {
    margin-top: 30px;
  }

  .io-page .io-container,
  .io-page .io-framework,
  .io-page .invincible-company figure,
  .io-page .business-model,
  .io-page .navigating-scaling,
  .io-page .building-blocks,
  .io-page .business-innovation,
  .io-page .invincible-company,
  .io-page .recommendation {
    padding: 30px 0;
  }
  .io-page .invincible-company .explore-exploit .col-4 {
    width: 45%;
  }
  .io-page .invincible-company .compare ul,
  .io-page .business-model figure {
    width: 100%;
  }
  .io-page .breaker-container .col-7 {
    padding: 30px;
  }
  .io-page .breaker-container p,
  .io-page .breaker-container .col-4 .tagline {
    font-size: 26px;
  }
  .io-page .dynamic-funding {
    padding: 30px 0 0;
  }
  .io-page .stages-container .left-block,
  .io-page .stages-container .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .io-page .stages-container .right-block figure {
    width: 50%;
  }
  .io-page .navigating-scaling .pitfall-container {
    margin-top: 30px;
    text-align: left;
    flex-direction: column-reverse;
  }
  .io-page .navigating-scaling .pitfall-container.mid {
    flex-direction: column;
  }
  .io-page .navigating-scaling .pitfall-container .content-block {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  .io-page .navigating-scaling .pitfall-container .img-block {
    width: 100%;
    margin-bottom: 15px;
  }
  .io-page .navigating-scaling .pitfall-container.mid .content-block {
    padding-left: 15px;
  }
  .io-page .building-blocks .items-container .item {
    padding: 0 10px;
    margin-bottom: 30px;
  }
  .io-page .business-innovation .row.mid {
    width: 100%;
    flex-direction: column;
  }
  .io-page .business-innovation .row.mid .img-block {
    width: 100%;
    margin-bottom: 30px;
  }
  .io-page .business-innovation .row.mid .diagram-block {
    width: 100%;
    margin-top: 30px;
  }
  .io-page .business-innovation .row.link a {
    margin: 0;
  }
  .io-page .recommendation .row .left-block {
    width: 65%;
  }
  .io-page .recommendation .row .right-block {
    width: 35%;
  }

  .odigital-page .header-container figure {
    height: auto;
  }
  .odigital-page .header-container .header-block .title {
    font-size: 26px;
  }
  .odigital-page .crm-cx-container,
  .odigital-page .spotlight-container,
  .odigital-page .numbers-container,
  .odigital-page .optimize-cx,
  .odigital-page .outdo-container,
  .odigital-page .optimize-ex,
  .odigital-page .plans-table .row {
    padding: 30px 0;
  }
  .odigital-page .crm-cx-container .title-content {
    font-size: 22px;
    margin-bottom: 60px;
    font-weight: 400;
  }
  .odigital-page .crm-cx-container .row:last-child {
    flex-wrap: wrap;
  }
  .odigital-page .crm-cx-container .items {
    width: 100%;
    margin-bottom: 30px;
  }
  .odigital-page .spotlight-container .page-wrap {
    flex-wrap: wrap;
  }
  .odigital-page .spotlight-container .content-block {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .odigital-page .spotlight-container .hightlight-block {
    width: 70%;
  }
  .odigital-page .xyz-video-container {
    padding: 60px 0 250px 0;
  }
  .odigital-page .xyz-video-container .content-block {
    font-size: 2em;
    line-height: normal;
  }
  .odigital-page .xyz-video-container .content-block .icon {
    width: 42px;
  }
  .odigital-page .crm-container .item {
    margin-right: 10px;
    padding: 30px 20px 20px 20px;
  }
  .odigital-page .odigital-breaker .page-wrap {
    max-width: 100%;
  }
  .odigital-page .odigital-breaker {
    height: 400px;
  }
  .odigital-page .odigital-breaker .item-container .title {
    font-size: 32px;
  }
  .odigital-page
    .optimize-cx
    .h-tabs-container
    .vertical-tab-content-wrapper
    .content-block
    .col-6,
  .odigital-page
    .optimize-ex
    .h-tabs-container
    .vertical-tab-content-wrapper
    .content-block
    .col-6 {
    padding: 0 30px;
    margin-bottom: 30px;
  }
  .odigital-page .optimize-ex .h-tabs-container .h-tabs-wrapper .item span {
    font-size: 16px;
  }
  .odigital-page .services-container .item-wrapper .item figure {
    width: 100px;
    height: 50px;
  }
  .odigital-page .services-container .item-wrapper .item h3 {
    font-size: 16px;
    font-weight: 500;
  }
  .odigital-page .section-breaker .content-wrapper {
    font-size: 26px;
    padding: 60px 0;
  }
  .odigital-page .section-breaker figure img {
    width: 100%;
    height: auto;
  }
  .odigital-page .plans-table .item-wrapper {
    padding: 0 10px;
    width: 50%;
  }

  .succeedant-page .breaker .page-wrap {
    flex-direction: column-reverse;
  }
  .succeedant-page .breaker .page-wrap .col-4 {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  .succeedant-page .breaker .page-wrap .col-7 {
    width: 100%;
    text-align: center;
  }
  .succeedant-page .project-succeedant {
    padding: 30px 0;
  }
  .succeedant-page .project-succeedant .row .left-block {
    width: 60%;
  }
  .succeedant-page .project-succeedant .row .right-block {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .succeedant-page .breaker .logo {
    margin: 30px auto;
  }

  .covid-page .product-hero-wrapper {
    height: 400px;
  }
  .covid-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 24px;
  }
  .covid-page .content-block {
    width: 500px;
    padding: 40px;
  }
  .covid-page .org-resilience {
    padding: 30px 15px;
  }
  .covid-page .accordian-section .row {
    flex-direction: column-reverse;
  }
  .covid-page .org-risk-container {
    padding: 0;
  }
  .covid-page .org-risk-container figure {
    width: 80%;
  }

  .invincible-page .top-hero-product-banner .right-fixed-logo {
    width: 200px;
    height: auto;
  }
  .invincible-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .invincible-page .product-hero-wrapper h3 {
    font-size: 32px;
  }
  .invincible-page .product-hero-wrapper h3 .red,
  .invincible-page .product-hero-wrapper h3 .yellow {
    font-size: 32px;
  }
  .invincible-page .product-hero-wrapper .note {
    width: 75%;
  }
  .invincible-page .invincible-company,
  .invincible-page .dynamic-funding {
    padding: 30px 0;
  }
  .invincible-page .invincible-company figure {
    padding: 30px 0 0;
  }
  .invincible-page .invincible-company .explore-exploit .col-4 {
    width: 45%;
  }
  .invincible-page .invincible-company .compare ul {
    width: 100%;
  }
  .invincible-page .invincible-company .compare .statement-container {
    padding-top: 30px;
    width: 100%;
    line-height: normal;
  }
  .invincible-page .business-model {
    padding: 30px 0;
  }
  .invincible-page .breaker-container {
    padding: 20px 0;
    width: 100%;
  }
  .invincible-page .breaker-container .row {
    flex-direction: column;
  }
  .invincible-page .breaker-container .col-7 {
    padding: 30px;
    margin-bottom: 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .invincible-page .breaker-container p {
    font-size: 26px;
  }
  .invincible-page .breaker-container .col-4 {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;
  }
  .invincible-page .breaker-container .col-4 figure {
    width: 100px;
  }
  .invincible-page .breaker-container .bg-banner img {
    transform: scale(2);
  }
  .invincible-page .dynamic-funding .arrow {
    width: 25px;
    margin: 30px auto;
    display: block;
  }
  .invincible-page .stages-container .row {
    flex-direction: column;
  }
  .invincible-page .stages-container .left-block,
  .invincible-page .stages-container .right-block {
    width: 100%;
  }
  .invincible-page .stages-container .right-block figure {
    width: 50%;
  }
  .invincible-page .navigating-scaling {
    padding: 0;
  }
  .invincible-page .navigating-scaling .pitfall-container {
    flex-direction: column-reverse;
    margin-top: 20px;
    display: flex;
  }
  .invincible-page .navigating-scaling .pitfall-container .content-block,
  .invincible-page .navigating-scaling .pitfall-container .img-block {
    width: 100%;
  }
  .invincible-page .navigating-scaling .pitfall-container.mid {
    flex-direction: column;
  }
  .invincible-page .navigating-scaling .pitfall-container.mid .content-block {
    padding-left: 15px;
  }
  .invincible-page .section-breaker {
    margin-top: 30px;
  }

  .agiloft-page .vivacis-agiloft .item3 .content .social {
    top: 15px;
    left: 15px;
  }
  .agiloft-page .vivacis-agiloft .item3 .content .tag {
    top: 15px;
    right: 15px;
  }
  .agiloft-page .stories-container .item .txt {
    min-height: 140px;
  }
  .agile-page .product-hero-wrapper {
    height: 300px;
  }

  .about-partner-page .product-hero-wrapper blockquote {
    top: -200px;
  }
  .about-partner-page .top-hero-product-banner blockquote:after,
  .about-partner-page .top-hero-product-banner blockquote:before {
    font-size: 20px;
  }
  .business-page .image-title-layout .product-hero-wrapper figure {
    width: 150px;
  }
  .business-page .invincible-company,
  .business-page .invincible-model,
  .business-page .business4-container,
  .business-page .emp-exp-container,
  .business-page .imp-links,
  .business-page .venture-container,
  .business-page .business {
    padding: 30px 0;
  }
  .business-page .invincible-model .row {
    justify-content: space-between;
    margin-top: 30px;
  }
  .business-page .invincible-model .row .left-block {
    width: 50%;
    margin: 0;
  }
  .business-page .invincible-model .row .right-block {
    width: 40%;
  }
  .business-page .invincible-model .row .item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    padding: 15px;
  }
  .business-page .invincible-model .row .item .circle {
    margin: 0 50px 0 0;
  }
  .business-page .invincible-model .row .item .content-container {
    width: 60%;
  }
  .business-page
    .invincible-model
    .row
    .item
    .content-container
    .logo-block
    ul {
    padding: 0;
    justify-content: space-between;
    border-top: 0;
  }
  .business-page .invincible-model .row .item .content-container h3,
  .business-page .invincible-model .row .item .content-container p {
    padding: 0;
  }
  .business-page .breaker-container {
    margin: 0;
  }
  .business-page .breaker-container .row {
    flex-wrap: wrap;
  }
  .business-page .breaker-container .row .col-3 {
    width: 50%;
  }
  .business-page .emp-exp-container .row img {
    width: 100%;
  }
  .business-page .business4-container .row .item {
    width: 50%;
  }
  .business-page .business .row {
    flex-direction: column;
  }
  .business-page .business .row .arrow-container {
    width: 100%;
    order: 1;
  }
  .business-page .business .row .arrow-container figure {
    width: 50%;
    margin: 0 auto;
  }
  .business-page .business .row .logo-container {
    width: 100%;
    margin-top: 30px;
    order: 2;
  }
  .business-page .invincible-company figure {
    padding: 30px 0;
  }
  .business-page .xonomy-bootstrap figure img {
    transform: scale(1.5);
  }
  .business-page .xonomy-bootstrap .row {
    flex-wrap: wrap;
  }
  .business-page .xonomy-bootstrap .left-block {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .business-page .xonomy-bootstrap .right-block {
    width: 100%;
  }
  .business-page .xonomy-bootstrap .right-block ul {
    padding: 0;
  }
  .business-page .xonomy-bootstrap .right-logo {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    width: 50%;
    text-align: center;
  }
  .business-page .xonomy-bootstrap figure {
    height: auto;
  }
  .business-page .xonomy-bootstrap {
    margin: 0;
  }
  .business-page .xonomy-bootstrap .right-logo img {
    width: 100%;
  }
  .business-page .xonomy-bootstrap .right-block ul li {
    width: 50%;
  }
  .business-page .business .row .mid-block.xonomy {
    display: flex;
    flex-wrap: wrap;
  }
  .business-page .business .row .mid-block.xonomy ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .business-page .business .row .mid-block.xonomy ul li {
    width: 50%;
  }
  .business-page .business .row .mid-block.xonomy ul li:first-child {
    width: 100%;
  }
  .business-page .imp-links .row .col-3 {
    margin-bottom: 30px;
  }

  .b4-page .header-slider-container ul {
    display: flex;
  }
  .b4-page .header-slider-container ul li div a figure {
    width: 100px;
  }
  .b4-page .business-model {
    padding: 30px 0;
  }
  .b4-page .business-model .row {
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .b4-page .business-model .item {
    margin: 0 0 15px 0;
    width: 48%;
  }
  .b4-page .global4-container h2,
  .b4-page .industry4-container h2,
  .b4-page .digital4-container h2,
  .b4-page .society4-container h2 {
    font-size: 24px;
  }
  .b4-page .global4-container .heading-big,
  .b4-page .digital4-container .heading-big,
  .b4-page .society4-container .heading-big {
    font-size: 3.5em;
  }
  .b4-page .industry4-container .heading-big {
    font-size: 3em;
  }
  .b4-page .global4-container {
    padding: 0;
    margin-top: 30px;
  }
  .b4-page .industry4-container {
    padding: 0;
    margin-top: 90px;
  }
  .b4-page .digital4-container {
    padding: 0;
    margin-top: 90px;
  }
  .b4-page .society4-container {
    padding: 0;
    margin-top: 90px;
  }
  .b4-page .resources .logos-container .item {
    width: 25%;
    padding: 5px;
  }
  .b4-page .resources .logos-container figure {
    width: 100%;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    border: 1px solid #eee;
    background-color: #fff;
    margin: 0;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  .b4-page .resources .logos-container figure img {
    width: 75%;
    margin-bottom: 15px;
    height: 40px;
  }
  .b4-page .resources .logos-container p {
    font-size: 14px;
    line-height: normal;
    position: relative;
    text-align: center;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .b4-page .resources .logos-container .item:hover img {
    opacity: 1;
  }
  .b4-page .resources .logos-container .item:hover p {
    bottom: 0;
    transition: 0.3s ease-in-out;
    background-color: #fff;
    color: #666;
  }
  .b4-page .section-breaker .content-wrapper {
    padding: 60px 0;
  }
  .b4-page .section-breaker figure img {
    width: 100%;
    height: auto;
  }
  .b4-page .b4-strategy-container .row ul li {
    background: #fff;
    padding: 15px;
  }
  .b4-page .b4-strategy-container .row figure {
    width: 100%;
  }
  .b4-page .agile-breaker h3 {
    font-size: 20px;
  }
  .b4-page .agile-breaker h2 {
    font-size: 42px;
  }
  .b4-page .new-economy .arrow .left-block,
  .b4-page .new-economy .arrow .right-block {
    font-size: 32px;
  }
  .b4-page .new-economy .row .item {
    margin: 0;
  }

  .agilenterprise-page .header-container {
    background-position: 80%;
    height: 300px;
  }
  .agilenterprise-page .header-container .row {
    width: 85%;
  }
  .agilenterprise-page .header-container .row .col-6 {
    width: 100%;
  }
  .agilenterprise-page .header-container h2 {
    font-size: 36px;
  }
  .agilenterprise-page .strategy-container,
  .agilenterprise-page .blocks-container,
  .agilenterprise-page .key-strategy,
  .agilenterprise-page .scale-model,
  .agilenterprise-page .scale-model,
  .agilenterprise-page .three-steps,
  .agilenterprise-page .covid-reset,
  .agilenterprise-page .emp-cust {
    padding: 30px 0;
  }
  .agilenterprise-page .key-strategy .row {
    flex-wrap: wrap;
  }
  .agilenterprise-page .key-strategy .item {
    width: 47%;
  }
  .agilenterprise-page .scale-model .item {
    padding: 0;
    margin: 5px;
  }
  .agilenterprise-page .scale-model .img-container h3,
  .agilenterprise-page .three-steps .row .item h3 {
    font-size: 14px;
  }
  .agilenterprise-page .three-steps .row,
  .agilenterprise-page .emp-cust .row {
    margin-top: 30px;
  }
  .agilenterprise-page .three-steps .row .item p {
    height: 100px;
  }
  .agilenterprise-page .covid-reset .block2 .item {
    margin: 0 5px;
  }
  .agilenterprise-page .emp-cust .steps-block .item {
    margin: 5px;
    padding: 15px;
  }

  .careers-page .vertical-image-hoverable-wrapper .page-wrap .col-2 {
    width: 22%;
    overflow: hidden;
  }
  .degree360-page .product-hero-wrapper p {
    font-size: 22px;
  }
  .degree360-page .image-title-layout .product-hero-wrapper .logos img {
    width: 50px;
  }
  .degree360-page .factors-wrapper .two-col-section {
    padding: 0;
  }
  .degree360-page .abcde-container .abcde-img {
    width: 75%;
  }
  .degree360-page
    .image-title-layout
    .product-hero-wrapper
    .logos
    img:last-child {
    width: 100px;
  }
  .factors-wrapper .hero-factors {
    padding: 0 15px;
  }
  .employee-beneits-page .image-title-layout .product-hero-wrapper p {
    font-size: 32px;
  }
  .life-at-virtuos-page .image-title-layout .product-hero-wrapper p {
    font-size: 22px;
  }
  .life-at-virtuos-page iframe {
    width: 50%;
  }
  .alumni-page .image-title-layout .product-hero-wrapper p {
    font-size: 32px;
  }

  .filter-container .portfolio-wrapper {
    overflow: hidden;
    position: relative;
    flex-direction: column;
  }
  .filter-container .portfolio img {
    top: 0;
  }
  .filter-container .portfolio .label {
    position: relative;
  }
  .cdigital-page .filter-container {
    padding: 30px 0;
  }
  .qualtrics-page .filter-container {
    padding: 30px 0;
  }
  .customer360-page .top-hero-product-banner figure {
    padding: 0;
    height: auto;
  }

  .dragon-page .bg-image-wrapper img {
    transform: translateX(-100px);
  }
  .dragon-page .keys a {
    padding: 0;
  }
  .dragon-page .digital-industry figure {
    padding: 0;
  }
  .wdigital-page .image-title-layout .product-hero-wrapper .item-wrapper {
    text-align: center;
    padding: 0;
    width: 70%;
  }
  .wdigital-page .wall-breaker .row ul li {
    font-size: 16px;
  }
  .wdigital-page .persona-container .row {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .wdigital-page .persona-container .row .item {
    width: 45%;
    margin: 0;
  }
  .wdigital-page .strategy .row {
    flex-wrap: wrap;
  }
  .wdigital-page .strategy .row .item {
    width: 50%;
  }
  .wdigital-page .modern-workplace .row .item h3 {
    height: 100px;
  }
  .crm-digital-page .advantage-container .items-container {
    padding: 0 15px;
    flex-wrap: wrap;
  }
  .crm-digital-page .advantage-container .items-container .item {
    width: 48%;
    margin-bottom: 30px;
    margin: 0;
  }
  .sales-page .virtuos-advantage .row li {
    width: 48%;
  }
  .service-page .mega-trends .row {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .service-page .mega-trends .row .item {
    margin: 0 0 30px 0;
    width: 48%;
  }
  .service-page .mega-trends {
    padding: 30px 0;
  }
  .service-page .partner-container .item ul li figure {
    width: 75%;
  }
  .service-page .recommendation .row .left-block {
    width: 65%;
  }
  .service-page .recommendation .row .right-block {
    width: 35%;
  }
  .service-page .recommendation .row .left-block figure {
    width: 100%;
  }
  .marketing-page .core-compo .bottom-block-left ul li,
  .marketing-page .core-compo .bottom-block-right ul li {
    width: 35%;
  }
  .cx-page .cx-view-container {
    padding: 30px 0 0;
  }
  .microsoft-page .header-container .right-fixed-logo {
    width: 150px;
  }
  .microsoft-page .header-container .right-fixed-logo span {
    font-size: 12px;
  }
  .vertical-section .center-wrapper ul.links-container li a {
    color: #333;
  }
  .vertical-section .center-wrapper .btn {
    background-color: #fff;
    color: #00a1fe;
    font-weight: 500;
    margin: 15px 5px;
    border: 1px solid #00a1fe;
  }
  .cx-digital-page .vertical-section .full-screen-wrapper figure img {
    transform: none;
  }
  .application-page .application-container .content-block a.more {
    bottom: 0;
    top: auto;
  }
  .engineering-service-page .product-hero-wrapper {
    height: 300px;
  }
  .platform-ecosystem-page .cx-offerings,
  .platform-ecosystem-page .related-offerings {
    padding-bottom: 60px;
  }
  .technology-services-page .two-col-full-section.cloudle .right-fixed-logo {
    display: none;
  }
  .technology-services-page .ecosystem-section {
    padding-bottom: 100px;
  }
  .about-page .exp-business .row {
    flex-wrap: wrap;
  }
  .about-page .exp-business .row .item {
    width: 100%;
  }
  .exp-edge-page .top-hero-product-banner .product-hero-wrapper .col-3,
  .exp-edge-page .top-hero-product-banner .product-hero-wrapper .col-9 {
    width: 100%;
  }
  .hcm-page .hcm-container .items-block {
    flex-wrap: wrap;
  }
  .hcm-page .hcm-container .items-block .item {
    padding: 0;
    border-right: 0;
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    flex-direction: column;
  }
  .hcm-page .hcm-container .items-block .item figure {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 220px;
    overflow: hidden;
    width: 300px;
  }
  .hcm-page .two-col-full-section.cloudle .bg-banner img {
    transform: scale(2.2);
  }
  .hcm-page .digital-process-tabs-wrapper .item {
    width: 100%;
  }
  .hcm-page .vertical-tab-content-wrapper .vertical-tab-content:before {
    display: none;
  }
  .hcm-page
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .content-block
    ul
    li {
    margin-left: 30px;
    width: 100%;
  }
  .hcm-page .hrtech-container,
  .hcm-page .ejourney-container,
  .hcm-page .ta-container,
  .hcm-page .it-container,
  .hcm-page .seven-c-wrapper {
    padding: 30px 0;
  }
  .hcm-page .digital-program-wrapper {
    margin-top: 0;
  }
  .hcm-page .hcm-offerings-container {
    padding: 0;
  }
  .hcm-page .hcm-offerings-container .items-container {
    justify-content: space-between;
  }
  .hcm-page .hcm-offerings-container .items-container .item {
    width: 45%;
  }
  .hcm-page .hcm-offerings-container .agnitor-block {
    padding: 30px 15px;
  }
  .hcm-page .seven-c-wrapper {
    padding: 100px 0 30px 0;
  }
  .hcm-page .seven-c-wrapper .right-fixed-logo {
    width: 100px;
  }
  .creatio-page
    .h-tabs-container
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .img-block {
    width: 100%;
    margin-top: 30px;
  }
  .creatio-page .testimonials .item {
    padding: 0 100px;
  }
  .cx-marries-digital-page .top-hero-product-banner {
    height: auto;
  }
  .cx-marries-digital-page .product-hero-wrapper .row {
    max-width: 90%;
  }
  .cx-marries-digital-page .product-hero-wrapper p {
    font-size: 22px;
    margin: 0;
    padding: 0;
  }
  .cx-marries-digital-page .product-hero-wrapper .item-wrapper figure {
    width: 150px;
  }
  .cx-marries-digital-page .cx-digital-marketing,
  .cx-marries-digital-page .digital-cx-container,
  .cx-marries-digital-page .evolution-cx,
  .cx-marries-digital-page .cx-marketing,
  .cx-marries-digital-page .cmo-cco {
    padding: 30px 0;
  }
  .cx-marries-digital-page .cx-digital-marketing .row .left-block,
  .cx-marries-digital-page .cx-digital-marketing .row .right-block {
    width: 30%;
  }
  .cx-marries-digital-page .cx-digital-marketing .row ul li {
    font-size: 18px;
  }
  .cx-marries-digital-page .digital-cx-container .row {
    padding: 15px;
  }
  .cx-marries-digital-page .digital-cx-container .row .item {
    padding: 0 5px;
  }
  .cx-marries-digital-page .evolution-cx .row .item,
  .cx-marries-digital-page .cmo-cco .row .box-block {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .cx-unified-page .top-hero-product-banner .right-fixed-logo {
    height: 30px;
  }
  .cx-unified-page .exp-business .grey-bg {
    margin-top: 30px;
  }
  .cx-unified-page .exp-business .value-wrapper {
    padding: 60px 0;
  }
  .cx-unified-page .cr-wrapper .row.page-wrap {
    justify-content: space-between;
    padding: 30px 0;
    align-items: center;
  }
  .cx-unified-page .cr-wrapper .row.page-wrap .left-content {
    margin-top: 0;
  }
  .cx-unified-page .clients-list {
    padding: 0;
  }
  .cx-unified-page .business-facts {
    padding: 30px 0;
  }
  .odigital-page .product-hero-wrapper {
    height: 300px;
  }
  .odigital-page .veracis-breaker {
    padding: 60px 0;
  }
  .odigital-page .veracis-breaker h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .odigital-page .veracis-breaker figure {
    width: 150px;
  }
  .odigital-page .odigital-breaker .item-container .top-logo {
    width: 40%;
  }
  .orc-page .about-wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .orc-page .about-wrapper .laptop-block {
    height: auto;
    left: 0;
    position: relative;
    right: 0;
    width: 75%;
    margin: 40px auto;
  }
  .orc-page .about-wrapper .laptop-block img {
    width: 100%;
  }
  .orc-page .about-wrapper {
    display: flex;
    flex-direction: column;
  }
  .orc-page .about-wrapper .col-6:first-child {
    display: none;
  }
  .orc-page .about-wrapper .col-6 {
    width: 100%;
  }
  .orc-page .talk-video-breaker .content-block {
    padding: 30px 0;
  }
  .oracle-netsuite-page .erp-practice .row {
    justify-content: space-around;
  }
  .oracle-netsuite-page .erp-practice .item {
    margin: 0 0 30px 0;
    padding: 30px;
    width: 45%;
  }
  .oracle-netsuite-page .erp-practice,
  .oracle-netsuite-page .key-benefits,
  .oracle-netsuite-page .netsuite-container,
  .oracle-netsuite-page .tabs-container,
  .oracle-netsuite-page .resources {
    padding: 30px 0;
  }
  .oracle-netsuite-page .key-benefits .row {
    flex-wrap: wrap;
  }
  .oracle-netsuite-page .key-benefits .item {
    width: 100%;
    margin: 0 0 15px 0;
    padding: 30px;
    box-sizing: border-box;
  }
  .oracle-netsuite-page .suite-success .row {
    flex-direction: column-reverse;
    text-align: center;
  }
  .oracle-netsuite-page .suite-success .row .col-5 {
    width: 100%;
    margin-top: 30px;
  }
  .oracle-netsuite-page .suite-success {
    padding: 30px;
  }
  .oracle-netsuite-page .suite-success figure.bg {
    align-items: center;
  }
  .oracle-netsuite-page .suite-success figure.bg img {
    transform: scale(1.3);
  }
  .oracle-netsuite-page .netsuite-container .row.erp .item .content-block {
    padding: 15px;
    font-size: 16px;
  }
  .oracle-netsuite-page .netsuite-container .row.erp .item {
    margin: 0 5px;
  }
  .oracle-netsuite-page .netsuite-container .row.industry,
  .oracle-netsuite-page .customer-success .row {
    margin-top: 30px;
    flex-wrap: wrap;
    padding-top: 0;
  }
  .oracle-netsuite-page .netsuite-container .row.industry .item {
    margin: 0 0 30px 0;
    display: flex;
    width: 100%;
  }
  .oracle-netsuite-page .customer-success .item {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .oracle-netsuite-page .tabs-container .tabcontent .box1 {
    display: none;
  }
  .oracle-netsuite-page .tabs-container .tabcontent .box1,
  .oracle-netsuite-page .tabs-container .tabcontent .box2,
  .oracle-netsuite-page .tabs-container .tabcontent .box3 {
    width: 45%;
  }
  .oracle-netsuite-page .tabs-container .tabcontent .box3 {
    margin-right: 0;
  }
  .oracle-netsuite-page .erp-cx-container .item .content-container {
    width: auto;
    left: 30px;
    right: 30px;
    box-sizing: border-box;
    padding: 30px;
    height: auto;
    top: 15px;
    bottom: 30px;
  }
  .oracle-netsuite-page .erp-cx-container .item figure {
    width: 100%;
    height: 450px;
  }
  .oracle-netsuite-page .erp-cx-container .item figure img {
    transform: scale(2);
  }
  .oracle-netsuite-page .digital-transformation .row {
    flex-wrap: wrap;
  }
  .oracle-netsuite-page .digital-transformation .col-3 {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .oracle-netsuite-page .resources .row {
    flex-wrap: wrap;
  }
  .oracle-netsuite-page .resources .item {
    width: 50%;
  }
  .rainbowcx-page
    .top-hero-product-banner
    .product-hero-wrapper
    .item-wrapper
    p {
    font-size: 22px;
  }
  .cx-now-page .cx-header-img .content-wrapper {
    padding: 15px;
  }
  .cxprism-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 22px;
  }
  .next-steps-wrapper .item {
    width: 49%;
  }
  .cxprism-page .next-steps-wrapper .item br {
    display: none;
  }
  .cxprism-page .product-hero-wrapper figure {
    height: auto;
  }
  .cxprism-page .video-wrapper .page-wrap {
    flex-direction: column-reverse;
  }
  .cxprism-page .video-wrapper .page-wrap .col-6 {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  .cxprism-page .video-wrapper .page-wrap .col-6 figure {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .cxprism-page .video-wrapper .page-wrap .col-5 {
    width: 100%;
    text-align: center;
  }
  .cxprism-page .video-wrapper .page-wrap .col-5 iframe {
    width: 100%;
    height: 400px;
  }

  .cjourney-page .product-hero-wrapper .header-img {
    width: 100%;
  }
  .cxpyramid-page .cxpyramid-container blockquote {
    max-width: 80%;
    margin: 15px auto;
    text-align: center;
  }
  .cxpyramid-page .cxpyramid-cjourney-container .pyramid-journey .item,
  .cxpyramid-page .triangle-container .item {
    margin: 5px;
  }
  .cx-ex-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 32px;
  }
  .cx-ex-page .image-title-layout .product-hero-wrapper p {
    font-size: 22px;
  }
  .cx-ex-page .product-hero-wrapper {
    height: 300px;
  }
  .cx-ex-page .two-col-full-section.cloudle .cloudle-wrapper .illus {
    padding: 0 0 15px 0;
  }
  .cx-ex-page .two-col-full-section.cloudle .cloudle-wrapper h3 {
    font-size: 22px;
  }
  .cx-ex-page .two-col-full-section.cloudle .bg-banner img {
    transform: scale(2);
  }
  .cx-ex-page .our-consultare-services .illus figure {
    height: 250px;
  }
  .cx-ex-page .personas-container .items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cx-ex-page .personas-container .items-container .item-block {
    width: 44%;
    margin: 0 0 15px 0;
  }
  .cx-ex-page .theory-container .row {
    margin: 15px auto;
  }
  .cx-ex-page .theory-container .item {
    width: 33%;
  }
  .cx-ex-page .emp-engage-container .items-container {
    padding: 15px;
  }
  .cx-ex-page .theory-container,
  .cx-ex-page .emp-engage-container,
  .cx-ex-page .emp-exp-container,
  .cx-ex-page .key-factors-container,
  .cx-ex-page .relationship-container {
    padding: 30px 0;
  }
  .cx-ex-page .key-factors-container .items-container {
    padding: 0;
    margin-bottom: 30px;
  }
  .cx-ex-page .key-factors-container .items-container .item {
    margin: 0 5px;
  }
  .cx-ex-page .relationship-container .items-block {
    padding: 15px;
  }
  .cx-digital-page.w-digital-page .full-screen-wrapper .right-logo-block {
    top: 8%;
    width: 150px;
    margin: 0 auto;
  }
  .aury-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 24px;
  }
  .exp-crowd-page .product-hero-wrapper .item-wrapper figure {
    width: 150px;
  }
  .exp-crowd-page .community-crowd .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .exp-crowd-page .community-crowd .row.table {
    display: flex;
    flex-direction: column;
  }
  .exp-crowd-page .community-crowd .row.foundation {
    display: flex;
    flex-direction: column-reverse;
  }
  .exp-crowd-page .community-crowd .row [class*="col-"] {
    width: 100%;
  }
  .exp-cloud-page .product-hero-wrapper .item-wrapper figure {
    width: 150px;
  }
  .exp-cloud-page .birth .row .item {
    width: 44%;
  }
  .experienceo-page .header-container h2 {
    font-size: 36px;
  }
  .experienceo-page .deal-container .diagram .left,
  .experienceo-page .deal-container .diagram .right,
  .experienceo-page .deal-container .diagram .bottom {
    width: 100px;
  }
  .experienceo-page .ceo-job-container,
  .experienceo-page .emp-exp,
  .experienceo-page .deal-container {
    padding: 30px 0;
  }
  .experienceo-page .emp-exp .row.block2,
  .experienceo-page .emp-exp .row.block3 {
    margin-top: 60px;
  }
  .experienceo-page .ceo-job-container .row .left-block,
  .experienceo-page .ceo-job-container .row .right-block {
    width: 100%;
  }
  .experienceo-page .ceo-job-container .row .left-block {
    margin-bottom: 30px;
  }
  .experienceo-page .ceo-job-container .row .left-block figure {
    width: 50%;
    margin: 0 auto;
  }
  .indicial-page .vertical-image-hoverable-wrapper,
  .indicial-page .advantages-of-aury,
  .indicial-page .two-col-full-section.accordian-section .content-wrapper,
  .indicial-page .related-offerings {
    padding: 30px 0;
  }
  .indicial-page .hoverable-tabs {
    overflow: unset;
  }
  .indicial-page .digital-human-wrapper .title {
    font-size: 22px;
  }
  .indicial-page .accordian-content .content-block {
    flex-direction: row;
    justify-content: space-between;
  }
  .indicial-page .accordian-content .item-block {
    width: 45%;
    padding: 0;
    margin-bottom: 30px;
  }
  .subscribe-section.insights-download form .btn {
    width: auto;
  }
  .io-page .product-hero-wrapper .item-wrapper {
    width: 70%;
    margin: 0;
    padding: 0;
  }
  .io-page .io-container .row .item {
    width: 48%;
  }
  .dthinking-page .key-issues,
  .dthinking-page .premise-container,
  .dthinking-page .analysis-container,
  .dthinking-page .stages-container,
  .dthinking-page .integrates-container,
  .dthinking-page .mindset-container,
  .dthinking-page .doctor-technology,
  .dthinking-page .doctor-day,
  .dthinking-page .cx-map-container,
  .dthinking-page .ecosystem-container,
  .dthinking-page .develop-container,
  .dthinking-page .iterate-container,
  .dthinking-page .build-container,
  .dthinking-page .recommendation {
    padding: 30px 0;
  }
  .dthinking-page .breaker-container p {
    font-size: 22px;
  }
  .dthinking-page .compare-container,
  .dthinking-page .start-grow-container {
    padding: 30px 0 0;
  }
  .dthinking-page .image-title-layout .product-hero-wrapper {
    height: 400px;
  }
  .dthinking-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1) translateX(-100px);
  }
  .dthinking-page .key-issues a {
    width: 170px;
    height: 170px;
  }
  .dthinking-page .breaker-container .bg-banner img {
    transform: scale(1.6);
  }
  .investors-page
    .top-hero-product-banner
    .product-hero-wrapper
    .row
    .img
    figure {
    width: 100%;
  }
  .investors-page .transform-container .section-main-heading {
    font-size: 36px;
  }
  .investors-page .transform-container {
    padding: 0;
  }
  .investors-page .book-container,
  .investors-page .about-content-wrapper,
  .investors-page .culture-values-container,
  .investors-page .showcase-news-cards-wrapper {
    padding: 30px 0;
  }
  .investors-page .bootstrap-container {
    padding: 30px 15px;
  }
  .investors-page .brand-breaker .row .left {
    padding: 30px;
  }
  .investors-page .invincible-container .diagram {
    margin-top: 30px;
  }
  .investors-page .top-hero-product-banner,
  .investors-page .top-hero-product-banner .product-hero-wrapper {
    height: 400px;
  }
  .investors-page .brand-breaker .row {
    flex-direction: column;
    padding: 15px;
  }
  .investors-page .brand-breaker .row .left {
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .investors-page .brand-breaker .row .mid {
    padding: 60px 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .investors-page .brand-breaker .row .right {
    width: 50%;
    margin: 0 auto;
  }
  .investors-page .invincible-container blockquote {
    width: 80%;
  }
  .investors-page .showcase-news-cards-wrapper span {
    margin-bottom: 0;
  }
  .investors-page .showcase-news-cards-wrapper .row {
    padding-top: 0;
    flex-wrap: wrap;
  }
  .investors-page .showcase-news-cards-wrapper .item-wrapper {
    margin-right: 0;
    width: 50%;
  }
  .invincible-page .video-wrapper .page-wrap {
    flex-direction: column;
    text-align: center;
  }
  .invincible-page .video-wrapper .page-wrap .col-4 {
    width: 100%;
  }
  .invincible-page .video-wrapper .page-wrap .col-7 {
    width: 100%;
    margin-top: 30px;
  }
  .w-digital-page .within-metrics .items-container .item h3,
  .w-digital-page .within-metrics .items-container .item .highlighter {
    font-size: 16px;
  }
  .w-digital-page .action-plan .row {
    margin: 0 auto;
    flex-wrap: wrap;
  }
  .w-digital-page .action-plan .col-4 {
    width: 100%;
    margin: 0 0 15px 0;
    box-sizing: border-box;
  }
  .cx-digital-page
    .vertical-section
    .full-screen-wrapper
    .right-logo-block
    figure
    img {
    height: 100px;
  }
  .ventures-page .top-hero-product-banner,
  .ventures-page .top-hero-product-banner .product-hero-wrapper {
    height: 400px;
  }
  .ventures-page
    .top-hero-product-banner
    .product-hero-wrapper
    .row
    .img
    figure {
    width: 100%;
  }
  .ventures-page .exp-economy-section .row {
    display: flex;
    flex-wrap: wrap;
  }
  .ventures-page .exp-economy-section .row .left-section {
    width: 100%;
  }
  .ventures-page .exp-economy-section .row .right-section {
    width: 50%;
    margin: 0 auto;
  }
  .ventures-page .exp-economy-section .row .right-section img {
    height: auto;
  }
  .ventures-page .io-i3-section .content {
    font-size: 36px;
    padding: 30px 0;
    flex-direction: column;
  }
  .ventures-page .ar-wrapper figure {
    transform: scale(1.5);
  }
  .ventures-page .ar-wrapper .row.page-wrap {
    justify-content: center;
    flex-direction: column;
    min-height: auto;
    padding: 30px 0;
  }
  .ventures-page .ar-wrapper .left-content {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  .ventures-page .ar-wrapper .left-content p {
    margin: 0;
    padding: 0;
  }
  .org-page .product-hero-wrapper .item-wrapper figure {
    width: 250px;
  }
  .org-page .ar-wrapper .left-content {
    text-align: center;
  }
  .clients-page .industry-main-wrapper .industry-product-banner {
    background: linear-gradient(to bottom, #4897d2, #b1e1f8);
  }
  .clients-page .industry-main-wrapper .industry-product-banner img {
    display: none;
  }
  .clients-page .industry-main-wrapper .industry-form-wrapper {
    width: 100%;
    padding: 30px;
    min-height: auto;
  }
  .clients-page .industry-main-wrapper .content-wrapper .default-wrapper h2 {
    color: #000;
    text-align: center;
  }
  .clients-page .customer-success-section .clients-wrapper .row li {
    margin: 15px;
    padding: 0;
    width: 45%;
  }
  .clients-page .industry-main-wrapper .industry-form-wrapper .row {
    padding: 0;
  }
  .clients-page
    .industry-main-wrapper
    .content-wrapper
    .default-wrapper
    h2
    .highlight {
    color: #fff;
  }
  .clients-page .industry-main-wrapper .industry-form-wrapper .input-wrapper {
    margin-right: 5px;
  }
  .solutions-page .customer-success-section .clients-wrapper .item figure img {
    transform: scale(1.5);
  }
  .solutions-page .customer-success-section .clients-wrapper .c-logo img {
    width: 100%;
    height: auto;
  }
  .solutions-page .customer-success-section .clients-wrapper .item {
    height: 200px;
  }
  .solutions-page .tabs-container1 .tabcontent .col-2 {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .home-slider1-container .logos-bar {
    bottom: -25px;
    height: 100px;
  }
  .home-slider1-container .logos-bar ul li {
    width: 100px;
  }
  .home-slider1-container .logos-bar ul li span {
    line-height: 22px;
    font-size: 22px;
  }
  .home-page .services-container .item-wrapper .heading1 p {
    font-size: 16px;
    line-height: 18px;
  }
  .home-page .services-container .item-wrapper .heading2 p {
    font-size: 26px;
    line-height: 22px;
  }

  .rainbow-page .product-hero-wrapper figure.logo {
    width: 150px;
  }
  .rainbow-page .product-hero-wrapper figure {
    width: 100%;
  }
  .rainbow-page .product-hero-wrapper p {
    font-size: 22px;
  }
  .rainbow-page .cx-view-container {
    padding: 30px 0 0;
  }
  .rainbow-page .everything-container,
  .rainbow-page .low-expectation,
  .rainbow-page .everything-mindset,
  .rainbow-page .customer-journey,
  .rainbow-page .pandemic-impact,
  .rainbow-page .everything-free,
  .rainbow-page .digital-giants,
  .rainbow-page .everything-customer,
  .rainbow-page .model-container {
    padding: 30px 0;
  }
  .rainbow-page .breaker-slider li h2 {
    font-size: 26px;
  }
  .rainbow-page .breaker-slider {
    padding: 0;
  }
  .rainbow-page .everything-mindset .box {
    margin: 15px;
  }
  .rainbow-page .breaker-slider li {
    padding: 30px;
  }
  .rainbow-page .customer-journey figure {
    width: 100%;
  }
  .rainbow-page .pandemic-impact .row,
  .rainbow-page .everything-free .row,
  .rainbow-page .digital-giants .row,
  .rainbow-page .model-container .row {
    flex-direction: column;
  }
  .rainbow-page .pandemic-impact .row .item {
    width: 100%;
    margin-bottom: 30px;
  }
  .rainbow-page .everything-free .row .item {
    width: 100%;
    margin-bottom: 30px;
    border-bottom: 2px solid #ddd;
    border-right: none;
  }
  .rainbow-page .everything-free .row .item:last-child {
    border: none;
  }
  .rainbow-page .digital-giants .row .item {
    margin: 0;
    width: 100%;
  }
  .rainbow-page .digital-giants .row .item h2 br {
    display: none;
  }
  .rainbow-page .model-container .row .item {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .rainbow-page .how-to-serve figure {
    width: 100%;
  }

  .home-slider1-container .slide1 .superpower-bg {
    width: 150%;
  }
  .home-page .dt-container .row .col-4 {
    width: 100%;
  }

  .exp-new-page .product-hero-wrapper {
    height: 300px;
  }
  .exp-new-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 24px;
  }
  .exp-new-page .product-hero-wrapper figure.logo {
    width: 200px;
    margin-bottom: 10px;
  }
  .exp-new-page .struggle-container .item {
    height: 175px;
    margin: 10px;
    font-size: 1rem;
  }
  .exp-new-page .trans-container,
  .exp-new-page .human-resi,
  .exp-new-page .comp-container,
  .exp-new-page .struggle-container,
  .exp-new-page .fusion-container,
  .exp-new-page .principle-container,
  .exp-new-page .comp-tech-container,
  .exp-new-page .use-container {
    padding: 30px 0;
  }
  .exp-new-page .struggle-container .support,
  .exp-new-page .struggle-container .belief {
    margin-top: 50px;
  }
  .exp-new-page .new-abc-container {
    padding: 50px 0;
  }

  .error-page .error-container {
    padding: 50px 0;
  }
  .error-page .error-container .row {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .error-page .error-container .col-5,
  .error-page .error-container .col-6 {
    width: 100%;
  }
  .error-page .error-container ul {
    width: 65%;
    margin: 0 auto;
    text-align: left;
  }
  .error-page .error-container h2 {
    font-size: 72px;
    margin: 30px;
  }

  .nexorama-page .customer-success {
    padding: 0;
    margin: 60px 0;
  }
  .nexorama-page .customer-success .row {
    display: flex;
    flex-direction: column;
  }
  .nexorama-page .customer-success .image-container {
    height: 400px;
    width: 100%;
  }
  .nexorama-page .customer-success .content-container {
    width: 100%;
    padding: 30px;
  }
  .nexorama-page .customer-success .content-container .title {
    font-size: 2em;
  }
  .nexorama-page .customer-success .content-container ul.points {
    margin-bottom: 0;
  }
  .nexorama-page .numbers-container {
    padding: 60px 0;
  }

  .nexorama-page .xonomy-bootstrap .row {
    flex-wrap: wrap;
  }
  .nexorama-page .xonomy-bootstrap .left-block {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .nexorama-page .xonomy-bootstrap .right-block {
    width: 100%;
  }
  .nexorama-page .xonomy-bootstrap .right-block ul {
    padding: 0;
  }
  .nexorama-page .xonomy-bootstrap .right-logo {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    width: 50%;
    text-align: center;
  }
  .nexorama-page .xonomy-bootstrap figure {
    height: auto;
  }
  .nexorama-page .xonomy-bootstrap {
    margin: 0;
  }
  .nexorama-page .xonomy-bootstrap .right-logo img {
    width: 100%;
  }
  .nexorama-page .xonomy-bootstrap .right-block ul li {
    width: 50%;
  }

  .vaves-page .slider-container .slider-wrapper {
    height: 55vh;
  }
  .vaves-page .slider-container .slider-wrapper .title {
    font-size: 2.5em;
  }
  .vaves-page .cx-tech,
  .vaves-page .digital-portfolio,
  .vaves-page .portfolio-container,
  .vaves-page .leadership {
    padding: 50px 0;
  }
  .vaves-page .cx-tech h3,
  .vaves-page .digital-portfolio .header h2,
  .vaves-page .portfolio-container .header h2,
  .vaves-page .cx-primer-container .header h2,
  .vaves-page .leadership .header h2,
  .vaves-page .virtuoso-team .header h2 {
    font-size: 24px;
    line-height: 34px;
  }
  .vaves-page .digital-portfolio .row {
    flex-wrap: wrap;
  }
  .vaves-page .digital-portfolio .item {
    width: 100%;
  }
  .vaves-page .portfolio-container .item figure .content {
    bottom: 0;
  }
  .vaves-page .portfolio-container .item figure:hover img {
    transform: none;
  }
  .vaves-page .cx-primer-container .page-wrap {
    flex-wrap: wrap;
  }
  .vaves-page .cx-primer-container .page-wrap .col-5,
  .vaves-page .cx-primer-container .page-wrap .col-6 {
    width: 100%;
  }
  .vaves-page .cx-primer-container .page-wrap .header {
    margin-bottom: 30px;
  }
  .vaves-page .qualtrics-container .row {
    display: flex;
    flex-direction: column;
  }
  .vaves-page .qualtrics-container .row .col-6 {
    width: 100%;
  }
  .vaves-page .qualtrics-container .row .col-5 {
    width: 100%;
    padding: 50px;
  }
  .vaves-page .virtuoso-team .header h4 {
    margin: 50px 0 10px 0;
  }
  .vaves-page .virtuoso-team {
    min-height: 400px;
  }
  .vaves-page .cx-primer-container {
    padding: 0 0 50px 0;
  }
  .vaves-page .leadership .item figure {
    height: 150px;
  }

  .nexorama-page .nex-xonomy,
  .nexorama-page .innovation,
  .nexorama-page .new-business,
  .nexorama-page .nexo-economy,
  .nexorama-page .numbers-container {
    padding: 30px 0;
  }
  .nexorama-page .nex-xonomy .row {
    flex-wrap: wrap;
  }
  .nexorama-page .nex-xonomy .row .col-4 {
    width: 100%;
    padding: 0;
  }
  .nexorama-page .nex-xonomy .content {
    padding: 0;
    margin: 0 0 30px 0;
  }
  .nexorama-page .innovation ul li {
    width: 25%;
  }
  .nexorama-page .nex-xonomy .content h2,
  .nexorama-page .nexo-brand h2,
  .nexorama-page .new-business h2,
  .nexorama-page .nexo-economy .content h2,
  .nexorama-page .customer-success .content-container .title,
  .showcase-news-cards-wrapper h2 {
    font-size: 24px;
    line-height: 34px;
  }
  .nexorama-page .nexo-economy .row {
    display: flex;
    flex-wrap: wrap;
  }
  .nexorama-page .nexo-economy .row .content,
  .nexorama-page .nexo-economy .row .item {
    width: 100%;
  }
  .nexorama-page .nexo-economy .row .content {
    margin-bottom: 30px;
  }
  .nexorama-page .customer-success {
    margin: 0;
  }
  .nexorama-page .numbers-container .row {
    display: flex;
    flex-wrap: wrap;
  }
  .nexorama-page .numbers-container .item {
    width: 50%;
    margin: 0;
    box-sizing: border-box;
  }
  .nexorama-page .xonomy-bootstrap .right-block ul li {
    width: 30%;
  }
  .nexorama-page .xonomy-bootstrap .right-block ul li figure {
    width: 150px;
  }
  .nexorama-page .xonomy-bootstrap .right-logo {
    width: 250px;
  }

  .lab-page .product-hero-wrapper {
    height: 400px;
  }
  .lab-page .bg-image-wrapper {
    height: 400px;
  }
  .lab-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(1) translateX(-70px);
  }
  .lab-page .top-hero-product-banner .item-wrapper figure {
    width: 200px;
  }
  .lab-page .image-title-layout .product-hero-wrapper h3,
  .lab-page .breaker h3 {
    font-size: 24px;
  }
  .lab-page .breaker h4 {
    font-size: 20px;
    line-height: 28px;
  }
  .lab-page .breaker {
    padding: 30px;
  }
  .lab-page .key-issues .item {
    width: 200px;
    height: 200px;
    font-size: 20px;
    line-height: 28px;
  }
  .lab-page .key-issues .item .highlight {
    font-size: 28px;
  }
  .lab-page .quote h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .lab-page .key-issues {
    padding: 30px 0;
    margin: 30px 0;
  }
  .lab-page .challenge-solution .number.text {
    font-size: 20px;
  }
  .lab-page .key-issues .row {
    margin-top: 0;
  }
}
