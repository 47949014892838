* {
  font-family: Roboto;
  font-weight: 300;
  line-height: 1.8;
}

.bold-class {
  font-weight: 500 !important;
}
.highlight {
  font-weight: 500 !important;
}
.number {
  font-weight: 500 !important;
}
.clients-list li {
  height: 6.2em;
  margin: 0 50px 20px 0;
}
.footer-navlinks {
  color: #000 !important;
  font-weight: bold !important;
}
.apph3 {
  margin-bottom: 1em !important;
  font-weight: bold !important;
  color: #3a3939c9 !important;
}
.apph2 {
  color: #3a3939c9 !important;
}
.lineh2 {
  color: #3a3939c9 !important;
  line-height: 1;
}

.content-wrapper h3 {
  font-weight: bold;
}

.app-p {
  color: #666;
}
.app-pp {
  color: #666;
  margin-bottom: 1em;
}
.points li {
  color: #666;
}
.steps li {
  color: #666;
}
.content-wrapper p {
  color: #666;
}

.title {
  font-weight: bold;
}

.section-heading strong {
  color: #666;
}
.section-heading {
  color: #666;
}

#app-h3-p p {
  color: #666;
}
#app-h3-p h2 {
  color: #666;
}

.legal-doc-wrapper h2 {
  color: black;
}
.col-3 h2 {
  font-weight: bold;
}

.col-12 h3 {
  font-weight: bold;
}

.center-wrapper span {
  font-weight: 400 !important;
}
.center-wrapper p {
  margin-bottom: 1em;
}
.italic {
  line-height: 0 !important;
}
.text-wrapper p {
  color: #fff;
}
.talentare-li li {
  color: #fff !important;
}

.btn-tertiary {
  margin-top: 1em;
}

.pink {
  color: #f01c86 ;
}
.red {
  color: red !important;
}

.org-page .aury-metrices .count-band .count-wrapper {
  width: 5em;
  height: 5em;
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 40px; /* Increase the font size of the arrow icons */
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}

.page-wrap li {
  color: #666;
}

.arrow-icon {
  color: #fff;
}

/* Hide the second arrow on each side */
.custom-arrow:nth-child(n + 3) {
  display: none;
}

/* .....................for qaulitrics */

/* SliderComponent.css */
.slider-container {
  position: relative;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
}

.slider-slide {
  position: relative;
  height: 100%;
}

.slider-slide-inner {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.slider-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #f01c86;
}

.slider-block {
  width: 400px;
  max-width: 100%;
}

.slider-title {
  /* font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px; */
  color: #fff;

  font-size: 65px;
  font-weight: 700;
  line-height: normal;
}

.slider-description {
  font-size: 18px;
  color: #fff;
  margin-bottom: 40px;
}

.slider-image-container {
  flex: 1;
  display: flex;
  justify-content: flex-start; /* Changed to flex-start */
  align-items: center;
}

.slider-image {
  max-width: 100%;
  max-height: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.bxsliders {
  animation: scroll 20s infinite linear;
}
#logosliders {
  display: flex;
  animation: scroll 20s infinite linear;
}

#logosliders li {
  margin-left: 2.5rem;
}
.appointment-form-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: opacity 0.3s ease;
}

.appointment-form-container {
  width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.appointment-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.appointment-form-container form input {
  width: 100%;
  margin-bottom: 10px;
}

/* Add more styles as needed */

.input-wrapper {
  display: flex;
  flex-direction: column;
  /* padding: 6px 12px; */
  position: relative;
}
.subtitle1 {
  font-size: 18px;
  font-weight: 400;
  color: #666;
  text-align: center;
  margin-bottom: 0;
  display: block;
}

.link-span {
  background-color: black;
  color: white;
}

.link-span.clicked {
  background-color: blue;
  color: white;
}

.view {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 388px;
}
/* Customize the arrow styles as per your design */
.arrow-prev,
.arrow-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
}

.arrow-prev {
  left: -50px;
}

.arrow-next {
  right: -50px;
}

/* Customize the arrow icons as per your design */
.arrow-prev::before,
.arrow-next::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.arrow-prev::before {
  transform: rotate(224deg);
  left: 50%;
  margin-left: -4px;
}

.arrow-next::before {
  transform: rotate(45deg);
  right: 50%;
  margin-right: -4px;
}

.slide-content {
  display: flex;
}

.slide-text {
  flex: 1;
}

.slide-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.slide-image img {
  max-width: 100%;
  max-height: 100%;
}

.pdf-download {
  height: 300px; /* Adjust the height value as desired */
}
/* .item{
  width:86%
} */

/* CSS for Slider Component */
.carousel-root {
  width: 100%;
  position: relative;
}

.carousel .slider-wrapper {
  overflow: hidden;
  position: relative;
}

.carousel .slider {
  display: flex;
  align-items: center;
  width: 100%;
  transition: transform 0.5s ease;
}

.carousel .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.carousel .slide img {
  max-width: 100%;
  height: auto;
}

.carousel .slide .content {
  text-align: center;
  padding: 20px;
}

.carousel .slide .content h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.carousel .slide .content p {
  font-size: 16px;
}

.carousel .thumbs-wrapper {
  margin-top: 20px;
}

.carousel .thumbs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .thumbs .thumb {
  margin: 0 5px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.carousel .thumbs .thumb img {
  max-width: 100%;
  height: auto;
}

.carousel .dot-group {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.carousel .dot-group .dot {
  margin: 0 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.carousel .dot-group .dot.active {
  background-color: #000;
}

/* Additional Custom Styles */
.carousel {
  margin: 0 auto;
  max-width: 800px;
}

.carousel .slide {
  background-color: #f7f7f7;
}

.carousel .slide .content {
  color: #000;
}

.carousel .slide .content h3 {
  color: #000;
}

.carousel .slide .content p {
  color: #666;
}
.hidden-container.slide-in {
  animation: slide-in 1.1s ease-in-out forwards;
}

.hidden-container.slide-out {
  animation: slide-out 1.1s ease-in-out forwards;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-50%);
  }
}

.slide-out-left {
  animation: slide-out-left 1.1s ease-in-out forwards;
}
@keyframes slide-out-left {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slider-item {
  position: relative;
}

.prev-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}
.next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.prev-arrow:hover,
.next-arrow:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.prev-arrow {
  left: 20px;
  z-index: 1;
}

.next-arrow {
  right: 20px;
}

#vaves_slides {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.subscribe {
  display: flex;
  flex-direction: column;
}
.article-content .button {
  width: 100%;
  text-align: center;
  margin: 30px 0;
}
.article-content .button a {
  background: #00a1fe;
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
}

/* .sticky-content {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  height: 100%;
} */
.main-area .header {
  width: 25%;
  text-align: center;
  position: absolute;
  top: 35%;
  left: 10%;
}

.main-area .header .logo {
  width: 100%;
  margin: 0;
}
.main-area .header h2 {
  font-size: 3em;
  line-height: 1.2;
  font-weight: 500;
  margin-top: 30px;
  color: #fff;
  text-transform: uppercase;
}
.main-area .footer {
  width: 15%;
  text-align: center;
  position: absolute;
  bottom: 50px;
  left: 15%;
  color: #fff;
  flex-direction: row;
  display: flex;
  font-weight: 300;
}
.main-area .footer a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
  line-height: 1;
}
.main-area .footer a:hover {
  text-decoration: none;
  color: #aa0000;
}

.main-area-wrapper {
  height: 100vh;
  padding: 0;
  background-size: cover;
}
.main-area {
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 0;
  background: linear-gradient(to right, #c2e59c, #64b3f4);
}

.main-area-wrapper .main-area .header {
  width: 80%;
  position: absolute;
  top: 20%;
  display: flex;
  margin: 0 auto;
  left: 0;
  right: 0;
  justify-content: space-around;
}

.main-area-wrapper .main-area .header .right {
  width: 40%;
  margin-top: 100px;
}

.main-area-wrapper .main-area .header .right .linkdeco {
  margin-left: 5px;
  text-decoration: underline;
}

.main-area-wrapper .main-area .header .main-img {
  width: 50%;
}
.main-area-wrapper .main-area .header .right h2 {
  font-size: 1.2em;
  color: #000;
  font-weight: bold;
  line-height: 1.5;
  text-transform: capitalize;
}
.main-area-wrapper .main-area .header .right img {
  width: 75%;
}
.main-area-wrapper .main-area .header .right button {
  font-size: 1em;
  color: #000;
  font-weight: 600;
  line-height: 1.2;
  background: #fff;
  padding: 15px 30px;
  margin-top: 30px;
  display: inline-block;
  transition: 0.3s ease-in-out;
  border-radius: 4px;
  border: none;
}
.main-area-wrapper .main-area .header .right button:hover {
  background: #000;
  color: #fff;
}

.careerjob input[type="file"] {
  display: block !important;
}
.career-slider .item {
  display: flex !important;
}
.culturetabs .item {
  width: 15% !important;
}

.close-btn {
  display: inline-block;
  padding: 13px 11px;
  background-color: #000;
  /* color: red; */
  border-radius: 5%;
  cursor: pointer;
  position: relative;
  width: 10px;
  margin-top: 20px;
  left: 90%;
}

.close-btn::before,
.close-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-btn::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.close-btn:hover {
  background-color: #222121;
}

.close-btn:focus {
  outline: none;
}

.top-hero-product-banner {
  background: linear-gradient(180deg, #429ec7, #f9f9f9);
}

.top-hero-product-banner .product-hero-wrapper {
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.product-hero-wrapper .section-heading {
  font-size: 70px;
  line-height: normal;
}

.industry-main-wrapper {
  position: relative;
  /* overflow: hidden; */
  z-index: 10;
}

.industry-main-wrapper .industry-product-banner {
  opacity: 1;
  background: rgba(0, 0, 0, 1);
  filter: brightness(0.65);
}

.industry-main-wrapper .industry-product-banner img {
  width: 100%;
  height: auto;
  opacity: 1;
  transform: none;
}

.industry-main-wrapper .industry-form-wrapper {
  width: 100%;
  padding: 120px;
}
.industry-main-wrapper .industry-form-wrapper .row {
  width: auto;
  padding: 0;
  margin-top: 15px;
}
.industry-main-wrapper .content-wrapper.active {
  display: block;
}
.industry-main-wrapper .default-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center;
  height: 100%;
}
.industry-main-wrapper .content-wrapper .default-wrapper h2 {
  color: #fff;
  text-align: center;
}

.highlight {
  padding: 0;
}
.industry-main-wrapper .content-wrapper h2 {
  font-size: 32px;
  margin-bottom: 5px;
  padding: 0;
}
.industry-main-wrapper .industry-form-wrapper form {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  max-width: 380px;
}
.industry-main-wrapper .industry-form-wrapper h3 {
  font-weight: 700;
  color: #000;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.industry-main-wrapper .industry-form-wrapper .input-wrapper {
  padding: 0;
  margin-bottom: 5px;
}
.industry-main-wrapper .industry-form-wrapper .input-wrapper label {
  font-size: 16px;
  font-weight: 400;
}
.industry-main-wrapper .industry-form-wrapper .input-wrapper label:hover {
  color: #fff;
  background: #00a1fe;
}
.industry-main-wrapper .industry-form-wrapper .input-wrapper label span {
  display: block;
  font-size: 12px;
}

.customer-success-section {
  padding: 60px 0;
}

.customer-success-section .page-wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
}
.customer-success-section .header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.customer-success-section .header .subtitle {
  font-size: 18px;
  font-weight: 400;
  color: #666;
  text-align: center;
  margin-bottom: 0;
  display: block;
}
.customer-success-section .header .title {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: normal;
  color: #000;
  text-align: center;
  display: block;
  position: relative;
}
.customer-success-section .header .title::after {
  content: "";
  width: 100px;
  height: 2px;
  background-color: #c00;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.customer-success-section .clients-wrapper {
  position: relative;
  padding: 0;
  margin-top: 30px;
}
.customer-success-section .clients-wrapper .row li {
  margin: 15px;
  padding-right: 0;
}
.customer-success-section .clients-wrapper .item {
  height: 170px;
}
.customer-success-section .clients-wrapper .item figure {
  position: absolute;
  z-index: 0;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 1);
  justify-content: center;
  display: flex;
  transition: 0.3s ease-in-out;
}
.customer-success-section .clients-wrapper .item figure img {
  opacity: 0.5;
}
.customer-success-section .clients-wrapper .item img {
  height: 100%;
  transition: 0.2s ease-in-out;
  transform: scale(1.2);
}
.customer-success-section .clients-wrapper .label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
  width: 75%;
  line-height: 1;
  font-weight: 500;
  text-align: center;
}

.customer-success-section .clients-wrapper .c-logo {
  position: absolute;
  bottom: 1px;
  right: 1px;
  background-color: #fff;
  width: 100px;
  height: 50px;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-success-section .clients-wrapper .item .c-logo img {
  width: 100%;
  height: auto;
  transform: none;
}
.customer-success-section .clients-wrapper .item-wrapper {
  text-align: center;
  line-height: normal;
}
.customer-success-section .clients-wrapper .item-wrapper p.fadeIn {
  opacity: 1;
  transform: translate(0);
}

.group-container {
  padding: 60px 0;
}

.group-container .subtitle {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #f01c86;
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}
.group-container .section-main-heading {
  text-transform: none;
}
.section-desc.fadeIn {
  opacity: 1;
  transform: translate(0);
  margin-top: -5px;
}
.section-desc {
  text-align: center;
  max-width: 1000px;
  display: block;
  font-size: 20px;
  margin:auto;
  color: #444;
  padding-bottom: 20px;
}
.section-desc {
  opacity: 1;
  transform: translateY(4px);
  transition: all 1s ease-in-out;
}
.section-desc strong {
  font-weight: 700;
}
.group-container .row {
  margin-top: 30px;
}
.group-container .row figure {
  width: 85%;
  margin: 0 auto;
}
.group-container .row figure img {
  width: 100%;
}
.industry-main-wrapper .content-wrapper h2 {
  color: #000;
  font-size: 32px;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
}

.industry-main-wrapper .content-wrapper .section-main-heading {
  display: block;
  text-align: left;
  font-size: 32px;
  font-weight: 500;
}
/* .insights-article-section {
  position: sticky;
  padding-top: 60px;
}
.insights-article-section .sticky-down#sticky-tabs {
  top: 90px;
}
.insights-article-section #sticky-tabs {
  position: fixed;
}

.insights-article-section #sticky-tabs {
  top: 0;
  width: 100%;
  position: absolute;
  background-color: #fff;
  z-index: 99;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.non-sticky.sticky #sticky-tabs {
  position: absolute;
} */

.insights-article-section #sticky-tabs {
  position: sticky;
  top: 0;
  /* height: 200px;  */
  /* Set the desired fixed height */
  /* background-color: #f2f2f2; */
}

.industry-insights .item {
  display: flex;
  align-items: center;
  padding: 15px 40px;
  box-sizing: border-box;
  flex-direction: column;
  text-align: center;
}
.industry-insights .item figure {
  width: 150px;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
}
.industry-insights .item .image-wrap {
  float: left;
  list-style: none;
  position: relative;
  width: 1170px;
}

.industry-insights-wrapper .item {
  display: flex !important;
}

.bg-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: #fff;
  width: 100%;
  /* height: 58vh; */
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.bg-image-wrapper .item {
  float: left;
  list-style: none;
  position: relative;
  width: 1519px;
  margin-right: 20px;
}
.cx-page .product-hero-wrapper {
  height: 58vh;
}
.bg-image-wrapper img {
  width: 100%;
  display: block;
}
.product-hero-wrapper {
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.product-hero-wrapper .page-wrap {
  max-width: 85%;
  position: static;
}
.product-hero-wrapper .item-wrapper {
  padding-right: 20px;
}
.cx-page .image-title-layout .product-hero-wrapper h3 {
  padding: 0;
  font-size: 2.4rem;
  font-weight: 700;
}

.webinar-page .top-hero-product-banner {
  background: linear-gradient(-120deg, #4897d2, #6dd6d5);
  position: relative;
}

.webinar-page .webinars-wrapper {
  padding: 60px 0;
}

.webinar-page .webinars-wrapper .filters select {
  padding: 10px;
  margin-right: 20px;
  cursor: pointer;
  background-color: #f6f6f6;
  border: 1px solid #ddd;
}
.webinar-page .webinars-wrapper .filters label {
  padding: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.webinar-page .webinars-wrapper input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/* veracis */

.veracis .padding-five-tb {
  padding-top: 5%;
  padding-bottom: 5%;
}

.veracis .bg-light-gray {
  background-color: #f7f7f7;
}

.veracis .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.veracis .display-table {
  display: table !important;
}

.veracis .text-small {
  font-size: 12px;
  line-height: 20px;
}

.veracis .alt-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.veracis .display-table-cell {
  display: table-cell !important;
}
.veracis .vertical-align-middle {
  vertical-align: middle;
}
.veracis a,
a:active,
a:focus {
  color: #6f6f6f;
  text-decoration: none;
}
.veracis .footer-logo {
  max-height: 40px;
}
.veracis img {
  max-width: 100%;
  /* height: auto; */
  vertical-align: middle;
  margin-bottom: -15px;
}

#whiteli li {
  color: white;
}
#hovertextblack:hover li {
  color: #6f6f6f;
}

#itemli li {
  color: white;
}

#all-p-h3 p {
  color: #666;
}
#all-p-h3 strong {
  color: #666;
}
#all-p-h3 h3 {
  color: #666;
  font-weight: bold;
}

.cdigital-page .header-container .page-wrap {
  max-width: 100%;
  padding: 0;
  flex-direction: row !important;
  display: flex !important;
}

.cwide-page .product-hero-wrapper h3 {
  color: #fff;
  font-weight: 700;
  font-size: 50px !important;
}

.system-of-engagement {
  padding: 60px 0;
  position: relative;
}

.system-of-engagement .illus {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 0;
}

.system-of-engagement .illus img {
  width: 100%;
  opacity: 0.4;
}

.system-of-engagement .content-wrapper {
  background-color: #fff;
  padding: 40px;
}

.system-of-engagement .content-wrapper p.note {
  font-style: italic;
  font-size: 14px;
  padding-top: 40px;
}

.system-of-engagement .consultare-services .item-wrapper {
  padding: 10px;
}

.system-of-engagement .tabs a {
  padding: 15px;
  font-size: 18px;
  display: flex;
  border-left: 5px solid #ddd;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.system-of-engagement .tabs a figure {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.system-of-engagement .tabs a figure img {
  width: 100%;
}

.system-of-engagement .tabs a.active {
  color: #f01c86;
  background-color: #fff;
  border-color: #ddd #f01c86;
  font-weight: 500;
}

.plus-page .enablement .illus {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#83a4d4),
    to(#b6fbff)
  );
  background: linear-gradient(-180deg, #83a4d4, #b6fbff);
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.plus-page .enablement .illus ul {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.plus-page .enablement .illus ul li .item {
  width: 100px;
  height: 75px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  margin: 20px;
}

.plus-page .enablement .illus ul li img {
  width: 100%;
  height: auto;
}

.plus-page .enablement .illus ul li img.adjust-height {
  height: 50px;
}

.plus-page .enablement .illus ul li img.adjust-height1 {
  height: 85px;
  width: 85px;
}

.plus-page .enablement .illus .logo-ticker-wrapper .bx-wrapper {
  max-width: 100% !important;
}
.marquee-container {
  overflow: hidden;
  width: 100%;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.crm-cx-page .vteam-wrapper {
  padding: 100px 0;
  margin: 60px 0 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#36d1dc),
    to(#5b86e5)
  );
  background: linear-gradient(to right, #36d1dc, #5b86e5);
}

.oracle-netsuite-page .tablinks {
  color: #000 !important;
}

.oracle-netsuite-page .activetab {
  color: #e55844 !important;
}

.exp-cloud-page .product-hero-wrapper h3 {
  font-size: 47px !important;
}

.exp-cloud-page .top-hero-product-banner {
  background: linear-gradient(180deg, #ffffff, #f9f9f9) !important;
}

/* survey */
.star {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #ffc107; /* Adjust the color as needed */
  position: relative;
}

.star:before,
.star:after {
  content: "";
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #ffc107; /* Adjust the color as needed */
}

.star:before {
  left: -20px;
  transform: rotate(35deg);
}

.star:after {
  left: 20px;
  transform: rotate(-35deg);
}

.survey .surveyrow {
  width: 60%;
  justify-content: end;
}

.survey .form-control {
  display: block;
  width: 85%;
  height: calc(1em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.survey .input::focus {
  border-color: #00a1fe;
}
div.stars {
  width: 270px;
  display: inline-block;
}

label.star {
  padding: 0px !important;
  font-size: 15px !important;
}
/* input.star4 {
  display: none;
}

label.star4 {
  float: right;
  padding: 10px;
  font-size: 36px;
  color: #444;
  transition: all .2s;
}

input.star4:checked~label.star4:before {
  content: '\f005';
  color: #FD4;
  transition: all .25s;
}

input.star-21:checked~label.star4:before {
  color: #FE7;
  text-shadow: 0 0 20px #952;
}

input.star-25:checked~label.star4:before {
  color: #F62;
}

label.star4:hover {
  transform: rotate(-15deg) scale(1.3);
}

label.star4:before {
  content: '\f006';
  font-family: FontAwesome;
} */

.star {
  --star-color: #ffdd44;
  margin: 1em auto;
  font-size: 10em;
  position: relative;
  display: block;
  width: 0px;
  height: 0px;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid var(--star-color);
  border-left: 1em solid transparent;
  transform: rotate(35deg);
  /* position: relative;
  
  display: inline-block;
  width: 0;
  height: 0;
  
  margin-left: .9em;
  margin-right: .9em;
  margin-bottom: 1.2em;
  
  border-right:  .3em solid transparent;
  border-bottom: .7em  solid #FC0;
  border-left:   .3em solid transparent; */

  /* Controlls the size of the stars. */
  /* font-size: 24px;
  
  &:before, &:after {
    content: '';
    
    display: block;
    width: 0;
    height: 0;
    
    position: absolute;
    top: .6em;
    left: -1em;
  
    border-right:  1em solid transparent;
    border-bottom: .7em  solid #FC0;
    border-left:   1em solid transparent;
  
    transform: rotate(-35deg); */
}

/* &:after {  
    transform: rotate(35deg);
  } */
/* } */
.star:before {
  border-bottom: 0.8em solid var(--star-color);
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -0.45em;
  left: -0.65em;
  display: block;
  content: "";
  transform: rotate(-35deg);
}
.star:after {
  position: absolute;
  display: block;
  top: 0.03em;
  left: -1.05em;
  width: 0;
  height: 0;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid var(--star-color);
  border-left: 1em solid transparent;
  transform: rotate(-70deg);
  content: "";
}
div.stars {
  width: 270px;
  display: inline-block;
}

input.star {
  display: none;
}

label.star {
  float: left;
  padding: 10px;
  font-size: 36px;
  color: #444;
  transition: all 0.2s;
  margin-left: 10px;
}

input.star:checked ~ label.star:before {
  content: "\f005";
  color: #fd4;
  transition: all 0.25s;
}
label.star:hover {
  transform: rotate(-15deg) scale(1.3);
}

.survey textarea {
  width: 100%;
  background: #f6f6f6;
  padding: 10px;
  border: 1px solid #eee;
  box-sizing: border-box;
}
.surveybutton {
  /* margin: 1px 25px; */
  width: 25%;
  font-weight: bold;
  background-color: #00a1fe;
  color: white;
  padding: 4px 12px;
  border: 2px solid white;
  /* border-radius: 10px; */
  font-size: 15px;
  cursor: pointer;
  /* box-shadow: 0 4px 8px 0 rgb(30, 242, 164), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  font-family: "Noto Sans", sans-serif;
  font-family: "Roboto Slab", serif;
}
/* survey end */
.slide-content1 {
  flex-direction: row !important;
  display: flex !important;
}
.slide-content1 svg {
  width: 85% !important;
  margin-top: 45px;
}
.slide-content1 h2 {
  padding: 85px 30px !important;
}

.header-nav-wrapper .mid-section .left-block ul li {
  padding: 22px 50px 24px 30px !important;
}
.header-nav-wrapper .mid-section .right-block li {
  padding: 18px 10px 0 13px !important;
}

.content-wrapper .industry-type-section {
  margin-top: -30rem !important;
}

.industry-main-wrapper .industry-type-section {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  box-sizing: border-box;
}

.tabs-container1 .tabs-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #eee;
  padding-bottom: 60px;
}
.tabs-container1 .tab {
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  border-bottom: 1px solid #eee;
}
/* .tabs-container1 .tab a.active {
  background-color: #f01c86;
  color: #fff;
  font-weight: 500;
} */
.tabs-container1 .tab a {
  padding: 5px 30px;
  text-align: right;
  align-items: flex-end;
  cursor: pointer;
  background-color: #ddd;
  margin-right: 10px;
  text-transform: uppercase;
  color: #333;
  position: relative;
}

.tabs-container1 .tabs-wrapper .page-wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
}
.tabs-container1 .tabcontent .col-2 a.link {
  border: 1px solid #ddd;
  padding: 30px;
  text-align: center;
  line-height: normal;
  font-weight: 500;
  color: #f01c86;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
  /* margin-left:10px; */
}

.tabs-container1 .tabcontent figure.solution {
  border: 1px solid #ddd;
  padding: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-container1 .tabcontent figure img {
  width: 100%;
  height: 3rem;
  /* height: 100%; */
}
.tabs-container1 .tabcontent .section-heading {
  font-size: 26px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  line-height: normal;
  margin-bottom: 10px;
}
.tabs-container1 .tabcontent p.fadeIn {
  opacity: 1;
  transform: translate(0);
}
.tabs-container1 .tabcontent .btn-black {
  min-width: auto;
  background-color: #000;
  color: #fff;
  margin-top: 10px;
}

.tabs-container1 .tabcontent .row {
  justify-content: center;
}

.tabs-container1 .tabcontent .row [class*="col-"] {
  box-sizing: border-box;
  margin: 15px;
}
.tabs-container1 {
  margin-top: 4em;
}

.tab-content-wrapper .tab-content-wrap .solutions1 ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.training-page .product-hero-wrapper p {
  font-weight: 600 !important;
  font-size: 2.2em !important;
}

.training-page .product-hero-wrapper h3 {
  font-weight: 600 !important;
  font-size: 45px !important;
}

.employee-beneits-page .product-hero-wrapper p {
  font-weight: 600 !important;
  font-size: 2.2em !important;
}

.employee-beneits-page .product-hero-wrapper h3 {
  font-weight: 600 !important;
  font-size: 45px !important;
}

.alumni-page .product-hero-wrapper p {
  font-weight: 600 !important;
  font-size: 2.2em !important;
}

.alumni-page .product-hero-wrapper h3 {
  font-weight: 600 !important;
  font-size: 45px !important;
}

.degree360-page .product-hero-wrapper p {
  font-weight: 600 !important;
  font-size: 2.2em !important;
}

.degree360-page .product-hero-wrapper h3 {
  font-weight: 600 !important;
  font-size: 45px !important;
}

.degree360-page .product-hero-wrapper .item-wrapper span {
  font-size: 30px !important;
  margin-top: 25px !important;
}

.degree360-page .image-title-layout .product-hero-wrapper h3 {
  font-weight: 600;
  font-size: 45px;
}

.platform-ecosystem-page .content p {
  font-size: 1em !important;
}

.o-digital-page .cx-bg {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#00a1fe),
    to(#7ee9cc)
  );
  background: linear-gradient(-90deg, #00a1fe, #7ee9cc);
}

.o-digital-page .bg-image-wrapper img {
  transform: scale(1.2);
}

.o-digital-page .header-block .top-logo {
  width: 26% !important;
}

.cjourney-page .image-title-layout .product-hero-wrapper h3 {
  font-size: 33px !important;
}

.cx-continuum-page .cx-bg {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#00a1fe),
    to(#7ee9cc)
  );
  background: linear-gradient(-90deg, #00a1fe, #7ee9cc);
}

.cx-continuum-page .header-block .top-logo {
  width: 26% !important;
}

/* .customer360-page .product-hero-wrapper h3 {
  font-size: 45px;
} */

.w-digital-page .how-breaker .content {
  padding: 10px 10px !important;
}
.xlv-page .close-statement .item {
  padding: 26px;
  font-size: 19px;
}
.bpm-page .block-4 .item {
  padding-bottom: 20px !important;
  height: 100% !important;
}
.bpm-page .block-4 .item p {
  color: #787878;
  font-weight: 300;
}

.bpm-page .block-4 .item h3 {
  color: #6c6c6c;
}

.bpm-page .cx-now-section .content p {
  color: white !important;
}

.budh-page .top-hero-product-banner {
  background: linear-gradient(
    -120deg,
    #4897d2,
    #6dd6d5,
    #b2eaeb,
    #8cdbdf
  ) !important;
}

.main-blogs .article-content .items-container .item {
  font-size: 15px !important;
}

.main-blogs .clients-wrapper .item-wrapper p.fadeIn {
  color: #838383;
  font-weight: 300;
  font-size: 14px;
  padding-top: 10px;
}

.main-blogs .article-content .highlights-wrapper p {
  margin-bottom: 0;
}
.main-blogs .article-content .highlights-wrapper p {
  margin-bottom: 0;
}
.main-blogs .article-content p {
  font-size: 1em !important;
}
.main-blogs .article-content .italic {
  line-height: 30px !important;
}
.client-detail-page .clients-wrapper .item-wrapper p.fadeIn {
  color: #838383;
  font-weight: 300;
  font-size: 14px;
  padding-top: 10px;
}

.client-detail-page .normal-heading {
  margin-top: 25px;
  margin-bottom: 15px;
}

.client-detail-page .section-heading.fadeIn {
  margin-top: 15px;
  margin-bottom: 15px;
}

.term-condition-page .normal-heading {
  margin-top: 25px;
  margin-bottom: 15px;
}

.term-condition-page .section-heading.fadeIn {
  margin-top: 15px;
  margin-bottom: 15px;
}

/* .client-detail-page p.fadeIn {
  margin-top: 15px;
} */
.indicial-page p.fadeIn {
  margin-top: 15px;
}
.indicial-page .journey-path-wrapper {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#b6fbff),
    to(#83a4d4)
  );
  /* background: linear-gradient(90deg,#b6fbff,#83a4d4); */
}
.cvoice-page p.fadeIn,
ul {
  /* margin-top: 15px; */
  margin-top: 2px;
}
.webinar-page ul {
  margin-top: 15px !important;
}
.cxaudit-page .xd-process .process-items .item p {
  margin-top: 25px;
  color: #5d5d5d;
}

#mainEvents a {
  font-weight: 500;
  line-height: 1em;
}

.required-message {
  font-size: 14px;
  color: red;
}

.veracis section {
  padding: 130px 0;
  overflow: hidden;
}
#what {
  visibility: visible;
  animation-name: fadeIn;
}

.veracis .feature-box-1 .char-value {
  display: table-cell;
  vertical-align: middle;
}
.veracis .font-weight-300 {
  font-weight: 300;
}
.veracis .letter-spacing-minus-1 {
  letter-spacing: -1px;
}
.veracis h3 {
  font-size: 48px;
  line-height: 54px;
}

.veracis .width-100 {
  width: 100%;
}
.veracis .display-table-cell {
  display: table-cell !important;
}
.veracis .padding-20px-left {
  padding-left: 20px;
}
.veracis .vertical-align-middle {
  vertical-align: middle;
}

.veracis .line-height-22 {
  line-height: 22px;
}

#what .fadeIn {
  animation-name: fadeIn;
}
.bg-deep-blue {
  background-color: #00a1fe;
}
.sidebar-wrapper .container {
  width: 1000px;
}
.sidebar-wrapper .feature-box-1 .char-value {
  display: table-cell;
  vertical-align: middle;
}
.sidebar-wrapper .display-table-cell {
  display: table-cell !important;
}
.sidebar-wrapper .text-white {
  color: #fff;
}

.wow {
  visibility: visible;
  animation-name: fadeInUp;
  /* height: 149px; */
}
.veracis .margin-six-bottom {
  margin-bottom: 6%;
}

.veracis .feature-box-5 {
  padding-left: 75px;
}
/* .veracis .position-relative {
  position: relative !important;
} */

.veracis .feature-box-5 i {
  position: absolute;
  top: 0;
  left: 0;
}
.veracis .icon-medium {
  font-size: 35px;
}
.veracis .text-medium-gray {
  color: #939393;
}

.veracis .margin-5px-bottom {
  margin-bottom: 5px;
}
.veracis .font-weight-600 {
  font-weight: 600;
}
.veracis .text-extra-dark-gray {
  color: #232323;
}
.veracis .alt-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.veracis .wow {
  font-size: 15px;
}

#portfolio {
  visibility: visible;
  animation-name: fadeIn;
}

#portfolio .no-padding {
  padding: 0 !important;
}

#portfolio .no-padding:hover {
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

#portfolio .no-margin {
  margin: 0 !important;
}

.hover-option2 .grid-item .portfolio-hover-main {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  display: table;
  height: 100%;
  top: 0;
  width: 100%;
}

#portfolio .overflow-hidden {
  overflow: hidden !important;
}

#portfolio .grid-item .portfolio-hover-box {
  display: table-cell;
  height: 100%;
}
#portfolio .portfolio-grid .grid-item figure {
  margin: 0;
  position: relative;
  overflow: hidden;
}
#portfolio .portfolio-grid .grid-item figure:hover .portfolio-img {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
#portfolio .grid-item .portfolio-hover-box {
  display: table-cell;
  height: 100%;
}

#portfolio .hover-option2 .grid-item:hover .portfolio-hover-content {
  bottom: 0;
}
#portfolio .hover-option2 .separator-line-horrizontal-medium-light2 {
  opacity: 0.5;
}
#portfolio .margin-25px-bottom {
  margin-bottom: 25px;
}
#portfolio .display-block {
  display: block !important;
}
#portfolio .margin-one-half-bottom {
  margin-bottom: 1.5%;
}
#portfolio .last-paragraph-no-margin p:last-of-type {
  margin-bottom: 0;
}
#portfolio .portfolio-grid .grid-item figure img {
  cursor: pointer;
  display: block;
  opacity: 1;
  width: 100%;
  transition: all 0.3s ease 0s;
}
#portfolio ul {
  position: relative;
  height: 350px;
  width: 45%;
}

#news {
  visibility: visible;
  animation-name: fadeIn;
}

#news .container {
  width: 1000px;
}

#news .margin-eight-bottom {
  margin-bottom: 8%;
}
#news .center-col {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
#news .margin-20px-bottom {
  margin-bottom: 20px;
}
#news .font-weight-700 {
  font-weight: 700;
}
#news .text-extra-dark-gray {
  color: #232323;
}
#news .alt-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
#news .text-uppercase {
  text-transform: uppercase;
}
#news h5 {
  font-size: 32px;
  line-height: 40px;
}
#news .margin-lr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
#news p {
  margin: 0 0 25px;
}
#news .news-divider {
  visibility: visible;
  animation-name: fadeInUp;
  height: 462px;
}
#news .news-divider a,
a:active,
a:focus {
  color: #6f6f6f;
  text-decoration: none;
}
#news .news-divider a {
  color: #337ab7;
  text-decoration: none;
}
#news .news-divider a {
  background-color: transparent;
}
#news .overflow-hidden {
  overflow: hidden !important;
}

#news .margin-25px-bottom {
  margin-bottom: 25px;
}
#news img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}
#news .width-90 {
  width: 90%;
}
#news .display-block {
  display: block !important;
}
#news .text-extra-dark-gray {
  color: #232323;
}
#news .text-medium {
  font-size: 16px;
  line-height: 23px;
}
#news .text-dark-gray {
  color: #626262;
}
#news .text-small {
  font-size: 12px;
  line-height: 20px;
}
#news .margin-20px-tb {
  margin-top: 20px;
  margin-bottom: 20px;
}
#news .separator-line-horrizontal-full {
  width: 100%;
  height: 1px;
}
#news .bg-medium-light-gray {
  background-color: #ededed;
}
#news .display-inline-block {
  display: inline-block !important;
}
#news .text-medium-gray {
  color: #939393;
}
#news .text-extra-small {
  font-size: 11px;
  line-height: 14px;
}
#news .text-uppercase {
  text-transform: uppercase;
}

#news .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 1200px) {
  .veracis .col-lg-4 {
    width: 33.33333333%;
  }

  .veracis .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }
}
@media (min-width: 992px) {
  .veracis .col-md-6 {
    width: 50%;
  }

  .veracis .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }
}


@media (max-width: 768px) {

  .clients-list li {
    height: 6.2em;
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .veracis .col-sm-6 {
    width: 50%;
  }

  .veracis .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
}
.veracis .col-xs-12 {
  width: 100%;
}
.veracis .col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}
.veracis .col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.veracis .sidebar-wrapper .container {
  width: 1000px;
}
.veracis .bg-extra-dark-gray {
  background-color: #1c1c1c;
}

.veracis .display-table-cell {
  display: table-cell !important;
}
.veracis .padding-ten-right {
  padding-right: 10%;
}
.veracis .vertical-align-middle {
  vertical-align: middle;
}
.veracis .icon-box {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.veracis .width-100 {
  width: 100%;
}
.veracis .icon-box .icon-box-holder {
  padding-left: 74px;
  height: 112px;
}
.veracis .position-relative {
  position: relative !important;
}
.veracis .icon-box .icon-box-holder i {
  position: absolute;
  left: 0;
  vertical-align: middle;
  padding-top: 10px;
}
.veracis .icon-medium {
  font-size: 35px;
}
.veracis .line-height-24 {
  line-height: 24px;
}
.veracis .text-large {
  font-size: 18px;
  line-height: 26px;
}
.veracis .padding-ten-left {
  padding-left: 10%;
}
.veracis .display-block {
  display: block !important;
}
.veracis .margin-20px-bottom {
  margin-bottom: 20px;
}
.veracis .text-extra-large {
  font-size: 20px;
  line-height: 26px;
}

.veracis .no-padding {
  padding: 0 !important;
}
.veracis .feature-box {
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.veracis .col-lg-3 {
  width: 25%;
}
.veracis .feature-box.feature-box-7 div:first-child .box {
  border-left: none;
}
.veracis .feature-box.feature-box-7::before,
.feature-box.feature-box-7::after,
.feature-box.feature-box-7 .content::before,
.feature-box.feature-box-7 .content::after {
  background: transparent;
}
.veracis .feature-box.feature-box-7:before,
.feature-box.feature-box-7:after {
  content: "";
  display: table;
}
.veracis .feature-box:before,
.feature-box:after {
  height: 100%;
  width: 3px;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}
.veracis .feature-box.feature-box-7 .box .content {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  padding: 0;
  position: relative;
  top: 30px;
}
.veracis .feature-box.feature-box-7 .box figure {
  -ms-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -o-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  padding: 8% 0;

  margin: 0;
}

.veracis .feature-box.feature-box-7 .box .icon {
  width: 50px;
  margin: 0 auto;
  display: block;
}

.veracis .feature-box.feature-box-7 .box .margin-15px-bottom {
  margin-bottom: 15px;
}
.veracis .feature-box.feature-box-7 .box img {
  max-width: 100%;
  height: auto;

  vertical-align: middle;
}

.veracis .display-block {
  display: block !important;
}
.veracis .margin-10px-bottom {
  margin-bottom: 10px;
}
.veracis .text-extra-dark-gray {
  color: #232323;
}
.veracis .feature-box.feature-box-7 .box .content .details {
  opacity: 0;
}
.veracis .last-paragraph-no-margin p:last-of-type {
  margin-bottom: 0;
}
.veracis .width-80 {
  width: 80%;
}

/* pricing box */
.veracis .highlight .pricing-box {
  box-shadow: 0px 0px 40px rgba(200, 200, 200, 0.3);
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
}

/* feature box style 4 */
.veracis .grid-item.feature-box-4 {
  overflow: hidden;
  position: relative;
}
.veracis .grid-item.feature-box-4 figure img {
  width: 100%;
  cursor: auto;
}
.veracis .grid-item.feature-box-4 figure figcaption {
  position: absolute;
  left: 0px;
  top: auto;
  z-index: 9;
  bottom: 0px;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transition: transform 0.35s ease-out;
  -webkit-transition: transform 0.35s ease-out;
  -moz-transition: transform 0.35s ease-out;
  -ms-transition: transform 0.35s ease-out;
  -o-transition: transform 0.35s ease-out;
  padding: 0 0 15px 60px;
  width: auto;
  height: auto;
  text-align: left;
  opacity: 1;
  cursor: default;
}
.veracis .grid-item.feature-box-4 figure:hover img,
.feature-box-4 figure:focus img {
  opacity: 1;
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}
.veracis .grid-item.feature-box-4 figure:hover figcaption {
  transform: translateY(-50px);
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  -o-transform: translateY(-50px);
}
.veracis .grid-item.feature-box-4 figure figcaption .btn {
  opacity: 0;
}
.veracis .grid-item.feature-box-4 figure:hover .btn {
  opacity: 1;
  display: inline-block;
}
.veracis .grid-item.feature-box-4 figure:hover img {
  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transform: scale(1.09, 1.09);
  -webkit-transform: scale(1.09, 1.09);
  -moz-transform: scale(1.09, 1.09);
  -ms-transform: scale(1.09, 1.09);
  -o-transform: scale(1.09, 1.09);
}

/* feature box style 5 */
.veracis .feature-box-5 {
  padding-left: 75px;
}
.veracis .feature-box-5 i {
  position: absolute;
  top: 0;
  left: 0;
}

/* feature box style 6 */
.veracis .feature-box-6 {
  padding-left: 70px;
}
.veracis .feature-box-6 i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-22px);
  -webkit-transform: translateY(-22px);
  -moz-transform: translateY(-22px);
  -ms-transform: translateY(-22px);
  -o-transform: translateY(-22px);
}

.veracis .feature-box.feature-box-7:before,
.feature-box.feature-box-7:after {
  content: "";
  display: table;
}
.veracis .feature-box.feature-box-7:hover,
.feature-box.feature-box-7:hover:before,
.feature-box.feature-box-7:hover:after {
  border: none;
}
.veracis .feature-box.feature-box-7::before,
.feature-box.feature-box-7::after,
.feature-box.feature-box-7 .content::before,
.feature-box.feature-box-7 .content::after {
  background: transparent;
}
.veracis .feature-box.feature-box-7:after {
  clear: both;
}
.veracis .feature-box.feature-box-7 .box {
  cursor: default;
  height: 100%;
  width: 100%;
  display: table;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -moz-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -mstransition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -o-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  border: 1px solid #ededed;
  border-right: none;
  overflow: hidden;
}
.veracis .feature-box.feature-box-7 div:first-child .box {
  border-left: none;
}
.veracis .feature-box.feature-box-7 .box figure {
  -webkit-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -moz-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -ms-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  -o-transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  transition: all 0.4s cubic-bezier(0.48, 0.83, 0.63, 0.91);
  padding: 8% 0;
}
.veracis .feature-box.feature-box-7 .box .icon {
  width: 50px;
  margin: 0 auto;
  display: block;
}
.veracis .feature-box.feature-box-7 .box .content {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  padding: 0;
  position: relative;
  top: 30px;
}
.veracis .feature-box.feature-box-7 .box .content .details {
  opacity: 0;
}
.veracis .feature-box.feature-box-7 .box:hover .content .details {
  opacity: 1;
}
.veracis .feature-box.feature-box-7 .box:hover .content {
  top: 0;
  border-bottom-color: #ff214f;
}
.veracis .feature-box.feature-box-7 .box:hover i {
  color: #ff214f;
}
.veracis .container .feature-box.feature-box-7 .box .content {
  top: 43px;
}
.veracis .container .feature-box.feature-box-7 .box:hover .content {
  top: 0;
}

.veracis .last-paragraph-no-margin p:last-of-type {
  margin-bottom: 0;
}
.veracis .width-80 {
  width: 80%;
}
.veracis .center-col {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.veracis h5 {
  font-size: 32px;
  line-height: 40px;
}
.veracis .margin-lr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* team style 1 */
.veracis .team-style-1 figure .team-image {
  position: relative;
  overflow: hidden;
}
.veracis .team-style-1 .team-overlay {
  display: block;
  position: absolute;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}
.veracis .team-style-1 .team-overlay {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
}
.veracis .team-style-1 figure:hover .team-overlay {
  height: 100%;
}
.veracis .team-style-1 figure figcaption {
  width: 100%;
  position: relative;
  margin-top: 17px;
}
.veracis .team-style-1 figure .overlay-content {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  top: -15px;
}
.veracis .team-style-1 figure .overlay-content img {
  width: 70%;
  margin: 0 auto;
}
.veracis .team-style-1 figure:hover .overlay-content {
  opacity: 1;
  z-index: 1111;
  top: 0;
}
.veracis .team-style-1 figure figcaption .team-member-position {
  position: relative;
  top: 0;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}

/* team style 2 */
.veracis .team-style-2 figure {
  position: relative;
}
.veracis .team-style-2 figure .team-image {
  position: relative;
}
.veracis .team-style-2 .team-overlay {
  display: block;
  position: absolute;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.veracis .team-style-2 figure:hover .team-overlay {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}
.veracis .team-style-2 figure .team-member-position {
  position: absolute;
  top: 100%;
  width: 100%;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.veracis .team-style-2 figure:hover .team-member-position {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  margin: 0;
}
.veracis .team-style-2 figure:hover .team-member-position .text-medium-gray,
.team-style-2 figure:hover .team-member-position .text-extra-dark-gray {
  color: #fff;
}
.veracis .team-style-2 figure figcaption {
  min-height: 20px;
}
.veracis .team-style-2 figure figcaption .overlay-content {
  opacity: 0;
  top: 0;
  position: relative;
}
.veracis .team-style-2 figure:hover figcaption .overlay-content {
  opacity: 1;
  top: 30px;
}

.veracis .margin-eight-bottom {
  margin-bottom: 8%;
}
.veracis .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.veracis .team-style-1 figure .overlay-content img {
  width: 70%;
  margin: 45% auto;
}
.veracis .veracis-offering {
  text-align: center;
}
#agnitor-iconss img {
  height: 4rem;
  width: 4rem;
}
#agnitor-iconss figure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46% !important;
}
.right-apps-page #mobileimg {
  height: 22rem;
  width: 20rem;
  /* margin-top: 2.5rem;
  poa */
  position: absolute;
  bottom: 0;
}
.right-apps-page #rightappimg {
  height: 9rem;
  position: absolute;
  width: 13rem;
  right: 50px;
  top: 30px;
}

@media only screen and (max-width: 767px) {
  .cloud-page .main-wrapper {
    padding-top: 28px !important;
  }

  .right-apps-page #mobileimg {
    height: 7rem !important;
    width: 6.3rem !important;
    margin-top: 6.8rem !important;
  }
  .right-apps-page #rightappimg {
    display: none;
  }
  /* .breadcrumb-kanaban{
    display: none !important;
  } */
  #news {
    margin-top: 10px;
  }
  #news .company-detail {
    margin-top: 10px !important;
  }
  #news .article-info {
    margin-top: -5px;
    position: relative;
    color: #333;
    padding: 13px;
  }
  #news .article-info .article-heading {
    font-size: 24px;
  }

  #news .article-info .date-posted {
    font-size: 16px;
  }
  #news .row {
    margin-top: -44px;
  }
  #news .journal {
    margin-top: 0px;
  }
  #news .agenda .row {
    margin-top: 0px;
    color: #666;
  }
  #news .tweet {
    margin-top: -16vh;
  }
  #news_main .subscribe-section {
    margin-top: 55px;
    padding: 30px 0;
  }

  #news_main #S_News a {
    padding: 14px 25px !important;
  }
  #crm-bottom-box {
    height: 100vh !important;
  }
  .o-digital-page .header-block .top-logo {
    width: 85% !important;
  }
  .o-digital-page .header-block {
    padding: 40px 0 !important;
    margin-top: 30px;
  }
  .case-study.main-blogs .article-info .title-wrapper {
    padding: 22px 50px 16px 30px !important;
  }
  /* .main-blogs .article-content .highlights-wrapper p {
    margin-top: 35px !important;
  } */
  .customer-success-section {
    padding: 20px 0;
  }
  .customer-success-section .clients-wrapper .row li {
    margin: 10px 12px !important;
    padding-right: 15px !important;
  }
  .customer-success-section .section-main-heading {
    margin: -30px 0px !important;
  }
  #career-arrow {
    display: block !important;
    margin-left: -7px;
    margin-right: 5px;
  }
  #form-mobile-view p {
    margin-right: 0px !important;
    font-size: 14px;
  }

  #form-mobile-view .section-heading {
    margin-top: 2.5rem !important;
  }
  blockquote ul li {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 20px !important;
  }
  /* blockquote {
    padding-right: 40px;
  } */
}
.fig-number {
  font-weight: 500;
}
.fig-number span {
  font-weight: 500;
}

.business3-page .plans-table .red ul li {
  color: red;
}
.business3-page .plans-table .blue ul li {
  color: #00a1fe;
}

/* .twitter-feeds {
    height: 400px;
    overflow-y: scroll;
} */
@media only screen and (min-width: 961px) and (max-width: 1200px) {
  .product-hero-wrapper figure img {
    width: 100%;
    height: auto;
  }
}

.product-hero-wrapper figure img {
  width: 100%;
  height: auto;
}
#career-arrow {
  display: none;
}

.css-fiov70 {
  font-weight: 400;
  padding: 20px;
  background-color: #f9f9f9;
  text-transform: uppercase;
}
.css-fiov70 button,
h6 {
  color: #c00 !important;
}
.MuiList-root li {
  border-bottom: 1px solid #eee;
}
.MuiList-root li:last-child {
  border: 0;
}
.css-9mgopn-MuiDivider-root {
  border: 0 !important;
}
blockquote ul li {
  color: #f01c86 !important;
  font-size: 28px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  position: relative;
}

#news .sub-title {
  font-weight: 600;
  margin-bottom: 18px;
  color: #666;
}
.yippee-item h3 {
  font-weight: 500;
  color: #666;
}
.cloud-page .main-wrapper {
  padding-top: 68px;
}

.odigital-page .plans-table .red ul li {
  color: #eb1b23;
}
.odigital-page .plans-table .blue ul li {
  color: #00adee;
}

/* @media screen and (min-width: 768px) and (max-width: 900px) {
  .industry-main-wrapper {
  height: 100vh;
  }
} */

/* .bigthink{
  background:white;
  border-radius: 1rem;
  margin-top: 1rem;
} */
@media only screen and (max-width: 767px) {
  .mob-slider-logo {
    margin-left: 5rem;
    padding-top: 1rem;
  }
  /* .mob-slider-ul {
    margin-top: -7rem;
  } */
}

.white{
  color:white !important;

}

.cx-page p{
  font-weight: 600;
  color: #666;
}

.crm-new-digital h3{
  font-weight: 600;
}
.crm-new-digital p{
  font-size: 1rem;
}

.mt-4{
  margin-top: 2.5rem !important;
}


.leads_table {
  border: 2px solid #dee2e6;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
}

.leads {
  /* background-color: #dee2e6; */
  padding: 2rem;
  margin-top: 2rem;
}

.search-bar-leads {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 3rem;
  padding-top: 2rem;
}

.search-bar-leads input {
  height: 2rem;
  width: 20rem;
  border-radius: 30px;
  border: 1px solid lightgray;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 1.4rem;
}


nav.sc-iJCSeZ.bvimVq.rdt_Pagination {
  border-radius: 10px;
}

.sc-gtsqUy.iybuay.rdt_TableHead {
  font-size: 16px;
  color: rgb(0 161 254);
  font-weight: 600;
}

.QTDCI {
  min-height: 40px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #827575 !important;
}

.jXXTZN {
  min-height: 48px !important;
}

.bvimVq {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: rgba(0 161 254) !important;
}

.sc-dlMCtj.bXLDzR {
  padding-inline: 2rem;
}

.export_button {
  margin-right: 5px;
  display: flex;
  align-items: center;
  /* border: 1px solid lightgray; */
  padding: 0.4rem;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
  border-radius: 40px;
  padding-inline: 1rem;
  /* background-color: #34a853; */
}

.export_button img:hover {
  cursor: pointer;
}

.input_field {
  display: flex;
  align-items: center;
}

.export_button .export_text {
  font-weight: 700;
  color: black;
  font-size: 16px;
  margin-bottom: 0px;
}

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: #fff;
  /* border:1px solid gray; */
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.custom-modal .export_btn {
  background-color: #ec1b22;
  color: white;
}

.custom-modal .export_btn_csv {
  background-color: #34a853;
  color: white;
}

.modal_radio {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modal_radio label {
  display: flex;
  gap: 5px;
  font-weight: 700;
  color: #827575;
}