@media only screen and (max-width: 767px) {
  .mob-skillnow-logo {
    height: 2.3rem !important;
  }
  .mob-slider-logo-new {
    height: 2rem !important;
    width: 6rem !important;
  }
  .btn,
  .hero-content .btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .home-page .bx-wrapper .bx-pager {
    display: none;
  }
  .main-wrapper {
    padding-top: 48px;
  }
  .main-wrapper.fixed-screen {
    -webkit-transform: scale(0.5) translateX(-70%);
    transform: scale(0.5) translateX(-70%);
    height: 100vh;
    width: 100%;
    position: fixed;
  }
  .off-canvas.active {
    width: 75%;
  }
  .main-header .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .main-header .search {
    display: none;
  }
  .main-home-parallax-slider .item .center-wrapper .main-header .logo svg {
    width: 100px;
  }
  .main-header .logo-wrapper .page-title {
    font-size: 14px;
  }
  .how-we-work .content-wrapper {
    padding: 20px;
  }
  .main-home-parallax-slider .item {
    height: 300px;
  }
  .main-home-parallax-slider .item .home-bg-slider-wrapper {
    display: block;
  }
  .main-home-parallax-slider .item .home-bg-slider-wrapper img {
    height: 100%;
    width: auto;
    max-width: none;
  }
  .main-home-parallax-slider .center-wrapper {
    padding: 60px 20px 20px;
  }
  .main-home-parallax-slider .blinking-down-arrow {
    bottom: 60px;
  }
  .full-height-video {
    height: 300px;
  }
  .how-we-work .content-wrapper {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 90%;
    margin: -30px auto 0;
  }
  .offices-region .item ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .showcase-news-cards-wrapper .item .content-wrapper {
    padding: 20px 15px;
    min-height: auto;
  }
  .showcase-news-cards-wrapper .item-wrapper {
    padding: 20px;
  }
  /*
.twitter-feeds-wrapper:after {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;
    height: 70px;
    background-color: #fff;
    display: block;
    filter: blur(20px)
}
*/
  .showcase-news-cards-wrapper .item figure img {
    width: 100%;
    height: auto;
  }
  .full-width-image-breaker.aikidox-container .content-wrapper {
    padding: 0 20px;
  }
  .landing-page .main-header .logo-wrapper .page-title {
    padding-left: 25px;
  }
  .landing-page .main-header .logo-wrapper .page-title:after {
    content: "\f104";
    position: absolute;
    left: 10px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
  }
  .hero-banner .hero-illustration {
    padding: 10px;
  }
  .hero-banner .hero-illustration .vector-wrapper {
    height: 200px;
  }
  .featured-hero-article {
    position: static;
    width: 100%;
  }
  .hero-banner:after {
    display: none;
  }
  .services {
    padding-top: 40px;
  }
  .hero-content .btn {
    width: 100%;
    box-sizing: border-box;
  }
  .bx-wrapper .bx-controls-auto,
  .bx-wrapper .bx-pager {
    position: static;
  }
  .our-products .btn {
    margin-top: 20px;
  }
  .our-products .info {
    padding: 20px 0;
  }
  .customer-success-section .clients-wrapper [class*="col-"] {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .news-section .web-feeds .row:last-child [class*="col-"] {
    border-bottom: 0;
  }
  .why-choose-us-section .item .icon {
    width: auto;
    height: auto;
  }
  .success-mantra-section .item {
    padding-bottom: 40px;
  }
  .success-mantra-section .item:last-child {
    padding-bottom: 0;
  }
  .success-mantra-section .icon {
    height: auto;
  }
  .success-mantra-section .item-container {
    padding: 0;
    margin-bottom: 20px;
  }
  .success-mantra-section .page-wrap {
    padding-bottom: 30px;
  }
  .abcde-nav .navgation-wrapper .list {
    margin-left: 0;
  }
  .fixed-form-wrapper.active {
    bottom: auto;
    top: 0;
    overflow: scroll;
    height: 100%;
  }
  .fixed-form-wrapper .btn-wrapper,
  .sticky-content {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
  }
  .fixed-form-wrapper .btn-wrapper .btn {
    width: 100%;
    padding: 10px;
  }
  .why-choose-us-section .item .content-wrapper {
    padding: 10px;
  }
  .clients-page .top-hero-product-banner,
  .clients-page .product-hero-wrapper {
    height: 40vh;
  }
  .clients-page .customer-success-section .row .quote {
    margin-bottom: 30px;
  }
  .clients-page .customer-success-section .item.featured .item-wrapper,
  .customer-success-section .clients-wrapper .item img {
    height: 100%;
  }
  .clients-page .customer-success-section .abc-container .abc-block ul {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }
  .clients-page .customer-success-section .abc-container .abc-block .item {
    width: 100%;
  }
  .clients-page .product-hero-wrapper .item-wrapper span {
    font-size: 1em;
  }
  .clients-page .customer-success-section .clients-wrapper .item .title {
    font-size: 1em;
  }
  .clients-page .product-hero-wrapper .col-5 figure {
    display: none;
  }
  .clients-page .customer-success-section .item.featured .item-wrapper {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .solutions-page .top-hero-product-banner,
  .solutions-page .product-hero-wrapper {
    height: 40vh;
  }
  .solutions-page .customer-success-section .row .quote {
    margin-bottom: 30px;
  }
  .solutions-page .customer-success-section .item.featured .item-wrapper,
  .customer-success-section .clients-wrapper .item img {
    height: 100%;
  }
  .solutions-page .customer-success-section .abc-container .abc-block ul {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }
  .solutions-page .customer-success-section .abc-container .abc-block .item {
    width: 100%;
  }
  .solutions-page .product-hero-wrapper .item-wrapper span {
    font-size: 1em;
  }
  .solutions-page .customer-success-section .clients-wrapper .item .title {
    font-size: 1em;
  }
  .solutions-page .product-hero-wrapper .col-5 figure {
    display: none;
  }
  .solutions-page .customer-success-section .item.featured .item-wrapper {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .customer-success-section .clients-wrapper .item:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    opacity: 0.8;
  }
  .clients-list li {
    margin-right: 0;
    display: inline-block;
  }
  .blog-social-share ul,
  .clients-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
  }
  .blog-social-share ul {
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .blog-social-share {
    padding: 0;
    text-align: center;
    background: #000;
  }
  .sticky-content {
    top: auto;
    z-index: 999999;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #000;
  }
  .blog-social-share span {
    padding: 0 20px;
    color: #fff;
    float: left;
  }
  .blog-social-share li {
    padding: 0;
  }
  .blog-social-share li a {
    color: #fff;
    border-color: #fff;
    width: 30px;
    height: 30px;
  }
  .case-study .c-logo {
    margin-top: auto;
    margin-left: 20px;
  }
  .main-blogs .article-wrapper .article-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .main-home-parallax-slider .center-wrapper .category,
  .project-hero a .hero-name {
    font-size: 14px;
  }
  .client-detail-page .main-blogs .article-wrapper .article-info {
    padding: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: static;
    margin-top: -150px;
  }
  .case-study.main-blogs .article-info .title-wrapper {
    padding-left: 0;
    width: 100%;
  }
  .case-study.main-blogs .article-wrapper .article-heading {
    padding: 15px 0;
    line-height: 1.4;
  }
  .main-blogs .article-wrapper {
    max-height: initial;
    min-height: 0;
  }
  .case-study .c-logo {
    width: 100px;
    height: 100px;
  }
  .main-blogs .article-content {
    padding: 40px 0;
  }
  .project-hero a {
    margin-bottom: 15px;
  }
  .clients-list ul {
    display: flex;
  }
  .clients-list li {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .twitter-feeds-wrapper .tweet {
    height: 220px;
  }
  .main-blogs .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .main-blogs .article-wrapper .article-info {
    padding: 0 20px;
  }
  .main-blogs .article-container .item {
    padding-right: 0;
    padding-bottom: 15px;
  }
  .main-blogs .article-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .main-blogs .article-container .item:last-child {
    padding-bottom: 0;
  }
  .subscribe-section .input-wrapper {
    border: 0;
    padding: 0 15px;
  }
  .subscribe-section form .btn {
    position: static;
    margin-top: 20px;
  }
  .subscribe-section form input[type="email"] {
    border: 1px solid #ddd;
    min-width: initial;
  }
  .disqus-wrapper {
    padding: 20px 20px 60px;
  }
  .about-content-wrapper .main-about {
    padding-bottom: 50px;
  }
  .about-content-wrapper .main-about .feature-section a {
    padding: 20px;
  }
  .ar-wrapper figure,
  .ar-wrapper img,
  .ar-wrapper.skills figure {
    left: auto;
    width: 100%;
    margin-bottom: 20px;
  }
  .blue-ocean-strategy .blue-ocean-wrapper {
    padding: 50px 0;
  }
  .blue-ocean-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .blue-ocean-wrapper .item-wrapper {
    width: 50%;
  }
  .ar-wrapper .row.page-wrap,
  .exp-economy-section .row,
  .strategy-career-section .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  #business-tech .col-4,
  #c-suite .col-4,
  #industry-focus .col-4 {
    width: calc(8.33333% * 12);
  }
  .about-content-wrapper .main-about .feature-section li {
    width: calc(8.33333% * 6);
  }
  .section-breaker figure img {
    width: auto;
    height: 100%;
  }
  .section-breaker .content-wrapper {
    padding: 50px 20px;
    font-size: 22px;
  }
  .milestones .item-wrapper {
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  .milestones .blank {
    display: none;
  }
  .abcde-nav .navgation-wrapper {
    padding: 0 50px;
  }
  .abcde-nav .a,
  .abcde-nav .b,
  .abcde-nav .c,
  .abcde-nav .d,
  .abcde-nav .e {
    width: 100%;
  }
  .customer-success-section .item .item-wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .news-section .title .section-main-heading {
    padding: 0;
  }
  .news-section .title .section-heading {
    padding: 0 0 10px;
  }
  .news-section .title {
    padding: 0 0 20px;
  }
  .our-products .row .col-3:first-child {
    width: calc(8.33333% * 6);
    margin: 0 auto;
  }
  .blogs-section .article-wrapper .blog-info,
  .blogs-section .article-wrapper p {
    padding: 0;
  }
  .cx-digital-wrapper {
    padding: 30px;
  }
  .cx-offerings,
  .related-offerings {
    padding: 30px 0;
  }
  .consultare-services .item-wrapper,
  .related-offerings .item-wrapper {
    padding: 20px;
  }
  .related-offerings .section-main-heading {
    padding: 0;
    margin-bottom: 20px;
  }
  .related-offerings .item .content-wrap {
    text-align: center;
    padding: 0;
  }
  .related-offerings .item .content-wrap .section-heading {
    text-align: center;
    display: block;
    padding-top: 0;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .ecosystem-section .content-wrapper img {
    width: 100%;
    margin-bottom: 20px;
  }
  .main-blogs .article-content {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .main-blogs .sticky-content,
  .news-events-wrapper .sticky-content {
    position: relative;
    background-color: #fff;
    padding: 0;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .main-blogs .tags-wrapper,
  .news-events-wrapper .tags-wrapper {
    padding: 30px 0;
    border-top: 1px solid #888;
    float: left;
    width: 100%;
  }
  .main-blogs .blog-social-share,
  .partner-wrapper .partner-whats-new .content-wrapper {
    padding: 10px 0;
  }
  .main-blogs .tags-wrapper li,
  .news-events-wrapper .tags-wrapper li {
    width: auto;
    float: left;
    margin: 0;
  }
  .main-blogs .blog-social-share ul {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .main-blogs .blog-social-share li.download {
    display: none;
  }
  .about-content-wrapper .company-mv-wrapper .vision {
    margin-bottom: 20px;
  }
  .exp-economy-section .right-section {
    margin-top: 20px;
  }
  .ar-wrapper figure {
    left: auto;
    width: 100%;
  }
  .strategy-page .about-content-wrapper .main-about .feature-section a {
    background-color: #fff;
    margin-bottom: 20px;
    border: 1px solid #ddd;
  }
  .partner-wrapper .partner-desc .content-wrapper {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .partner-wrapper .partner-whats-new {
    padding: 20px 0;
  }
  .partner-wrapper .partner-whats-new .row {
    padding-bottom: 20px;
  }
  .alternate-content-section .section-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .alternate-content-section .section-wrapper .section-heading,
  .alternate-content-section .section-wrapper p {
    display: block;
    text-align: center;
  }
  .alternate-content-section .section-wrapper .illustration {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 30px;
  }
  .opportunities .additional-resources .row .col-3 {
    width: 100%;
  }
  .contact-page-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .offices-wrapper .address-wrapper .address:before,
  .offices-wrapper .map-wrapper .g-map {
    display: none;
  }
  .contact-page-wrapper .row .col-7 {
    width: 100%;
  }
  .offices-wrapper .address-wrapper .address {
    padding-left: 0;
    padding-bottom: 0;
    width: 100%;
  }
  .offices-wrapper .map-wrapper {
    margin-top: 30px;
  }
  .offices-wrapper .map-wrapper .office-image {
    position: relative;
  }
  .contact-page-wrapper .row .sticky-content {
    background-color: #eee;
    padding: 0 20px;
    z-index: 10;
    position: static;
    width: 100%;
  }
  .contact-form-wrapper {
    padding: 40px 20px;
  }
  .contact-career-section {
    padding: 50px 15px;
  }
  .why-choose-us-section .item {
    width: 100%;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  .why-choose-us-section .item:last-child {
    border: none;
    padding-bottom: 0;
  }
  .success-mantra-section {
    padding-bottom: 0;
  }
  .cf-form-wrapper {
    padding: 30px 0;
    text-align: center;
  }
  .consultare-detail-container .detail-wrapper {
    padding: 0 15px;
  }
  .visible-wrapper .content-wrap .col-6 {
    margin-top: 120px;
  }
  .consultare-detail-container .product-wrapper .item .content-wrap {
    padding: 30px 15px;
  }
  .consultare-detail-container .product-wrapper .item .content-wrap figure {
    display: none;
  }
  .consultare-detail-container .product-wrapper .item.active {
    padding-bottom: 80px;
  }
  .consultare-detail-container
    .product-wrapper
    .item.active
    .invisible-wrapper {
    padding-top: 0;
  }
  .consultare-detail-container .product-wrapper .item form {
    width: 100%;
  }
  .consultare-detail-container .product-wrapper {
    padding: 40px 0 0;
  }
  .two-col-section {
    padding: 15px;
    margin: 0;
  }
  .two-col-section .item {
    border: 1px solid #eee;
    margin-bottom: 20px;
  }
  .two-col-section .page-wrap {
    padding-top: 0;
  }
  .section-desc {
    margin: 0 15px;
  }
  .cxa-member-wrapper .item-wrapper {
    width: 100%;
    padding: 0;
  }
  .ai-tech .content-wrapper h1.highlight {
    line-height: 1.5;
  }
  .business3-parts .row a.item-wrapper {
    display: block;
    width: 100%;
  }
  .consultare-services .item {
    border: 1px solid #eee;
    padding: 15px;
    background-color: #fff;
    margin-top: 20px;
  }
  .cx-extra-features .item {
    display: block;
    padding: 0 30px;
  }
  .business3-page .cx-extra-features .item-wrapper {
    padding: 30px 0;
  }
  .cx-extra-features .item p {
    line-height: 1.5;
  }
  .business3-page .exp-business .grey-bg {
    padding-bottom: 30px;
  }
  .business3-page .exp-business .grey-bg:after {
    left: 35%;
  }
  .business3-page .exp-business .item {
    padding: 0 20px;
  }
  .business3-page .exp-business .item .index {
    font-size: 40px;
  }
  .business3-page .exp-business .value-wrapper {
    padding: 70px 0 50px;
  }
  .exp-economy-section .right-section img {
    height: auto;
  }
  .exp-economy-section .col-6 {
    width: 100%;
  }
  .cxnow-plans .plans-wrapper .item-wrapper {
    margin-bottom: 30px;
    padding: 0;
    min-width: 300px;
  }
  .cxnow-plans .plans-wrapper .item {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #eee;
  }
  .cxnow-plans .plans-wrapper .item .button-wrapper {
    padding: 0;
  }
  .cxnow-plans .plans-wrapper .item .section-heading {
    padding: 20px 0;
    font-weight: 700;
  }
  .cxnow-plans {
    padding: 30px 0;
  }
  .advantages-of-aury {
    padding: 15px;
  }
  .advantages-of-aury .page-wrap .row .col-5,
  .advantages-of-aury .page-wrap .row .col-6 {
    width: 100%;
  }
  .business3-tab-section .advantages-of-aury .points .section-main-heading {
    text-align: center;
  }
  .advantages-of-aury .points li {
    margin-bottom: 20px;
    border: 1px solid #eee;
    text-align: center;
    padding: 10px;
  }
  .ecosystem-section .icon {
    padding: 20px;
  }
  .engg-service-wrapper .item .content-wrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 30px 0;
  }
  .engineering-service-page .engg-service-tab-wrapper .tabs li.active {
    border-top: 4px solid #00a1fe;
    border-left: none;
  }
  .factors-wrapper .hero-factors .row:last-child,
  .two-col-section .item:last-child {
    border: 1px solid #eee;
  }
  .block-over-image-section .block-over-image-wrapper .col-5 {
    width: 90%;
    margin: -20px auto 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
  }
  .block-over-image-section
    .block-over-image-wrapper:nth-child(even)
    .content-wrapper {
    margin-right: auto;
  }
  .core-values-wrapper .the-values .item {
    padding: 0;
  }
  .core-values-wrapper .the-values .item:hover {
    background-color: transparent;
    border-color: transparent;
  }
  .careers-main-wrapper .culture .item figure {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .core-values-wrapper .tabs .item {
    max-width: inherit;
  }
  .core-values-wrapper .vertical-clickable-tabs .item figure {
    margin: 0;
  }
  .careers-job-page .job-openings-section {
    padding-top: 30px;
  }
  .careers-job-page .job-openings-section .item {
    padding-bottom: 20px;
  }
  .job-detail-wrapper .sticky-content {
    position: static;
    background-color: #fff;
  }
  .job-detail-wrapper .col-8 {
    width: 100%;
  }
  .job-application-wrapper .share-job ul li {
    padding: 0 5px;
    display: inline-block;
  }
  .job-header {
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .job-header figure {
    width: 100%;
  }
  .job-header .profile-illustration {
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .job-header .profile-detail {
    padding-left: 0;
    padding-bottom: 30px;
  }
  .two-col-section .item figure {
    height: auto;
    margin: 15px 0;
  }
  .factors-wrapper .hero-factors .row {
    padding: 30px 15px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #eee;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .factors-wrapper .hero-factors .row .col-7 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .factors-wrapper .hero-factors .row .section-heading {
    display: block;
    padding-top: 20px;
  }
  .vertical-tab-content .sticky-content {
    position: static;
    width: 100%;
    background-color: #eee;
    padding: 30px 15px;
  }
  #talentare-section .our-consultare-services .col-4,
  #talentare-section .our-consultare-services .col-8,
  .vertical-tab-content .scrollable-vertical-section {
    width: 100%;
  }
  .consultare-services .item-wrapper br {
    display: none;
  }
  .our-consultare-services .right-content {
    padding-left: 0;
  }
  .cx-flow-section .flow-elements {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cx-flow-section .flow-elements li {
    width: 38%;
  }
  .cx-flow-section {
    padding: 30px 0;
  }
  .cx-flow-section .other-features .item .icon i {
    margin-top: 5px;
  }
  .digital-services-section .item-wrapper {
    padding: 20px 0;
  }
  .two-col-full-section.accordian-section .content-wrapper {
    padding: 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .accordian-wrapper .accordian-content ul li {
    width: 100%;
  }
  .digital-process-tabs-wrapper {
    padding: 0;
    position: relative;
  }
  .subscribe-section {
    padding: 30px 0;
  }
  .digital-process-content-wrapper {
    padding: 50px 0 0;
  }
  .digital-process-content-wrapper .digital-steps-wrapper .content-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .digital-process-content-wrapper
    .digital-steps-wrapper
    .content-wrap
    .title-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .digital-process-content-wrapper .digital-steps-wrapper .title-wrapper h3 {
    text-align: center;
    line-height: 1.5;
    padding: 20px 0 0;
  }
  .digital-process-content-wrapper .digital-steps-wrapper .text-wrapper {
    padding: 10px 0;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
  .section-breaker .section-logo {
    width: 100px;
  }

  .cx-strategy-page .virtuoso-features .item {
    border: 0;
  }
  .cx-strategy-wrapper .row.page-wrap {
    padding-top: 20px;
  }
  .cx-strategy-page .gen .btn-wrapper {
    text-align: center;
  }

  .accordian-section .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .order .col-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .order .col-4 h3 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order .col-4 p {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order .col-4 figure {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .innovation-landscape .row .col-3 {
    width: 100%;
  }
  .exp-economy-section .economy-products a {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .our-ideas-section .item img {
    height: auto;
    width: 75%;
  }
  .our-ideas-section .item:last-child {
    border: none;
  }
  .ar-wrapper {
    padding-bottom: 30px;
  }
  .about-team-wrap .item-wrap {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  .about-team-wrap .page-wrap figure img {
    width: 100%;
  }
  .careers-tabs ul,
  .cxd-tabs ul,
  .vertical-clickable-tabs ul {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .careers-tabs li,
  .cxd-tabs li,
  .vertical-clickable-tabs li {
    width: 100%;
    display: block;
  }
  .core-values-wrapper .vertical-clickable-tabs .item figure,
  .org-page .exp-economy-section .right-section {
    display: none;
  }
  .careers-tabs li a,
  .core-values-wrapper .vertical-clickable-tabs li .item span,
  .cxd-tabs li a {
    font-size: 16px;
    white-space: nowrap;
    padding: 20px;
    width: 200px;
  }
  .core-values-wrapper .vertical-clickable-tabs li .item span {
    width: auto;
  }
  .core-values-wrapper {
    margin: 0;
  }
  .core-values-section .heading {
    padding: 30px 0;
  }
  .community-login-section .item {
    min-height: 200px;
  }
  .about-page .story-wrapper.exp-business .item .content-wrapper,
  .consultare-page .service-wrapper .item {
    min-height: auto;
  }
  .community-login-section .item .section-main-heading {
    padding: 20px;
  }
  .community-login-section .item a {
    width: 40%;
  }
  .comminity-edge .item .title-wrapper h3 {
    font-size: 32px;
  }
  .org-page .exp-economy-section {
    padding: 30px 0;
  }
  .org-page .exp-economy-section .section-main-heading {
    font-size: 22px;
  }
  .org-page .mission .section-main-heading {
    font-size: 28px;
  }
  .org-page .mission .mission-wrapper .col-2-half {
    width: 100%;
    margin-bottom: 20px;
  }
  .org-page .mission .mission-wrapper .col-2-half:last-child {
    margin-bottom: 0;
  }
  .org-page .upcoming-event .content-wrapper {
    padding: 40px 0 300px;
  }
  .events .event-slider .item figure img {
    width: auto;
    max-width: inherit;
  }
  .events .event-slider .item figure {
    height: 500px;
  }
  .org-page .countdown-wrapper .item {
    margin-bottom: 10px;
    width: 70px;
    height: 70px;
  }
  .org-page .upcoming-event figure img {
    width: auto;
    height: 100%;
  }
  .org-page .ar-wrapper {
    padding-bottom: 0;
  }
  .org-page .ar-wrapper figure {
    position: static;
    margin: 0;
    display: none;
  }
  .org-page .ar-wrapper figure img {
    width: 100%;
    height: auto;
  }
  .org-page .ar-wrapper .row .col-6 {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .org-page .product-hero-wrapper .page-wrap .col-5-half {
    display: none;
  }
  .countdown-wrapper {
    font-size: 18px;
  }
  .upcoming-event .content-wrapper p {
    padding-bottom: 20px;
  }
  .countdown-wrapper .smalltext {
    font-size: 12px;
  }
  .org-page .ar-wrapper .section-heading {
    font-size: 24px;
  }
  .org-page .latest-org-blogs .section-heading,
  .org-page .stats .section-heading,
  .org-page .upcoming-event .content-wrapper .section-main-heading {
    font-size: 28px;
  }
  .org-page .mission-wrapper .item {
    padding: 30px 15px;
  }
  .org-page .upcoming-event .content-wrapper .event-detail-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
  }
  .org-page .upcoming-event .content-wrapper .event-detail-wrapper li {
    width: 100%;
  }
  .org-page .events .event-slider .item .content-wrap {
    top: 0;
  }
  .org-page .ar-wrapper .row.page-wrap {
    height: auto;
    min-height: auto;
  }
  .donation-section .item .donate-button {
    position: relative;
  }
  .org-page .donation-section .item-wrapper {
    margin-bottom: 20px;
  }
  .org-page .timeline-blogs-wrapper .left-wrapper .item:before,
  .timeline-blogs-wrapper .right-wrapper .item:before {
    display: none;
  }
  .org-page .stats .product-section {
    height: auto;
  }
  .org-page .aury-metrices .count-band .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .professional-services-page .count-band .item,
  .right-apps-page .two-col-section {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }
  .org-page .aury-metrices .count-band .item {
    width: 50%;
  }
  .team-banner figure img {
    transform: skew(0deg, 5deg) scale(4.5);
  }
  .team-banner .content-container {
    margin-top: -450px;
  }
  .team-page .about-team-wrap ul li {
    width: 300px;
    margin-bottom: 20px;
  }
  .team-page .about-team-wrap .feature-section a .txt {
    font-size: 32px;
  }
  .team-page .about-team-wrap .feature-section a i {
    font-size: 42px;
  }
  .team-page .about-team-wrap .feature-section a:hover i {
    font-size: 42px;
  }
  .team-page .about-team-wrap ul.feature-section {
    display: flex;
    margin: 0;
  }
  .team-banner figure {
    height: 500px;
  }
  .team-banner .section-main-heading {
    font-size: 2em;
  }
  .team-page .team-banner .hiring {
    width: 50px;
    top: 10px;
    right: 10px;
  }
  .team-page .team-member-wrap .content .member-pic {
    width: 75%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
  }
  .team-page .team-member-wrap .content .member-info {
    padding: 15px;
    text-align: center;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .team-page .team-member-wrap .content .member-info .section-main-heading {
    font-size: 32px;
    text-align: center;
  }
  .product-page .product-services-wrapper .section-main-heading,
  .professional-services-page .consultare-services .section-main-heading {
    font-size: 28px;
  }
  .insights-page .insights-featured-customers .row .col-3 {
    width: 100%;
    margin-bottom: 50px;
  }
  .insights-page .insights-featured-customers {
    padding: 30px 15px;
  }
  .insights-page .insights-clients-wrapper .filter-wrapper li {
    padding-right: 0;
  }
  .insights-page .customer-success-section .clients-wrapper .item {
    padding: 0;
    border: 0;
    margin-bottom: 10px;
  }
  .insights-page .customer-success-section .clients-wrapper [class*="col-"],
  .insights-page .video-wrapper {
    padding: 0;
  }
  .insights-page .section-breaker .content-wrapper {
    padding: 50px 20px 100px;
  }
  .product-page .product-services-wrapper {
    padding: 30px 15px;
  }
  .product-page .product-services-wrapper .item-wrapper {
    padding: 0;
    margin-bottom: 30px;
  }
  .product-page .product-services-wrapper .item {
    padding: 30px;
  }
  .product-page .cf-form-wrapper {
    margin-bottom: 0;
  }

  .accordian-wrapper .item .title h3 {
    font-size: 14px;
  }
  .skills-wrap i {
    bottom: 20px;
    line-height: normal;
  }
  .professional-services-page .business-facts .page-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .service-wrapper .item figure {
    opacity: 0;
    display: none;
  }

  .exp-economy-section .economy-products a i {
    opacity: 1;
  }
  .aury-metrices .count-band .count-wrapper {
    font-size: 24px;
  }
  .related-offerings .section-main-heading,
  .right-apps-page .right-apps .section-main-heading {
    font-size: 28px;
  }
  .professional-services-page .count-band .item span {
    margin: 0 0 0 10px;
  }
  .right-apps-page .right-apps {
    padding: 0 15px;
  }
  .right-apps-page .right-apps .page-wrap {
    padding: 0;
  }
  .right-apps-page figure.right-apps-logo {
    position: absolute;
    top: 20px;
    right: 0;
    width: 150px;
    left: 0;
    bottom: auto;
    margin: 0 auto;
  }
  .right-apps-page figure.right-apps-logo img {
    width: 100%;
  }

  .right-apps-page .two-col-section {
    padding: 30px 0 0;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .iov-page .ar-wrapper .row.page-wrap,
  .right-apps-page .ar-wrapper .row.page-wrap {
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }
  .right-apps-page .two-col-section .item-wrap {
    margin-bottom: 20px;
  }
  .right-apps-page .ar-wrapper .row.page-wrap {
    flex-direction: column;
    padding: 30px 15px;
  }
  .right-apps-page .ar-wrapper figure,
  .right-apps-page .ar-wrapper img {
    margin-bottom: 0;
  }
  .right-apps-page .product-info-wrapper .section-heading {
    padding: 0 0 15px;
    font-size: 28px;
  }
  .product-section .product-banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
  }
  .product-section .product-banner img {
    width: auto;
    height: 100%;
  }
  .right-apps-products .item-wrapper {
    padding: 10px 0;
  }

  .experience-design .two-col-section {
    padding: 0;
  }
  .xd-process .process-number {
    display: none;
  }
  .xd-process .process-items {
    margin-top: 15px;
  }
  .xd-process .process-items .item h3 {
    margin-bottom: 0;
  }

  .xd-process .process-items .item h3:before {
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 32px;
    background-color: #00a1fe;
    width: 75px;
    height: 75px;
    text-align: center;
    margin: 15px auto;
    border-radius: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
  }
  .experience-design-page .xd-process .process-items {
    margin-top: 0;
  }
  .experience-design-page
    .xd-process
    .process-items
    .item:nth-child(1)
    h3:before {
    content: "Shu";
  }
  .experience-design-page
    .xd-process
    .process-items
    .item:nth-child(2)
    h3:before {
    content: "Ha";
  }
  .experience-design-page
    .xd-process
    .process-items
    .item:nth-child(3)
    h3:before {
    content: "Ri";
  }
  .fixed-form-wrapper .form-wrapper {
    padding-bottom: 50px;
  }
  .industry-insights .item .content-wrapper {
    padding: 0;
    font-size: 14px;
  }
  .experience-design-page .why-us-section .benefits .row .col-6 {
    width: 100%;
    margin-bottom: 20px;
  }
  .experience-design-page .about-team-wrap .item-wrap,
  .experience-design-page .why-us-section .page-wrap {
    text-align: center;
  }
  .experience-design-page .about-team-wrap .item-wrap:last-child {
    border-bottom: none;
  }
  .experience-design-page .about-team-wrap .item-wrap img {
    width: 75%;
  }
  .experience-design-page .why-us-section .section-heading {
    display: block;
  }
  .experience-design-page .about-team-wrap .btn-wrap {
    width: 75%;
    margin: 0 auto;
  }
  .cx-digital-inner-page .cx-doc-section .row {
    text-align: center;
  }
  .cx-digital-inner-page .cf-form-wrapper .section-main-heading,
  .cx-digital-inner-page .cx-process-section .section-main-heading {
    font-size: 28px;
  }
  .cxa-page .awards-section .item figure {
    width: 250px;
    height: 250px;
  }
  .cx-digital-inner-page .cf-form-wrapper {
    margin-bottom: 0;
  }
  .consultare-page .abcde-nav .navgation-wrapper {
    padding: 0 15px;
    text-align: center;
  }
  .consultare-page .abcde-nav .item .alphabet-embelem {
    margin: 0 auto;
  }
  .consultare-page .news-section {
    padding: 30px 0;
  }
  .consultare-page .news-section .title,
  .consultare-page .news-section .title .section-heading,
  .consultare-page .news-section .title .section-main-heading {
    text-align: center;
    display: block;
  }
  .vertical-section .center-wrapper {
    padding: 15px;
    width: 100%;
  }
  .vertical-section .full-screen-wrapper figure {
    height: 200px;
  }
  .vertical-section .center-wrapper p {
    font-size: 14px;
  }
  .vertical-section .center-wrapper .section-main-heading {
    font-size: 20px;
    padding-bottom: 0;
  }
  .vertical-section.last-slide .content-wrapper {
    padding-top: 20px;
  }
  .main-wrapper.slides {
    padding-top: 48px;
  }
  .iov-page .ar-wrapper {
    padding-bottom: 0;
  }
  .iov-page .ar-wrapper .row.page-wrap {
    flex-direction: column;
    padding: 50px 15px;
    text-align: center;
  }
  .iov-page .io-i3-section .content {
    font-size: 22px;
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .iov-page .io-i3-section .banner img {
    width: 100%;
  }
  .iov-page .io-i3-section .icon {
    width: 75px;
    height: 75px;
    margin: 0 auto;
  }
  .iov-page .ar-wrapper .col-4 img {
    width: 75%;
  }
  .iov-page .io-i3-section .ar-wrapper .left-content h3 {
    font-size: 20px;
  }
  .iov-page .aury-section .banner {
    margin-bottom: 0;
  }
  .iov-page .aury-section .banner img {
    width: auto;
    height: 100%;
    margin-bottom: 0;
  }
  .iov-page .ar-wrapper .left-content h3 {
    font-size: 24px;
  }
  .digital-process-tabs-wrapper .item i {
    font-size: 14px;
    display: none;
    position: absolute;
    color: #333;
    bottom: -40px;
    width: 100%;
    z-index: 99;
    left: 0;
  }
  .business3-page .skills-wrap i,
  .engineering-service-page .skills-wrap i {
    bottom: 15px;
  }
  .digital-process-tabs-wrapper .item span {
    font-size: 14px;
  }
  .digital-process-tabs-wrapper .item h3 {
    font-size: 24px;
  }
  .digital-process-tabs-wrapper .item {
    padding: 10px;
  }
  .digital-process-content-wrapper .vertical-tab-content:before {
    height: 4px;
  }
  .digital-process-content-wrapper .steps-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .digital-process-content-wrapper .steps-wrapper .item {
    margin: 0;
  }
  .digital-process-content-wrapper .steps-wrapper .item-wrap {
    padding: 5px 10px;
  }
  .digital-process-content-wrapper .steps-wrapper .item-wrap:after {
    display: none;
  }
  .digital-process-content-wrapper .steps-wrapper .item-wrap h4 {
    font-size: 12px;
    line-height: normal;
    padding-top: 10px;
  }
  .digital-process-content-wrapper .steps-wrapper .item-wrap .icon img {
    height: 30px;
  }
  .digital-process-content-wrapper .digital-steps-wrapper .content-wrap {
    padding: 20px;
  }
  .digital-process-content-wrapper
    .digital-steps-wrapper
    .title-wrapper
    .step-index {
    width: 60px;
    height: 60px;
    font-size: 30px;
  }
  .digital-process-content-wrapper .digital-steps-wrapper .title-wrapper h3 {
    font-weight: 500;
    font-size: 26px;
  }
  .industry-main-wrapper .industry-form-wrapper,
  .industry-main-wrapper .industry-form-wrapper form,
  .industry-main-wrapper .industry-type-section {
    padding: 15px;
  }
  .industry-main-wrapper .industry-form-wrapper .input-wrapper label {
    padding: 5px 15px;
  }
  .industry-main-wrapper .industry-type-section p {
    font-size: 16px;
    margin: 0;
  }
  .industry-product-wrapper .tabs {
    width: 100%;
    overflow-x: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .industry-product-wrapper .tabs li {
    border: 0;
    border-top: 4px solid transparent;
  }
  .industry-two-cols .item-wrapper {
    padding: 0;
  }
  .industry-two-cols .item {
    padding: 10px 0;
  }
  .industry-main-wrapper .content-wrapper .button-wrapper {
    display: block;
  }
  .industry-main-wrapper .content-wrapper .button-wrapper a {
    margin-bottom: 10px;
  }
  .community-login-section .item-wrapper figure img {
    width: 100%;
    height: auto;
  }
  .community-page .comminity-edge .icon img {
    width: 75%;
  }
  .engineering-service-page .full-width-image-breaker .aikidox-wrapper {
    padding: 40px 0;
  }
  .engineering-service-page .full-width-image-breaker .section-heading {
    font-size: 28px;
  }

  .engineering-service-page .full-width-image-breaker .aikidox-wrapper ul {
    font-size: 16px;
  }
  .related-offerings .item img {
    width: 100%;
    height: auto;
  }

  .story-wrapper.exp-business .item {
    border: 1px solid #ddd;
    margin-bottom: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .core-values-band {
    background-color: #f9f9f9;
  }

  .about-consultare-page .exp-edge-section,
  .business-facts .page-wrap {
    flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }

  .about-consultare-page .about-content-wrapper {
    padding: 40px 0;
  }
  .about-consultare-page .clients-list,
  .about-consultare-page .exp-edge-wrapper,
  .cx-continuum-page .cx-desk-section .content {
    padding: 40px 15px;
  }
  .about-consultare-page .exp-edge-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
  }
  .business3-page .banner-wrapper .business3-parts,
  .business3-page .exp-economy-section .right-section {
    display: none;
  }
  .about-consultare-page .exp-edge-wrapper .content-block {
    margin-left: 0;
  }
  .about-consultare-page .count-band .item {
    margin-bottom: 20px;
  }
  .about-consultare-page .count-band .count-wrapper.animate {
    border: 10px solid #444;
  }
  .about-consultare-page .count-band .count-wrapper {
    width: 120px;
    height: 120px;
  }
  .about-consultare-page .about-team-wrap .item-wrap {
    border: none;
  }
  .about-consultare-page .exp-edge-section .col-6 {
    width: auto;
  }

  .business3-page .engg-service-wrapper .item .content-wrapper {
    padding: 20px 0;
  }
  .business3-page .engg-service-tab-wrapper .tabs li.active {
    border-top: 4px solid #00a1fe;
    border-left: none;
  }
  .business3-page .product-section .right-content.page-wrap {
    position: relative;
    padding: 20px;
    top: 0;
  }
  .business3-page .section-breaker figure img {
    width: auto;
    height: 100%;
  }
  .clients-list ul,
  .exp-business,
  .exp-economy-section {
    padding: 15px;
  }
  .business-facts .page-wrap {
    flex-direction: column;
  }
  .business-facts .content-wrapper {
    width: 100%;
  }
  .business3-page .exp-business .item p {
    font-size: 18px;
  }
  .cx-continuum-page .ecosystem-section .icon {
    padding: 0;
  }
  .cx-continuum-page .ecosystem-section .icon img {
    width: 80px;
  }
  .cx-continuum-page .related-offerings .item .icon {
    width: 60%;
    margin: 0 auto;
  }
  .cx-continuum-page .related-offerings .item img {
    width: 100%;
  }

  .strategy-page .ar-wrapper .row.page-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .strategy-page .ar-wrapper.skills .row.page-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .strategy-page .strategy-tabs-section .content-wrapper {
    padding: 40px 20px;
  }
  .strategy-page .ar-wrapper {
    padding-bottom: 0;
  }
  .strategy-page .ar-wrapper .col-6 {
    width: 100%;
  }
  .strategy-page .ar-wrapper figure {
    text-align: center;
  }
  .strategy-page .ar-wrapper figure img {
    height: auto;
    width: 100%;
  }
  .strategy-page .about-content-wrapper .main-about .feature-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .strategy-page .blue-ocean-strategy .blue-ocean-wrapper {
    padding: 20px 0;
  }
  .strategy-page .blue-ocean-strategy .section-main-heading {
    padding: 0;
  }
  .strategy-page .milestones .item {
    padding: 30px;
  }
  .strategy-page .milestones .item-wrapper figure {
    width: 100%;
    height: auto;
  }
  .strategy-page .milestones .item-wrapper figure img,
  .strategy-page .skills-wrapper {
    width: 100%;
  }
  .about-partner-page .cloudle-section .content {
    font-size: 1.5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .about-partner-page .cloudle-section .banner {
    height: 100%;
  }
  .about-partner-page .cloudle-section .banner img {
    width: auto;
    height: 100%;
  }
  .news-detail-page .main-blogs .article-wrapper .article-heading {
    font-size: 24px;
  }
  .news-detail-page .main-blogs .article-content .title {
    font-size: 22px;
  }
  .blogs-page .main-blogs .article-wrapper .article-heading {
    font-size: 20px;
  }
  .blogs-detail-page .main-blogs .article-wrapper .article-heading {
    font-size: 24px;
  }
  .careers-page .job-openings-section figure {
    height: 250px;
  }
  .careers-page .job-openings-section .item {
    margin-bottom: 20px;
    padding: 0;
  }
  .careers-page .alternate-content-section .section-wrapper {
    border-bottom: none;
  }
  .careers-page .experience-job .two-list-col .row {
    padding: 20px 0;
  }
  .careers-page .experience-job .two-list-col .row .col-3-half {
    margin-bottom: 20px;
  }
  .careers-page .careers-main-wrapper .two-list-col .page-wrap .list-wrap {
    width: 100%;
  }
  .careers-page .section-breaker {
    min-height: 100px;
  }
  .careers-page
    .block-over-image-section.iq
    .block-over-image-wrapper
    .content-wrapper {
    margin-right: auto;
  }
  .careers-page
    .vertical-image-hoverable-wrapper
    .content-image-wrapper
    .item-wrapper
    p {
    min-height: auto;
  }
  .cx-desk-page .product-hero-wrapper,
  .cxa-page .cxa-membership.section-breaker .content-wrapper {
    min-height: 200px;
  }
  .cx-transformation-page .cx-offerings,
  .digital-transformation-page .digital-services-section {
    padding: 30px 0;
  }
  .cxa-page #cxa-section .content-wrapper h2,
  .cxa-page .cxa-membership.section-breaker .content-wrapper h2 {
    margin-bottom: 0;
  }
  .cxa-page .other-small-blocks .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .crm-application-page
    .consultare-detail-container
    .product-wrapper
    .item.active
    .invisible-wrapper {
    height: 350px;
  }
  .technology-services-page .two-col-full-section figure,
  .technology-services-page .two-col-full-section figure img,
  .technology-services-page .two-col-full-section.cloudle .bg-banner img {
    height: 100%;
    width: auto;
  }
  .platform-ecosystem-page .ecosystem-section .icon {
    display: inherit;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
  }
  .platform-ecosystem-page .ecosystem-section .col-6.points {
    margin-top: 20px;
  }
  .platform-ecosystem-page .consultare-services .item-wrapper,
  .technology-services-page .consultare-services .item-wrapper {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 15px;
  }
  .technology-services-page .cloudle-wrapper .content-wrapper {
    width: auto;
    text-align: center;
    padding-bottom: 20px;
  }
  .technology-services-page .cloudle-wrapper .col-7.content-wrapper h3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 32px;
  }
  .technology-services-page
    .cloudle-wrapper
    .col-7.content-wrapper
    .inline-logo-wrapper {
    width: 200px;
  }
  .platform-ecosystem-page .advantages-of-aury .points,
  .technology-services-page .ecosystem-section .points {
    padding: 0;
  }
  .cx-header-img .section-heading {
    font-size: 28px;
  }
  .cx-header-img .section-desc {
    font-size: 16px;
  }
  .main-header .header-nav-wrapper {
    display: none;
  }
  .technology-services-page .consultare-services .item-wrapper .icon {
    width: 30%;
  }
  .crm-cx-page .abc-wrapper .abc-block {
    width: 100%;
    background: none;
    padding: 5px;
  }
  .crm-cx-page .abc-wrapper .abc-block .left {
    width: 100%;
  }
  .crm-cx-page .abc-wrapper .abc-block .right {
    display: block;
    width: 99.8%;
    box-sizing: border-box;
  }
  .product-section {
    height: auto;
  }
  .product-section .right-content.page-wrap {
    position: static;
  }
  .count-band .count-wrapper {
    width: 30px;
    height: 30px;
    font-size: 24px;
  }
  .crm-cx-page .happy-customers-wrapper .items-block > .col-12,
  .crm-portfolio-block .item {
    width: 100%;
  }
  .crm-cx-page .vertical-image-hoverable-wrapper .content-image-wrapper {
    padding: 20px;
  }
  .crm-cx-page
    .vertical-image-hoverable-wrapper
    .content-image-wrapper
    .row
    .col-8 {
    margin: 0;
    padding-bottom: 20px;
  }
  .crm-cx-page .vertical-image-hoverable-wrapper {
    padding: 0;
  }
  .crm-cx-page .happy-customers-wrapper .items-block .item {
    text-align: center;
    border: 1px solid #eee;
    margin: 20px;
  }
  .crm-cx-page .happy-customers-wrapper .items-block .item .icon {
    margin: 0 auto;
  }
  .crm-cx-page .happy-customers-wrapper .items-block h2 .highlight,
  .crm-cx-page .vteam-wrapper .content-wrapper h2 {
    font-size: 24px;
  }
  .crm-cx-page .happy-customers-wrapper .items-block .col-12 {
    position: relative;
    top: 0;
  }
  .crm-cx-page .abc-wrapper h2,
  .crm-cx-page .happy-customers-wrapper .items-block h2 {
    font-size: 20px;
    width: 100%;
    position: static;
    text-align: center;
    padding: 10px;
  }
  .crm-cx-page .abc-wrapper .image-wrapper,
  .crm-cx-page .happy-customers-wrapper .image-wrapper {
    height: 300px;
  }
  .crm-cx-page .abc-wrapper .image-wrapper img,
  .crm-cx-page .happy-customers-wrapper .image-wrapper img {
    width: auto;
    height: 100%;
  }
  .crm-cx-page .abc-wrapper .image-wrapper img {
    -webkit-transform: translateX(-40%);
    transform: translateX(-40%);
  }
  .professional-services-page .two-col-full-section .content-wrapper ul {
    width: 100%;
    padding: 0;
  }
  .professional-services-page .application-services ul li span figure,
  .professional-services-page
    .application-services
    ul
    li
    span
    figure.adjust-height {
    width: 100px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .professional-services-page .two-col-full-section figure img {
    width: 100%;
    height: auto;
  }
  .professional-services-page .two-col-full-section .content-wrapper {
    padding: 20px;
    text-align: center;
  }
  .core-values-wrapper .tabs .item {
    width: 300px;
  }
  .core-values-wrapper .hoverable-tabs ul,
  .system-of-engagement .tabs ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .org-page .mission-wrapper .item-wrapper {
    width: 100%;
  }
  .system-of-engagement .tabs a.active {
    border-left: 0;
    border-top: 4px solid #f01c86;
  }
  .plus-page .two-col-full-section .content-wrapper {
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .plus-page .about-testimonials .item {
    padding: 20px;
  }
  .plus-page .full-text-slider-wrapper .item .content-wrapper {
    padding: 0;
  }
  .about-testimonials.full-text-slider-wrapper .item p {
    font-size: 14px;
  }
  .icon-text-layout .section-desc {
    font-size: 20px;
  }
  .two-col-fig-content-wrapper .points li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 0;
    border-bottom: 1px dashed #ddd;
    padding: 0;
    margin-bottom: 10px;
  }
  .right-apps .two-col-section .row {
    border: 0;
  }
  .right-apps .two-col-section .row:first-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .cx-desk-page .bg-image-wrapper {
    height: 250px;
  }
  .cx-desk-page .two-col-fig-content-wrapper .points li {
    text-align: left;
  }
  .center-image-layout figure img,
  .center-image-layout figure svg {
    width: auto;
  }
  .two-col-fig-content-wrapper .points {
    padding: 0 20px;
  }
  .business3-tab-section .consultare-services .col-6,
  .o-digital-page .two-col-section {
    padding: 0;
  }
  .o-digital-page .business3-tab-section .consultare-services .harmony {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .o-digital-page .services-block {
    padding-top: 0;
  }
  .business3-page .product-section {
    height: auto;
  }
  .business3-page .product-section .product-banner img {
    height: 100%;
    width: auto;
  }

  .business-facts .illus {
    width: 100%;
  }
  .cx-continuum-page .advantages-of-aury figure {
    margin-top: 0;
  }
  .exp-economy-section .left-section .section-main-heading {
    padding-left: 0;
  }
  .business3-page .product-section p,
  .o-digital-page .product-section p {
    font-size: 16px;
  }
  .business3-page .product-section .section-heading,
  .o-digital-page .product-section .section-heading {
    font-size: 32px;
  }
  .business-facts .illus figure {
    margin: 0 auto;
  }
  .o-digital-page .related-offerings .icon figure {
    text-align: center;
  }
  .o-digital-page .related-offerings span.icon img {
    width: 40%;
  }
  .cx-continuum-page .related-offerings .item .icon.adjust-height {
    width: 30%;
  }
  .cx-continuum-page .product-hero-wrapper .item-wrapper figure {
    width: 150px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .cx-continuum-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 22px;
  }
  .cx-continuum-page .product-hero-wrapper figure img {
    height: 80%;
  }
  .cx-continuum-page .product-hero-wrapper figure {
    height: 220px;
    width: 100%;
  }
  .cx-continuum-page .top-hero-product-banner .bg-image-wrapper {
    width: auto;
    height: 100%;
  }
  .plans-table .item,
  .plans-table .item-wrapper {
    padding: 10px;
  }
  .plans-table .item {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .insights-article-section .blogs-section .article-wrapper {
    width: 100%;
    border: 1px solid #ddd;
    margin-bottom: 15px;
  }
  .cx-strategy-page .virtuoso-features,
  .subscribe-section.insights-download form .btn {
    margin: 0;
  }
  .cx-strategy-page .product-hero-wrapper h3 {
    font-size: 24px;
  }
  .cx-strategy-page .cx-view-container .col-2:first-child {
    justify-content: center;
  }
  .subscribe-section.insights-download .input-wrapper {
    padding: 0;
  }
  .partner-wrapper .partner-desc .content-wrapper .partner-logo img {
    width: 50%;
  }
  .partner-wrapper
    .partner-desc
    .content-wrapper
    .partner-logo.adjust-height
    img {
    width: 30%;
  }
  .industry-insights .item .content-wrapper {
    width: 100%;
  }
  .industry-insights .item {
    padding: 15px;
  }
  .cx-strategy-page .ecosystem-section .gen figure {
    height: 250px;
  }
  .cx-strategy-page .ecosystem-section .icon {
    padding: 0;
  }
  .cx-strategy-page .ecosystem-section .icon img {
    width: 100px;
  }
  .cx-strategy-page .ecosystem-section {
    padding-bottom: 15px;
  }
  .cx-strategy-page .product-hero-wrapper .item-wrapper {
    text-align: center;
    padding-top: 20px;
  }
  .cx-strategy-page .next-steps-wrapper .row .item {
    width: 100%;
  }
  .cx-strategy-page .product-hero-wrapper,
  .cx-strategy-page .product-hero-wrapper .image-wrapper {
    height: 300px;
    padding: 0;
  }
  .cx-strategy-page .product-hero-wrapper .col-7 {
    display: none;
  }
  .cx-strategy-page .bg-image-wrapper img {
    transform: scale(1.5);
  }
  .next-steps-wrapper .item {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .digital-human-wrapper {
    text-align: center;
  }
  .digital-human-wrapper figure img {
    width: 50%;
    padding-bottom: 15px;
  }
  .digital-human-wrapper .col-8,
  .digital-human-wrapper .title {
    padding-bottom: 20px;
  }
  .cx-strategy-page .section-breaker {
    display: none;
  }
  .cx-strategy-page .vertical-tab-content {
    overflow-x: scroll;
  }
  .cx-strategy-page .vertical-tab-content .row.content-block {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .cx-strategy-page .vertical-tab-content .item {
    min-width: 250px;
  }
  .cx-now-section .icon {
    float: none;
    width: 150px;
    display: inline-block;
    border: 0;
  }
  .cx-now-section .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .cx-header-img .image-wrapper figure {
    width: 100%;
    height: auto;
  }
  .cx-header-img .image-wrapper figure img {
    height: auto;
    width: 70%;
  }
  .cx-flow-section .other-features {
    padding-top: 0;
    margin-top: 20px;
  }
  .cx-flow-section .other-features .item-wrapper .item {
    background-color: #f9f9f9;
  }
  .cxnow-plans .plans-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: scroll;
  }
  .seven-c-wrapper {
    text-align: center;
    font-size: 24px;
  }
  .seven-c-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .cvoice-page .animate-arrows-horizontal,
  .logos-steps-wrapper .animate-arrows-horizontal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .right-apps-page .image-title-layout .bg-image-wrapper img {
    transform: scale(2);
  }
  .cvoice-page .animate-arrows-horizontal .arrow,
  .logos-steps-wrapper .animate-arrows-horizontal .arrow {
    -webkit-animation-name: animate-arrows-vertical;
    animation-name: animate-arrows-vertical;
    line-height: 0.5;
  }
  .cvoice-page .animate-arrows-horizontal .arrow:before,
  .logos-steps-wrapper .animate-arrows-horizontal .arrow:before {
    content: "\f107";
  }
  .logos-steps-wrapper figure {
    width: 50%;
    margin: 0 auto;
  }
  .process-steps-wrapper .item .image-wrapper,
  .process-steps-wrapper .item figure,
  .process-steps-wrapper .item figure img {
    width: 100%;
    height: auto;
  }
  .cxprimer-page .bg-image-wrapper img {
    margin-top: 0;
  }
  .cxprimer-page .advantages-of-aury li {
    border: 0;
  }
  .cxprimer-page .advantages-of-aury .page-wrap:nth-child(even) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .cxprimer-page .advantages-of-aury .page-wrap .col-6 {
    width: 100%;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block {
    height: auto;
    flex-direction: column;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block .cx-primer-logo {
    width: 100%;
    padding-right: 10px;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block ul.links {
    display: none;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block .title {
    font-size: 1.2em;
    width: 50%;
    margin: 0;
    padding: 0 20px;
    border: none;
    padding-top: 20px;
    text-align: center;
  }
  .cxaudit-page .xd-process .process-items .item p:before {
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 32px;
    background-color: #00a1fe;
    width: 75px;
    height: 75px;
    text-align: center;
    margin: 15px auto;
    border-radius: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
  }
  .inferred-feedback-wrapper .row,
  .nps-wrapper .two-col-section:nth-child(even) .row {
    flex-direction: column-reverse;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
  }
  .cxaudit-page .xd-process .process-items .item {
    padding: 5px;
  }
  .cxaudit-page .digital-human-wrapper .col-6,
  .cxaudit-page .two-col-section .illus,
  .cxaudit-page .two-col-section .item-wrap {
    padding: 10px;
  }
  .cxaudit-page .xd-process .process-items .item:nth-child(1) p:before {
    content: "1";
  }
  .cxaudit-page .xd-process .process-items .item:nth-child(2) p:before {
    content: "2";
  }
  .cxaudit-page .xd-process .process-items .item:nth-child(3) p:before {
    content: "3";
  }
  .cxaudit-page .digital-human-wrapper .col-3 {
    text-align: center;
  }
  .nps-wrapper .page-wrap {
    padding: 0;
  }
  .nps-wrapper .two-col-section:nth-child(even) .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column-reverse;
  }
  .cxaudit-page .nps-wrapper {
    padding-bottom: 0;
  }
  .cxaudit-page .next-steps-wrapper .item {
    width: 100%;
  }

  .customer-voice-wrapper .item figure {
    margin-right: 20px;
    width: 75px;
  }
  .cvoice-page .develop .item-wrap {
    border: 1px solid #ddd;
    margin-top: 15px;
  }
  .cvoice-page .next-steps-wrapper {
    padding: 20px 0;
  }
  .cvoice-page .next-steps-wrapper .item {
    text-align: center;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    background-color: transparent;
  }
  .cvoice-page .digital-process-section .content-block .item {
    font-size: 16px;
  }
  .inferred-feedback-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column-reverse;
  }
  .cvoice-page .digital-human-wrapper .title {
    padding: 15px 0;
  }
  .cvoice-page .two-col-fig-content-wrapper .col-5 {
    display: block;
  }
  .cvoice-page .two-col-fig-content-wrapper .points .content-wrapper {
    text-align: left;
  }

  .other-partners-wrapper {
    padding: 0;
  }
  .other-partners-wrapper .item figure {
    width: 50%;
    margin-bottom: 20px;
  }
  .other-partners-wrapper .more-partners {
    padding: 30px 15px;
    margin-top: 30px;
  }
  .other-partners-wrapper .more-partners .logos {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .other-partners-wrapper .more-partners .logos figure {
    padding: 15px;
    margin: 15px;
  }

  .content-table-wrapper .content-wrap,
  .content-table-wrapper .table-wrap {
    width: 100%;
  }
  .product-hero-wrapper {
    padding: 30px 0;
  }
  .two-col-section .ux-processes-wrapper {
    padding-top: 30px;
  }
  .content-table-wrapper .content-wrap {
    padding-top: 20px;
  }
  .c-digital-page .next-steps-wrapper {
    padding: 30px 0;
  }
  .c-digital-page .two-col-section .item {
    padding: 20px;
  }
  .c-digital-page .two-col-section .note.page-wrap {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
  }
  .c-digital-page .next-steps-wrapper .section-heading {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 10px;
  }
  .indicial-page .digital-human-wrapper figure {
    width: 50%;
    margin: 0 auto;
  }
  .indicial-page .digital-human-wrapper .title {
    font-size: 26px;
    padding: 20px 0;
  }
  .indicial-page
    .vertical-image-hoverable-wrapper
    .content-image-wrapper
    .item-wrapper
    figure {
    height: 200px;
  }
  .indicial-page
    .vertical-image-hoverable-wrapper
    .content-image-wrapper
    .item-wrapper
    figure
    img {
    width: 100%;
    height: auto;
  }
  .indicial-page .vertical-image-hoverable-wrapper .item {
    padding: 10px;
    font-size: 14px;
  }
  .indicial-page .vertical-image-hoverable-wrapper .item-wrapper {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
  }
  .indicial-page .hoverable-content-wrapper .hoverable-tab-content p {
    min-height: auto;
  }
  .indicial-page .advantages-of-aury .points li {
    margin-bottom: 10px;
    border: 0;
    text-align: left;
    padding: 0;
  }
  .indicial-page .half-egde-icon-wrapper.related-offerings .item {
    margin-top: 20px;
  }
  .journey-path-wrapper {
    padding: 20px 0;
    background-image: none;
  }
  .journey-path-wrapper .journey-path-wrap .item {
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }
  .journey-path-wrapper .journey-path-wrap .title img {
    width: 70%;
  }
  .journey-path-wrapper .journey-path-wrap .item.big + .title img {
    width: 40%;
  }
  .journey-path-wrapper .journey-path-wrap .desc {
    font-size: 12px;
  }
  .journey-path-wrapper .journey-path-wrap .item-wrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .journey-path-wrapper .journey-path-wrap .col-2-half.item-wrapper {
    width: 33%;
  }
  .journey-path-wrapper .journey-path-wrap .row {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .iov-page .product-hero-wrapper .item-wrapper {
    display: none;
  }

  .connect-wrapper figure {
    height: 100%;
  }
  .connect-wrapper figure img {
    height: 100%;
    width: auto;
  }
  .connect-container .item .icon {
    margin: 0;
    font-size: 30px;
  }
  .connect-container .item {
    width: 100%;
    padding: 20px;
    border: 1px solid #ddd;
    border-bottom: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .connect-container .item:last-child {
    border-bottom: 1px solid #ddd;
  }
  .budh-page .two-col-section .page-wrap {
    padding: 20px 0 0;
  }
  .budh-page .product-hero-wrapper {
    height: auto;
  }
  .budh-page .product-hero-wrapper .item-wrapper figure {
    width: 75%;
    margin: 0 auto 20px auto;
  }
  .budh-page .product-hero-wrapper .col-4-half figure {
    height: auto;
    text-align: center;
  }
  .social-breaker figure {
    height: 100%;
  }
  .social-breaker figure img {
    height: 100%;
    width: auto;
  }
  .social-breaker .social-breaker-wrapper .item {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
  }
  .social-breaker .social-breaker-wrapper .item-wrapper:last-child .item {
    margin-bottom: 0;
  }
  .social-breaker .social-breaker-wrapper .item .icon {
    padding-bottom: 0;
    font-size: 30px;
  }
  .industrie {
    margin: 0;
  }

  .product-hero-wrapper .image-wrapper,
  .rigour-page .ai-wrapper .product-section {
    height: auto;
  }

  .our-ideas-section .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .our-ideas-section .item {
    width: 50%;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0;
  }
  .about-page .why-virtuos .right-section img,
  .about-story-page .history .page-wrap:first-child .col-7,
  .team-page .cheart .info .section-illus {
    width: 100%;
  }

  .team-page .feature-section li {
    margin-bottom: 10px;
  }
  .about-team-wrap {
    padding: 30px 0;
  }
  .about-team-wrap ul {
    padding-left: 0;
  }
  .team-page .domain-expertise .item {
    padding: 0;
  }
  .team-page .cheart .info .text-content {
    margin-top: 20px;
  }
  .team-page .cheart .info {
    padding: 20px 0;
  }
  .section-main-heading {
    padding: 5px 15px 20px;
  }
  .team-page .etiquette .diagram .note {
    float: left;
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
  }
  .section-breaker .content-wrapper h2 {
    margin-bottom: 0;
    font-size: 32px;
  }
  .team-page .section-breaker .content-wrapper {
    padding: 30px 0;
  }
  .contact-career-section ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .contact-career-section ul li {
    margin-bottom: 10px;
  }
  .xd-process .process-number .item {
    display: none;
  }
  .xd-process .process-items .item {
    list-style: decimal;
    border: 1px solid #eee;
    margin-bottom: 20px;
  }
  .team-page .xd-process .process-items {
    margin-top: 40px;
  }
  .team-page .xd-process .process-items .item h3 {
    padding: 20px;
  }
  .team-page .xd-process .process-items .item h3:before {
    content: "";
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 32px;
    background-color: #00a1fe;
    width: 75px;
    height: 75px;
    text-align: center;
    margin: 15px auto;
    border-radius: 50%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
  }
  .team-page .xd-process .process-items .item:nth-child(1) h3:before {
    content: "I";
  }
  .team-page .xd-process .process-items .item:nth-child(2) h3:before {
    content: "D";
  }
  .team-page .xd-process .process-items .item:nth-child(3) h3:before {
    content: "E";
  }
  .team-page .xd-process .process-items .item:nth-child(4) h3:before {
    content: "A";
  }

  .cxa-member-wrapper .row {
    padding: 0;
  }
  .careers-page .abcde-wrapper .abcde-block ul {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .careers-page .abcde-block ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .careers-page .section-breaker .content-wrapper {
    padding: 40px 15px;
  }
  .careers-page .section-breaker .content-wrapper h2 {
    font-size: 36px;
  }
  .careers-page .section-breaker .content-wrapper p {
    margin: 0;
    line-height: normal;
    font-size: 24px;
  }
  .full-text-slider-wrapper .item blockquote,
  .happiests-culture-page .ar-wrapper .left-content h3 {
    font-size: 20px;
  }
  .careers-page .abcde-wrapper .abcde-block .item {
    width: 100%;
  }
  .careers-tabs li a {
    width: auto;
  }
  .careers-page .vertical-image-hoverable-wrapper .page-wrap .col-2 {
    width: 100%;
  }
  .block-over-image-section .block-over-image-wrapper.ej {
    display: block;
  }
  .culture .two-col-section .page-wrap {
    padding: 0;
  }
  .careers-main-wrapper .culture .item figure {
    margin: 0;
  }
  .culture-page .two-col-wrapper .left-content {
    background-color: #f01c86;
    text-align: center;
    padding: 20px;
    margin-top: -50px;
  }
  .culture-page .core-values-section {
    padding-top: 160px;
  }
  .comminity-edge.shared-value .item figure {
    max-height: 350px;
  }
  .comminity-edge {
    padding: 30px 15px;
  }
  .comminity-edge .item-wrapper {
    padding: 0;
  }
  .comminity-edge .page-wrap {
    padding: 30px 0 0;
  }
  .case-study .c-logo {
    margin: 0 auto;
  }
  .case-study.main-blogs .article-info .title-wrapper {
    padding: 20px;
    background: #01a2fe;
  }
  .case-study.main-blogs .article-wrapper .article-heading {
    margin-bottom: 0;
    padding-bottom: 0;
    color: #fff;
  }
  .main-blogs {
    padding: 0;
  }
  .main-blogs .article-content .items-container .item {
    margin-right: 0;
  }
  .main-blogs .sticky-content {
    z-index: 0;
  }
  .main-blogs .article-wrapper .article-image {
    min-height: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .main-blogs blockquote {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .main-blogs .article-wrapper .article-image img {
    width: 100%;
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  .contactpage .contact-container .address-wrapper {
    width: 100%;
  }
  .contactpage .virtual-office-section .virtual-wrapper {
    padding: 20px 0;
    -webkit-box-pack: normal;
    -ms-flex-pack: normal;
    justify-content: normal;
  }
  .contactpage .virtual-office-section .content-wrapper {
    margin-top: 10px;
  }
  .contactpage .virtual-office-section .content-wrapper .section-main-heading {
    padding: 0;
  }
  .contactpage .virtual-office-section {
    height: auto;
  }
  .contactpage .virtual-offices-wrapper {
    margin-top: 0;
    padding: 15px;
  }
  .contactpage .virtual-offices-wrapper ul {
    padding-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .contactpage .virtual-offices-wrapper ul li {
    width: 50%;
    text-align: center;
    margin-bottom: 15px;
  }
  .contactpage .virtual-offices-wrapper li figure {
    margin: 0 auto;
  }
  .contactpage .contact-career-section a {
    margin-top: 15px;
  }
  .contactpage .contact-career-section .btn-wrapper {
    padding-top: 0;
  }
  .contactpage .contact-career-section .col-4 {
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 40px;
  }
  .contactpage .contact-career-section .col-4:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .careers-page .tab-content {
    padding-top: 80px;
  }
  .additional-resources .section-main-heading {
    padding-bottom: 20px;
  }
  .exp-job-page .experience-job .col-3-half .icon {
    margin-bottom: 20px;
  }
  .exp-job-page .experience-job .col-4 {
    margin-bottom: 60px;
  }
  .exp-job-page .experience-job .col-4:last-child {
    margin-bottom: 0;
  }
  .careers-main-wrapper .two-list-col .page-wrap .what-is {
    padding: 0 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .exp-job-page .exp1 .content-wrap {
    width: 100%;
  }
  .block-over-image-section.iq .block-over-image-wrapper .content-wrapper {
    margin-right: auto;
    position: relative;
    z-index: 1;
  }
  .exp-job-page .exp-job-features .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .solution-page .cx-services-wrapper .services-container .col-12,
  .solutions-wrapper .solutions-container .feature-block .icon:before {
    display: none;
  }
  .full-text-slider-wrapper .item {
    flex-direction: column;
    padding: 20px;
  }
  .exp-job-page .exp-job-features .row .col-3 {
    width: 50%;
  }
  .additional-resources {
    padding: 60px 0 0;
  }
  .training-page blockquote {
    padding: 10px 0 10px 10px;
  }
  .training-page #td-program {
    padding: 15px;
  }
  .training-page .two-col-section .item figure {
    margin: 0;
  }
  .training-page .factors-wrapper .hero-factors .section-desc {
    padding: 0 0 20px;
  }
  .training-page .factors-wrapper .hero-factors .row {
    margin: 0 0 20px;
  }
  .training-page .factors-wrapper .hero-factors .col-4 {
    width: 100%;
  }
  .training-page .factors-wrapper .hero-factors {
    padding: 0 15px 15px;
  }
  .training-page .exp-job-features {
    padding: 30px 0 0;
  }
  .training-page .contact-career-section {
    padding: 50px 0;
  }
  .happiests-culture-page .factors-wrapper .factors-content {
    padding: 0;
  }
  .happiests-culture-page .two-col-section .item figure {
    height: auto;
    margin: 0;
  }
  .happiests-culture-page .ar-wrapper {
    padding-bottom: 0;
  }
  .happiests-culture-page .ar-wrapper .row.page-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 20px 0;
  }
  .happiests-culture-page .ar-wrapper img {
    margin-bottom: 10px;
  }
  .happiests-culture-page .factors-wrapper .hero-factors {
    padding: 30px 0;
  }
  .happiests-culture-page .factors-wrapper .hero-factors .row {
    padding: 20px;
    margin: 0 0 20px;
  }
  .happiests-culture-page .factors-wrapper .hero-factors .row:last-child {
    border: none;
  }
  .happiests-culture-page .factors-wrapper .exp-job-features .col-4 {
    width: 100%;
    margin-bottom: 40px;
  }
  .happiests-culture-page .factors-wrapper .exp-job-features .item {
    padding: 0;
  }
  .happiests-culture-page .factors-wrapper .two-col-section {
    padding: 30px 0;
  }
  .happiests-culture-page .contact-career-section {
    padding: 40px 0;
  }
  .employee-beneits-page .factors-wrapper .hero-factors {
    padding: 30px 15px;
  }
  .employee-beneits-page .color-highlight {
    margin: 0;
    padding: 0;
  }
  .degree360-page .factors-wrapper .hero-factors,
  .degree360-page .factors-wrapper .two-col-section {
    padding: 15px;
  }
  .factors-wrapper .hero-factors .color-highlight.smile:before {
    height: 0;
  }
  .factors-wrapper .hero-factors .row {
    margin: 0 0 20px;
  }
  .factors-wrapper .hero-factors .row .col-7 {
    width: 100%;
  }
  .degree360-page .factors-wrapper .two-col-section figure {
    margin: 0;
  }
  .degree360-page .hero-factors .section-main-heading {
    font-size: 36px;
  }
  .degree360-page .factors-wrapper .hero-factors .icon img {
    height: auto;
    width: 100%;
  }
  .job-form-wrapper .row {
    width: 100%;
  }
  .job-form-wrapper .left-wrapper,
  .job-form-wrapper .right-wrapper,
  .tab-content .tab-brief {
    padding: 0;
  }
  .job-openings-section .item {
    margin-bottom: 20px;
    padding: 0;
  }
  .careers-main-wrapper .view-all-btn {
    margin-top: 30px;
  }
  .plus-page .two-col-section .item,
  .solution-page .solutions-wrapper .solutions-container {
    margin-top: 20px;
  }
  .cxprimer-page .half-egde-icon-wrapper.related-offerings .item {
    margin-top: 0;
  }
  .alternate-content-section .btn {
    width: 100%;
    margin-bottom: 10px;
  }
  .alumni-page .factors-wrapper .hero-factors .row {
    padding: 15px;
  }
  .alumni-page .main-wrapper {
    padding-top: 20px;
  }
  .solutions-wrapper .solutions-container .item {
    padding: 20px;
    width: 100%;
    border: 1px solid #ddd;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .our-products.travel .btn {
    width: 100%;
  }
  .solution-page .about-wrapper {
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 30px 15px;
  }
  .solution-page .about-wrapper .laptop-block {
    position: relative;
    left: 0;
    height: auto;
  }
  .solution-page .about-wrapper .laptop-block img {
    width: 100%;
  }
  .solution-page .about-wrapper .page-wrap {
    padding: 0;
  }
  .solution-page .about-wrapper h1 {
    line-height: normal;
  }
  .talk-video-breaker .content-block p {
    line-height: normal;
    margin-bottom: 10px;
  }
  .talk-video-breaker .content-block {
    padding: 30px 15px;
    text-align: center;
  }
  .solution-page .cx-services-wrapper .services-container,
  .solution-page .solutions-wrapper {
    padding: 30px 0;
  }
  .talk-video-breaker h2 {
    font-size: 32px;
    line-height: normal;
  }
  .talk-video-breaker video {
    width: auto;
    height: 100%;
  }
  .solution-page .cx-services-wrapper .services-container .item {
    width: 100%;
    padding: 15px 15px 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  .experts-wrapper .experts-container .item {
    width: 100%;
  }
  .connect-wrapper .page-wrap {
    padding: 30px 15px;
  }
  .connect-wrapper p {
    line-height: normal;
  }
  .connect-container {
    margin-bottom: 0;
  }
  .org-page .two-col-full-section .content-wrapper {
    padding: 30px 15px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .org-page .two-col-full-section .content-wrapper ul li {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: center;
  }
  .org-page .ar-wrapper .row .col-6,
  .org-page .mission-wrapper .item {
    margin-right: 0;
  }
  .org-page .ar-wrapper .left-content p {
    padding-top: 20px;
    color: #333;
  }
  .org-page .aury-metrices.product-section .right-content.page-wrap {
    padding: 20px 0;
  }
  .timeline-blogs-wrapper .item {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #eee;
  }
  .org-page .timeline-wrapper {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .org-page .timeline-blogs-wrapper .item-wrapper:last-child {
    padding-bottom: 0;
  }
  .bpm-page .product-hero-wrapper figure {
    width: 150px;
  }
  .technology-services-page .product-hero-wrapper .page-wrap .col-5,
  .technology-services-page .product-hero-wrapper .page-wrap [class*="col-"] {
    width: 80%;
  }
  .engineering-service-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(3.2);
  }
  .engg-service-wrapper .soft-engg .vdev span {
    text-align: center;
  }
  .experience-design-page .product-hero-wrapper img {
    width: 100%;
  }
  .experience-design-page .bg-image-wrapper img {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
  }
  .professional-services-page .product-hero-wrapper {
    height: 300px;
  }
  .professional-services-page .product-hero-wrapper .item-wrapper {
    width: 100%;
    text-align: center;
    padding-left: 0;
    padding-top: 0;
  }
  .professional-services-page .bg-image-wrapper img {
    -webkit-transform: scale(2.5) translateX(0);
    transform: scale(2.5) translateX(0);
  }
  .cx-continuum-page .product-hero-wrapper figure {
    height: 270px;
  }
  .o-digital-page .product-hero-wrapper .page-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .c-digital-page .top-hero-product-banner .page-wrap,
  .exp-cloud-page .product-hero-wrapper .row {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
  }
  .o-digital-page .product-hero-wrapper figure {
    height: 150px;
  }
  /*
.talentare-page .product-hero-wrapper {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}
*/
  .product-hero-wrapper .item-wrapper figure {
    width: 160px;
    margin: 0 auto;
  }

  .plus-page .product-hero-wrapper .row,
  .plus-page .product-hero-wrapper figure img {
    width: 100%;
  }

  .rigour-page .image-title-layout .bg-image-wrapper {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .cxprimer-page .bg-image-wrapper img,
  .rigour-page .image-title-layout .bg-image-wrapper img {
    width: auto;
    height: 100%;
  }
  .cjourney-page .product-hero-wrapper .consumer-cycle,
  .cjourney-page .product-hero-wrapper .item-wrapper,
  .cxaudit-page .top-hero-product-banner .page-wrap .col-12,
  .exp-cloud-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .cvoice-page .bg-image-wrapper img {
    -webkit-transform: scale(3);
    transform: scale(3);
  }
  .cvoice-page .product-hero-wrapper {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .cxaudit-page .gen .illus {
    margin-bottom: 20px;
  }
  .cxaudit-page .next-steps-wrapper .row {
    margin-top: 0;
    padding-top: 0;
  }
  .cvoice-page .product-hero-wrapper figure {
    left: 20px;
  }
  .cvoice-page .indirect-feedback-wrapper .row .illus,
  .cvoice-page .inferred-feedback-wrapper .row .illus {
    padding: 0;
    margin-bottom: 20px;
  }
  .cvoice-page .two-col-fig-content-wrapper .page-wrap {
    padding: 0 15px 40px;
  }
  .cvoice-page .develop .item-wrap:last-child {
    border: 1px solid #ddd;
  }
  .c-digital-page .product-hero-wrapper {
    height: auto;
  }
  .c-digital-page .top-hero-product-banner .page-wrap {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: flex;
  }
  .c-digital-page .top-hero-product-banner {
    text-align: center;
  }
  .c-digital-page .product-hero-wrapper h3 {
    font-size: 24px;
  }
  .c-digital-page .top-hero-product-banner .right-fixed-logo {
    width: 100px;
  }
  .c-digital-page .top-hero-product-banner figure img {
    width: 100%;
  }
  .c-digital-page .section-breaker figure img {
    width: auto;
    height: 100%;
  }
  .application-page .product-hero-wrapper .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .application-page .product-hero-wrapper .col-7 {
    width: 100%;
  }
  .application-page .application-container .vertical-clickable-tabs {
    display: none;
  }
  .application-page .application-container .vertical-tab-content {
    width: 100%;
    margin-bottom: 20px;
  }
  /*
.vertical-tab-content-wrapper .vertical-tab-content {
    display: block
}
*/
  .application-page .application-container .content-block {
    width: auto;
    padding: 20px;
  }
  .application-page .application-container .content-block h3 {
    font-size: 1.3rem;
  }
  .application-page .application-container .content-block p {
    margin: 5px 0 20px;
    line-height: 1.5;
  }
  .application-page .application-container .content-block a.more {
    position: static;
    padding: 5px 10px;
    font-size: 1rem;
  }
  .application-page .application-container .img-block {
    width: 100%;
    position: static;
  }
  .app-group-circle-links {
    display: none;
  }
  .platform-ecosystem-page .product-hero-wrapper {
    height: auto;
  }
  .platform-ecosystem-page .product-hero-wrapper .page-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .platform-ecosystem-page .product-hero-wrapper figure img {
    width: 100%;
  }
  .full-height-video .item span {
    font-size: 34px;
  }
  .bpm-page .bg-image-wrapper img {
    height: 100%;
    width: auto;
  }
  .bpm-page .block-4 .item-wrapper {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .bpm-page .aury-metrices {
    height: auto;
  }
  .bpm-page .aury-metrices .count-band .count-wrapper {
    width: auto;
    height: auto;
  }
  .bpm-page .exp-cloud-services .row .item-wrapper .item {
    padding: 30px 15px;
  }
  .experience-design-page .product-hero-wrapper .row [class*="col-"] {
    width: 100%;
  }

  .news-detail-page .main-blogs .article-wrapper .article-info:after,
  .org-page .bg-image-wrapper {
    display: none;
  }

  .careers-page .vertical-image-hoverable-wrapper .item i,
  .cx-marries-digital-page .top-hero-product-banner h2,
  .product-showcase:after {
    font-size: 20px;
  }

  .about-partner-page .product-hero-wrapper {
    padding: 30px 0 0;
    height: auto;
  }
  .about-partner-page .image-title-layout .product-hero-wrapper h3 {
    padding-left: 20px;
    background-color: #e6e7e8;
  }
  .news-detail-page .main-blogs .article-content {
    width: 100%;
    padding-top: 0;
  }
  .news-detail-page .main-blogs .article-wrapper .article-info {
    position: relative;
    color: #333;
  }
  .product-showcase {
    position: relative;
    padding: 60px 10px;
  }
  .product-showcase:after {
    content: "\f100\f100 \ Please scroll \f101\f101";
    position: absolute;
    top: -20px;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    left: 10%;
    right: 10%;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 5px;
    font-family: "Font Awesome 5 Pro";
    text-align: center;
    font-weight: 300;
  }
  .product-showcase .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
  }
  .product-showcase figure {
    width: 300px;
  }
  .careers-page .vertical-image-hoverable-wrapper .hoverable-tabs ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .careers-page .vertical-image-hoverable-wrapper .item {
    padding: 10px;
  }
  .cx-marries-digital-page .product-hero-wrapper {
    height: auto;
    text-align: center;
  }
  .cx-marries-digital-wrapper figure {
    width: 100%;
  }
  .consultare-page .blogs-section .page-wrap,
  .how-we-work .row {
    padding-top: 0;
  }
  .term-condition-page .product-hero-wrapper .row {
    display: flex;
    flex-direction: column;
  }
  .privacy-policy-page .product-hero-wrapper .row,
  .safe-harbour-page .product-hero-wrapper .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .privacy-policy-page .product-hero-wrapper,
  .safe-harbour-page .product-hero-wrapper,
  .term-condition-page .product-hero-wrapper {
    height: auto;
  }
  .privacy-policy-page .product-hero-wrapper .section-heading,
  .safe-harbour-page .product-hero-wrapper .section-heading,
  .term-condition-page .product-hero-wrapper .section-heading {
    padding: 20px 0 0;
    width: 100%;
    text-align: center;
  }
  .legal-section,
  .legal-wrapper {
    padding: 15px;
  }
  .privacy-policy-page .product-hero-wrapper [class*="col-"],
  .safe-harbour-page .product-hero-wrapper [class*="col-"],
  .term-condition-page .product-hero-wrapper [class*="col-"] {
    width: 90%;
  }
  .legal-doc-wrapper ul {
    padding: 20px;
  }
  .crm-cx-page .crm-wrapper .product-section {
    height: auto;
    padding: 20px 0;
  }
  .platform-ecosystem-page .campusyes {
    padding: 40px 0;
  }
  .engg-service-tab-wrapper .advantages ul li {
    width: auto;
  }
  .product-hero-wrapper .item-wrapper {
    text-align: center;
    padding-bottom: 0;
    padding-top: 30px;
  }
  .image-title-layout .product-hero-wrapper h3 {
    font-size: 22px;
  }
  .product-hero-wrapper .item-wrapper span {
    font-size: 18px;
  }
  .opportunities-page .product-hero-wrapper figure {
    padding-top: 30px;
  }
  .opportunities-page .product-hero-wrapper {
    height: auto;
    padding-bottom: 0;
  }
  .exp-job-page .top-hero-product-banner .page-wrap figure {
    height: 100%;
    padding-top: 30px;
  }
  .exp-job-page .product-hero-wrapper {
    height: auto;
    padding-bottom: 0;
  }
  .culture-page .top-hero-product-banner figure {
    height: 100%;
    padding: 20px 0;
    position: static;
    margin: 0 auto;
  }
  .culture-page .product-hero-wrapper {
    height: auto;
    padding-bottom: 0;
  }
  .culture-page .happiests-culturo-logo {
    margin: 0 auto;
  }

  .happiests-culture-page .top-hero-product-banner .page-wrap .col-7 {
    padding-top: 20px;
  }
  .happiests-culture-page
    .top-hero-product-banner
    .page-wrap
    .right-fixed-logo {
    height: auto;
    padding-top: 0;
    width: 100px;
  }
  .happiests-culture-page .top-hero-product-banner .page-wrap figure {
    height: 100%;
    padding-top: 20px;
  }
  .happiests-culture-page .product-hero-wrapper {
    height: auto;
  }
  .training-page .top-hero-product-banner .page-wrap figure {
    height: 100%;
    padding-top: 20px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .training-page .product-hero-wrapper {
    height: auto;
  }
  .training-page .image-title-layout .product-hero-wrapper .left-img {
    display: none;
  }
  .employee-beneits-page .top-hero-product-banner .page-wrap .item-wrapper,
  .training-page .top-hero-product-banner .page-wrap .item-wrapper {
    padding-top: 0;
  }
  .employee-beneits-page .top-hero-product-banner .page-wrap figure {
    height: 100%;
    padding-top: 20px;
  }
  .employee-beneits-page .product-hero-wrapper {
    height: auto;
  }
  .alumni-page .top-hero-product-banner .page-wrap .col-7 {
    padding-top: 20px;
  }
  .alumni-page .top-hero-product-banner .page-wrap .right-fixed-logo {
    height: auto;
    padding-top: 0;
    width: 75px;
  }
  .alumni-page .top-hero-product-banner .page-wrap figure {
    height: 100%;
    padding-top: 20px;
  }
  .alumni-page .product-hero-wrapper {
    height: auto;
  }
  .degree360-page .top-hero-product-banner .page-wrap figure {
    height: 100%;
    padding-top: 30px;
    position: static;
  }
  .about-page .bg-image-wrapper img {
    width: auto;
    height: 100%;
  }
  .filter-container .portfolio .label,
  .showcase-news-cards-wrapper .item figure {
    height: auto;
  }
  .about-page .product-hero-wrapper .page-wrap h3,
  .business3-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 32px;
  }
  .business3-page .image-title-layout .bg-image-wrapper:after {
    background: rgba(255, 255, 255, 0);
  }
  .degree360-page .product-hero-wrapper {
    padding-bottom: 0;
    height: auto;
  }
  .home-page .about-virtuos {
    padding: 50px 0 70px 0;
  }
  .home-page .about-virtuos .title-content {
    font-size: 1.2rem;
  }
  .home-page .services-container {
    padding: 50px 0 0;
  }
  .home-page .full-height-video .item span {
    font-size: 32px;
    text-align: center;
    line-height: normal;
  }
  .capabilities-section .row.block1 .item-wrapper {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #eee;
  }
  .capabilities-section .row.block2 .item-wrapper,
  .capabilities-section .row.block2 .item-wrapper:first-child {
    padding: 30px 0;
  }
  .four-blocks-container .content-block,
  .four-blocks-container .img-block,
  .four-blocks-container .img-block figure img {
    width: 100%;
  }
  .filter-container #filters li span {
    font-size: 1rem;
    padding: 0 5px;
    margin: 0 5px;
  }
  .home-page .cx-extra-features .item-wrapper {
    padding: 15px;
  }

  .main-home-parallax-slider .item .center-wrapper .category:before {
    display: none;
  }
  .main-home-parallax-slider .item .center-wrapper {
    width: 100%;
    padding-top: 5%;
    box-sizing: border-box;
  }
  .main-home-parallax-slider .center-wrapper h2 {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 10px;
  }
  .main-home-parallax-slider .center-wrapper p {
    padding-bottom: 20px;
  }
  .main-home-parallax-slider .item .center-wrapper .btn-secondary {
    padding: 5px 20px;
  }
  .capabilities-section .row.block2 .highlight {
    font-size: 1.5rem;
  }
  .four-blocks-container .row .col-3:nth-child(4) {
    order: 4;
  }
  .four-blocks-container .row .col-3:nth-child(3) {
    order: 3;
  }
  .filter-container .portfolio .label-text {
    font-size: 16px;
  }
  .filter-container .portfolio .text-category {
    font-size: 14px;
  }
  .cx-now-page .cx-header-img .content-wrap figure {
    width: 200px;
  }

  .right-apps-page .two-col-section .content-wrap figure {
    width: 50%;
  }
  .careers-job-page .product-hero-wrapper {
    height: 300px;
  }

  .download-brochure {
    margin-top: 100px;
    height: auto;
    padding: 20px;
  }
  .download-brochure .brochure-container {
    flex-direction: column;
  }
  .download-brochure .brochure-container .thumbnail {
    margin-bottom: 20px;
  }
  .download-brochure .brochure-container .content {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  .cx-header-img .links-wrapper li a {
    font-size: 11px;
    padding: 5px;
  }

  .cx-unified-page .image-title-layout .product-hero-wrapper .col-7 {
    display: none;
  }
  .cx-unified-page .product-hero-wrapper .item-wrapper {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .crm-cx-page .abc-wrapper .abc-block a.left::after {
    content: ">";
    position: absolute;
    right: 20px;
  }

  .bpm-page .bg-image-wrapper {
    height: 350px;
  }
  .platform-ecosystem-page .product-hero-wrapper figure {
    height: auto;
  }
  .technology-services-page .product-hero-wrapper {
    height: 400px;
  }
  .technology-services-page .product-hero-wrapper .page-wrap {
    flex-direction: column;
    justify-content: center;
  }
  .technology-services-page .product-hero-wrapper .page-wrap .col-5 {
    width: 100%;
  }

  /* digital page css STARTS here */
  .top-hero-product-banner .right-fixed-logo,
  .digital-page .top-hero-product-banner .right-fixed-logo {
    width: 100px;
  }
  .digital-page .digital-header-img .content-wrapper {
    margin: 0 50px;
    text-align: center;
  }
  .digital-page .digital-services-section figure,
  .digital-page .digital-services-section figure img {
    width: 100%;
    height: auto;
  }
  .digital-page .digital-header-img .col-6 img {
    opacity: 1;
    width: 100%;
    height: auto;
  }
  .digital-page .digital-header-img {
    height: 400px;
  }
  .digital-page .digital-header-img .section-heading {
    font-size: 24px;
    font-weight: 500;
  }
  .digital-page .digital-header-img .image-wrapper {
    width: 100%;
  }
  .digital-page .digital-process-content-wrapper .digital-steps-wrapper figure {
    width: 100%;
    height: 100%;
  }
  .digital-page
    .digital-process-content-wrapper
    .digital-steps-wrapper
    figure
    img {
    height: 100%;
    width: auto;
  }
  .digital-page .accordian-content .item-block {
    border: 1px solid #ddd;
    width: 100%;
    margin-bottom: 15px;
    padding: 30px 15px 15px 15px;
    text-align: center;
  }
  .digital-page .indicial .illus {
    width: 50%;
    margin: 0 auto;
  }
  .digital-page .accordian-content .item-block .icon {
    margin: 0 auto;
  }
  .digital-page .indicial .btn {
    width: 100%;
  }
  .digital-page .indicial .btn:hover {
    background-color: #fff;
    color: #333;
  }
  .digital-page .color-highlight {
    line-height: normal;
    font-size: 32px;
    padding: 0 10px;
    margin: 0;
    display: inline-block;
  }

  /* digital page css ENDS here */

  /* O-digital page css STARTS here */
  .o-digital-page .product-hero-wrapper {
    height: auto;
  }
  .o-digital-page .product-hero-wrapper figure.o-icon {
    height: 100px;
    margin-top: 20px;
  }
  .o-digital-page .cx-header-img .links-wrapper {
    display: none;
  }
  .o-digital-page .header-block {
    flex-direction: column;
  }
  .o-digital-page .header-block .title {
    border: none;
    padding-top: 20px;
    text-align: center;
  }
  .o-digital-page .header-block .left-logo {
    padding-right: 20px;
  }
  .o-digital-page .business3-page .services-block .item,
  .plans-table .item {
    border: 1px solid #eee;
  }
  .o-digital-page .cx-header-img .row {
    flex-direction: column;
  }
  .o-digital-page .cx-header-img .row .content-wrap {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* o-digital-page css ENDS here */

  /* w-digital-page css STARTS here */
  .w-digital-page .full-screen-wrapper .right-logo-block {
    top: 15%;
    left: 20%;
    right: 20%;
    width: 50px;
    margin: 0 auto;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block p {
    padding: 0;
    margin: 0;
    font-size: 1rem;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block.josh {
    width: 100px;
    top: 12%;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block.okrx {
    width: 100px;
    top: 12%;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block.expjob {
    width: 75px;
    top: 8%;
    right: 0;
    left: 0;
  }
  .w-digital-page .full-screen-wrapper .right-logo-block.culturo {
    width: 150px;
    top: 15%;
  }
  /* w-digital-page css ENDS here*/

  /* cx-now-page css STARTS here */
  .cx-now-page .cx-header-img {
    height: auto;
  }
  .cx-now-page.home-page .main-wrapper {
    padding-top: 50px;
  }
  .cx-now-page .cx-header-img .row {
    height: 300px;
  }
  .cx-now-page .cx-header-img .content-wrapper {
    padding: 0;
    border: 0;
    flex-direction: column;
  }
  .cx-now-page .cx-header-img .content-wrapper .content-wrap {
    width: 100%;
    border: 0;
    margin-top: 20px;
  }
  .cx-now-page .cx-header-img .image-wrapper figure img {
    width: 100%;
  }
  .cx-now-page .cx-flow-section .section-heading {
    margin: 0 auto;
    display: block;
    text-align: center;
  }
  .cx-now-page .cx-flow-section .other-features .item-wrapper {
    padding: 0;
    margin-bottom: 10px;
  }
  .cx-now-page.home-page .services-container .item-wrapper {
    margin: 0 0 20px 0;
  }
  .cx-now-page .cx-view-container .col-2:first-child {
    justify-content: center;
  }
  /* cx-now-page css ENDS here */

  /* experience-edge-page css STARTS here */
  .exp-edge-page .product-hero-wrapper {
    height: 300px;
  }
  .exp-edge-page .product-hero-wrapper .item-wrapper {
    bottom: 75px;
  }
  .exp-edge-page .product-hero-wrapper figure {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 300px;
  }
  .exp-edge-page .product-hero-wrapper img {
    width: auto;
    height: 100%;
    opacity: 0.2;
  }
  .exp-edge-page .two-col-section .page-wrap {
    padding: 30px 0 0;
  }
  .exp-edge-page .digital-drawinist .item {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #eee;
  }
  .exp-edge-page .digital-drawinist .item:last-child {
    border: 1px solid #eee;
  }
  .exp-edge-page .section-breaker .content-wrapper h2 {
    margin-bottom: 0;
    font-size: 24px;
  }
  .exp-edge-page .section-breaker .content-wrapper,
  .two-col-section .item {
    padding: 15px;
  }
  .exp-edge-page .exp-advantages .item {
    padding: 15px;
  }
  /* experience-edge-page css ENDS here */

  /* team-page css STARTS here */
  .team-page .domain-expertise .item-wrapper {
    margin-bottom: 20px;
  }
  /* team-page css ENDS here */

  /* iov-page css STARTS here */
  .iov-page .top-hero-product-banner .right-fixed-logo {
    width: 100px;
  }
  .iov-page .product-hero-wrapper.two-image-wrapper .image-wrapper figure {
    height: 180px;
  }
  .iov-page .product-hero-wrapper .image-wrapper span {
    line-height: normal;
  }
  .iov-page .exp-economy-section .economy-products a {
    padding: 20px;
    margin-bottom: 10px;
  }
  .iov-page .exp-economy-section .page-wrap.row,
  .iov-page .order .col-4 {
    padding: 0;
  }
  /* iov-page css ENDS here */

  /* partners-page css STARTS here */
  .about-partner-page .product-hero-wrapper {
    padding: 0;
    height: 300px;
  }
  .about-partner-page .product-hero-wrapper figure {
    height: auto;
  }
  .about-partner-page .product-hero-wrapper .col-8 {
    display: none;
  }
  .about-partner-page .product-hero-wrapper .col-8 figure {
    align-items: center;
    height: auto;
  }
  .about-partner-page .product-hero-wrapper .row {
    position: static;
    width: 85%;
    margin: 0 auto;
  }
  .about-partner-page .image-title-layout .bg-image-wrapper {
    align-items: flex-start;
    background-color: #e6e7e8;
  }
  .about-partner-page .product-hero-wrapper blockquote {
    top: -50px;
    padding: 0;
  }

  /* partners-page css ENDS here */

  /* specialized-partners-page css STARTS here */
  .specialised-page .other-partners-wrapper {
    padding: 0;
  }
  .specialised-page .main-header-img figure {
    height: 300px;
  }
  .specialised-page .main-header-img h1 {
    line-height: normal;
    font-size: 24px;
  }
  .specialised-page .main-header-img .content-wrap span {
    font-size: 14px;
  }
  /* specialized-partners-page css ENDS here */

  /* about page css STARTS here */
  .about-page .section-breaker figure img {
    width: auto;
    height: 100%;
  }
  .about-page .experience-design-page .about-team-wrap .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .about-page .experience-design-page .about-team-wrap .item-wrap {
    width: 50%;
    border: 0;
    margin: 0;
  }
  .about-page .core-values-band a {
    background-color: #00a1fe;
  }
  .about-page .exp-business {
    padding: 15px;
  }
  .about-page .story-wrapper .btn-wrapper {
    width: 100%;
  }
  .about-page .story-wrapper .item:nth-child(even) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .about-page .donation-section {
    padding: 30px 15px;
  }
  .about-page .donation-section .item-wrapper {
    padding: 0;
  }
  .about-page .blogs-section .article-wrapper {
    padding: 0;
    border-right: none;
  }
  .about-page .company-mv-wrapper .col-6 {
    padding: 0;
    box-sizing: border-box;
  }
  .about-page .experience-design-page .about-team-wrap .row {
    flex-direction: column;
  }
  .about-page .experience-design-page .about-team-wrap .item-wrap {
    width: 100%;
  }
  .about-page .about-team-wrap .btn-wrap {
    margin-top: 30px;
  }
  .about-page .core-values-band .col-3 {
    width: 100%;
    margin: 10px 0;
  }
  .about-page .company-mv-wrapper .col-6 {
    padding: 15px;
  }
  .about-page .company-mv-wrapper .mission {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .about-page .company-mv-wrapper .mission h3 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .about-page .company-mv-wrapper .vision {
    border-right: none;
  }
  .about-page .company-mv-wrapper blockquote {
    padding: 10px 0;
  }
  .about-page .why-virtuos {
    padding: 30px 0;
  }
  .about-page .full-text-slider-wrapper .item {
    padding: 20px 0;
    line-height: normal;
  }
  .about-page .about-virtuos blockquote {
    padding: 0;
  }
  /* about page css ENDS here */

  /* about story page css STARTS here */
  .about-story-page .story-banner {
    padding: 20px 20px 0;
  }
  .about-story-page .about-content-wrapper {
    padding: 30px 0;
  }
  .about-story-page .about-content-wrapper .main-about {
    max-width: 100%;
    padding: 0 0 20px;
  }
  .about-story-page .about-content-wrapper .main-about .feature-section a {
    padding: 10px 20px;
  }
  .about-story-page
    .about-content-wrapper
    .main-about
    .feature-section:last-child {
    padding-top: 0;
  }
  .about-story-page .about-content-wrapper .section-illus {
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 50px 0;
    border-top: 1px solid #ddd;
  }
  .about-story-page .about-content-wrapper .content-wrap {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 40px 0;
    flex-direction: column;
  }
  .about-story-page .about-content-wrapper .content-wrap:last-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .about-story-page .about-content-wrapper .main-about {
    padding: 0;
  }
  .about-story-page .about-content-wrapper .section-illus a {
    opacity: 1;
  }
  .about-story-page .about-content-wrapper .main-about p,
  .about-story-page .about-content-wrapper p {
    font-size: 16px;
  }
  .about-story-page .about-content-wrapper .content-wrap .text-content,
  .about-story-page .vistuos-story-wrapper .item-wrapper {
    padding: 0;
  }
  .about-story-page .vistuos-story-wrapper .header {
    min-height: 200px;
  }
  .about-story-page .header figure img {
    height: 100%;
  }
  .about-story-page .vistuos-story-wrapper {
    padding-bottom: 0;
  }
  .about-story-page .vistuos-story-wrapper .item {
    margin-bottom: 30px;
  }
  .about-story-page .vistuos-story-wrapper .item figure img {
    width: auto;
    height: 100%;
  }
  .about-story-page .about-content-wrapper .content-wrap {
    padding-top: 0;
  }
  .about-story-page .history .page-wrap:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .about-story-page .feature-section li {
    margin-bottom: 10px;
  }
  .about-story-page .about-content-wrapper .section-desc {
    margin-bottom: 20px;
  }
  .about-story-page .vistuos-story-wrapper .header .content-wrapper h2 {
    font-size: 32px;
  }
  .about-story-page .about-team-wrap .col-9 {
    flex-wrap: wrap;
  }
  .about-story-page
    .about-team-wrap
    .col-9
    .row:last-child
    .col-4:nth-child(odd) {
    flex-direction: row;
  }
  .about-story-page
    .about-team-wrap
    .col-9
    .row:last-child
    .col-4:nth-child(even) {
    flex-direction: row-reverse;
  }
  .about-story-page .about-team-wrap .col-3.item-wrap {
    padding: 20px;
  }
  .about-story-page .about-team-wrap .col-9 .col-4 .item figure {
    margin-bottom: 0;
  }
  .about-story-page .about-team-wrap .col-3 .item figure {
    margin-bottom: 0;
  }
  .about-story-page .about-culture {
    margin-bottom: 0;
  }
  .about-team-wrap .color-highlight {
    font-size: 52px;
  }
  /* about story page css ENDS here */

  /* professional-services page css STARTS here */
  .professional-services-page .consultare-services .section-desc,
  .professional-services-page .standard-page .accordian-wrapper {
    margin-bottom: 30px;
  }
  .professional-services-page .consultare-services .page-wrap {
    padding: 0;
  }
  .professional-services-page .consultare-services .item {
    padding: 20px;
  }
  .professional-services-page .standard-page .content-wrapper {
    padding-right: 0;
  }
  .professional-services-page
    .standard-page
    .accordian-wrapper
    .item.active
    .accordian-content {
    height: 280px;
    font-size: 14px;
  }
  .professional-services-page .skills-wrapper .barWrapper {
    margin-bottom: 5px;
  }
  .professional-services-page .product-section {
    height: auto;
  }
  .professional-services-page .count-band .item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    padding: 10px 0;
  }
  .professional-services-page .business-facts .content-wrapper {
    text-align: center;
  }
  .professional-services-page .skills-wrap i {
    bottom: 24px;
  }
  /* professional-services page css ENDS here */

  /* ai page css STARTS here */
  .ai-page .product-hero-wrapper {
    height: 300px;
  }
  .ai-page .product-hero-wrapper .row {
    text-align: center;
  }
  .ai-page .ecosystem-section .page-wrap.row figure {
    display: none;
  }
  .ai-page .ecosystem-section .icon {
    padding: 0;
  }
  .ai-page .ecosystem-section .points {
    padding-top: 15px;
  }
  .ai-page .ecosystem-section .icon img {
    width: 100px;
  }
  .ai-page .skills-container figure {
    position: static;
    height: 300px;
  }
  .ai-page .skills-container .page-wrap {
    padding-top: 20px;
  }
  /* ai page css ENDS here */

  /* experience cloud page css STARTS here */
  .exp-cloud-page .top-hero-product-banner .page-wrap {
    width: 100%;
  }
  .exp-cloud-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2.2) translateX(100px);
  }
  .exp-cloud-page .product-hero-wrapper {
    height: 300px;
  }
  .exp-cloud-page .product-hero-wrapper .item-wrapper {
    padding: 0;
  }
  .exp-cloud-page .product-hero-wrapper figure {
    padding: 20px 0 0 0;
    height: auto;
  }
  .exp-cloud-page .product-hero-wrapper .row {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    display: flex;
    text-align: center;
  }
  .exp-cloud-page .product-hero-wrapper .col-7 {
    display: none;
  }
  .exp-cloud-page .product-hero-wrapper .item-wrapper figure {
    width: 150px;
    margin: 10px auto;
  }
  .exp-cloud-page .product-hero-wrapper span {
    font-size: 18px;
  }
  .exp-cloud-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 24px;
  }
  .exp-cloud-page .exp-business .item {
    border-bottom: 1px solid #ddd;
  }
  .exp-cloud-page .exp-business .item-wrapper:last-child .item {
    border-bottom: 0;
  }
  .exp-cloud-page .accordian-wrapper .item.active .accordian-content {
    height: 100%;
  }
  .exp-cloud-page .two-col-full-section.accordian-section .illus {
    padding: 30px 0 0;
  }
  .exp-cloud-page .exp-business .item-wrapper,
  .exp-cloud-services .row .item-wrapper {
    width: 100%;
  }
  .exp-cloud-page .customer-voice-wrapper .item-container {
    padding: 15px;
    margin-top: 15px;
  }
  .exp-cloud-page .ecosystem .row .left-block {
    margin-bottom: 30px;
  }
  .exp-cloud-page .ecosystem .row .right-block figure {
    margin: 0 auto;
  }
  .exp-cloud-page .birth .row .item {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 15px 0;
  }
  .exp-cloud-page .birth .row .item p {
    min-height: auto;
  }
  /* experience cloud page css ENDS here */

  /* talentare page css STARTS here */
  .talentare-page .product-section .product-banner img {
    width: 100%;
    height: 100%;
  }
  .talentare-page .product-section .content {
    padding: 20px 0;
    font-size: 24px;
  }
  .talentare-page .product-section .content .icon {
    width: 200px;
  }
  .talentare-page .core-values-wrapper .tabs .item {
    width: 250px;
  }
  .talentare-page .business3-tab-section {
    padding-bottom: 0;
  }
  .talentare-page .product-hero-wrapper span {
    font-size: 24px;
  }
  .talentare-page .block-over-image-wrapper.ej .content-wrapper:hover {
    margin-left: 0;
  }
  .talentare-page .product-section {
    height: 200px;
    display: flex;
  }
  .talentare-page .product-section .content figure {
    width: 85%;
  }
  .talentare-page .product-section .content p {
    font-size: 22px;
    line-height: normal;
  }
  /* talentare page css ENDS here */

  /* experience design page css STARTS here */
  .experience-design-page .engg-service-wrapper .experience-design .item {
    border: 1px solid #eee;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px;
  }
  .experience-design-page .experience-design .section-desc {
    margin-bottom: 15px;
  }
  .experience-design-page .why-us-section .page-wrap .col-6 {
    padding: 0;
  }
  /* experience design page css ENDS here */

  /* right apps page css STARTS here */
  .right-apps-page .products-info {
    margin-bottom: 0;
  }
  .right-apps-page .products-info .item {
    padding: 0;
  }
  .right-apps-page .related-offerings .section-main-heading {
    padding: 0 20px;
  }
  /* right apps page css ENDS here */

  /* technololgy services page css STARTS here */
  .technology-services-page .advantages-of-aury .points li {
    padding: 30px;
  }
  /* technololgy services page css ENDS here */

  /* customer voice page css STARTS here */
  .cvoice-page .product-hero-wrapper .item-wrapper {
    padding: 0;
  }
  .cvoice-page .product-hero-wrapper figure {
    margin: 0 auto;
    padding: 20px;
    justify-content: center;
  }
  .cvoice-page .product-hero-wrapper .page-wrap {
    display: flex;
    flex-direction: column-reverse;
  }
  /* customer voice page css ENDS here */

  /* customer journey page css STARTS here */
  .cjourney-page .next-steps-wrapper .row {
    margin-top: 20px;
    padding: 0;
  }
  .cjourney-page .product-hero-wrapper .page-wrap {
    max-width: 100%;
  }
  .cjourney-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 24px;
  }
  /* customer journey page css ENDS here */

  /* ORG page css STARTS here */
  .org-page .product-hero-wrapper figure {
    height: auto;
  }
  .org-page .product-hero-wrapper figure img {
    width: auto;
    height: auto;
    margin: 0 auto;
  }
  .org-page .product-hero-wrapper {
    height: 300px;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#76ceea),
      to(#fff)
    );
    background: linear-gradient(to bottom, #76ceea, #fff);
  }
  .org-page .product-hero-wrapper .item-wrapper {
    text-align: center;
    padding-top: 10px;
  }
  .org-page .product-hero-wrapper .item-wrapper figure {
    width: 100%;
  }
  .org-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 20px;
  }
  .org-page .product-hero-wrapper span {
    font-size: 16px;
  }
  .org-page .timeline-blogs-wrapper .right-wrapper {
    padding-top: 24px;
  }
  .org-page .two-col-full-section .content-wrapper .section-heading {
    font-size: 24px;
    text-align: center;
  }
  /* ORG page css STARTS here */

  /* life at virtuos page css STARTS here */
  .life-at-virtuos-page iframe {
    border: none;
    width: 100%;
  }
  .life-at-virtuos-page .top-hero-product-banner .page-wrap .item-wrapper {
    padding-top: 0;
  }
  .life-at-virtuos-page .top-hero-product-banner .page-wrap figure {
    height: 100%;
    padding-top: 20px;
  }
  .life-at-virtuos-page .product-hero-wrapper {
    height: auto;
  }
  /* life at virtuos page css ENDS here */

  /* opportunity page css STARTS here */
  .opportunities-page .job-openings-section .job-item-wrapper {
    height: 300px;
  }
  /* opportunity page css ENDS here */

  /* 360 degree page css STARTS here */
  .degree360-page .product-hero-wrapper .col-4,
  .degree360-page .product-hero-wrapper .col-7 {
    width: 100%;
  }
  /* 360 degree page css ENDS here */

  /* c-digital page css STARTS here */
  .cdigital-page .header-container .header-block {
    padding: 40px 20px;
  }
  .cdigital-page .header-container figure {
    height: 250px;
  }
  .cdigital-page .header-container .right-fixed-logo figure {
    width: 72px;
  }
  .cdigital-page .header-container .header-block .title {
    font-size: 1.5em;
  }
  .cdigital-page .crm-cx-container .title-content {
    font-size: 1.5em;
  }
  .cdigital-page .spotlight-container .barc {
    margin-right: 0;
  }
  .cdigital-page .numbers-container .item {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .cdigital-page .spotlight-container .hightlight-block {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .cdigital-page .crm-container .item {
    margin: 0 0 20px 0;
  }
  .cdigital-page .xyz-video-container {
    padding: 60px 0;
    margin-bottom: 20px;
  }
  .cdigital-page .crm-container .usp {
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .cdigital-page .crm-container .usp .item {
    padding: 0 0 20px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
  }
  .cdigital-page .filter-container {
    padding: 60px 0;
  }
  .cdigital-page .filter-container #filters {
    margin-bottom: 5px;
    overflow-x: scroll;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .cdigital-page .filter-container #filters li {
    float: none;
    width: auto;
    margin: 0 20px;
  }
  .cdigital-page .flip-container .item {
    margin-right: 0;
    width: 100%;
    margin-bottom: 0;
  }
  .cdigital-page .company-hightlight .img-container {
    height: 250px;
  }
  .cdigital-page .company-hightlight .content-container {
    box-sizing: border-box;
  }
  .cdigital-page .industry-container .row .item {
    width: 100%;
    margin-bottom: 30px;
  }
  .cdigital-page .customer-success .content-container {
    box-sizing: border-box;
  }
  .cdigital-page .customer-success .content-container ul.points {
    display: flex;
    flex-direction: column;
  }
  .cdigital-page .customer-success .content-container ul.points li {
    width: 100%;
  }
  .cdigital-page .overview-container .content-wrapper .bg-img {
    display: none;
  }
  .cdigital-page .overview-container .content-wrapper .content-block {
    padding: 30px;
    margin-left: 0;
  }
  .cdigital-page .overview-container .row .col-5 {
    box-sizing: border-box;
  }
  .cdigital-page .testi-container .testi-wrapper {
    padding: 30px;
    box-sizing: border-box;
  }
  .cdigital-page .tabs-container {
    padding: 0;
    box-sizing: border-box;
  }
  .cdigital-page .tabs-container .tab {
    overflow-x: scroll;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 60px;
    box-sizing: border-box;
    width: 100%;
  }
  .cdigital-page .tabs-container .tab a {
    width: auto;
    text-align: center;
    display: flex;
  }
  .cdigital-page .tabs-container .tabcontent .box1 {
    height: 250px;
  }
  .cdigital-page .blog-container .blog-wrapper {
    margin: 0 0 20px 0;
  }
  /* c-digital page css ENDS here */

  /* Qualtrics page css STARTS here */
  .qualtrics-page .header-container .header-block {
    padding: 40px 20px;
  }
  .qualtrics-page .header-container figure {
    height: 250px;
  }
  .qualtrics-page .header-container .header-block .title {
    font-size: 1.5em;
  }
  .qualtrics-page .qxm-practice .title-content {
    font-size: 1.5em;
  }
  .qualtrics-page .cxnow-qualtrics .logo-block {
    margin-right: 0;
  }
  .qualtrics-page .numbers-container .item {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .qualtrics-page .cxnow-qualtrics .hightlight-block {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .qualtrics-page .usp-container .item {
    margin: 0 0 20px 0;
  }
  .qualtrics-page .qxm-video {
    padding: 60px 0;
    margin-bottom: 20px;
  }
  .qualtrics-page .usp-container .usp {
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .qualtrics-page .usp-container .usp .item {
    padding: 0 0 20px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
  }
  .qualtrics-page .filter-container {
    padding: 60px 0;
  }
  .qualtrics-page .filter-container #filters {
    margin-bottom: 5px;
    overflow-x: scroll;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .qualtrics-page .filter-container #filters li {
    float: none;
    width: auto;
    margin: 0 20px;
  }
  .qualtrics-page .flip-container .item {
    margin-right: 0;
    width: 100%;
    margin-bottom: 0;
  }
  .qualtrics-page .qxm-benefit .img-container {
    height: 250px;
  }
  .qualtrics-page .qxm-benefit .content-container {
    box-sizing: border-box;
  }
  .qualtrics-page .team-container .row .item {
    width: 100%;
    margin-bottom: 30px;
  }
  .qualtrics-page .customer-success .content-container {
    box-sizing: border-box;
  }
  .qualtrics-page .customer-success .content-container ul.points {
    display: flex;
    flex-direction: column;
  }
  .qualtrics-page .customer-success .content-container ul.points li {
    width: 100%;
  }
  .qualtrics-page .overview-container .content-wrapper .bg-img {
    display: none;
  }
  .qualtrics-page .overview-container .content-wrapper .content-block {
    padding: 30px;
    margin-left: 0;
  }
  .qualtrics-page .overview-container .row .col-5 {
    box-sizing: border-box;
  }
  .qualtrics-page .testi-container .testi-wrapper {
    padding: 30px;
    box-sizing: border-box;
  }
  .qualtrics-page .tabs-container {
    padding: 0;
    box-sizing: border-box;
  }
  .qualtrics-page .tabs-container .tab {
    overflow-x: scroll;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 60px;
    box-sizing: border-box;
    width: 100%;
  }
  .qualtrics-page .tabs-container .tab a {
    width: auto;
    text-align: center;
    display: flex;
  }
  .qualtrics-page .tabs-container .tabcontent .box1 {
    height: 250px;
  }
  /* Qualtrics page css ENDS here */

  /* Nexorama page css STARTS here */
  .nexorama-page .customer-success .content-container {
    box-sizing: border-box;
  }
  .nexorama-page .customer-success .content-container ul.points {
    display: flex;
    flex-direction: column;
  }
  .nexorama-page .customer-success .content-container ul.points li {
    width: 100%;
  }
  .nexorama-page .numbers-container .item {
    margin-right: 0;
  }
  .nexorama-page .xonomy-bootstrap figure {
    height: 200px;
  }
  .showcase-news-cards-wrapper h2 {
    margin: 0;
    padding: 0;
  }
  .nexorama-page .innovation ul li {
    width: 35%;
  }
  .nexorama-page .xonomy-bootstrap figure img {
    transform: scale(1.5);
  }
  .nexorama-page .xonomy-bootstrap .right-block ul li {
    width: 50%;
  }
  /* Nexorama page css ENDS here */

  /* home page css STARTS here */
  .home-page .services-container .item-wrapper {
    margin-right: 0;
    margin-bottom: 30px;
    display: block;
  }
  .capabilities-section .row.block1 .item-wrapper {
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .home-page .four-blocks-container .content-block {
    box-sizing: border-box;
    padding: 20px;
  }
  .home-page .dt-container .item {
    width: 100%;
    box-sizing: border-box;
  }
  .filter-container #portfoliolist_s .portfolio,
  .four-blocks-container .img-block {
    width: 100%;
  }
  .home-page .interest-container .custom-select {
    height: 50px;
  }
  .about-virtuos .row.block2 .left-content:before,
  .about-virtuos .row.block2 .left-content:after {
    display: none;
  }
  .home-page .four-blocks-container .img-block {
    margin-bottom: 0;
  }
  /* home page css ENDS here */

  /* cx-strategy-page css STARTS here */
  .cx-strategy-page .gen .col-6:first-child {
    display: none;
  }
  .cx-strategy-page .gen .col-6:last-child {
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
  }
  /* cx-strategy-page css ENDS here */

  /* cx-cntinuum-page css STARTS here */
  .cx-continuum-page
    .related-offerings
    .item-wrapper:last-child
    .content-wrap
    .logo {
    width: 40%;
    margin: 0 auto 40px;
  }
  .cx-continuum-page .header-block .left-logo {
    padding-right: 20px;
  }
  .cx-continuum-page .header-block {
    flex-direction: column;
  }
  .cx-continuum-page .header-block .left-logo figure img {
    width: 80%;
  }
  .cx-continuum-page .cx-header-img .links-wrapper {
    display: none;
  }
  .cx-continuum-page .header-block .title {
    border: none;
    padding-top: 20px;
    text-align: center;
  }
  /* cx-cntinuum-page css ENDS here */

  /* insights-page css STARTS here */
  .insights-page .product-hero-wrapper figure {
    height: auto;
  }
  /* insights-page css ENDS here */

  /* bpm-page css STARTS here */
  .bpm-page .product-hero-wrapper {
    height: 300px;
  }
  .bpm-page .product-hero-wrapper .row .col-7 {
    display: none;
  }
  .bpm-page .bg-image-wrapper img {
    transform: scale(2);
  }
  .bpm-page .product-hero-wrapper .page-wrap {
    margin-top: 50px;
  }
  /* bpm-page css ENDS here */

  /* consultare page css STARTS here */
  .consultare-page .header-slider-container ul {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .consultare-page .header-slider-container ul li {
    height: 33.3%;
  }
  .consultare-page .header-slider-container ul li:nth-child(2) {
    background-size: 190%;
  }
  .consultare-page .header-slider-container ul li div a {
    height: 100%;
  }
  .consultare-page .header-slider-container ul li div {
    height: 100%;
  }
  .consultare-page .meaning-container .col-6 {
    padding: 0;
  }
  .consultare-page .highlights-container .item {
    width: 45%;
    margin: 0 auto 40px;
  }
  .consultare-page .numbers-container {
    padding: 30px 0;
  }
  .consultare-page .numbers-container .item {
    padding: 20px 0;
    margin-right: 0;
  }
  .consultare-page .services-container .item {
    display: flex;
    flex-direction: column;
  }
  .consultare-page .services-container .item .content-container {
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;
  }
  .consultare-page .services-container .item figure img {
    display: none;
  }
  .consultare-page .industry-container .col-2 {
    width: 50%;
    margin-bottom: 30px;
  }
  .consultare-page .industry-container {
    padding: 60px 0 30px;
  }
  .consultare-page .flip-container {
    padding: 30px 0;
  }
  .consultare-page .full-height-video {
    height: 400px;
    text-align: center;
  }
  .consultare-page .full-height-video figure img {
    width: auto;
    height: 100%;
  }
  .consultare-page .full-height-video .item {
    padding: 20px;
    box-sizing: border-box;
  }
  .consultare-page .flip-container .item {
    margin-right: 0;
  }
  .consultare-page .capabilities-section .row.block1 .item-wrapper {
    margin-bottom: 20px;
  }
  .consultare-page .applications-container {
    padding: 0;
  }
  .consultare-page .indicial figure {
    width: 50%;
    margin: 0 auto;
  }
  .consultare-page .applications-container .item {
    margin-right: 0;
    border-bottom: 1px solid #ddd;
  }
  .consultare-page .applications-container .item-wrapper:last-child .item {
    border: none;
  }
  .consultare-page .full-height-video .item span {
    font-size: 32px;
  }
  /* consultare page css ENDS here */

  /* exonoomy page css STARTS here */
  .xonomy-page .slider-container,
  .xonomy-page .slider-container .slider-wrapper {
    height: 50vh;
  }
  .xonomy-page .slider-container .slider-wrapper .title,
  .xonomy-page .about-container .title {
    font-size: 2em;
  }
  .xonomy-page .about-container,
  .xonomy-page .clients-container {
    padding: 60px 0;
  }
  .xonomy-page .numbers-container .item {
    padding: 20px;
  }
  .xonomy-page .clients-container figure {
    padding: 20px;
    margin: 0 auto;
  }
  .xonomy-page .form-container .form-wrapper input[type="text"],
  .xonomy-page .form-container .form-wrapper textarea {
    width: 90%;
  }
  .xonomy-page .slider-container .slider-wrapper figure img {
    transform: scale(3);
  }
  /* exonoomy page css ENDS here */

  /* new page css STARTS here */
  .rigour-page .top-hero-product-banner .right-fixed-logo {
    display: none;
  }
  .rigour-page .product-hero-wrapper .title {
    font-size: 2em;
  }
  .rigour-page .product-hero-wrapper figure {
    height: 250px;
  }
  .rigour-page .product-hero-wrapper figure img {
    padding: 20px;
  }
  .rigour-page .portfolio-container .page-wrap .col-3 {
    display: block;
  }
  .rigour-page .portfolio-container .item {
    display: block;
  }
  .rigour-page .portfolio-container .item .overlay {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .rigour-page .portfolio-container .item .overlay span {
    color: #fff;
  }
  .rigour-page .portfolio-container .item:hover {
    display: block;
  }
  .rigour-page .people-container .row .content-block,
  .rigour-page .blog-container .content-block {
    margin-bottom: 30px;
  }
  .rigour-page .people-container .row .item {
    margin: 0 0 20px 0;
  }
  .rigour-page .blog-container .blog-wrapper {
    padding: 0;
    margin-bottom: 10px;
  }
  .rigour-page .people-container,
  .rigour-page .blog-container {
    padding: 30px 0;
  }
  .rigour-page .block2-container .title {
    font-size: 1.5em;
  }
  .rigour-page .product-hero-wrapper .page-wrap {
    height: 450px;
  }
  .rigour-page .slider1,
  .rigour-page .slider2,
  .rigour-page .slider3 {
    background-position: 35% 0;
  }
  .rigour-page .product-hero-wrapper {
    height: 450px;
  }
  .rigour-page .product-hero-wrapper .col-5,
  .rigour-page .product-hero-wrapper .col-7 {
    width: 100%;
    padding-bottom: 0;
  }
  .rigour-page .product-hero-wrapper figure {
    width: 100%;
  }
  .rigour-page .product-hero-wrapper .content {
    display: none;
  }
  /* new page css ENDS here */

  .cx-story-page .product-hero-wrapper .col-7 figure {
    display: none;
  }
  .cx-story-page .two-col-fig-content-wrapper .page-wrap.row.team-pics {
    flex-direction: column;
  }
  .cx-story-page .connect-wrapper p {
    font-size: 28px;
  }

  .aury-page.rigour-page .product-hero-wrapper .page-wrap {
    height: 100%;
  }
  .aury-page.rigour-page .product-hero-wrapper .item-wrapper span {
    font-weight: bold;
  }
  .aury-page.self-service-page .product-hero-wrapper .left-img figure {
    width: 100px;
  }
  .aury-page.self-service-page .product-hero-wrapper .item-wrapper {
    padding-top: 0;
  }
  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  .about-virtuos .row.block2 .col-8.item-wrapper {
    padding: 30px 0;
    background: none;
  }
  .about-virtuos .row.block2 .col-8.item-wrapper .item-block {
    width: 100%;
  }
  .about-virtuos .row.block2 .col-8.item-wrapper .item-block:last-child {
    padding-right: 0;
  }
  .home-page .about-virtuos .lft-bg {
    width: 100%;
  }
  .home-page .dt-container {
    padding: 30px 0;
  }
  .capabilities-section .row.block1 .item-wrapper figure {
    padding: 0;
  }
  .capabilities-section .row.block1 .item-wrapper h3,
  .capabilities-section .row.block1 .item-wrapper p,
  .capabilities-section .row.block1 .item-wrapper .btn-wrapper {
    padding: 0;
  }
  .clients-list li {
    width: 250px;
    height: 75px;
  }
  .twittie {
    padding: 60px 0;
  }
  .twitter-feeds-wrapper {
    height: 375px;
  }
  .offices-region .item ul {
    margin-top: 40px;
    justify-content: left;
  }
  .offices-region .item ul li {
    padding: 0;
    margin-right: 20px;
  }
  .footer-bottom .left-content {
    text-align: left;
  }
  .main-footer .legal {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-footer .legal li {
    padding-left: 0;
  }
  .home-slider1-container {
    height: 300px;
  }
  #home-slide1 svg #header-img {
    transform: translateY(50%);
  }
  .home-slider1-container .slide1 .content {
    top: 40%;
  }
  .home-slider1-container .slide1 .content .subtitle {
    font-size: 1em;
  }
  .home-slider1-container .slide1 .content h1 {
    font-size: 1em;
  }
  .home-slider1-container .slide1 .content .tagline {
    font-size: 1em;
  }
  .home-slider1-container .slide1 .content .btn {
    padding: 5px 25px;
    font-size: 14px;
    border-radius: 20px;
  }
  .mob-slider-logo-new {
    height: 1.8rem !important;
  }

  .mob-hidden {
    display: none !important;
  }

  .home-slider1-container .slide1 .left-side-text {
    top: 40% !important;
    left: 30% !important;
  }
  .home-slider1-container .slide1 .left-side-text .subtitle {
    font-size: 1em !important;
  }
  .home-slider1-container .slide1 .left-side-text h1 {
    font-size: 1em !important;
  }
  .home-slider1-container .slide1 .left-side-text .tagline {
    font-size: 1em !important;
  }
  .home-slider1-container .slide1 .left-side-text .btn {
    padding: 5px 25px !important;
    font-size: 14px !important;
    border-radius: 20px !important;
  }

  .home-slider1-container .slide1 svg,
  .home-slider1-container .slide1 img,
  .home-slider1-container .slide1 video {
    width: 100%;
    transform: scale(1.5) translateY(-60px);
  }
  .home-slider1-container .slide1 {
    height: 300px;
  }
  .home-slider1-container .npicon {
    width: 30px;
    height: 40px;
    font-size: 12px;
  }
  .home-slider1-container #home-slide3,
  .home-slider1-container #home-slide4 {
    background: #fff;
  }
  .home-slider1-container .npicon.npre,
  .home-slider1-container .npicon.nnext {
    top: 45%;
  }
  .home-page .interest-container {
    padding: 75px 0 30px 0;
  }

  .sun {
    width: 50px;
    height: 50px;
  }
  @keyframes sun {
    0% {
      transform: translateY(-400px);
    }
    100% {
      transform: translateY(40%);
    }
  }
  .digital-page .digital-services-section .rgt-bg #solar-wind-combi {
    position: absolute;
    right: 150px;
    bottom: -40px;
    height: 220px;
  }
  .digital-page .digital-services-section .rgt-bg {
    width: 350px;
  }
  .digital-page .digital-services-section img {
    width: 100%;
    height: 100%;
  }
  .consultare-page .meaning-container {
    padding: 60px 0 250px;
  }
  .o-digital-page.agnitor-page .product-hero-wrapper {
    padding: 0;
    height: 300px;
  }
  .o-digital-page.agnitor-page .bg-image-wrapper img {
    -webkit-transform: scale(2.5) translateX(0);
    transform: scale(2.5) translateX(0);
  }
  .o-digital-page.agnitor-page .product-hero-wrapper .page-wrap {
    width: 100%;
    flex-direction: row;
  }
  .o-digital-page.agnitor-page .product-hero-wrapper figure {
    width: 160px;
    margin-bottom: 0;
  }

  .cxpyramid-page .cxpyramid-container .pyramid {
    padding: 0;
  }
  .cxpyramid-page .cx-different-container .col-5 figure {
    width: 100%;
    margin-bottom: 10px;
  }
  .cxpyramid-page .cx-different-container .col-6 {
    padding-right: 0;
    text-align: center;
  }
  .cxpyramid-page .cxpyramid-container .pyramid figure,
  .cxpyramid-page .cx-balance-container .cx-balance figure {
    width: 95%;
  }
  .cxpyramid-page .cxpyramid-cjourney-container {
    padding: 30px 0;
  }
  .cxpyramid-page .vertical-image-hoverable-wrapper .hoverable-tabs {
    width: 100%;
  }
  .cxpyramid-page .hoverable-content-wrapper .hoverable-tab-content h4 {
    padding: 5px;
  }

  .rainbowcx-page .image-title-layout .product-hero-wrapper p {
    font-size: 16px;
  }
  .rainbowcx-page .product-hero-wrapper figure {
    padding: 20px 0;
  }
  .rainbowcx-page .exp-business,
  .rainbowcx-page .cx-ex-container {
    padding: 30px 0;
  }
  .rainbowcx-page .channels .channel-img {
    flex-direction: column;
    margin-bottom: 0;
  }
  .rainbowcx-page .channels .channel-img figure {
    width: 90%;
    margin-bottom: 20px;
  }
  .rainbowcx-page .channels .multi-img {
    margin: 20px 0;
  }
  .rainbowcx-page .channels .multi-img figure {
    width: 100%;
    margin: 0 auto 30px;
  }
  .rainbowcx-page .stack-container .multi-icons {
    margin-top: 30px;
  }
  .rainbowcx-page .stack-container .multi-icons .item-wrapper {
    width: 100%;
  }
  .rainbowcx-page .stack-container .multi-icons .item-wrapper h3 {
    font-size: 24px;
    font-weight: 400;
  }
  .rainbowcx-page .stack-container .multi-icons .item {
    padding: 0 20px;
    margin: 0;
  }
  .rainbowcx-page .digital-process-section {
    padding: 0;
  }
  .rainbowcx-page .cx-ex-container figure {
    width: 100%;
  }
  .rainbowcx-page .two-col-full-section.cloudle .bg-banner img {
    transform: scale(5);
  }
  .rainbowcx-page .two-col-full-section.cloudle .illus figure {
    width: 50%;
    padding: 20px 0;
  }
  .rainbowcx-page .cx-stack-container .items-container {
    flex-wrap: wrap;
    padding: 30px 0;
  }
  .rainbowcx-page .cx-stack-container .items-container .item {
    margin-bottom: 20px;
  }
  .rainbowcx-page .cx-stack-container .items-container .item h4 {
    font-size: 20px;
  }
  .rainbowcx-page .cf-form-wrapper {
    padding: 30px 0;
  }
  .rainbowcx-page .vertical-tab-content .row.content-block {
    justify-content: space-between;
  }
  .rainbowcx-page .vertical-tab-content .col-3.item {
    width: 100%;
    padding: 0;
  }
  .rainbowcx-page .vertical-tab-content .col-3.btn-block a {
    width: 100%;
    margin-bottom: 10px;
  }
  .rainbowcx-page .vertical-tab-content .col-3 h3 {
    text-align: center;
  }
  .rainbowcx-page .digital-process-tabs-wrapper {
    display: none;
  }
  .rainbowcx-page .vertical-tab-content .col-3 li {
    font-size: 16px;
  }
  .rainbowcx-page .cx-stack-container .items-container .item .col-5 {
    width: 40%;
  }
  .rainbowcx-page .cx-stack-container .items-container .item .col-7 {
    width: 60%;
  }

  .virtuosoship-page .bg-image-wrapper img {
    transform: scale(2.5);
  }
  .virtuosoship-page .virtuoso-container .ship-container figure {
    width: 75%;
    margin: 30px auto;
  }
  .virtuosoship-page .virtuoso-container .ship-container .col-5 figure {
    width: 100%;
  }
  .virtuosoship-page .being-container img {
    margin-bottom: 30px;
  }
  .virtuosoship-page .framework-container .items-container .item ul li {
    font-size: 20px;
  }
  .virtuosoship-page .framework-container .items-container .item {
    margin-bottom: 15px;
  }
  .virtuosoship-page .breaker-container .bg-banner img {
    display: none;
  }
  .virtuosoship-page .breaker-container .col-7,
  .virtuosoship-page .breaker-container p {
    transform: none;
  }
  .virtuosoship-page .heuristic-container .item,
  .virtuosoship-page .innovative-container .mission-wrapper .item-wrapper,
  .virtuosoship-page .innovative-container .mission-wrapper .item {
    width: 100%;
  }
  .virtuosoship-page .heuristic-container .items-container .item {
    width: 98%;
    box-sizing: border-box;
  }
  .virtuosoship-page .productivity-container .items-block .item {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 15px 0;
  }
  .virtuosoship-page .innovative-container .mission-wrapper .item h3,
  .virtuosoship-page
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .item
    h3 {
    font-size: 24px;
    font-weight: 400;
  }
  .digital-process-tabs-wrapper {
    display: none;
  }
  .virtuosoship-page .vertical-tab-content-wrapper .vertical-tab-content .item {
    border: 1px solid #eee;
    width: 100%;
    box-sizing: border-box;
    display: block;
  }
  .virtuosoship-page
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .item:hover {
    background-color: transparent;
    transform: none;
    box-shadow: none;
  }
  .virtuosoship-page
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .item
    h3::after {
    height: 2px;
    transform: translate(-50%, 40px);
  }
  .virtuosoship-page .innovative-container .mission-wrapper p {
    min-height: auto;
  }

  .cxunity-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(3.2) translateX(50px);
  }
  .cxunity-page .rcit-container figure,
  .cxunity-page .rw-container figure,
  .cxunity-page .cdp-container figure {
    width: 100%;
  }
  .cxunity-page .cxunity-container,
  .cxunity-page .rcit-container,
  .cxunity-page .cdp-container,
  .cxunity-page .rw-container,
  .cxunity-page .unity-culture-container {
    padding: 30px 0;
  }
  .cxunity-page .unity-culture-container .bitems-container .items {
    flex-wrap: wrap;
  }

  .cxopia-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(3.2) translateX(140px);
  }
  .cxopia-page .cxopia-container svg {
    margin: 30px 0 0;
  }
  .cxopia-page .customer-voice-wrapper .item-container {
    margin-top: 30px;
  }
  .cxopia-page .customer-voice-wrapper .item {
    margin: 0 0 30px 0;
  }
  .cxopia-page .advantages-of-aury .illus {
    padding: 30px 0 0;
  }
  .cxopia-page .advantages-of-aury .illus img {
    width: 100%;
  }
  .cxopia-page .cxmetrics-container .diagram,
  .cxopia-page .optimize-container figure {
    width: 100%;
    margin: 30px 0 0 0;
  }
  .cxopia-page .eight-block-container .items-container {
    margin-top: 30px;
  }
  .cxopia-page .video-wrapper p {
    font-size: 1.5em;
    text-align: center;
  }

  .cxdatum-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(3.1) translateX(-25px);
  }
  .cxdatum-page .image-title-layout .product-hero-wrapper h3 {
    width: 100%;
    text-align: left;
  }
  .cxdatum-page .advantages-of-aury .row:first-child {
    flex-direction: column-reverse;
    display: flex;
  }
  .cxdatum-page .advantages-of-aury .row:nth-child(n + 3) {
    flex-direction: column-reverse;
    display: flex;
  }
  .cxdatum-page .advantages-of-aury .row:nth-child(n + 3) .col-6 {
    width: 100%;
  }
  .cxdatum-page .advantages-of-aury .row:nth-child(n + 4) {
    flex-direction: column;
    display: flex;
  }
  .cxdatum-page .advantages-of-aury .points li {
    border: none;
  }
  .cxdatum-page .advantages-of-aury figure.adjust-height img {
    width: 100%;
  }
  .cxdatum-page .breaker-container {
    padding: 30px 0;
  }
  .cxdatum-page .breaker-container .bg-banner img {
    transform: scale(2.5);
  }
  .cxdatum-page .breaker-container p {
    font-size: 1.5em;
  }
  .cxdatum-page .dexp-container .item {
    width: 100%;
  }

  .aury-page.rigour-page .product-hero-wrapper .col-6:last-child figure {
    display: none;
  }
  .aury-page .reach-container .item {
    padding: 15px;
    background-color: #f6f6f6;
    margin: 10px;
    border-right: none;
  }
  .aury-page .reach-container .item h3 {
    font-size: 26px;
    height: auto;
  }
  .aury-page .purpose-container .choice-container {
    padding: 0;
  }
  .aury-page .purpose-container .choice-container .items-container {
    margin: 0 15px 15px 15px;
    width: 92%;
    box-sizing: border-box;
  }
  .aury-page .channel-container ul li {
    width: 100%;
  }
  .aury-page .poll-container .poll-block {
    margin: 30px auto;
  }
  .aury-page .poll-container .poll-block .col-4 {
    width: 100%;
  }
  .aury-page .ai-robot-container .items-container {
    padding: 30px 0 0 0;
    flex-wrap: wrap;
  }
  .aury-page .ai-robot-container .items-container .item {
    padding: 0 20px;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
  }
  .aury-page .ai-robot-container h3 {
    margin: 0;
  }
  .aury-page .breaker-container .content-block,
  .aury-page .breaker-container .img-block {
    width: 100%;
    box-sizing: border-box;
  }
  .aury-page .breaker-container .img-block img {
    width: 100%;
  }
  .aury-page .ai-robot-container .content-block .item {
    margin-bottom: 15px;
  }

  .crm-digital-page .bg-image-wrapper img {
    transform: scale(3) translateX(-40px);
  }
  .crm-digital-page .product-hero-wrapper .item-wrapper {
    text-align: left;
  }
  .crm-digital-page .crm-digital-container .items-container figure {
    width: 100% !important;
  }
  .crm-digital-page .crm-digital-container .items-container .content-block {
    padding: 30px 0;
  }
  .crm-digital-page .crm-digital-container .items-container ul li {
    width: 100%;
  }
  .crm-digital-page .breaker-container .content-block,
  .crm-digital-page .breaker-container .img-block {
    width: 100%;
    box-sizing: border-box;
  }
  .crm-digital-page .breaker-container .img-block figure {
    width: 100%;
    overflow: hidden;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .crm-digital-page .breaker-container .img-block figure img {
    width: 100%;
  }
  .crm-digital-page .industry-container .items-container .item {
    width: 100%;
    margin: 20px 0;
  }
  .crm-digital-page .form-breaker {
    padding: 30px 0;
    text-align: center;
  }
  .crm-digital-page .skills-cloud-container h2 {
    font-size: 3em;
  }
  .crm-digital-page .skills-cloud-container figure {
    width: 100%;
    margin: 20px 0 0 0;
  }
  .crm-digital-page .skills-cloud-container .row {
    padding-top: 20px;
  }
  .crm-digital-page .skills-cloud-container .free-tag {
    position: relative;
    top: 0;
    right: 0;
    width: 150px;
    margin: 0 auto;
  }
  .crm-digital-page .advantage-container .items-container .item {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .crm-digital-page .cust-centric-breaker .items-container .mid {
    padding: 15px;
  }
  .crm-digital-page .cust-centric-breaker .items-container .mid figure {
    width: 100%;
  }
  .crm-digital-page .speed-container .items-container .item-block {
    width: 100%;
  }
  .crm-digital-page .blogs-section .page-wrap {
    padding-top: 0;
  }

  .w-digital-page .bg-image-wrapper img {
    transform: scale(2.2) translateX(-20px);
  }
  .w-digital-page .top-hero-product-banner .right-fixed-logo {
    width: 75px;
  }
  .w-digital-page .lifecycle-container .items-container {
    padding: 30px 15px;
  }
  .w-digital-page .why-breaker .row .col-4 {
    width: 100%;
  }
  .w-digital-page .story-container .row {
    overflow: auto;
    margin-bottom: 30px;
  }
  .w-digital-page .metrics-container .items-container .item-rgt li:before {
    display: none;
  }
  .w-digital-page .it-hr-container {
    height: 450px;
  }
  .w-digital-page .it-hr-container .content {
    font-size: 32px;
  }
  .w-digital-page .it-hr-container .striker {
    top: 170px;
    left: 20px;
  }
  .w-digital-page .it-hr-container figure {
    width: 350px;
  }
  .w-digital-page .hidden-container .digital-process-content-wrapper {
    box-sizing: border-box;
    padding: 15px;
  }
  .w-digital-page .hidden-container,
  .w-digital-page .within-metrics .items-container {
    padding: 30px 15px;
  }
  .w-digital-page .hidden-container .diagram figure {
    width: 100%;
  }
  .w-digital-page .hidden-container .items-container {
    display: flex;
    flex-wrap: wrap;
  }
  .w-digital-page .hidden-container .items-container .item-block {
    width: 100%;
    margin: 20px 0 0 0;
  }
  .w-digital-page .inside-outside-metrics {
    padding: 30px 0;
  }
  .w-digital-page .within-metrics .items-container .item {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .w-digital-page .pdf-download .row .col-2 {
    width: 50%;
  }
  .w-digital-page .action-plan .col-4 {
    margin: 0 0 15px 0;
  }

  .cx-page .bg-image-wrapper img {
    transform: scale(3) translateX(-40px);
  }
  .cx-page .breaker-container .bg img {
    display: none;
  }
  .cx-page .breaker-container .item {
    margin-bottom: 10px;
  }
  .cx-page .cx-leader-container h2 {
    margin: 0;
  }
  .cx-page .cx-leader-container .bg img {
    transform: scale(3.2);
  }
  .cx-page .cx-quality-container figure {
    width: 100%;
  }
  .cx-page .cx-head-container .items-container .item {
    margin: 0 0 20px 0;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
    font-size: 18px;
  }
  .cx-page .cx-head-container .items-container .item:hover {
    transform: none;
  }
  .cx-page .cx-head-container .items-container .item .number,
  .cx-page .cx-head-container .items-container .item .heading {
    width: 100%;
    margin: 0;
    text-transform: uppercase;
    line-height: normal;
  }
  .cx-page .cx-head-container .items-container .item ul.pointers {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    text-align: left;
    list-style: outside;
    padding-left: 40px;
    border-top: 1px solid #eee;
  }
  .cx-page .cx-head-container .items-container .item ul.sub-pointers {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    text-align: left;
    list-style: outside;
    padding-left: 40px;
    border-top: 1px solid #eee;
  }
  .cx-page .cx-head-container .items-container .item .heading {
    justify-content: center;
    font-size: 22px;
  }
  .cx-page .pressure-container figure {
    width: 100%;
  }
  .cx-page .realtime-capability-container .row {
    padding: 0;
  }
  .cx-page .realtime-cx-container .diagram .left-note {
    width: 100%;
    top: 0;
  }
  .cx-page .realtime-cx-container .diagram figure {
    width: 100%;
  }
  .cx-page .realtime-cx-container .diagram .footer-note {
    width: 100%;
  }
  .cx-page .future-tech-container .item {
    margin: 10px 0;
  }
  .cx-page .questions-container .items-container .item {
    width: 100%;
  }
  .cx-page .future-tech-container .items-container {
    padding-bottom: 30px;
  }
  .cx-page .cx-perception-container .item {
    margin-bottom: 30px;
  }

  .brand-page .top-hero-product-banner figure {
    padding: 0;
  }
  .brand-page .how-to-use .row,
  .brand-page .logo-architecture .row .logo figure {
    padding-top: 30px;
  }
  .brand-page .how-to-use .row figure,
  .brand-page .how-not-to-use .row figure {
    width: 100%;
  }

  .webinar-page .image-title-layout .product-hero-wrapper {
    height: 300px;
  }
  .webinar-page .top-hero-product-banner figure {
    padding: 0;
    height: 300px;
  }
  .webinar-page .webinars-wrapper .filters select {
    margin: 0 0 10px 0;
    display: block;
  }
  .webinar-page .webinars-wrapper ul li {
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #ccc;
  }
  .webinar-page .webinars-wrapper .webinar-detail {
    width: 100%;
  }
  .webinar-page .webinars-wrapper .webinar-detail .desc ul li {
    border: none;
  }
  .webinar-page .webinars-wrapper .col-4 {
    width: 100%;
  }

  .customer360-page .image-title-layout .bg-image-wrapper img {
    transform: scale(2.2) translateX(-25px);
  }
  .customer360-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 22px;
  }
  .customer360-page .retailer-challenge .items-container {
    margin: 0;
  }
  .customer360-page .retailer-challenge .items-container .diagram {
    width: 100%;
    margin-top: 20px;
  }
  .customer360-page .retailer-challenge .items-container .top-content {
    width: 100%;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
    margin-top: 30px;
  }
  .customer360-page .retailer-challenge .items-container .left-content1 {
    width: 100%;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    margin-top: 30px;
  }
  .customer360-page .retailer-challenge .items-container .left-content2 {
    left: 0;
    width: 100%;
    top: 0;
    position: relative;
    margin-top: 30px;
  }
  .customer360-page .retailer-challenge .items-container .right-content1 {
    width: 100%;
    top: 0;
    right: 0;
    margin-top: 30px;
    position: relative;
  }
  .customer360-page .retailer-challenge .items-container .right-content2 {
    width: 100%;
    top: 0;
    right: 0;
    margin-top: 30px;
    position: relative;
  }
  .customer360-page .retailer-challenge .items-container .note {
    margin-top: 30px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .customer360-page .airline-challenge .items-container .item {
    width: 100%;
  }
  .customer360-page .airline-challenge .items-container .item h3 {
    height: 30px;
  }
  .customer360-page .university-challenge figure {
    width: 100%;
    margin: 0;
  }
  .customer360-page .breaker-quote h2 {
    font-size: 22px;
  }
  .customer360-page .breaker-quote p {
    font-size: 16px;
  }
  .customer360-page .breaker-quote .col-8 {
    padding: 30px 0;
  }
  .customer360-page .breaker-quote .bg-banner img {
    transform: scale(2.5);
  }
  .customer360-page .key-issues .items-container {
    flex-wrap: wrap;
  }
  .customer360-page .key-issues a {
    width: 200px;
    height: 200px;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .customer360-page .key-issues figure {
    width: 100%;
    margin: 30px 0 0 0;
  }
  .customer360-page .engagement-hub .items-container {
    margin: 0;
  }
  .customer360-page .col-7 {
    padding: 30px;
  }
  .customer360-page .breaker-container p {
    font-size: 1em;
  }
  .customer360-page .engagement-hub .diagram {
    width: 100%;
    margin: 30px 0 0 0;
  }

  .ci-page .image-title-layout .bg-image-wrapper img {
    transform: scale(2.2) translateX(-25px);
  }
  .ci-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 22px;
  }
  .ci-page .key-issues .items-container {
    flex-wrap: wrap;
  }
  .ci-page .key-issues a {
    width: 200px;
    height: 200px;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .ci-page .key-issues figure {
    width: 100%;
    margin: 30px 0 0 0;
  }
  .ci-page .virgin-train .items-container .col-4 {
    display: block;
  }
  .ci-page .virgin-train .solution,
  .ci-page .virgin-train .result {
    width: 100%;
  }
  .ci-page .virgin-train .bg-banner img {
    display: none;
  }
  .ci-page .virgin-train .col-7 figure {
    margin-bottom: 30px;
  }
  .ci-page .virgin-train .col-7 .note1,
  .ci-page .virgin-train .col-7 .note2,
  .ci-page .virgin-train .col-7 .note3 {
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .ci-page .virgin-train .col-7 .note1:after,
  .ci-page .virgin-train .col-7 .note2::after,
  .ci-page .virgin-train .col-7 .note3::after {
    display: none;
  }
  .ci-page .virgin-train .col-7 .note4,
  .ci-page .virgin-train .col-7 .note5,
  .ci-page .virgin-train .col-7 .note6 {
    right: 0;
    top: 0;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }
  .ci-page .virgin-train .col-7 .note4::after,
  .ci-page .virgin-train .col-7 .note5::after,
  .ci-page .virgin-train .col-7 .note6::after {
    display: none;
  }
  .ci-page .customer-moment,
  .ci-page .cust-moment {
    display: none;
  }
  .ci-page .cust-moment .items {
    padding: 0 15px;
  }
  .ci-page .cust-moment .items .item {
    flex-direction: column;
    margin: 0;
  }
  .ci-page .cust-moment .items .item .col-5 {
    width: 100%;
    margin-bottom: 20px;
  }
  .ci-page .cust-moment .items figure,
  .ci-page .increase-capability figure {
    width: 100%;
  }
  .ci-page .cust-moment .row .col-6:last-child .items .item {
    flex-direction: column;
  }
  .ci-page .ci-mainstream .items-container {
    margin: 0;
  }
  .ci-page .ci-mainstream .items-container .item {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .ci-page .breaker-quote h2 {
    font-size: 22px;
  }
  .ci-page .breaker-quote p {
    font-size: 16px;
  }
  .ci-page .breaker-quote .bg-banner img {
    transform: scale(2.2);
  }

  .ceh-page .image-title-layout .bg-image-wrapper img {
    transform: scale(2.2) translateX(-25px);
  }
  .ceh-page .image-title-layout .product-hero-wrapper h3 {
    font-size: 22px;
  }
  .ceh-page .key-issues .items-container {
    flex-wrap: wrap;
  }
  .ceh-page .key-issues a {
    width: 200px;
    height: 200px;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .ceh-page .key-issues figure {
    width: 100%;
    margin: 30px 0 0 0;
  }
  .ceh-page .start .items-container .item figure {
    width: 25%;
  }
  .ceh-page .lead-hub figure img {
    box-sizing: border-box;
    margin-top: 20px;
  }
  .ceh-page .business-ceh .item {
    margin: 0 0 20px 0;
  }
  .ceh-page .stock .row {
    padding-top: 20px;
    overflow: auto;
  }
  .ceh-page .develop-roadmap .items-container {
    display: block;
  }
  .ceh-page .develop-roadmap .item,
  .ceh-page .enable-technology .row .content {
    width: 100%;
  }
  .ceh-page .develop-roadmap .item.animate-arrow-wrapper {
    display: none;
  }
  .ceh-page .enable-technology .row .table {
    width: 100%;
    padding: 0;
    overflow: auto;
  }
  .ceh-page .enable-technology .row div:nth-child(3) {
    order: unset;
  }
  .ceh-page .recommendation {
    padding: 30px 0;
  }
  .ceh-page .recommendation .row {
    padding-top: 0;
  }
  .ceh-page .recommendation ul {
    display: block;
    flex-wrap: unset;
    justify-content: unset;
  }
  .ceh-page .recommendation li {
    width: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
  }

  .business-page .bg-image-wrapper img {
    transform: scale(1.8);
  }
  .business-page .business-container .item {
    padding: 15px 0;
    border-right: 0;
    margin: 0;
    text-align: center;
  }
  .business-page .video-wrapper {
    padding: 30px 0;
  }
  .business-page .video-wrapper .page-wrap .col-5 iframe {
    width: 100%;
    height: 300px;
  }
  .business-page .business4-container .row .item {
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
  .business-page .emp-exp-container .row {
    flex-direction: column-reverse;
  }
  .business-page .emp-exp-container .row img {
    width: 100%;
    margin-bottom: 20px;
  }
  .business-page .breaker-container .bg-banner img {
    transform: scale(2.5);
  }
  .business-page .breaker-container .col-7 {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .business-page .business {
    padding: 30px 0;
  }
  .business-page .invincible-model .row .item .circle {
    margin: 0;
  }
  .business-page .business .row .logo-container {
    margin-top: 20px;
  }
  .business-page .business .row .logo-container ul li {
    width: 50%;
  }
  .business-page .business .row .footer-block p {
    min-height: auto;
  }
  .business-page .business .row .top-block ul li {
    height: 100%;
  }
  .business-page .business.right .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .business-page .xonomy-bootstrap figure {
    height: 200px;
  }

  .sales-page ul.crm {
    display: none;
  }
  .sales-page .product-hero-wrapper {
    height: auto;
  }
  .sales-page .product-hero-wrapper .item-wrapper {
    padding: 0;
  }
  .sales-page .product-hero-wrapper .title {
    font-weight: 500;
    border: none;
    padding: 0;
  }
  .sales-page .sales-pipeline figure {
    width: 100%;
  }
  .sales-page
    .stack-model
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .item
    h3 {
    font-size: 22px;
  }
  .sales-page
    .stack-model
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .content-block
    ul
    li {
    width: 100%;
  }
  .sales-page .stack-model .digital-program-wrapper a {
    margin-top: 30px;
  }
  .sales-page .succeedant-container .row .col-5 {
    display: flex;
    flex-direction: column;
  }
  .sales-page .succeedant-container a {
    margin: 20px 0;
  }
  .sales-page .mastermind .row {
    flex-direction: column;
    width: 100%;
  }
  .sales-page .mastermind .right-fixed-logo {
    display: none;
  }
  .sales-page .mastermind .row .left-content {
    font-size: 26px;
    text-align: center;
    margin-bottom: 30px;
  }
  .sales-page .mastermind .row .col-5,
  .sales-page .mastermind .row .col-5 figure,
  .sales-page .virtuos-advantage figure {
    width: 100%;
  }
  .sales-page .crm-xyz-container,
  .sales-page .cpq-container {
    padding: 30px 0;
  }
  .sales-page .sales-training figure {
    width: 100%;
  }
  .sales-page .sales-training .left-note,
  .sales-page .sales-training .right-note {
    width: 100%;
    border-bottom: 1px dotted #ccc;
    padding: 30px 0;
  }
  .sales-page .sales-training .bottom-note {
    margin: 0;
    padding: 30px 0 0 0;
  }
  .sales-page .virtuos-advantage .row ul {
    flex-wrap: wrap;
  }
  .sales-page .virtuos-advantage .row ul li {
    width: 100%;
  }

  .service-page .product-hero-wrapper {
    height: auto;
  }
  .service-page .product-hero-wrapper .item-wrapper {
    padding: 0;
  }
  .service-page .product-hero-wrapper .title {
    font-weight: 500;
    border: none;
    padding: 0;
  }
  .service-page .partner-container .item {
    padding: 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-right: none;
  }
  .service-page .partner-container .item:last-child {
    margin-bottom: 0;
  }
  .service-page .partner-container .item h2 {
    min-height: auto;
  }
  .service-page .broad-spectrum .row,
  .service-page .four-pillars .row {
    padding-top: 0;
  }
  .service-page .support-breaker .row {
    flex-direction: column;
  }

  .marketing-page ul.crm {
    display: none;
  }
  .marketing-page .product-hero-wrapper {
    height: auto;
  }
  .marketing-page .product-hero-wrapper .item-wrapper {
    padding: 0;
  }
  .marketing-page .product-hero-wrapper .title {
    font-weight: 500;
    border: none;
    padding: 0;
  }
  .marketing-page .tech-imp figure {
    width: 100%;
  }
  .marketing-page .healthy-marketing ul {
    flex-wrap: wrap;
  }
  .marketing-page .healthy-marketing ul li {
    width: 35%;
  }
  .marketing-page .core-compo .top-block ul li {
    width: 40%;
    margin: 0 0 20px 0;
  }
  .marketing-page .core-compo .bottom-block {
    padding: 15px;
  }
  .marketing-page .core-compo .bottom-block ul li {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .marketing-page .strategy-breaker .row {
    flex-direction: column;
  }
  .marketing-page .strategy-breaker .row .left-content {
    width: 100%;
    padding: 0;
  }
  .marketing-page .strategy-breaker .row .col-7 {
    width: 100%;
    margin-top: 30px;
  }
  .marketing-page .strategy-breaker .row .col-7 figure,
  .marketing-page .strategy-breaker .row .col-7 figure {
    width: 100%;
  }
  .marketing-page .update-strategy .left-block {
    width: 100%;
    font-size: 36px;
    padding-right: 0;
    border-right: 0;
    text-align: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .marketing-page .update-strategy .right-block {
    padding-left: 0;
    width: 100%;
  }
  .marketing-page .update-strategy .right-block ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .marketing-page .update-strategy .right-block ul li {
    box-shadow: 0 0 10px #ddd;
    margin: 0 0 15px 0;
    width: 100%;
    padding: 10px;
  }
  .marketing-page .update-strategy .row a {
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
  }
  .marketing-page .relationware ul li {
    margin: 15px;
  }
  .marketing-page .relationware .row .col-12:last-child ul li:nth-child(1),
  .marketing-page .relationware .row .col-12:last-child ul li:nth-child(2),
  .marketing-page .relationware .row .col-12:last-child ul li:nth-child(3),
  .marketing-page .relationware .row .col-12:last-child ul li:nth-child(4) {
    padding: 15px;
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
  }
  .marketing-page .strategy-framework .row .col-4 {
    margin: 10px 0;
    width: 100%;
  }
  .marketing-page .abm-container .row {
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .marketing-page .abm-container .row .left-block {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px dotted #ccc;
  }
  .marketing-page .abm-container figure,
  .marketing-page .abm-container figure img {
    width: 100%;
    margin: 0;
  }
  .marketing-page .abm-container .row .col-12,
  .marketing-page .abm-container .row .right-block,
  .marketing-page .expertise-category figure {
    width: 100%;
  }
  .marketing-page .expertise-category {
    height: auto;
  }
  .marketing-page .expertise-category .tl-note,
  .marketing-page .expertise-category .tr-note,
  .marketing-page .expertise-category .bl-note,
  .marketing-page .expertise-category .br-note {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: 100%;
    border-bottom: 1px dotted #ccc;
    padding: 15px;
  }
  .marketing-page .expertise-category .br-note {
    border-bottom: none;
  }
  .marketing-page .relationware .row .col-12:last-child {
    padding-top: 30px;
  }
  .marketing-page .recommendation .row .left-block,
  .marketing-page .recommendation .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }

  .commerce-page ul.crm {
    display: none;
  }
  .commerce-page .product-hero-wrapper {
    height: auto;
  }
  .commerce-page .product-hero-wrapper .item-wrapper,
  .commerce-page .digital-business {
    padding: 0;
  }
  .commerce-page .product-hero-wrapper .title {
    font-weight: 500;
    border: none;
    padding: 0;
  }
  .commerce-page .future-commerce .items-container {
    flex-direction: column;
  }
  .commerce-page .future-commerce .items-container .left-block,
  .commerce-page .future-commerce .items-container .mid-block,
  .commerce-page .future-commerce .items-container .right-block {
    width: 100%;
  }
  .commerce-page .c-digital figure {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .commerce-page .magento figure {
    width: 100%;
    margin: 30px auto;
  }
  .commerce-page .digital-business .left-block,
  .commerce-page .digital-business .right-block {
    padding: 30px;
    border: 1px solid #ddd;
    text-align: center;
    margin-bottom: 30px;
  }
  .commerce-page .digital-business .left-block figure,
  .commerce-page .digital-business .right-block figure {
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .commerce-page .breaker-container .row .col-3 {
    width: 100%;
    box-sizing: border-box;
  }
  .commerce-page .dati-container .top-block .col-9 {
    align-items: center;
    padding: 10px 0;
    text-align: center;
  }
  .commerce-page .dati-container .top-block .col-3 {
    padding: 15px 0;
    border: none;
  }
  .commerce-page .trusted-partner .row .left-block {
    width: 100%;
    box-sizing: border-box;
  }
  .commerce-page .trusted-partner .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }

  .it-page .bg-image-wrapper img {
    width: auto;
    transform: scale(1);
  }
  .it-page .product-hero-wrapper {
    height: 650px;
  }
  .it-page .enterprise {
    margin-top: 250px;
  }
  .it-page .product-hero-wrapper ul {
    flex-direction: column;
  }
  .it-page .enterprise ul li {
    width: 100%;
    margin-bottom: 10px;
  }
  .it-page .enterprise ul li .hover {
    bottom: 0;
  }
  .it-page .technology .row ul li {
    width: 100%;
    margin-bottom: 10px;
  }
  .it-page .technology .row ul li .left {
    display: none;
  }
  .it-page .technology .row ul li .right {
    padding: 20px;
  }
  .it-page .technology .row ul li figure {
    width: 100%;
  }
  .it-page .vivacis-container .subtitle {
    margin: 30px 0;
  }
  .it-page .vivacis-container ul li {
    width: 40%;
  }
  .it-page .vivacis-container ul li figure {
    width: 75%;
  }
  .it-page .vivacis-container .col-12 a {
    margin-top: 30px;
  }
  .it-page .fabindia-container .left-block figure {
    width: 150px;
  }
  .it-page .fabindia-container p {
    font-size: 20px;
  }
  .it-page .fabindia-container .right-block img {
    width: 85px;
  }
  .it-page .fabindia-container .bg-banner img {
    transform: scale(3.2);
  }
  .it-page .digital-engineering .row.points .item {
    width: 100%;
    box-sizing: border-box;
  }
  .it-page .digital-engineering .platform .col-5 {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  .it-page .digital-engineering .platform .col-6 {
    width: 100%;
    margin-top: 30px;
    padding: 15px;
    box-sizing: border-box;
  }
  .it-page .cloud-container {
    background: #0f1934 url("../img/svg/cloud-bg.svg") no-repeat 0 102%;
  }
  .it-page .cloud-container .items-container .item,
  .it-page .rightapps-container .items-container .item {
    width: 100%;
    margin-bottom: 10px;
  }
  .it-page .breaker-container .content-block {
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .it-page .breaker-container .bg-banner img {
    transform: scale(3);
  }
  .it-page .mastermind ul {
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-around;
  }
  .it-page .mastermind ul li figure {
    width: 100px;
    margin: 15px;
  }

  .ex-page .top-hero-product-banner,
  .ex-page .top-hero-product-banner .product-hero-wrapper {
    height: auto;
    text-align: center;
  }
  .ex-page .top-hero-product-banner .row .mid-block {
    width: 100%;
  }
  .ex-page .employee-exp .left-container {
    margin-bottom: 30px;
  }
  .ex-page .gartner .items-container,
  .ex-page .worthy .right-block {
    flex-wrap: wrap;
  }
  .ex-page .gartner .items-container .item,
  .ex-page .worthy .right-block .item {
    margin: 0;
    width: 100%;
  }
  .ex-page .worthy .left-block .icon {
    display: none;
  }
  .ex-page .breaker-container .row {
    text-align: center;
  }
  .ex-page .breaker-container .left-block {
    padding: 0;
    margin: 0 0 15px 0;
  }
  .ex-page .breaker-container .bg-banner img {
    display: none;
  }
  .ex-page .investing-container figure {
    width: 100%;
    margin: 30px auto;
  }
  .ex-page .ex-practice figure {
    width: 100%;
  }
  .ex-page .mastermind .row .links ul {
    display: flex;
    flex-direction: column;
  }
  .ex-page .mastermind .row .links ul li {
    width: 100%;
    text-align: center;
    padding: 5px;
  }

  .vivacis-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .vivacis-page .vivacis-container .right-block .item {
    width: 100%;
  }
  .vivacis-page .agiloft-container .logos img {
    width: 100px !important;
  }
  .vivacis-page .agiloft-container .logos ul li:last-child img {
    width: 50px !important;
  }

  .vivacis-page .v-tabs-container .v-tabs-wrapper {
    width: 100%;
  }
  .vivacis-page .v-tabs-container .v-tabs-wrapper ul {
    flex-wrap: wrap;
  }
  .vivacis-page .v-tabs-container .v-tabs-wrapper .item {
    width: 100%;
    text-align: left;
  }
  .vivacis-page .v-tabs-container .vertical-tab-content-wrapper {
    width: 100%;
  }
  .vivacis-page
    .v-tabs-container
    .vertical-tab-content-wrapper
    .vertical-tab-content
    .item {
    padding: 0;
  }
  .vivacis-page .clm-projects-container {
    padding: 30px 0 0;
  }
  .vivacis-page .clm-projects-container .item,
  .vivacis-page .services {
    padding: 0;
  }
  .vivacis-page .clm-projects-container .item .footer {
    width: 100%;
    position: relative;
  }
  .vivacis-page .clm-breaker .left-block figure {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    margin-bottom: 30px;
  }
  .vivacis-page .clm-breaker .left-block figure img {
    width: 100%;
  }
  .vivacis-page .clm-breaker .mid-block {
    margin-bottom: 30px;
  }
  .vivacis-page .creatio-container .logos img,
  .vivacis-page .netsuite-container .logos img,
  .vivacis-page .automation-container .logos img {
    width: 100px;
  }
  .vivacis-page .netsuite-container .row.erp .item .content-block {
    position: relative;
  }
  .vivacis-page .netsuite-container .row.industry {
    margin-top: 30px;
  }
  .vivacis-page .netsuite-container .row.industry .item {
    width: 100%;
    margin: 0 0 30px 0;
  }

  .cwide-page .faces-container .img-block .showcase {
    background: rgba(255, 255, 255, 0.6);
  }
  .cwide-page .faces-container .content-block {
    padding-left: 0;
  }
  .cwide-page .faces-container .row.right {
    display: flex;
    flex-direction: column;
  }
  .cwide-page .faces-container .row.right .number {
    order: 2;
  }
  .cwide-page .faces-container .row.right .img-block {
    order: 1;
  }
  .cwide-page .faces-container .row.right .content-block {
    order: 3;
  }

  .creatio-page .top-hero-product-banner .item-wrapper {
    padding-right: 0;
    width: 100%;
  }
  .creatio-page .top-hero-product-banner figure.bg-image-wrapper {
    background-color: #8dc9ff;
  }
  .creatio-page .top-hero-product-banner figure.bg-image-wrapper img {
    display: none;
  }
  .creatio-page .product-hero-wrapper .item-wrapper span {
    font-size: 20px;
  }
  .creatio-page .product-hero-wrapper figure {
    top: 0;
  }
  .creatio-page .top-hero-product-banner .right-fixed-logo {
    display: none;
  }
  .creatio-page .product-hero-wrapper h3 {
    color: #fff;
  }
  /* .creatio-page .slide3 .product-hero-wrapper .right-fixed-logo, .creatio-page .slide3 .product-hero-wrapper .right-fixed-logo figure { width: 300px; } */
  .creatio-page .edge-container .row:last-child .col-6 {
    margin-bottom: 30px;
  }
  .creatio-page .why-creatio .row .item {
    width: 100%;
    margin-bottom: 30px;
  }
  .creatio-page .creatio-lead ul li {
    width: 145px;
  }
  .creatio-page .pdf-download .row {
    text-align: center;
  }
  .creatio-page .pdf-download .row figure {
    margin: 0 auto;
    width: 25%;
    margin-top: 30px;
  }
  .creatio-page .creatio-container .logos img {
    width: 100px;
  }
  .creatio-page .h-tabs-container .vertical-tab-content-wrapper .content-block {
    padding: 30px;
  }
  .creatio-page .advantage .row .left-block {
    width: 100%;
  }
  .creatio-page .advantage .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .creatio-page .advantage .row .right-block .title1,
  .creatio-page .advantage .row .right-block .title2 {
    text-align: center;
  }
  .creatio-page .trust-indicator ul {
    flex-direction: column;
  }
  .creatio-page .testimonials {
    display: none;
  }

  .xlv-page .mastermind .right-block {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
    box-sizing: border-box;
  }
  .xlv-page .cev-container figure {
    width: 100%;
  }
  .xlv-page .cev-container .value,
  .xlv-page .cev-container .quote {
    padding: 15px;
  }
  .xlv-page .cev-container .value .item {
    margin-bottom: 30px;
  }
  .xlv-page .two-col-section .item {
    padding: 15px;
    border: none;
    margin: 0;
  }
  .xlv-page .factors-wrapper .hero-factors .row:last-child,
  .xlv-page .two-col-section .item:last-child {
    border: none;
  }
  .xlv-page .hub-cev .item {
    margin: 0 0 30px 0;
  }
  .xlv-page .board-actions .row .left-block {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .xlv-page .board-actions .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .xlv-page .board-actions .row .right-block .title2 {
    text-align: center;
  }

  .exp-crowd-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(3.5);
  }
  .exp-crowd-page .product-hero-wrapper {
    height: auto;
  }
  .exp-crowd-page .product-hero-wrapper .col-5 figure {
    width: 150px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .exp-crowd-page .exp-business .right-block {
    margin-bottom: 30px;
    width: 100%;
  }
  .exp-crowd-page .exp-business .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .exp-crowd-page .exp-business .row:last-child {
    display: flex;
    flex-direction: column-reverse;
  }
  .exp-crowd-page .real-crowd .items-container .item {
    padding: 15px;
    border: 1px solid #eee;
    margin-bottom: 15px;
  }
  .exp-crowd-page .community-crowd figure,
  .exp-crowd-page .community-crowd .foundation figure {
    width: 100%;
  }
  .exp-crowd-page .community-crowd .table {
    overflow: auto;
  }
  .exp-crowd-page .opencoder-container .row .col-4,
  .exp-crowd-page .opencoder-container .row .item {
    width: 100%;
  }
  .exp-crowd-page .customernow-container .mobile figure,
  .exp-crowd-page .customernow-container .mobile figure img {
    width: 100%;
  }
  .exp-crowd-page .dati-container .row:last-child {
    display: flex;
    flex-direction: column-reverse;
  }
  .exp-crowd-page .democrate-container .row .left-block {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .exp-crowd-page .democrate-container .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .exp-crowd-page .democrate-container .row .right-block .title1 {
    text-align: right;
  }

  .oracle-page .header-container::before {
    border-radius: 0;
  }
  .oracle-page .header-container {
    width: 100%;
    height: auto;
    padding: 100px 30px 30px 30px;
    box-sizing: border-box;
  }
  .oracle-page .header-container .row .col-5 {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .oracle-page .header-container .right-fixed-logo {
    width: 250px;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .oracle-page .duo-container .row figure {
    width: 100%;
    margin-bottom: 30px;
  }
  .oracle-page .duo-container .row:last-child {
    display: flex;
    flex-direction: column-reverse;
  }
  .oracle-page .header-container .right-block .item {
    margin-right: 0 !important;
  }
  .oracle-page .vedam-oracle .section-desc {
    flex-direction: column-reverse;
  }
  .oracle-page .vedam-oracle .section-desc .left {
    text-align: center;
    width: 100%;
    margin: 15px 0 0 0;
    border-right: none;
    padding-right: 0;
  }
  .oracle-page .vedam-oracle .section-desc .logo {
    width: 50%;
  }
  .oracle-page .vedam-oracle .row.services .item .content-block {
    font-size: 26px;
  }
  .oracle-page .vedam-oracle .row.services .item {
    margin: 0 0 15px 0;
  }
  .oracle-page .vedam-oracle .row.oracle-products .item,
  .oracle-page .chatbot-container .items-container .item {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }
  .oracle-page .stories-container h2,
  .oracle-page .stories-container .section-desc {
    color: #333;
  }
  .oracle-page .stories-container .item {
    position: relative;
    margin: 0 0 30px 0;
    display: block;
  }
  .oracle-page .chatbot-container .items-container .item {
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid #eee !important;
    margin-bottom: 15px;
  }
  .oracle-page .chatbot-container .items-container a {
    margin-top: 15px;
    width: 100%;
  }
  .oracle-page .stories-container .item .txt {
    background: #e55844;
    color: #fff;
  }
  .oracle-page .stories-container figure.bg {
    display: none;
  }
  .oracle-page .customers .left-block {
    width: 100%;
    box-sizing: border-box;
  }
  .oracle-page .customers .right-block {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .oracle-page .customers .right-block ul,
  .oracle-page .tabs-container .tab {
    flex-wrap: wrap;
  }
  .oracle-page .customers .right-block ul li {
    width: 30%;
    margin: 0 20px;
    margin-bottom: 30px;
  }
  .oracle-page .tabs-container .tab a {
    padding: 10px;
    margin: 0 10px;
    width: 35%;
  }
  .oracle-page .tabs-container .tabcontent .box2,
  .oracle-page .tabs-container .tabcontent .box3 {
    width: 100%;
    margin-right: 0;
  }
  .oracle-page .industry-container .header {
    margin-bottom: 30px;
  }
  .oracle-page .industry-container .row .item {
    margin: 0 0 30px 0;
  }
  .oracle-page .industry-container .front .overlay {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    color: #fff;
    top: 0;
  }
  .oracle-page .industry-container .front:hover .overlay {
    height: auto;
    transition: none;
  }
  .oracle-page .industry-container .front .overlay a {
    color: #ff0000;
  }

  .microsoft-page .header-container {
    height: auto;
  }
  .microsoft-page .header-container .row {
    text-align: center;
    padding: 150px 0 0;
  }
  .microsoft-page .header-container .right-fixed-logo {
    width: 200px;
    top: 50px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .microsoft-page .header-container figure {
    padding: 10px;
    margin: 30px 15px 15px 15px;
  }
  .microsoft-page .dynamics365 figure {
    margin-top: 30px;
  }
  .microsoft-page .dynamics365 .row:last-child {
    display: flex;
    flex-direction: column-reverse;
  }
  .microsoft-page .cloudle-powerapps figure img {
    width: 100%;
    transform: scale(1.6);
  }
  .microsoft-page .cloudle-powerapps .right-logo ul li img {
    width: 100px;
  }
  .microsoft-page .cloudle-powerapps .right-logo ul li:last-child img {
    width: 40px !important;
  }
  .microsoft-page .cloudle-powerapps .right-block ul {
    margin-top: 0;
    padding-top: 30px;
  }
  .microsoft-page .cloudle-powerapps .right-block ul li {
    width: 45%;
  }
  .microsoft-page .industry-container {
    margin: 30px 0 0 0;
    padding: 30px 0 0;
  }
  .microsoft-page .industry-container .header {
    margin-bottom: 30px;
  }
  .microsoft-page .industry-container .row .item {
    margin: 0 0 30px 0;
  }
  .microsoft-page .industry-container .front .overlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
    color: #fff;
  }
  .microsoft-page .industry-container .front .overlay a {
    color: #ff0000;
  }
  .microsoft-page .tabs-container .tab {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .microsoft-page .tabs-container .tab a {
    padding: 0;
    margin: 0 10px 15px 10px;
    width: 40%;
  }
  .microsoft-page .tabs-container .tabcontent .box2,
  .microsoft-page .tabs-container .tabcontent .box3 {
    width: 100%;
    margin: 0;
    padding: 30px;
  }
  .microsoft-page .next-step-container figure {
    width: 100%;
  }
  .microsoft-page .consulting .row {
    padding: 0;
  }
  .microsoft-page .consulting .row:last-child {
    margin-top: 30px;
  }

  .agiloft-page .canvas {
    overflow-x: unset;
  }
  .agiloft-page .header-container .header-block {
    padding: 120px 40px 30px;
    text-align: center;
  }
  .agiloft-page .header-container {
    height: 450px;
  }
  .agiloft-page .header-container figure img {
    transform: none;
  }
  .agiloft-page .header-container .right-fixed-logo figure {
    width: 200px;
    top: 30px;
    right: 0;
    left: 0;
  }
  .agiloft-page .vivacis-agiloft .col-6 {
    margin: 0;
  }
  .agiloft-page .vivacis-agiloft .item2 .content {
    height: auto;
  }
  .agiloft-page .top-rated .left-block {
    margin-bottom: 30px;
  }
  .agiloft-page .vivacis-agiloft .item3 .content {
    padding: 15px;
  }
  .agiloft-page .vivacis-agiloft .item3 .content .social {
    top: 15px;
    left: 15px;
  }
  .agiloft-page .vivacis-agiloft .item3 .content .tag {
    top: 15px;
    right: 15px;
  }
  .agiloft-page .stories-container .item {
    margin: 0 0 10px 0;
    display: block;
  }
  .agiloft-page .stories-container .highlights .item {
    display: flex;
    margin-bottom: 20px;
  }
  .agiloft-page .clm-implement .banner-left,
  .agiloft-page .clm-implement .banner-right {
    margin: 10px 0 0 0;
  }
  .agiloft-page .buy-clm .right-block {
    margin-top: 30px;
  }

  .cloud-page .header-container .right-fixed-logo {
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .cloud-page .header-container h1 {
    font-size: 42px;
  }
  .cloud-page .header-container .subtitle {
    font-size: 26px;
  }
  .cloud-page .header-container figure,
  .cloud-page .opportunities .row figure,
  .cloud-page .opportunities .item,
  .cloud-page .cloud-container .items-container .item {
    width: 100%;
  }
  .cloud-page .header-container {
    height: auto;
    padding: 100px 0 30px 0;
  }
  .cloud-page .opportunities .row .item figure {
    width: 75px;
  }
  .cloud-page .opportunities .row:last-child {
    margin-top: 0;
  }
  .cloud-page .public-cloud .row .col-5 {
    width: 100%;
    margin-bottom: 30px;
  }
  .cloud-page .cloud-container {
    padding: 30px 0 0;
    background: #0f1934;
  }
  .cloud-page .cloud-container .right-fixed-logo {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .cloud-page .cloud-container .items-container .mid-block {
    display: none;
  }
  .cloud-page .cloud-agility .item {
    padding: 0;
    margin-bottom: 30px;
  }
  .cloud-page .power-cloud .row .left-block {
    width: 100%;
  }
  .cloud-page .power-cloud .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .cloud-page .power-cloud .row .right-block .title2 {
    text-align: center;
  }

  .io-page .product-hero-wrapper {
    height: 300px;
  }
  .io-page .product-hero-wrapper .item-wrapper {
    padding-top: 0;
    width: 100%;
  }
  .io-page .top-hero-product-banner .item-wrapper figure {
    padding: 0;
    width: 200px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .io-page .io-container .row .item {
    width: 100%;
  }
  .io-page .io-container .row a {
    margin-top: 30px;
  }
  .io-page .invincible-company figure {
    width: 100%;
  }
  .io-page .invincible-company .explore-exploit .col-4 {
    width: 100%;
    box-sizing: border-box;
  }
  .io-page .invincible-company .compare li:nth-child(2) {
    font-size: 16px;
  }
  .io-page .invincible-company .compare a {
    margin-top: 30px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
  .io-page .breaker-container .row {
    padding: 15px;
  }
  .io-page .breaker-container .bg-banner img {
    display: none;
  }
  .io-page .breaker-container .col-7 {
    margin-bottom: 30px;
  }
  .io-page .breaker-container .col-4 {
    justify-content: center;
  }
  .io-page .dynamic-funding figure {
    width: 100%;
  }
  .io-page .dynamic-funding .arrow {
    width: 25px;
    margin: 30px auto;
    display: block;
  }
  .io-page .stages-container .left-block {
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .io-page .stages-container .left-block .arrow2 {
    width: 100%;
    margin: 0;
    padding: 0;
    order: 0;
  }
  .io-page .stages-container .left-block li:nth-child(1) {
    order: 2;
  }
  .io-page .stages-container .left-block li:nth-child(2) {
    order: 3;
  }
  .io-page .stages-container .left-block li:nth-child(3) {
    order: 5;
  }
  .io-page .stages-container .left-block .arrow1 {
    width: 100%;
    order: 4;
  }
  .io-page .stages-container .left-block li {
    width: 100%;
    padding: 0;
  }
  .io-page .stages-container .row .links-block {
    margin-top: 30px;
  }
  .io-page .stages-container .row .links-block a {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 10px 0;
  }
  .io-page .stages-container {
    padding: 0;
  }
  .io-page .business-innovation .items-container .item {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .io-page .business-innovation figure {
    width: 100%;
    text-align: center;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .io-page .business-innovation .row.mid .arrow-block figure img {
    width: 100px;
    margin: 20px 0;
  }
  .io-page .business-innovation .row.link a {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
  }
  .io-page .recommendation .row .left-block,
  .io-page .recommendation .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .io-page .recommendation .row .right-block .title1,
  .io-page .recommendation .row .right-block .title2 {
    text-align: center;
  }
  .io-page .navigating-scaling .pitfall-container.mid {
    flex-direction: column-reverse;
    display: flex;
  }
  .io-page .business-innovation .top .item {
    margin: 0 0 30px 0;
  }
  .io-page .business-innovation .top .item .content-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
  }
  .io-page .business-innovation .top .item .content-container figure {
    width: 100px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .io-page .business-innovation .top .item .content-container .txt {
    text-align: center;
  }
  .io-page .business-innovation .row .diagram-block figure {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .io-page .recommendation .row .left-block .points-block {
    flex-wrap: wrap;
  }
  .io-page .recommendation .row .left-block .points-block ul {
    width: 50%;
    margin-bottom: 30px;
  }

  .odigital-page .header-container .header-block {
    padding: 40px 20px;
  }
  .odigital-page .header-container .header-block .title {
    width: 75%;
  }
  .odigital-page .header-container .right-fixed-logo figure {
    width: 72px;
  }
  .odigital-page .header-container figure {
    height: 250px;
  }
  .odigital-page .spotlight-container .barc {
    margin-right: 0;
  }
  .odigital-page .spotlight-container .hightlight-block {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .odigital-page .numbers-container .item {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .odigital-page .xyz-video-container {
    padding: 60px 0;
    margin-bottom: 20px;
  }
  .odigital-page .crm-container .item {
    margin: 0 0 20px 0;
  }
  .odigital-page .crm-container .usp {
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .odigital-page .crm-container .usp .item {
    padding: 0 0 20px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
  }
  .odigital-page .odigital-breaker .item-container {
    flex-direction: column;
  }
  .odigital-page .odigital-breaker .item-container .links-wrapper {
    display: none;
  }
  .odigital-page .odigital-breaker .item-container .top-logo {
    font-size: 22px;
  }
  .odigital-page .odigital-breaker .item-container .left-logo {
    width: 100%;
    padding-right: 0;
  }
  .odigital-page .odigital-breaker .item-container .left-logo figure {
    width: 30%;
  }
  .odigital-page .odigital-breaker .item-container .title {
    font-size: 26px;
    border: none;
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
  .odigital-page .odigital-breaker .bg-image {
    display: none;
  }
  .odigital-page
    .optimize-cx
    .h-tabs-container
    .vertical-tab-content-wrapper
    .content-block
    .col-6,
  .odigital-page
    .optimize-ex
    .h-tabs-container
    .vertical-tab-content-wrapper
    .content-block
    .col-6 {
    padding: 0;
    margin-bottom: 30px;
    border: none;
  }
  .odigital-page .optimize-cx .h-tabs-container .h-tabs-wrapper .item {
    text-align: center;
  }
  .odigital-page .services-container .item-wrapper {
    width: 100%;
    display: block;
    box-sizing: border-box;
  }
  .odigital-page .section-breaker .content-wrapper {
    padding: 30px 0;
  }
  .odigital-page .section-breaker figure img {
    transform: scale(1.5);
  }
  .odigital-page .plans-table .item-wrapper {
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .succeedant-page .breaker figure img {
    width: 100%;
  }
  .succeedant-page .project-succeedant .row .left-block,
  .succeedant-page .project-succeedant .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }

  .covid-page .top-hero-product-banner {
    height: 400px;
  }
  .covid-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2.5);
  }
  .covid-page .transforming-container {
    padding: 30px 0;
  }
  .covid-page .covid-diagram figure {
    width: 90%;
    margin: 0 auto;
  }
  .covid-page .org-risk-container {
    padding: 0;
  }
  .covid-page .org-risk-container figure {
    width: 90%;
  }
  .covid-page .content-block {
    width: 300px;
  }
  .covid-page .pandemics-container {
    padding: 30px 0;
  }
  .covid-page .page-wrap.v-block {
    max-width: 300px;
    padding: 30px;
  }
  .covid-page .page-wrap.v-block .col-4 {
    margin-bottom: 10px;
  }
  .covid-page .page-wrap.v-block .col-4 figure {
    text-align: center;
  }
  .covid-page .page-wrap.v-block .col-4 figure img {
    width: auto;
    height: 200px;
  }
  .covid-page .org-risk-container .risk-diagram .points,
  .covid-page .org-risk-container figure {
    padding: 15px;
  }

  .invincible-page .product-hero-wrapper .note {
    width: 90%;
    text-align: center;
  }
  .invincible-page .top-hero-product-banner .right-fixed-logo {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .invincible-page .invincible-company figure,
  .invincible-page .business-model figure,
  .invincible-page .breaker-container .col-4,
  .invincible-page .dynamic-funding figure,
  .invincible-page .stages-container .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .invincible-page .invincible-company .explore-exploit .col-4 {
    width: 100%;
    box-sizing: border-box;
  }
  .invincible-page .invincible-company .compare li:nth-child(2) {
    font-size: 16px;
  }
  .invincible-page .invincible-company .compare li {
    line-height: normal;
  }
  .invincible-page .invincible-company .compare .statement-container p,
  .invincible-page .breaker-container p {
    font-size: 22px;
  }
  .invincible-page .stages-container .row {
    flex-direction: column;
  }
  .invincible-page .stages-container .left-block {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .invincible-page .stages-container .left-block .arrow1,
  .invincible-page .stages-container .left-block .arrow2 {
    display: none;
  }
  .invincible-page .stages-container .left-block li {
    width: 100%;
    padding: 0;
  }
  .invincible-page .navigating-scaling .pitfall-container.mid {
    display: flex;
  }
  .invincible-page .section-breaker {
    padding-bottom: 50px;
  }
  .invincible-page .section-breaker.exp-container .content-wrapper {
    padding: 30px 0;
  }
  .invincible-page .page-wrap.v-block {
    background-color: transparent;
    padding: 30px;
    text-align: center;
  }
  .invincible-page .page-wrap.v-block .col-4 {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .agiloft-page .stories-container .item .txt {
    min-height: auto;
  }
  .o-digital-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .agnitor-page .breaker-container .left-block {
    padding: 30px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .agnitor-page .breaker-container .right-block {
    padding: 30px;
  }
  .agnitor-page .new-model-container .item {
    padding: 15px;
    margin: 0 0 15px 0;
    width: 100%;
    box-sizing: border-box;
  }
  .agnitor-page .four-blocks-container .items-container .item {
    width: 100%;
  }
  .agile-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2.5) translateX(0);
  }
  .agile-page .product-hero-wrapper figure {
    margin: 0 auto;
    width: 40%;
    padding: 0;
    box-sizing: border-box;
    height: auto;
  }
  .agile-page .agile-container .row.comp {
    padding: 30px 15px;
  }
  .agile-page .agile-container .row table td {
    padding: 5px;
  }
  .agile-page .agile-container .row.comp ul li {
    line-height: normal;
    margin-bottom: 10px;
  }
  .agile-page .agile-breaker .row {
    text-align: center;
  }
  .agile-page .agile-container .row.comp h3 {
    text-align: center;
    line-height: normal;
  }
  .agile-page .agile-container .row.comp .col-5 figure {
    margin-bottom: 15px;
  }
  .agile-page .all-agile .row.agile figure {
    margin: 0;
  }
  .agile-page .agile-container,
  .agile-page .all-agile,
  .agile-page .agile-journey,
  .agile-page .hr-role,
  .agile-page .finance-role,
  .agile-page .enterprise-leader,
  .agile-page .align-agile {
    padding: 15px 0;
  }
  .agile-page .agile-breaker figure img {
    margin-bottom: 30px;
  }
  .agile-page .hr-breaker {
    padding: 30px 0;
    font-size: 24px;
  }
  .agile-page .hr-breaker .row .col-4 {
    padding: 15px;
  }
  .agile-page .hr-breaker .row figure {
    width: 50px;
    margin: 15px auto;
  }
  .agile-page .hr-breaker .row figure img {
    transform: rotate(90deg);
  }
  .agile-page .hr-role .row .header,
  .agile-page .finance-role .row .header,
  .agile-page .procurement-role .row .header {
    margin: 30px 0 0 0;
  }
  .agile-page .hr-role .row .item,
  .agile-page .finance-role .row .item,
  .agile-page .procurement-role .row .item {
    padding: 0;
  }
  .agile-page .agile-journey figure {
    width: 100%;
    margin: 30px auto;
  }
  .agile-page .enterprise-leader .row figure {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }
  .agile-page .align-agile .item {
    margin: 15px 0;
  }

  .business-page .invincible-company figure {
    width: 100%;
  }
  .business-page .invincible-model .row .left-block,
  .business-page .invincible-model .row .right-block {
    width: 100%;
  }
  .business-page .invincible-model .row .item {
    flex-direction: column;
    box-sizing: border-box;
  }
  .business-page .invincible-model .row .item .content-container {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
  .business-page .breaker-container .row .col-3 {
    width: 100%;
  }
  .business-page .breaker-container figure {
    height: 250px;
  }
  .business-page .business .row .arrow-container figure {
    width: 100%;
  }

  .b4-page .header-slider-container ul {
    display: block;
  }
  .b4-page .header-slider-container ul li,
  .b4-page .header-slider-container ul li div a {
    height: 25%;
  }
  .b4-page .header-slider-container ul li:nth-child(2) div a figure img {
    padding: 0;
  }
  .b4-page .header-slider-container ul li div a figure {
    width: 70px;
  }
  .b4-page .business-model .row {
    padding: 15px;
  }
  .b4-page .global4-container .heading-big,
  .b4-page .digital4-container .heading-big,
  .b4-page .society4-container .heading-big,
  .b4-page .industry4-container .heading-big {
    display: none;
  }
  .b4-page .global4-container h2,
  .b4-page .industry4-container h2,
  .digital4-container h2,
  .b4-page .society4-container h2 {
    display: block;
    text-align: center;
  }
  .b4-page .resources .logos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .b4-page .resources .logos-container .item {
    width: 45%;
    padding: 5px;
  }
  .b4-page .resources .logos-container p {
    bottom: 0;
  }
  .b4-page .industry4-container,
  .b4-page .industry4-container .diagram-block {
    margin-top: 30px;
  }
  .b4-page .industry4-container .row,
  .b4-page .society4-container .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .b4-page .industry4-container .diagram-block figure {
    width: 100%;
    margin: 0;
  }
  .b4-page .agile-breaker {
    text-align: center;
  }
  .b4-page .digital4-container,
  .b4-page .society4-container {
    margin-top: 60px;
  }
  .b4-page .section-breaker .content-wrapper,
  .b4-page .memorable {
    padding: 30px 0;
  }
  .b4-page .section-breaker figure img {
    transform: scale(1.2);
  }
  .b4-page .society4-container .diagrams-block,
  .b4-page .memorable .row,
  .b4-page .new-economy .diagram {
    padding: 15px;
    margin-top: 30px;
  }
  .b4-page .b4-strategy-container .row ul {
    flex-direction: column-reverse;
  }
  .b4-page .b4-strategy-container .row ul li {
    width: 100%;
  }
  .b4-page .memorable .row figure {
    width: 100%;
    margin-bottom: 30px;
  }
  .b4-page .new-economy .row .col-3 {
    width: 100%;
    text-align: center;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .b4-page .new-economy .row .col-3 img {
    transform: rotate(90deg);
    width: 100px;
  }

  .agilenterprise-page .header-container .row {
    text-align: center;
  }
  .agilenterprise-page .blocks-container .diagram figure {
    width: 100%;
  }
  .agilenterprise-page .key-strategy .item {
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 15px 0;
  }
  .agilenterprise-page .scale-model .item {
    padding: 0;
    margin: 30px 0 0 0;
  }
  .agilenterprise-page .header-container h2 {
    font-size: 28px;
  }

  .agilenterprise-page .scale-model .img-container .row {
    align-items: center;
    padding: 0;
  }
  .agilenterprise-page .scale-model .img-container h3 {
    font-size: 22px;
    line-height: normal;
    padding-top: 15px;
  }
  .agilenterprise-page .scale-model .img-container .left-block img {
    margin-bottom: 15px;
  }
  .agilenterprise-page .strategy-container .row .item {
    margin: 0 0 15px 0;
  }
  .agilenterprise-page .three-steps .row .item {
    margin: 0 0 30px 0;
  }
  .agilenterprise-page .three-steps .row .item h3 {
    font-size: 22px;
  }
  .agilenterprise-page .three-steps .row .item p {
    height: auto;
  }
  .agilenterprise-page .covid-reset .row .diagram {
    margin-bottom: 15px;
  }

  .agilenterprise-page .emp-cust .logo,
  .agilenterprise-page .emp-cust .row h3 {
    margin-bottom: 15px;
  }
  .agilenterprise-page .key-strategy figure {
    width: 100%;
    margin: 30px auto;
  }
  .agilenterprise-page .covid-reset .block2 .item {
    margin: 0 0 15px 0;
  }
  .agilenterprise-page .emp-cust .row .mid-block figure {
    width: 100%;
    padding: 60px;
    text-align: center;
    box-sizing: border-box;
  }
  .agilenterprise-page .emp-cust .row .mid-block figure img {
    transform: rotate(90deg);
    width: 100px;
  }
  .agilenterprise-page .emp-cust .steps-block .item {
    margin: 0 0 15px 0;
  }

  .careers-page
    .image-hoverable-wrapper
    .hoverable-content-wrapper
    .hoverable-tab-content
    img {
    width: 100%;
  }
  .degree360-page .image-title-layout .product-hero-wrapper .logos {
    justify-content: center;
  }
  .culture-page .culture {
    padding-top: 30px;
  }
  .experience-job .content-wrapper .color-highlight {
    font-size: 36px;
  }
  .block-over-image-section.iq
    .block-over-image-wrapper
    .content-wrapper:hover {
    margin-right: 20px;
  }
  .happiests-culture-page .two-col-section .item {
    padding: 15px;
  }
  .happiests-culture-page .two-col-section ul {
    flex-wrap: wrap;
  }
  .happiests-culture-page .two-col-section ul li {
    padding: 0;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  .happiests-culture-page .two-col-section ul li:last-child {
    border: none;
  }
  .happiests-culture-page .two-col-section ul li figure {
    width: 200px;
  }
  .careers-job-page .product-hero-wrapper figure {
    padding: 0;
    height: auto;
  }
  .job-openings-section .job-item-wrapper {
    height: 300px;
  }
  .customer360-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .ci-page .product-hero-wrapper {
    padding: 0;
  }
  .ci-page .product-hero-wrapper .row .item-wrapper {
    width: 85%;
    margin: 0 auto;
  }
  .ceh-page .image-title-layout .product-hero-wrapper {
    height: 100%;
  }
  .ceh-page .product-hero-wrapper .item-wrapper {
    width: 100%;
    padding: 0;
  }
  .ceh-page .product-hero-wrapper figure {
    height: 100%;
  }
  .cwide-page .bg-image-wrapper img {
    transform: translateX(-200px);
  }
  .dragon-page .bg-image-wrapper img {
    transform: translateX(-200px);
  }
  .dragon-page .keys .items-container {
    display: flex;
    margin: 0;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .dragon-page .keys a {
    margin-bottom: 30px;
  }
  .dragon-page .keys,
  .dragon-page .digital-industry,
  .dragon-page .digital-dragon,
  .dragon-page .digital-exp,
  .dragon-page .digital-exp,
  .dragon-page .attitude,
  .dragon-page .dragon-deck,
  .dragon-page .audi-container,
  .dragon-page .digital-dragons,
  .dragon-page .peril-container,
  .dragon-page .recommendation {
    padding: 30px 0;
  }
  .dragon-page .digital-industry .row {
    padding-top: 30px;
  }
  .dragon-page .digital-industry .row .col-7 {
    text-align: center;
    border-left: none;
    margin-top: 30px;
  }
  .dragon-page .digital-exp .digital-program-wrapper {
    padding-top: 0;
  }
  .dragon-page .dragon-partner .row figure,
  .dragon-page .attitude .row figure,
  .dragon-page .dragon-deck .row figure,
  .dragon-page .digital-dragons .row figure,
  .dragon-page .dragon-breaker .row figure,
  .dragon-page .peril-container .row figure {
    width: 100%;
  }
  .dragon-page .audi-container .row .col-8-half {
    margin-top: 0;
  }
  .dragon-page .audi-container .row .col-3 {
    width: 100%;
    box-sizing: border-box;
    margin-top: 30px;
  }
  .dragon-page .dragon-breaker {
    overflow: hidden;
    position: relative;
    padding: 30px 0;
    margin-top: 0;
  }
  .dragon-page .peril-container .row .col-12 {
    overflow: auto;
  }
  .dragon-page .peril-container .row h3 {
    width: 100%;
    margin: 0;
    padding: 15px;
    line-height: normal;
    box-sizing: border-box;
  }
  .dragon-page .recommendation .row .left-block {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
  }
  .dragon-page .recommendation .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .dragon-page .recommendation .row .right-block .title2 {
    text-align: center;
  }
  .wdigital-page .image-title-layout .product-hero-wrapper {
    height: 550px;
  }
  .wdigital-page .image-title-layout .product-hero-wrapper .item-wrapper h1 {
    font-size: 42px;
  }
  .wdigital-page .top-hero-product-banner .right-fixed-logo {
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50px;
  }
  .wdigital-page .image-title-layout .product-hero-wrapper .row {
    margin-top: 150px;
  }
  .wdigital-page .top-hero-product-banner .bg-image-wrapper img {
    transform: translateX(-280px);
  }
  .wdigital-page .image-title-layout .product-hero-wrapper .item-wrapper {
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    padding: 15px;
    box-sizing: border-box;
  }
  .wdigital-page .wall-breaker {
    background: #eee;
  }
  .wdigital-page .project-portfolio .row {
    width: 100%;
  }
  .wdigital-page .modern-workplace,
  .wdigital-page .radical-work,
  .wdigital-page .project-portfolio,
  .wdigital-page .strategy,
  .wdigital-page .radical-portfolio,
  .wdigital-page .persona-container,
  .wdigital-page .longterm-view {
    padding: 30px 0;
  }
  .wdigital-page .strategy .row .item {
    width: 100%;
    padding: 0;
  }
  .wdigital-page .vardei-breaker .row .logos {
    flex-direction: column;
  }
  .wdigital-page .vardei-breaker {
    padding: 30px;
    background-position: 70%;
    text-align: center;
  }
  .wdigital-page .persona-container .row .item {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .wdigital-page .persona-container .row .item ul {
    height: auto;
    padding-bottom: 60px;
  }
  .wdigital-page .longterm-view .row figure {
    width: 100%;
  }
  .service-page ul.crm {
    display: none;
  }
  .service-page .mega-trends .row .item {
    width: 100%;
    box-sizing: border-box;
  }
  .service-page .broad-spectrum .row ul li {
    width: 50%;
  }
  .service-page .broad-spectrum .row ul {
    margin-bottom: 30px;
  }
  .service-page .service-maturity ul {
    flex-wrap: wrap;
  }
  .service-page .service-maturity li {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .service-page .service-maturity .links {
    display: flex;
  }
  .service-page .service-maturity .links .item {
    width: 100%;
    margin: 0;
  }
  .service-page .crm-cx-framework ul {
    flex-wrap: wrap;
  }
  .service-page .crm-cx-framework li {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .service-page .recommendation .row .left-block {
    width: 100%;
    box-sizing: border-box;
  }
  .service-page .recommendation .row .right-block {
    width: 100%;
    box-sizing: border-box;
  }
  .service-page .recommendation .row .right-block .title2 {
    text-align: center;
  }
  .marketing-page .core-compo .bottom-block-left ul li,
  .marketing-page .core-compo .bottom-block-right ul li {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .cx-page .cx-view-container .col-2:first-child {
    justify-content: center;
  }
  .cx-page .cx-roadmap-container .long-item {
    width: 70%;
  }
  .cx-page .cx-roadmap-container .small-item {
    width: 23%;
  }
  .cx-page .cx-roadmap-container .items-container .item-block {
    padding: 25px;
  }
  .privacy-policy-page .top-hero-product-banner .product-hero-wrapper figure,
  .safe-harbour-page .top-hero-product-banner .product-hero-wrapper figure {
    height: 300px;
  }
  .vertical-section .full-screen-wrapper .right-logo-block {
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .vertical-section .center-wrapper ul.links-container {
    flex-wrap: wrap;
  }
  .vertical-section .center-wrapper ul.links-container li {
    border-right: none;
    padding: 0 15px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
  .talentare-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2.5) translateX(-110px);
  }
  .talentare-page .block-over-image-section .content-wrapper:hover,
  .talentare-page .block-over-image-wrapper.ej .content-wrapper:hover {
    margin-left: 20px;
    position: relative;
    z-index: 10;
  }
  .blogs-section .article-wrapper:hover {
    background: none;
  }
  .related-offerings .item .icon {
    width: 100px;
  }
  .application-page .product-hero-wrapper .item-wrapper {
    display: none;
  }
  .experience-design-page .about-team-wrap {
    border: none;
    padding: 0;
  }
  .plus-page .product-hero-wrapper {
    height: 300px;
  }
  .plus-page .product-hero-wrapper .col-7 figure {
    display: none;
  }
  .plus-page .product-hero-wrapper .item-wrapper {
    padding-top: 0;
  }
  .plus-page .product-hero-wrapper .item-wrapper figure {
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .platform-ecosystem-page .related-offerings {
    padding-bottom: 30px;
  }
  .professional-services-page .product-hero-wrapper span img {
    width: 50px;
  }
  .professional-services-page .product-hero-wrapper span img:last-child {
    border-right: none;
    margin: 0;
    padding: 0;
  }
  .professional-services-page .application-services ul li span {
    width: 120px;
  }
  .professional-services-page .consultare-services .row .item .icon figure {
    width: 50%;
    margin: 0 auto;
  }
  .technology-services-page .product-hero-wrapper figure {
    height: auto;
  }
  .succeedant-page
    .top-hero-product-banner
    .product-hero-wrapper
    .row
    .item-wrapper
    figure {
    width: 200px;
  }
  .succeedant-page .customer-success-section .diagram-container {
    box-sizing: border-box;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .customer-success-section .row .quote {
    margin-bottom: 30px;
  }
  .customer-success-section .abc-container .abc-block .item {
    width: 100%;
  }
  .succeedant-page .foundation-container {
    padding: 0;
  }
  .succeedant-page .foundation-container ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .succeedant-page .foundation-container ul li {
    width: 100%;
    text-align: center;
  }
  .succeedant-page .foundation-container ul li:nth-child(even) {
    width: 100%;
    text-align: center;
    position: relative;
    height: 100px;
  }
  .succeedant-page .hierarchy-container .row figure {
    width: 100%;
  }
  .succeedant-page .succeedant-framework {
    padding: 0;
  }
  .succeedant-page .succeedant-framework .row .left-block {
    margin-bottom: 30px;
  }
  .about-page .story-wrapper.exp-business .item figure {
    order: 0;
  }
  .story-wrapper.exp-business .item img {
    transform: none;
  }
  .about-page .about-testimonials {
    height: 400px;
  }
  .hcm-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .hcm-page .product-hero-wrapper .page-wrap .header-img {
    display: none;
  }
  .hcm-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2.5) translateX(-100px);
  }
  .hcm-page .hrtech-container figure {
    width: 100%;
  }
  .hcm-page .hcm-container .items-block .item figure {
    width: 100%;
    height: auto;
  }
  .hcm-page .two-col-full-section.cloudle .cloudle-wrapper .illus {
    padding: 30px 0;
  }
  .hcm-page .two-col-full-section.cloudle .cloudle-wrapper h3 {
    font-size: 22px;
    text-align: center;
  }
  .hcm-page .two-col-full-section.cloudle .bg-banner img {
    transform: scale(4);
  }
  .hcm-page .ejourney-container figure {
    width: 100%;
  }
  .hcm-page .digital-process-tabs-wrapper {
    display: block;
  }
  .hcm-page .digital-process-tabs-wrapper ul {
    flex-wrap: wrap;
  }
  .hcm-page .digital-process-tabs-wrapper .item {
    margin: 0;
  }
  .hcm-page .ta-container .diagram-block figure.logo {
    margin: 0 auto;
  }
  .hcm-page .hcm-offerings-container .sub-heading {
    flex-direction: column-reverse;
    text-align: center;
  }
  .hcm-page .seven-c-wrapper {
    padding: 0 0 30px 0;
  }
  .hcm-page .seven-c-wrapper .right-fixed-logo {
    width: 100px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 60px;
    right: auto;
  }
  .cx-strategy-page .digital-process-tabs-wrapper {
    display: block;
  }
  .cx-marries-digital-page .top-hero-product-banner {
    height: 300px;
  }
  .cx-marries-digital-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2);
  }
  .cx-marries-digital-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .cx-marries-digital-page .product-hero-wrapper .item-wrapper figure {
    width: 150px;
    margin: 0 auto;
  }
  .cx-marries-digital-page .cx-digital-marketing .row .left-block,
  .cx-marries-digital-page .cx-digital-marketing .row .right-block {
    width: 100%;
    text-align: center;
  }
  .cx-marries-digital-page .cx-marketing .row figure {
    width: 100%;
  }
  .cx-desk-page .top-hero-product-banner .right-fixed-logo {
    transform: none;
    height: auto;
    top: 30px;
    width: 150px;
  }
  .cx-desk-page .top-hero-product-banner .right-fixed-logo img {
    width: 100%;
    height: auto;
  }
  .cx-desk-page .cx-view-container .col-2:first-child {
    justify-content: center;
  }
  .cx-unified-page .product-hero-wrapper {
    height: 300px;
  }
  .cx-unified-page .exp-business .value-wrapper {
    padding: 30px 0;
  }
  .cx-unified-page .cr-wrapper .row.page-wrap {
    padding: 30px 15px 0 15px;
    display: flex;
    flex-direction: column-reverse;
  }
  .cx-unified-page .cr-wrapper .row.page-wrap .col-4,
  .cx-unified-page .cr-wrapper .row.page-wrap .col-4 img {
    width: 100%;
  }
  .cx-unified-page .top-hero-product-banner .right-fixed-logo {
    right: 0;
  }
  .cx-desk-page .two-col-fig-content-wrapper .page-wrap.row {
    height: 100%;
  }
  .odigital-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .odigital-page .product-hero-wrapper .item-wrapper figure {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }
  .odigital-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2.5);
  }
  .odigital-page .crm-container .item .title,
  .odigital-page .crm-container .item p {
    min-height: auto;
  }
  .odigital-page .odigital-breaker .item-container .top-logo {
    width: 70%;
  }
  .odigital-page .plans-table {
    padding: 30px 0;
  }
  .odigital-page .odigital-breaker .item-container .title br {
    display: none;
  }
  .orc-page .about-wrapper .laptop-block {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .orc-page .solutions-container .item .icon {
    width: 50px;
    height: 50px;
  }
  .oracle-netsuite-page .header-container .page-wrap .col-4 {
    width: 100%;
    text-align: center;
  }
  .oracle-netsuite-page .header-container {
    height: 400px;
  }
  .oracle-netsuite-page .header-container .header-bg {
    background-position: 80%;
  }
  .oracle-netsuite-page .header-container .right-fixed-logo {
    width: 250px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50px;
  }
  .oracle-netsuite-page .erp-practice .item {
    width: 100%;
  }
  .oracle-netsuite-page .suite-success ul,
  .oracle-netsuite-page .netsuite-container .logos ul {
    flex-direction: column;
  }
  .oracle-netsuite-page .suite-success .row h2 {
    font-size: 22px;
  }
  .oracle-netsuite-page .suite-success figure.bg img {
    transform: scale(4.5);
  }
  .oracle-netsuite-page .netsuite-container .row.erp .item .content-block {
    position: relative;
  }
  .oracle-netsuite-page .tabs-container .tab {
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  .oracle-netsuite-page .tabs-container .tab a {
    padding: 15px;
    margin: 0 0 15px 0;
    width: 40%;
  }
  .oracle-netsuite-page .tabs-container .tabcontent .box1,
  .oracle-netsuite-page .tabs-container .tabcontent .box2,
  .oracle-netsuite-page .tabs-container .tabcontent .box3 {
    width: 100%;
  }
  .oracle-netsuite-page .erp-cx-container .item figure {
    height: 500px;
  }
  .oracle-netsuite-page .erp-cx-container .item .content-container .title {
    font-size: 22px;
  }
  .oracle-netsuite-page .erp-cx-container .item figure img {
    transform: scale(4.5);
  }
  .oracle-netsuite-page .digital-transformation .row {
    flex-wrap: wrap;
    display: flex;
  }
  .oracle-netsuite-page .digital-transformation .col-2 {
    margin-right: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 50%;
  }
  .oracle-netsuite-page .resources .item {
    width: 100%;
    box-sizing: border-box;
  }
  .oracle-netsuite-page .resources .item h3 {
    font-size: 22px;
  }
  .rainbowcx-page .top-hero-product-banner .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .cvoice-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2.5);
  }
  .cvoice-page .product-hero-wrapper figure {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: auto;
  }
  .cvoice-page .advantages-of-aury .page-wrap .row .col-6 {
    padding: 0;
  }
  .cvoice-page .digital-process-tabs-wrapper {
    display: block;
  }
  .cvoice-page .digital-process-content-wrapper {
    box-sizing: border-box;
  }
  .cvoice-page .digital-process-section .content-block .item {
    display: flex;
    flex-direction: column;
  }
  .cxaudit-page .gen .points li {
    font-size: 16px;
    padding: 10px;
  }
  .cx-continuum-page .header-block .top-logo {
    width: 70%;
  }
  .cx-continuum-page .consultare-services .item .invisible-wrapper {
    display: none;
  }
  .cxopia-page
    .digital-process-content-wrapper
    .digital-steps-wrapper
    .content-wrap {
    padding: 30px;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block .title {
    width: 100%;
    color: #fff;
  }
  .cxprimer-page .product-hero-wrapper .cx-primer-block .title br {
    display: none;
  }
  .cjourney-page .product-hero-wrapper figure {
    padding: 0;
    width: 100%;
  }
  .cxpyramid-page .pyramid-container .cx-pyramid figure {
    width: 100%;
  }
  .cxpyramid-page .full-width-container .triangle-bg .triangle {
    width: 100%;
  }
  .cxpyramid-page .triangle-container {
    padding: 0 0 60px 0;
  }
  .cxpyramid-page .recom-container .item-container .item {
    margin: 0 15px;
  }
  .cjourney-page .product-hero-wrapper .item-wrapper figure {
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .cxunity-page .cxunity-container blockquote {
    width: 75%;
  }
  .cxunity-page .rcit-container .item {
    margin: 0 0 30px 0;
  }
  .o-digital-page .header-block .top-logo {
    width: 80%;
  }
  .o-digital-page .header-block .left-logo figure {
    width: 70%;
  }
  .ex-page .top-hero-product-banner .row .left-block {
    width: 100%;
    margin-bottom: 30px;
  }
  .ex-page .ex-view-container .col-2:first-child {
    justify-content: center;
  }
  .cx-ex-page .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .cx-ex-page .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(2.5);
  }
  .cx-ex-page .two-col-full-section.cloudle .cloudle-wrapper {
    padding: 30px 30px 0 30px;
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .cx-ex-page .two-col-full-section.cloudle .bg-banner img {
    display: none;
  }
  .cx-ex-page .personas-container .items-container .item-block {
    width: 100%;
  }
  .cx-ex-page .theory-container .item {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  .cx-ex-page .theory-container .col-12 figure {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .cx-ex-page .emp-exp-container .emp-exp {
    width: 100%;
    margin: 15px 0 30px 0;
  }
  .cx-ex-page .key-factors-container .items-container .item {
    margin: 0 auto 15px auto;
    width: 90%;
    box-sizing: border-box;
  }
  .cx-ex-page .key-factors-container .emp-exp {
    width: 100%;
    margin: 15px auto;
  }
  .cx-ex-page .block-over-image-section .content-wrapper {
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
  .cx-ex-page .block-over-image-section .content-wrapper:hover {
    margin-left: 0;
  }
  .cx-ex-page .block-over-image-section .content-wrapper.pink:hover {
    margin-left: 0;
  }
  .cx-ex-page .relationship-container .items-block .col-1 figure {
    width: 100px;
  }
  .cx-ex-page .relationship-container .items-block .col-1 figure img {
    transform: rotate(90deg);
  }
  .cx-digital-page.w-digital-page
    .vertical-section
    .full-screen-wrapper
    .right-logo-block
    figure {
    width: 100px;
  }
  .budh-page .two-col-section .item h3 br {
    display: none;
  }
  .exp-crowd-page .democrate-container .row .right-block .title1,
  .exp-crowd-page .democrate-container .row .right-block .title2 {
    text-align: center;
  }
  .exp-crowd-page .dati-container .row figure {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .exp-crowd-page .dati-container .row:last-child .col-5,
  .exp-crowd-page .dati-container .row:last-child figure,
  .experienceo-page .ceo-job-container .row .left-block figure {
    width: 100%;
  }
  .experienceo-page .header-container {
    background-position: 70%;
  }
  .experienceo-page .ceo-job-container .row .left-block {
    margin-bottom: 30px;
  }
  .experienceo-page .ceo-job-container .row .right-block ul {
    width: 100%;
  }
  .experienceo-page .emp-exp .row.block2 .left-block {
    margin-bottom: 30px;
  }
  .experienceo-page .deal-container .diagram .left,
  .experienceo-page .deal-container .diagram .right,
  .experienceo-page .deal-container .diagram .bottom {
    width: 70px;
  }
  .experienceo-page .deal-container .diagram .left {
    top: 15%;
    left: 5%;
  }
  .experienceo-page .deal-container .diagram .right {
    top: 15%;
    right: 5%;
  }
  .indicial-page .advantages-of-aury .page-wrap .content-wrapper {
    padding: 15px;
  }
  .indicial-page .accordian-content .item-block {
    width: 100%;
  }
  .it-page .rightapps-container .items-container .mid-block {
    display: none;
  }
  .it-page .product-hero-wrapper .right-fixed-logo {
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
  }
  .dthinking-page .key-issues .items-container {
    flex-direction: column-reverse;
  }
  .dthinking-page .key-issues a {
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .dthinking-page .analysis-container figure,
  .dthinking-page .stages-container figure,
  .dthinking-page .integrates-container figure,
  .dthinking-page .doctor-technology .row figure,
  .dthinking-page .cx-map-container .row figure,
  .dthinking-page .ecosystem-container figure,
  .dthinking-page .iterate-container figure,
  .dthinking-page .build-container figure {
    width: 100%;
    margin: 15px auto 0 auto;
  }
  .dthinking-page .breaker-container .bg-banner img {
    transform: scale(3);
  }
  .dthinking-page .mindset-container .row,
  .dthinking-page .doctor-technology .row {
    margin-top: 0;
  }
  .dthinking-page .mindset-container .item {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
  .dthinking-page .doctor-day .col-4 {
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .dthinking-page .cx-map-container .row ul {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    box-sizing: border-box;
  }
  .dthinking-page .cx-map-container .row li {
    width: 100%;
    margin-bottom: 10px;
  }
  .dthinking-page .develop-container .row {
    margin-top: 0;
  }
  .dthinking-page .develop-container .row ul {
    margin-bottom: 30px;
  }
  .dthinking-page .recommendation li {
    width: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
  }
  .investors-page .top-hero-product-banner,
  .investors-page .top-hero-product-banner .product-hero-wrapper {
    height: 300px;
  }
  .investors-page .brand-breaker .row .mid {
    padding: 30px 0;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
  }
  .investors-page .culture-values-container .row {
    margin: 30px auto;
    text-align: center;
  }
  .investors-page .culture-values-container .row .item {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0 0 15px 0;
  }
  .investors-page .invincible-container .diagram .left {
    width: 70px;
    top: 32%;
    left: 8%;
  }
  .investors-page .invincible-container .diagram .right {
    width: 70px;
    top: 32%;
    right: 8%;
  }
  .investors-page .invincible-container .diagram .bottom {
    width: 70px;
  }
  .investors-page .showcase-news-cards-wrapper .item-wrapper {
    margin-right: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .invincible-page .breaker-container .bg-banner img {
    transform: scale(4.5);
  }
  .capabilities-section .page-wrap,
  .who-we-are .page-wrap {
    transform: none;
  }
  .consultare-page .offering-container .row .item {
    display: block;
    box-shadow: 0 0 10px #ddd;
    margin-bottom: 15px;
    text-align: center;
  }
  .consultare-page .offering-container .row .item figure {
    margin: 0 auto;
  }
  .consultare-page .offering-container .row .item small br {
    display: none;
  }
  .cx-digital-page
    .vertical-section
    .full-screen-wrapper
    .right-logo-block
    figure
    img {
    height: 100px;
  }
  .ventures-page .top-hero-product-banner,
  .ventures-page .top-hero-product-banner .product-hero-wrapper {
    height: 300px;
  }
  .ventures-page .exp-economy-section .row .right-section {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .ventures-page .exp-economy-section,
  .ventures-page .exp-business .item {
    padding: 0;
  }
  .ventures-page .io-i3-section .content {
    font-size: 24px;
  }
  .ventures-page .aury-section .banner img {
    transform: scale(2.5);
  }
  .clients-page .top-hero-product-banner .product-hero-wrapper .item-wrapper {
    width: 100%;
  }
  .clients-page .industry-main-wrapper .industry-form-wrapper .input-wrapper {
    width: 100%;
  }
  .clients-page .industry-main-wrapper .industry-form-wrapper {
    padding: 15px;
  }
  .clients-page
    .industry-product-wrapper
    .tab-content-wrapper
    .tab-content-wrap
    .solutions
    li {
    width: 45%;
    margin: 0;
    padding: 5px;
  }
  .clients-page .industry-product-wrapper .tab-content-wrapper {
    min-height: auto;
  }
  .clients-page .tabs-container1 .tab {
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  .clients-page .tabs-container1 .tabcontent .row {
    flex-wrap: wrap;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .clients-page .tabs-container1 .tabcontent .col-3 {
    margin: 0 10px;
    width: 42%;
  }
  .clients-page .tabs-container1 .tabcontent .col-6 {
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .clients-page .tabs-container1 .tab a {
    width: 100%;
    margin: 0;
  }
  .clients-page .tabs-container1 .tabs-wrapper .row {
    margin: 0 0 30px 0;
  }
  .clients-page .tabs-container1 {
    padding: 60px 0 0;
  }
  .clients-page .customer-success-section .clients-wrapper .row li {
    width: 100%;
  }
  .clients-page .group-container {
    padding: 0;
  }
  .clients-page .group-container .row {
    margin-top: 0;
  }
  .clients-page .group-container .row figure {
    width: 100%;
  }
  .industry-page
    .industry-product-wrapper
    .tab-content-wrapper
    .tab-content-wrap
    .resources
    li {
    width: 40%;
    margin: 0;
    padding: 5px;
  }
  .industry-page
    .industry-product-wrapper
    .tab-content-wrapper
    .tab-content-wrap
    .logos
    figure {
    width: 30%;
  }
  .industry-page .tabs-container1 .tab {
    width: 100%;
  }
  .industry-page .tabs-container1 .tabcontent .row .col-2 {
    margin: 0 0 30px 0;
  }
  .industry-page .tabs-container1 .tabcontent .row .col-3 {
    margin: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  .home-slider1-container .logos-bar {
    bottom: -50px;
    height: auto;
    padding: 15px;
  }
  .home-slider1-container .logos-bar ul {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .home-slider1-container .logos-bar ul li {
    width: 75px;
    margin: 5px 25px;
  }
  .home-slider1-container .logos-bar ul li span {
    line-height: 16px;
    font-size: 16px;
  }
  .home-page .services-container .item-wrapper .heading1 p {
    font-size: 24px;
    line-height: 26px;
  }
  .home-page .services-container .item-wrapper .heading2 p {
    font-size: 36px;
    line-height: 30px;
  }

  .top-hero-product-banner .bg-image-wrapper img {
    transform: scale(3);
  }
  .rainbow-page .cx-view-container .col-2:first-child {
    justify-content: center;
  }
  .rainbow-page .everything-container figure {
    width: 100%;
  }
  .rainbow-page .low-expectation ul li {
    width: 100%;
  }
  .rainbow-page .everything-mindset .row {
    flex-direction: column;
  }
  .rainbow-page .everything-mindset .row .col-4 {
    width: 100%;
  }
  .rainbow-page .breaker-slider li {
    flex-direction: column;
    height: 300px;
    margin: 0 !important;
  }
  .rainbow-page .breaker-slider li .left {
    width: 50%;
    margin-bottom: 15px;
  }
  .rainbow-page .everything-free .row .item {
    padding: 0;
  }
  .rainbow-page .digital-giants .row .item {
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  .rainbow-page .everything-customer .row .arrow-icon figure {
    transform: rotate(90deg);
  }
  .rainbow-page .product-hero-wrapper figure {
    height: 100%;
  }
  .rainbow-page .product-hero-wrapper .col-7 figure,
  .rainbow-page .product-hero-wrapper .col-6 figure {
    display: none;
  }
  .rainbow-page .product-hero-wrapper .item-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .rainbow-page .header-slider li:first-child figure {
    margin: 0 auto;
  }
  .rainbow-page .product-hero-wrapper p {
    margin: 0;
  }

  .home-slider1-container .slide1 .superpower-bg {
    width: 225%;
  }
  .cxprism-page .video-wrapper {
    padding: 30px 0;
  }
  .cxprism-page .video-wrapper .page-wrap .col-5 iframe {
    width: 100%;
    height: 300px;
  }

  .exp-new-page .product-hero-wrapper .item-wrapper,
  .exp-new-page .product-hero-wrapper .page-wrap .col-4 {
    width: 100%;
  }
  .exp-new-page .product-hero-wrapper figure.logo {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .exp-new-page .product-hero-wrapper ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .exp-new-page .product-hero-wrapper ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-bottom: 20px;
  }
  .exp-new-page .breaker .row {
    text-align: center;
  }
  .exp-new-page .breaker {
    padding: 30px 0 0 0;
  }
  .exp-new-page .trans-container .row .item {
    margin-bottom: 30px;
  }
  .exp-new-page .human-resi .row .col-5 {
    margin-bottom: 30px;
  }
  .exp-new-page .comp-container figure {
    width: 100%;
  }
  .exp-new-page .breaker figure img {
    width: 70%;
  }
  .exp-new-page .struggle-container .section-main-heading {
    text-align: center;
  }
  .exp-new-page .struggle-container .item {
    height: 250px;
    font-size: 1.5rem;
    width: 250px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .exp-new-page .struggle-container .support h3 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  .exp-new-page .breaker h3 {
    font-size: 1.5rem;
  }
  .exp-new-page .fusion-container figure,
  .exp-new-page .comp-tech-container figure,
  .exp-new-page .use-container figure {
    width: 100%;
  }
  .offices-region .section-heading {
    font-size: 20px;
  }

  .error-page .error-container {
    padding: 30px 0;
  }
  .error-page .error-container .row {
    flex-direction: column-reverse;
  }
  .error-page .error-container ul {
    width: 100%;
    margin: 0 0 20px 100px;
  }
  .error-page .error-container ul li {
    width: 100%;
  }
  .error-page .error-container h2 {
    font-size: 52px;
    margin: 30px;
  }
  .error-page .error-container figure img {
    width: 100%;
  }

  .vaves-page .slider-container {
    height: 300px;
  }
  .vaves-page .slider-container .slider-wrapper figure img {
    transform: scale(1.8);
  }
  .vaves-page .slider-container .slider-wrapper .title {
    font-size: 2em;
  }
  .vaves-page .cx-tech .row .col-6 {
    margin-top: 20px;
  }
  .vaves-page .digital-portfolio .item {
    box-sizing: border-box;
    padding: 20px;
    margin: 0 0 10px 0;
  }
  .vaves-page .cx-primer-container li {
    width: 100%;
  }
  .vaves-page .qualtrics-container .row .col-5 {
    padding: 20px;
    box-sizing: border-box;
  }
  .vaves-page .virtuoso-team {
    min-height: 250px;
  }
  .qualtrics-page .qualtrics-partner .content-container ul.points li {
    width: 100%;
  }

  .lab-page .product-hero-wrapper,
  .lab-page .bg-image-wrapper {
    height: 300px;
    background: #eee;
  }
  .lab-page .top-hero-product-banner .bg-image-wrapper img {
    display: none;
  }
  .lab-page .corp-lead {
    padding: 30px 0;
  }
  .lab-page .section-main-heading,
  .lab-page .quote h2 {
    font-size: 24px;
  }
  .lab-page .quote .col-12::before,
  .lab-page .quote .col-12::after {
    width: 50px;
    height: 50px;
  }
  .lab-page .quote .col-12 {
    padding: 30px;
  }
  .lab-page .key-issues .row {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .lab-page .key-issues .item {
    margin-bottom: 20px;
  }
  .lab-page .operating-model figure,
  .lab-page .building-blocks figure {
    width: 100%;
  }
  .lab-page .building-blocks .item {
    margin: 0;
  }
  .lab-page .quote h4 {
    font-size: 24px;
  }
  .lab-page .challenge-solution .item {
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px 0 0 0;
  }
  .lab-page .challenge-solution ul,
  .lab-page .challenge-solution .number.text {
    width: 100%;
  }
  .lab-page .challenge-solution .content {
    width: 100%;
    text-align: center;
  }
  .lab-page .challenge-solution .number.text {
    padding: 10px;
    height: auto;
  }
  .lab-page .sanity h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
}
